import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import DashboardWrapper from '../pages/dashboard/DashboardWrapper'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'
import {MarketingDashboardWrapper} from '../pages/marketing/MarketingWrapper'

import CalenderWrapper from '../pages/calender/CalenderWrapper'
import TaskList from '../pages/task-calendar/components/TaskList'
import MeetingsList from '../pages/meetings/MeetingList'
import SprintPage from '../pages/sprint/SprintPage'

//OLD PROJECT ROUTES
// import Project from '../pages/projects/Project'
// import ViewProject from '../pages/projects/ViewProject'
// import Target from '../pages/projects/Target'
// import Budget from '../pages/projects/Budget'
// import Users from '../pages/projects/Users'
// import Files from '../pages/projects/Files'
// import Activity from '../pages/projects/Activity'
// import Settings from '../pages/projects/Settings'
// import {Horizontal} from '../pages/projects/Horizontal'

import Products from '../pages/Inventory/ProductPage'
import CategoryPage from '../pages/Inventory/CategoryPage'
import {PMWrapper} from '../pages/pm/pmWrapper'
import {MultiStepSignup} from '../pages/userRegistration/RegistrationsWrapper'
import InventoryPage from '../pages/Inventory/InventoryPage'
import {StoreAnalytics} from '../pages/StoreAnalytics/StoreAnalytics'
// import EditProject from '../pages/projects/EditProject'
import HrmPage from '../pages/hrm/HrmPage'
import SalesPage from '../pages/sales/SalesPage'
import VendorPage from '../pages/purchaseOrders/VendorPage'
import ReportsPage from '../pages/reports/ReportsPage'
import AccountWrapper from '../pages/account/AccountWrapper'
import ProjectsPage from '../pages/projects/ProjectsPage'
import ItemsPage from '../pages/Inventory/Pages/ItemsPage'
import Maintenance from '../pages/maintenance/Maintenance'
import Vehicle from '../pages/maintenance/pages/Vehicle'
import Equipment from '../pages/maintenance/pages/Equipment'
import Maintenances from '../pages/maintenance/Maintenances'
import Parts from '../pages/maintenance/pages/Parts'
import InspectionPage from '../pages/maintenance/pages/InspectionPage'
import DashboardPage from '../pages/maintenance/components/DashboardPage'
import IssuesPage from '../pages/maintenance/pages/IssuesPage'
import ServicePage from '../pages/maintenance/pages/ServicePage'
import ServiceReminder from '../pages/maintenance/components/reminder/ServiceReminder'
import AddServiceReminder from '../pages/maintenance/components/reminder/AddServiceReminder'
import VehicleRenewal from '../pages/maintenance/components/reminder/VehicleRenewal'
import AddVehicleRenewal from '../pages/maintenance/components/reminder/AddVehicleRenewal'
import IndustryPage from '../pages/industry/IndustryPage'
import {useAuth} from '../modules/auth'
import {ProfileViewing} from '../pages/setting/ProfileViewing'

const PrivateRoutes = () => {
  const {currentUser} = useAuth()
  const DocsPage = lazy(() => import('../pages/documentation/DocsPage'))
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  const UsersPage = lazy(() => import('../pages/userManagement/UsersPage'))
  const UserManagementPage = lazy(() => import('../pages/user-management/UserManagementPage'))
  const Deparment = lazy(() => import('../pages/department/Deparment'))
  const ModulesPage = lazy(() => import('../pages/modules/ModulesPage'))
  const RoleManagementPage = lazy(() => import('../pages/role-management/RoleManagementPage'))
  const ProjectFeatureSetting = lazy(
    () => import('../pages/projects/components/ProjectFeatureSetting')
  )
  const InvoiceFeatureSetting = lazy(
    () => import('../pages/projects/components/InvoiceFeatureSetting')
  )
  const InventoryFeatureSetting = lazy(
    () => import('../pages/Inventory/Pages/InventoryFeatureSetting')
  )
  const EventPage = lazy(() => import('../pages/event/EventPage'))

  const OrganizationManagementPage = lazy(
    () => import('../pages/organization-management/OrganizationManagementPage')
  )
  const CodeWrapper = lazy(() => import('../pages/maintenance/components/system-codes/CodeWrapper'))
  const RepairCode = lazy(() => import('../pages/maintenance/components/system-codes/RepairCode'))
  const RepairPriorityCode = lazy(
    () => import('../pages/maintenance/components/system-codes/RepairPriorityCode')
  )
  const StoragePage = lazy(() => import('../pages/storage/StoragePage'))
  const Setting = lazy(() => import('../pages/setting/Setting'))
  const ColumnPricing = lazy(() => import('../pages/billingAndSubscription/pages/ColumnPricing'))
  const TablePricing = lazy(() => import('../pages/billingAndSubscription/pages/TablePricing'))
  const ProjectReportsRoute = lazy(() => import('../pages/projects/ProjectReportsPage')) // creation of inspection form inside app settings project
  const ReportsRoute = lazy(() => import('../pages/hrm/ReportsRoutes')) //creation of inspection form inside app settings
  const AttendanceType = lazy(() => import('../pages/hrm/Components/AttendanceType'))
  const AddAttendance = lazy(() => import('../pages/hrm/Components/AddAttendance'))
  const CustomizationPage = lazy(() => import('../pages/account_settings/CustomizationPage'))
  const SupportCenterPage = lazy(() => import('../pages/support-center/pages/SupportCenterPage'))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        <Route path='registration/*' element={<MultiStepSignup />} />

        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboards' />} />
        {/* Pages */}

        {/* <Route path='dashboards' element={<DashboardWrapper />} />
        <Route path='marketing/marketing1' element={<MarketingDashboardWrapper />} /> */}
        <Route path='marketing/marketing1' element={<DashboardWrapper />} />
        <Route path='dashboards' element={<MarketingDashboardWrapper />} />

        <Route
          path='docs/*'
          element={
            <SuspensedView>
              <DocsPage />
            </SuspensedView>
          }
        />

        {currentUser?.app?.hrm && (
          <>
            {/* <Route path='employee/*' element={<HrmPage />} /> */}
            <Route path='hrm/*' element={<HrmPage />} />
            <Route
              path='/reports/*'
              element={
                <SuspensedView>
                  <ReportsRoute />
                </SuspensedView>
              }
            />
            <Route
              path='/attendance'
              element={
                <SuspensedView>
                  <AttendanceType />
                </SuspensedView>
              }
            />
            <Route
              path='/attendance/add-attendance'
              element={
                <SuspensedView>
                  <AddAttendance />
                </SuspensedView>
              }
            />

            {/* <Route path='payroll/*' element={<Payroll />} /> */}
            {/* <Route path='leaveManagement/*' element={<LeaveManagement />} /> */}
            {/* <Route path='attendance/*' element={<LeavePage />} /> */}
            {/* <Route path='time-tracker/*' element={<TimeTrackerPage />} /> */}
            {/* <Route path='department/*' element={<DepartmentRoutes />} /> */}
            {/* <Route path='empDepartment/*' element={<DepartmentRoutes />} /> */}
            {/* <Route path='hrm/documents/' element={<HRMDocument />} />
        <Route path='hrm/documents/:id' element={<FilesPage />} />
        <Route path='/hrm/performance' element={<EmployeePerformance />} /> */}
            {/* <Route path='/hrm' element={<HrmWrapper />} /> */}
          </>
        )}

        {currentUser?.app?.procurement && <Route path='procurement/*' element={<VendorPage />} />}

        <Route path='project-dashboard' element={<PMWrapper />} />
        <Route
          path='customisation/*'
          element={
            <SuspensedView>
              <CustomizationPage />
            </SuspensedView>
          }
        />

        {currentUser?.app?.inventory && (
          <>
            <Route path='product/*' element={<Products />} />
            <Route path='category/*' element={<CategoryPage />} />
            <Route path='inventory/*' element={<InventoryPage />} />
            <Route path='items/*' element={<ItemsPage />} />
            <Route
              path='product-type'
              element={
                <SuspensedView>
                  <InventoryFeatureSetting />
                </SuspensedView>
              }
            />
          </>
        )}

        {currentUser?.app?.project && (
          <>
            <Route path='project/*' element={<ProjectsPage />} />
            <Route
              path='/projectReports/*'
              element={
                <SuspensedView>
                  <ProjectReportsRoute />
                </SuspensedView>
              }
            />
            <Route
              path='/projectfeatures'
              element={
                <SuspensedView>
                  <ProjectFeatureSetting />
                </SuspensedView>
              }
            />
            <Route
              path='/invoicefeatures'
              element={
                <SuspensedView>
                  <InvoiceFeatureSetting />
                </SuspensedView>
              }
            />
          </>
        )}

        <Route path='StoreAnalytics/*' element={<StoreAnalytics />} />

        {currentUser?.app?.sales && <Route path='sales/*' element={<SalesPage />} />}

        {currentUser?.app?.accounts && <Route path='account/*' element={<AccountWrapper />} />}

        {currentUser?.app?.maintenance && (
          <>
            <Route path='maintenance/*' element={<Maintenance />} />
            <Route path='dashboard/*' element={<DashboardPage />} />
            <Route path='vehicle/*' element={<Vehicle />} />
            <Route path='equipment/*' element={<Equipment />} />
            <Route path='maintenances/*' element={<Maintenances />} />
            <Route path='parts/*' element={<Parts />} />
            <Route path='inspection/*' element={<InspectionPage />} />
            <Route path='issues/*' element={<IssuesPage />} />
            <Route path='service/*' element={<ServicePage />} />
            <Route path='reminders/service' element={<ServiceReminder />} />
            <Route path='reminders/service/add' element={<AddServiceReminder />} />
            <Route path='reminders/vehicle-renewals' element={<VehicleRenewal />} />
            <Route path='reminders/vehicle-renewals/add' element={<AddVehicleRenewal />} />
          </>
        )}

        {/* //Projects Path */}

        {/* <Route path='horizontal/*' element={<Horizontal />} /> */}

        {/* <Route path='NewMember/*' element={<NewMember />} /> */}

        {/* <Route path='viewProject/:projectId' element={<ViewProject />} /> */}

        {/* <Route path='/editProject/:projectId' element={<EditProject />} /> */}
        {/* 
        <Route path='target/*' element={<Target />} />

        <Route path='budget/*' element={<Budget />} />

        <Route path='users/*' element={<Users />} />

        <Route path='files/*' element={<Files />} />

        <Route path='activity/*' element={<Activity />} />

        <Route path='settings/*' element={<Settings />} /> */}

        <Route path='/industry/*' element={<IndustryPage />} />

        <Route path='calendar' element={<CalenderWrapper />} />
        <Route path='tasks' element={<TaskList />} />
        <Route path='meetings' element={<MeetingsList />} />
        <Route path='sprint/*' element={<SprintPage />} />
        <Route
          path='event/*'
          element={
            <SuspensedView>
              <EventPage />
            </SuspensedView>
          }
        />
        <Route
          path='storage'
          element={
            <SuspensedView>
              <StoragePage />
            </SuspensedView>
          }
        />
        <Route path='report/*' element={<ReportsPage />} />

        <Route path='builder' element={<BuilderPageWrapper />} />
        <Route
          path='setting'
          element={
            <SuspensedView>
              <Setting />
            </SuspensedView>
          }
        />
        <Route path='profile-view' element={<ProfileViewing />} />
        <Route
          path='supportCenter/*'
          element={
            <SuspensedView>
              <SupportCenterPage />
            </SuspensedView>
          }
        />
        <Route
          path='pricing/columnPricing/*'
          element={
            <SuspensedView>
              <ColumnPricing />
            </SuspensedView>
          }
        />
        <Route
          path='pricing/tablePricing/*'
          element={
            <SuspensedView>
              <TablePricing />
            </SuspensedView>
          }
        />

        <Route
          path='modules/*'
          element={
            <SuspensedView>
              <ModulesPage />
            </SuspensedView>
          }
        />
        <Route
          path='department/*'
          element={
            <SuspensedView>
              <Deparment />
            </SuspensedView>
          }
        />
        <Route
          path='organization-management/*'
          element={
            <SuspensedView>
              <OrganizationManagementPage />
            </SuspensedView>
          }
        />

        <Route
          path='role-management/*'
          element={
            <SuspensedView>
              <RoleManagementPage />
            </SuspensedView>
          }
        />

        <Route
          path='superuser-management/*'
          element={
            <SuspensedView>
              <UserManagementPage />
            </SuspensedView>
          }
        />

        <Route
          path='system-codes/*'
          element={
            <SuspensedView>
              <CodeWrapper />
            </SuspensedView>
          }
        />

        <Route
          path='repair-codes/*'
          element={
            <SuspensedView>
              <RepairCode />
            </SuspensedView>
          }
        />

        <Route
          path='priority-class-codes/*'
          element={
            <SuspensedView>
              <RepairPriorityCode />
            </SuspensedView>
          }
        />

        <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />

        <Route
          path='crafted/pages/wizards/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />

        <Route
          path='crafted/widgets/*'
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/chat/*'
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />
        <Route
          path='user-management/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

export const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
