/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import React, {FC, useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import {
  defaultAlerts,
  defaultLogs,
  KTIcon,
  toAbsoluteUrl,
  useIllustrationsPath,
} from '../../../helpers'
import {useAuth} from '../../../../app/modules/auth'
import toast from 'react-hot-toast'
import {onMessage} from '@firebase/messaging'
import {messaging} from '../../../../app/firebase'
import {getAlerts, getnotification} from '../../../../app/pages/sales/Core/_requests'
import {isEqual} from 'lodash'
import {formatDistance} from 'date-fns'

interface Props {
  setShowDot: React.Dispatch<React.SetStateAction<boolean>>
}

const HeaderNotificationsMenu: FC<Props> = ({setShowDot}) => {
  const {currentUser} = useAuth()
  const [notifi, setnotifi] = useState([])
  const [alerts, setalerts] = useState([])
  const [payloadData, setPayLoadData] = useState<any>()
  const getnoti = async () => {
    const res = await getnotification(currentUser?._id)
    setnotifi(res)
  }

  useEffect(() => {
    getnoti()
    setShowDot(true)

    const unsubscribe = onMessage(messaging, (payload) => {
      console.log('Received message on focused tab', payload.data.extraData)
      setPayLoadData(payload)

      toast.success(payload.notification.title)
      fetchAlerts()
    })
    return () => {
      unsubscribe()
    }
  }, [payloadData])

  const fetchAlerts = async () => {
    const res = await getAlerts(currentUser?._id)
    if (!isEqual(res, alerts)) {
      setalerts(res)
      setShowDot(true)
    }
  }

  useEffect(() => {
    getnoti()
    fetchAlerts()
    setShowDot(true)
  }, [])

  const calcDiff = (date: string) => {
    const date1 = new Date(date)
    const date2 = new Date()
    const diffTime = date1.getTime() - date2.getTime()
    // console.log(diffTime/(1000 * 60 * 60))
    const result = diffTime / (1000 * 60 * 60)
    return result < 0 && result > -48
  }

  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column w-350px w-lg-375px'
      data-kt-menu='true'
    >
      <div
        className='d-flex flex-column bgi-no-repeat rounded-top'
        style={{backgroundImage: `url('${toAbsoluteUrl('/media/misc/menu-header-bg.jpg')}')`}}
      >
        <h3 className='text-white fw-bold px-9 mt-10 mb-6'>
          Notifications <span className='fs-8 opacity-75 ps-3'></span>
        </h3>

        <ul className='nav nav-line-tabs nav-line-tabs-2x nav-stretch fw-bold px-9'>
          <li className='nav-item'>
            <a
              className='nav-link text-white opacity-75 opacity-state-100 pb-4'
              data-bs-toggle='tab'
              href='#kt_topbar_notifications_1'
            >
              Alerts
            </a>
          </li>

          <li className='nav-item'>
            <a
              className='nav-link text-white opacity-75 opacity-state-100 pb-4 active'
              data-bs-toggle='tab'
              href='#kt_topbar_notifications_2'
            >
              Updates
            </a>
          </li>

          {/* <li className='nav-item'>
          <a
            className='nav-link text-white opacity-75 opacity-state-100 pb-4'
            data-bs-toggle='tab'
            href='#kt_topbar_notifications_3'
          >
            Logs
          </a>
        </li> */}
        </ul>
      </div>

      <div className='tab-content'>
        <div className='tab-pane fade' id='kt_topbar_notifications_1' role='tabpanel'>
          <div className='scroll-y mh-325px my-5 px-8'>
            {/* {defaultAlerts.map((alert, index) => (
              <div key={`alert${index}`} className='d-flex flex-stack py-4'>
                <div className='d-flex align-items-center'>
                  <div className='symbol symbol-35px me-4'>
                    <span className={clsx('symbol-label', `bg-light-${alert.state}`)}>
                      {' '}
                      <KTIcon iconName={alert.icon} className={`fs-2 text-${alert.state}`} />
                    </span>
                  </div>

                  <div className='mb-0 me-2'>
                    <a href='#' className='fs-6 text-gray-800 text-hover-primary fw-bolder'>
                      {alert.title}
                    </a>
                    <div className='text-gray-400 fs-7'>{alert.description}</div>
                  </div>
                </div>

                <span className='badge badge-light fs-8'>{alert.time}</span>
              </div>
            ))} */}
            {alerts?.map(
              (alert, index) =>
                alert?.type === 'eventadd' &&
                calcDiff(alert?.task?.reminder) && (
                  <div key={`alert${index}`} className='d-flex flex-stack py-4'>
                    <div className='d-flex align-items-center'>
                      <div className='symbol symbol-35px me-4'>
                        <span className={clsx('symbol-label', `bg-light-warning`)}>
                          {' '}
                          <KTIcon iconName='color-swatch' className={`fs-2 text-warning`} />
                        </span>
                      </div>
                      {alert.type === 'eventadd' && (
                        <div className='mb-0 me-2'>
                          <Link
                            to={`/calendar`}
                            className='fs-6 text-gray-800 text-hover-primary fw-bolder capitalize'
                          >
                            {alert?.task?.title}
                          </Link>
                          <div className='text-gray-400 fs-7'>To-Do</div>
                        </div>
                      )}
                    </div>

                    <span className='badge badge-light fs-8'>
                      {formatDistance(new Date(alert.task?.reminder), new Date(), {
                        addSuffix: true,
                      })}
                    </span>
                  </div>
                )
            )}
          </div>
        </div>

        <div className='tab-pane fade show active' id='kt_topbar_notifications_2' role='tabpanel'>
          <div className='scroll-y mh-325px my-5 px-8'>
            {notifi?.map((alert, index) => (
              <div key={`alert${index}`} className='d-flex flex-stack py-4'>
                <div className='d-flex align-items-center'>
                  <div className='symbol symbol-35px me-4'>
                    <span
                      className={clsx(
                        'symbol-label',
                        `${
                          alert.type === 'eventadd' || alert.type === 'sprintchange'
                            ? 'bg-light-success'
                            : alert.type === 'expense' || alert.type === 'payroll'
                            ? 'bg-light-info'
                            : alert.type === 'bills'
                            ? 'bg-light-warning'
                            : alert.type === 'pcr'
                            ? 'bg-light-primary'
                            : 'bg-light-danger'
                        }`
                      )}
                    >
                      <KTIcon
                        iconName='message-notif'
                        className={`fs-2 ${
                          alert.type === 'eventadd' || alert.type === 'sprintchange'
                            ? 'text-success'
                            : alert.type === 'expense' || alert.type === 'payroll'
                            ? 'text-info'
                            : alert.type === 'bills'
                            ? 'bg-light-warning'
                            : alert.type === 'pcr'
                            ? 'bg-light-primary'
                            : 'text-danger'
                        }`}
                      />
                    </span>
                  </div>
                  {alert.type === 'expense' ? (
                    <div className='mb-0 me-2'>
                      <Link
                        to={`/account/expenses`}
                        className='fs-6 text-gray-800 text-hover-primary fw-bolder capitalize'
                      >
                        New Expense
                      </Link>
                      {/* <div className='text-gray-400 fs-7'>{alert?.expense}</div> */}
                    </div>
                  ) : alert.type === 'payroll' ? (
                    <div className='mb-0 me-2'>
                      <Link
                        to={`/hrm/payroll`}
                        className='fs-6 text-gray-800 text-hover-primary fw-bolder capitalize'
                      >
                        New Payroll
                      </Link>
                      {/* <div className='text-gray-400 fs-7'>{alert?.task?.title}</div> */}
                    </div>
                  ) : alert.type === 'bills' ? (
                    <div className='mb-0 me-2'>
                      <Link
                        to={`/procurement/billsList/${alert?.bill}`}
                        className='fs-6 text-gray-800 text-hover-primary fw-bolder capitalize'
                      >
                        New Bill
                      </Link>
                      {/* <div className='text-gray-400 fs-7'>{alert?.task?.title}</div> */}
                    </div>
                  ) : alert.type === 'pcr' ? (
                    <div className='mb-0 me-2'>
                      <Link
                        to={`/account/pcr/${alert?.pcr}`}
                        className='fs-6 text-gray-800 text-hover-primary fw-bolder capitalize'
                      >
                        New Petty Cash Request
                      </Link>
                      {/* <div className='text-gray-400 fs-7'>{alert?.task?.title}</div> */}
                    </div>
                  ) : alert.type === 'eventadd' ? (
                    <div className='mb-0 me-2'>
                      <Link
                        to={`/calendar`}
                        className='fs-6 text-gray-800 text-hover-primary fw-bolder capitalize'
                      >
                        New Task Assigned
                      </Link>
                      <div className='text-gray-400 fs-7'>{alert?.task?.title}</div>
                    </div>
                  ) : alert.type === 'sprintchange' ? (
                    <div className='mb-0 me-2'>
                      <Link
                        to={`/sprint/${alert?.sprintTask?._id}`}
                        className='fs-6 text-gray-800 text-hover-primary fw-bolder capitalize'
                      >
                        Sprint Changed
                      </Link>
                      <div className='text-gray-400 fs-7'>{alert?.sprintTask?.title}</div>
                    </div>
                  ) : alert.type === 'bill' ? (
                    <div className='mb-0 me-2'>
                      <Link
                        to={`/procurement/billsList`}
                        className='fs-6 text-gray-800 text-hover-primary fw-bolder capitalize'
                      >
                        Bill Due
                      </Link>
                      <div className='text-gray-400 fs-7'>{alert?.title}</div>
                    </div>
                  ) : alert.type === 'invoice' ? (
                    <div className='mb-0 me-2'>
                      <Link
                        to={`/sales/invoice`}
                        className='fs-6 text-gray-800 text-hover-primary fw-bolder capitalize'
                      >
                        Invoice Due
                      </Link>
                      <div className='text-gray-400 fs-7'>{alert?.title}</div>
                    </div>
                  ) : alert.type === 'product' ? (
                    <div className='mb-0 me-2'>
                      <Link
                        to={`/category`}
                        className='fs-6 text-gray-800 text-hover-primary fw-bolder capitalize'
                      >
                        Product Low Stock
                      </Link>
                      <div className='text-gray-400 fs-7'>{alert?.title}</div>
                    </div>
                  ) : alert.type === 'document' ? (
                    <div className='mb-0 me-2'>
                      <Link
                        to={``}
                        className='fs-6 text-gray-800 text-hover-primary fw-bolder capitalize'
                      >
                        Document is Expiring
                      </Link>
                      <div className='text-gray-400 fs-7'>{alert?.title}</div>
                    </div>
                  ) : (
                    <div></div>
                  )}
                </div>

                <span className='badge badge-light fs-8'>
                  {alert.type === 'eventadd' || alert.type === 'sprintchange'
                    ? formatDistance(new Date(alert?.date), new Date(), {addSuffix: true})
                    : formatDistance(new Date(alert?.createdAt), new Date(), {addSuffix: true})}
                </span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export {HeaderNotificationsMenu}
