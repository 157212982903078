import React from 'react'
import profile from '../../../../_metronic/assets/profile.png'

export default function AboutMe({data}) {
  const workInformation = [
    {label: 'Department', value: data?.department?.name},
    {label: 'Location', value: data?.location},
    {label: 'Designation', value: data?.designation},
    {label: 'Role', value: data?.role},
    {label: 'Employment Type', value: data?.employmentType},
    {label: 'Employment Contract', value: data?.contractType},
    {label: 'Employment Status', value: data?.employeeStatus},
    {label: 'Source Of Hire', value: data?.sourceOfHire},
    {label: 'Date Of Joining', value: new Date(data?.dateOfJoining).toLocaleDateString('en-GB')},
  ]
  const IdentityInformation = [
    {label: 'Passport Number', value: data?.passportNumber},
    {label: 'National identity number', value: data?.idNumber},
  ]

  const contactDetails = [
    {label: 'Work Phone Number', value: data?.workPhoneNo},
    {label: 'Extension', value: data?.extension},
    {
      label: 'Current Address',
      value: data?.currentAddress,
      nestedKeys: ['addressLine1', 'addressLine2', 'city', 'state', 'country', 'postalCode'],
    },
    !data?.isPermanentSameAsCurrent
      ? {
          label: 'Permanent Address',
          value: data?.permanentAddress,
          nestedKeys: ['addressLine1', 'addressLine2', 'city', 'state', 'country', 'postalCode'],
        }
      : {
          label: 'Permanent Address',
          value: data?.currentAddress,
          nestedKeys: ['addressLine1', 'addressLine2', 'city', 'state', 'country', 'postalCode'],
        },
    {label: 'Personal Mobile Number', value: data?.personalMobile},
    {label: 'Personal Email Address', value: data?.personalEmail},
  ]

  const basicInformation = [
    {label: 'Employee ID', value: data?.employeeId},
    {label: 'First Name', value: data?.firstName},
    {label: 'Last Name', value: data?.lastName},
    {label: 'Nick Name', value: data?.nickName},
    {label: 'Email address', value: data?.email},
  ]

  const personalDetails = [
    {
      label: 'Date of Birth',
      key: 'dateOfBirth',
      format: (value) => (value ? new Date(value).toLocaleDateString('en-GB') : ''),
    },
    {label: 'Age', key: 'age'},
    {label: 'Gender', key: 'gender'},
    {label: 'Marital Status', key: 'maritalStatus'},
    {label: 'About Me', key: 'aboutMe'},
  ]

  const ctcDetails = [
    {
      label: 'Daily Working Hours',
      value: data?.dailyWorkingHours,
    },
    {label: 'Basic Monthly Salary', value: data?.monthlyPay},
    {label: 'Hourly Pay', value: data?.hourlyPay},
    {label: 'Overtime Pay', value: data?.overtimePay || 0},

    data?.ctc?.accomodation?.value > 0 && {
      label: 'Accomodation',
      value: data?.ctc?.accomodation?.value,
    },

    data?.ctc?.food?.value > 0 && {
      label: 'Food',
      value: data?.ctc?.food?.value,
    },

    data?.ctc?.transportation?.value > 0 && {
      label: 'Transportation',
      value: data?.ctc?.transportation?.value,
    },

    data?.ctc?.medical?.value > 0 && {
      label: 'Medical',
      value: data?.ctc?.medical?.value,
    },

    data?.ctc?.airTickets?.value > 0 && {
      label: 'Air Tickets',
      value: data?.ctc?.airTickets?.value,
    },
  ]

  return (
    <div>
      <div className='row'>
        <div className='col-xl-6 mb-xl-8'>
          <div className='card mb-5 h-100'>
            <div className='card-header align-items-center border-0'>
              <h3 className='card-title align-items-center flex-column '>
                <span className='fw-bold text-dark '>About Me</span>
              </h3>
            </div>
            <div className='card-body pt-8 pb-0 mb-xl-2  '>
              <div className=' d-flex flex-wrap flex-stack  '>
                <div className='d-flex flex-column flex-grow-1 '>
                  <div className='d-flex flex-wrap justify-content-between px-20 '>
                    <div className='mb-5 w-50 d-flex  space-x-3  fs-6 '>
                      <i class='bi bi-boxes fs-2'></i>
                      <p>{data?.department?.name}</p>
                    </div>
                    <div className='mb-5 w-50 d-flex  space-x-3 '>
                      <i class='bi bi-person-fill fs-2'></i>
                      <p> {data?.designation}</p>
                    </div>
                    <div className='mb-5 w-50 d-flex  space-x-3 fs-6'>
                      {/* <img src={officeChair} className='w-6 h-6  ' /> */}
                      <i class='bi bi-telephone-fill fs-2'></i>
                      <p>{data?.extension}</p>
                    </div>

                    <div className='mb-5 w-50 d-flex  space-x-3 fs-6'>
                      <h2 className=' text-slate-500 text-base '>
                        <i class='bi bi-phone-fill fs-2'></i>
                      </h2>
                      <p>{data?.workPhoneNo}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='col-xl-6'>
          <div className='card w-100  card-xl-stretch mb-xl-8'>
            <div className='card-header align-items-center border-0'>
              <h3 className='card-title align-items-center flex-column p-4'>
                <span className='fw-bold text-dark '>CTC</span>
              </h3>
            </div>
            <div className='d-flex flex-wrap flex-stack justify-content-center'>
              <div className=' d-flex flex-column align-items-center w-100 px-20'>
                <table className='table table-row-dashed table-row-gray-300'>
                  <tbody>
                    {ctcDetails.map(
                      (info, index) =>
                        info && (
                          <tr key={index}>
                            <th className='text-slate-500 text-base min-w-225px'>{info.label}</th>
                            <td>{info.value}</td>
                          </tr>
                        )
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='row g-5 g-xl-8 '>
        <div className='col-xl-6'>
          <div className='card w-100 card-xl-stretch'>
            <div className='card-header align-items-center border-0'>
              <h3 className='card-title align-items-start flex-column'>
                <span className='fw-bold text-dark'>Basic information</span>
              </h3>
            </div>

            <div className='d-flex flex-wrap flex-stack justify-content-center'>
              <div className=' d-flex flex-column align-items-center w-100 px-20 table-responsive'>
                <table className='table table-row-dashed table-row-gray-300'>
                  <tbody>
                    {basicInformation.map((info, index) => (
                      <tr key={index}>
                        <th className='text-slate-500 text-base min-w-225px'>{info.label}</th>
                        <td>{info.value}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <div className='col-xl-6'>
          <div className='card w-100 card-xl-stretch pb-10'>
            <div className='card-header align-items-center border-0 '>
              <h3 className='card-title align-items-start flex-column'>
                <span className='fw-bold text-dark'>Personal Details</span>
              </h3>
            </div>

            <div className='d-flex flex-wrap flex-stack justify-content-center'>
              <div className=' d-flex flex-column align-items-center w-100 px-20'>
                <table className='table table-row-dashed table-row-gray-300'>
                  <tbody>
                    {personalDetails.map((detail, index) => (
                      <tr key={index}>
                        <th className='text-slate-500 text-base min-w-225px'>{detail.label}</th>
                        <td>
                          {detail.format ? detail.format(data?.[detail.key]) : data?.[detail.key]}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='row g-5 g-xl-8 '>
        <div className='col-xl-6'>
          <div className='card w-100 mb-xl-8'>
            <div className='card-header align-items-center border-0'>
              <h3 className='card-title align-items-start flex-column'>
                <span className='fw-bold text-dark'>Contact Details</span>
              </h3>
            </div>

            <div className='d-flex flex-wrap flex-stack justify-content-center'>
              <div className='d-flex flex-column w-100 px-20 table-responsive'>
                <table className='table table-row-dashed table-row-gray-300'>
                  <tbody>
                    {contactDetails.map((item, index) => (
                      <tr key={index} className=''>
                        <td className='text-start min-w-225px border-bottom-0 d-flex'>
                          <h2 className='text-slate-500 text-base'>{item.label}</h2>
                        </td>
                        <td className='text-start '>
                          {item.nestedKeys ? (
                            <div className='d-flex flex-column '>
                              {item.nestedKeys.map((nestedKey, nestedIndex) => (
                                <p key={nestedIndex}>{item.value ? item.value[nestedKey] : ''}</p>
                              ))}
                            </div>
                          ) : (
                            <p>{item.value}</p>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className='card w-100 mb-xl-8'>
            <div className='card-header align-items-center border-0'>
              <h3 className='card-title align-items-center flex-column p-4'>
                <span className='fw-bold text-dark '>Reporting To</span>
              </h3>
            </div>
            <div className='card-body pt-9 pb-0 mb-xl-5 '>
              <div className='d-flex flex-wrap flex-stack'>
                <div className='d-flex flex-row flex-grow-1  '>
                  <div className='d-flex flex-wrap justify-content-between  text-center w-100 '>
                    <div className='mb-5 w-100 d-flex items-center'>
                      <img src={`${profile}`} className='w-20 h-20  me-6' />
                      <div>
                        <h2 className='text-slate-500 text-base'>Reporting Manger</h2>
                        <p>{data?.reportingManager}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='col-xl-6 h-100'>
          <div className='card w-100 mb-xl-8'>
            <div className='card-header align-items-center border-0'>
              <h3 className='card-title align-items-start flex-column'>
                <span className='fw-bold text-dark'>Work Information</span>
              </h3>
            </div>

            <div className='d-flex flex-wrap flex-stack justify-content-center'>
              <div className='d-flex flex-column align-items-center w-100 px-20'>
                <table className='table table-row-dashed table-row-gray-300'>
                  <tbody>
                    {workInformation.map((item, index) => (
                      <tr key={index}>
                        <td className='text-start min-w-225px border-bottom-0 d-flex'>
                          <h2 className='text-slate-500 text-base'>{item.label}</h2>
                        </td>
                        <td className='text-start'>
                          <p className='capitalize'>{item.value}</p>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className='card w-100 mb-xl-8 h-100'>
            <div className='card-header align-items-center border-0'>
              <h3 className='card-title align-items-start flex-column'>
                <span className='fw-bold text-dark'>Identity Information</span>
              </h3>
            </div>

            <div className='d-flex flex-wrap flex-stack justify-content-center'>
              <div className='d-flex flex-column align-items-center w-100 px-20'>
                <table className='table table-row-dashed table-row-gray-300'>
                  <tbody>
                    {IdentityInformation.map((item, index) => (
                      <tr key={index}>
                        <td className='text-start min-w-220px border-bottom-0 d-flex'>
                          <h2 className='text-slate-500 text-base'>{item.label}</h2>
                        </td>
                        <td className='text-start'>
                          <p>{item.value}</p>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
