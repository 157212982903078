import React, {useEffect, useState} from 'react'

export default function Products({products}) {
  console.log('products', products)
  const [productList, setProductList] = useState([])
  const [search, setSearch] = useState('')
  const [selectedType, setSelectedType] = useState('materials') // Default type: materials

  const PF = 'https://qf-assets.s3.me-central-1.amazonaws.com/'

  // Filter products by selected type and search term
  useEffect(() => {
    const filteredProducts = products?.filter(
      (item) =>
        item?.itemId?.productName?.toLowerCase().includes(search.toLowerCase()) &&
        item?.itemId?.category?.type === selectedType
    )
    setProductList(filteredProducts)
  }, [search, selectedType, products])

  return (
    <div className='content d-flex flex-column flex-column-fluid' id='kt_content'>
      <div className='container-xxl' id='kt_content_container'>
        <div className='card card-flush'>
          <div className='card-header align-items-center py-5 gap-2 gap-md-5'>
            <div className='card-title'>
              <div className='d-flex align-items-center position-relative my-1'>
                <i className='ki-duotone ki-magnifier fs-3 position-absolute ms-4'>
                  <span className='path1'></span>
                  <span className='path2'></span>
                </i>
                <input
                  type='text'
                  data-kt-ecommerce-product-filter='search'
                  className='form-control form-control-solid w-250px ps-12'
                  placeholder='Search Product'
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
              </div>
            </div>

            <div className='d-flex align-items-center justify-content-end gap-4'>
              <button
                className={`btn btn-outline-primary me-2 ${
                  selectedType === 'materials' ? 'active text-white' : ''
                }`}
                onClick={() => setSelectedType('materials')}
              >
                Materials
              </button>
              <button
                className={`btn btn-outline-primary ${
                  selectedType === 'consumables' ? 'active text-white' : ''
                }`}
                onClick={() => setSelectedType('consumables')}
              >
                Consumables
              </button>
              <button
                className={`btn btn-outline-primary ${
                  selectedType === 'rentals' ? 'active text-white' : ''
                }`}
                onClick={() => setSelectedType('rentals')}
              >
                Equipments
              </button>
            </div>

            {/* <div className='card-toolbar flex-row-fluid justify-content-end gap-5'>
              <div className='w-100 mw-150px'>
                <select
                  className='form-select form-select-solid'
                  data-control='select2'
                  data-hide-search='true'
                  value={selectedType}
                  onChange={(e) => setSelectedType(e.target.value)} // Update selected type
                >
                  <option value='materials'>Materials</option>
                  <option value='consumables'>Consumables</option>
                  <option value='rentals'>Rentals</option>
                </select>
              </div>
            </div> */}
          </div>

          <div className='card-body pt-0 table-responsive'>
            <table
              className='table align-middle table-row-dashed fs-6 gy-5 px-4'
              id='kt_ecommerce_products_table'
            >
              <thead>
                <tr className='text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0'>
                  <th className='min-w-200px'>Product</th>
                  <th className='text-end min-w-100px'>SKU</th>
                  <th className='text-end min-w-70px'>Qty</th>
                  <th className='text-end min-w-100px'>Price</th>
                </tr>
              </thead>
              <tbody className='fw-semibold text-gray-600'>
                {productList?.map((product, i) => (
                  <tr key={i}>
                    <td>
                      <div className='d-flex align-items-center'>
                        <div className='symbol symbol-50px me-2'>
                          <span className='symbol-label'>
                            <img
                              src={`${PF}${product?.itemId?.thumbnail}`}
                              alt=''
                              className='h-50 align-self-center'
                            />
                          </span>
                        </div>
                        <div className='ms-5'>
                          <a
                            href=''
                            className='text-gray-800 text-hover-primary fs-5 fw-bold'
                            data-kt-ecommerce-product-filter='product_name'
                          >
                            {product?.itemId?.productName}
                          </a>
                        </div>
                      </div>
                    </td>
                    <td className='text-end pe-0'>
                      <span className='fw-bold'>{product?.itemId?.sku}</span>
                    </td>
                    <td className='text-end pe-0' data-order='20'>
                      <span className='fw-bold ms-3'>{product?.quantity}</span>
                    </td>
                    <td className='text-end pe-0'>{product?.price}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}
