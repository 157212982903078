import React, {FC} from 'react'
import {Field} from 'formik'

const Step3: FC = () => {
  const currencies = [
    {value: 'USD', label: 'USD - US Dollar'},
    {value: 'EUR', label: 'EUR - Euro'},
    {value: 'JPY', label: 'JPY - Japanese Yen'},
    {value: 'GBP', label: 'GBP - British Pound Sterling'},
    {value: 'AUD', label: 'AUD - Australian Dollar'},
    {value: 'CAD', label: 'CAD - Canadian Dollar'},
    {value: 'CNY', label: 'CNY - Chinese Yuan'},
    {value: 'INR', label: 'INR - Indian Rupee'},
    {value: 'SAR', label: 'SAR - United Arab Emirates Dirham'},
    {value: 'SAR', label: 'SAR - Saudi Riyal'},
    {value: 'QAR', label: 'QAR - Qatari Rial'},
    {value: 'KWD', label: 'KWD - Kuwaiti Dinar'},
    {value: 'OMR', label: 'OMR - Omani Rial'},
    {value: 'BHD', label: 'BHD - Bahraini Dinar'},
    {value: 'EGP', label: 'EGP - Egyptian Pound'},
    {value: 'TRY', label: 'TRY - Turkish Lira'},
    {value: 'ZAR', label: 'ZAR - South African Rand'},
  ]

  return (
    <div className='w-100'>
      <div className='pb-10 pb-lg-15'>
        <h2 className='fw-bolder text-dark'>Other Details</h2>
      </div>

      <div className='fv-row mb-10'>
        <label className='form-label required'>Currency</label>

        {/* <Field
          type='text'
          className='form-control form-control-lg form-control-solid'
          name='currency'
          placeholder='Currency'
        /> */}
        <Field
          as='select'
          className='form-select form-control form-control-lg form-control-solid'
          id='currency'
          name='currency'
        >
          {currencies.map((currency) => (
            <option key={currency.value} value={currency.value}>
              {currency.label}
            </option>
          ))}
        </Field>
      </div>
      <div className='fv-row mb-10'>
        <label className='form-label required'>Opening Balance</label>

        <Field
          type='text'
          className='form-control form-control-lg form-control-solid'
          name='openingBalance'
          placeholder='Opening Balance'
        />
      </div>
      <div className='fv-row mb-10'>
        <label className='form-label required'>Payment Terms</label>

        <Field
          as='select'
          className='form-control form-control-lg form-control-solid'
          name='paymentTerms'
          placeholder='Payment Terms'
        >
          <option value='Net 15 days'>Net 15 days</option>
          <option value='Net 30 days'>Net 30 days</option>
          <option value='Net 45 days'>Net 45 days</option>
          <option value='Net 60 days'>Net 60 days</option>
          <option value='Net 90 days'>Net 90 days</option>
          <option value='50% Advance'>50% Advance</option>
          <option value='100% Advance'>100% Advance</option>
          <option value='Due end of the month'>Due end of the month</option>
          <option value='Due end of next month'>Due end of next month</option>
          <option value='Due on Receipt'>Due on Receipt</option>
        </Field>
      </div>
      {/* <div className='fv-row mb-10'> */}
    </div>
  )
}

export {Step3}
