import {useEffect, useRef, useState} from 'react'
import {Link} from 'react-router-dom'
import {useAuth} from '../../../modules/auth'
import {KTIcon} from '../../../../_metronic/helpers'
import {getFilteredRFP, getFilteredRFPByAgentId, updateRFPValidationStatus} from '../Core/_requests'
import qs from 'qs'
import {Filtertype2, ValidFilter} from '../Core/_model'
import Filter2 from '../Components/Filter2'
import {RiArrowDropDownLine} from 'react-icons/ri'
import {useDispatch, useSelector} from 'react-redux'
import {RootState} from '../../../redux/store'
import debounce from 'lodash/debounce'
import Pagination from '../Components/Pagination'
import DateFilter from '../../account/components/DateFilter'
import {setRfpDataList} from '../../../redux/procurement/rfp/rfpSlice'

const RFPList = () => {
  const {currentUser} = useAuth()
  const initialRef = useRef(true)
  // const [RFPList, setRFPList] = useState<any>([])
  const [permissions, setPermissions] = useState<any>({})
  const [dropdown, setDropdown] = useState([])
  const [filteredRFPList, setFilteredRFPList] = useState([])
  const [searchQuery, setSearchQuery] = useState('')
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)
  const rfpData = useSelector((state: RootState) => state.procurement.rfp.rfpData)
  const totalPagesStore = useSelector((state: RootState) => state.procurement.rfp.totalPages)
  const refetchData = useSelector((state: RootState) => state.procurement.rfp.fetch)
  const dateRange = useSelector((state: RootState) => state.procurement.rfp.dateState)
  const [dateState, setDateState] = useState<any>(dateRange)

  // Pagination states
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)

  const stringifyObject = (obj: ValidFilter): string => {
    const queryString = qs.stringify(
      {
        filter_validity: obj.filter.isValid,
        filter_approval: obj.filter.approval,
        search_query: searchQuery,
      },
      {encodeValuesOnly: true, skipNulls: true, delimiter: '&'}
    )

    return queryString
  }

  const getRFPList = async () => {
    setIsLoading(true)
    try {
      const queryString = stringifyObject(searchFilters)
      const res = await getFilteredRFP(
        queryString,
        currentUser?.organization,
        currentPage,
        25,
        dateState
      )
      setFilteredRFPList(res?.rfp)
      setTotalPages(res?.totalPages)
      setDropdown(Array(res?.rfp?.length).fill(false))
      return res
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }

  const getBillsByAgent = async () => {
    setIsLoading(true)
    try {
      const queryString = stringifyObject(searchFilters)
      const res = await getFilteredRFPByAgentId(
        queryString,
        currentUser?._id,
        currentPage,
        25,
        dateState
      )
      setFilteredRFPList(res?.rfp)
      setTotalPages(res?.totalPages)
      setDropdown(Array(res?.rfp?.length).fill(false))
      return res
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (currentUser?.permissions) {
      setPermissions(currentUser?.permissions)
    }
  }, [currentUser])

  const fetchRFPData = () => {
    if (dateState.startDate !== undefined && dateState.endDate !== undefined) {
      if (permissions?.purchaserequest?.admin === false) {
        getBillsByAgent()
      } else if (
        permissions?.purchaserequest?.admin === true ||
        currentUser?.profileType === 'superadmin'
      ) {
        getRFPList()
      }
    }
  }

  const fetchRFPs = () => {
    if (rfpData.length === 0 || refetchData) {
      if (permissions?.purchaserequest?.admin === false) {
        getBillsByAgent().then((res) => {
          dispatch(setRfpDataList(res))
        })
      } else if (
        permissions?.purchaserequest?.admin === true ||
        currentUser?.profileType === 'superadmin'
      ) {
        getRFPList().then((res) => {
          dispatch(setRfpDataList(res))
        })
      }
    } else {
      setFilteredRFPList(rfpData)
      setDropdown(Array(rfpData.length).fill(false))
      setTotalPages(totalPagesStore)
    }
  }

  useEffect(() => {
    fetchRFPs()
  }, [permissions])

  const debounceSearch = debounce(fetchRFPData, 500)

  useEffect(() => {
    if (initialRef.current) {
      initialRef.current = false
      return
    }

    debounceSearch()

    return () => debounceSearch?.cancel()
  }, [searchQuery, currentPage, dateState.endDate])

  const handleClick = (index) => {
    const newDropdownState = dropdown.map((_, i) => (i === index ? !dropdown[i] : false))
    setDropdown(newDropdownState)
  }

  const handleActivation = async (id, valid) => {
    const res = await updateRFPValidationStatus(id, {valid: !valid})
    if (res) {
      fetchRFPData()
    }
  }

  let searchFilters: ValidFilter = {
    filter: {
      isValid: true,
    },
  }

  const setSearchFilter = (updateValues: {filter?: Partial<Filtertype2>}): void => {
    searchFilters = {
      ...searchFilters,
      ...(updateValues.filter && {
        filter: {
          ...searchFilters.filter,
          ...updateValues.filter,
        },
      }),
    }
  }

  const setValidityFilter = (isValid: boolean) => {
    setSearchFilter({
      filter: {
        isValid,
      },
    })
  }

  const setApprovalFilter = (approval: string) => {
    setSearchFilter({
      filter: {
        isValid: searchFilters.filter.isValid,
        status: searchFilters.filter.status,
        approval: approval, // Update approval filter value
      },
    })
  }

  // useEffect(() => {
  //   const filteredData = RFPList.filter(
  //     (item) =>
  //       item.vendor?.displayName.toLowerCase().includes(searchQuery.toLowerCase()) ||
  //       item.employee?.firstName.toLowerCase().includes(searchQuery.toLowerCase()) ||
  //       item?.id?.toLowerCase().includes(searchQuery.toLowerCase())
  //   )
  //   setFilteredRFPList(filteredData)
  // }, [searchQuery, RFPList])

  const handlereset = async () => {
    setSearchFilter({
      filter: {
        isValid: true,
        approval: '',
      },
    })
    setFilteredRFPList(rfpData)
    setDropdown(Array(rfpData.length)?.fill(false))
  }

  return (
    <div className='card card-flush'>
      <span className='card-label fw-bold fs-3 ps-15 pt-8 '>Purchase Request</span>
      <div className='card-header border-0 pt-5'>
        <div className='flex gap-5'>
          <div className='d-flex flex-row card-title align-items-center gap-4'>
            <form
              data-kt-search-element='form'
              className='w-100 position-relative '
              autoComplete='off'
            >
              <KTIcon
                iconName='magnifier'
                className='fs-2 text-lg-1 text-gray-500 position-absolute top-50 translate-middle-y ms-2'
              />
              <input
                type='text'
                className='form-control form-control-solid w-250px ps-14'
                name='search'
                placeholder='Search (RFP ID)'
                data-kt-search-element='input'
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </form>
            <DateFilter dateState={dateState} setDateState={setDateState} />
          </div>
          <div className=' d-flex flex-row  card-title align-items-center'>
            <Filter2
              searchFilters={searchFilters}
              setSearchFilter={setSearchFilter}
              handlereset={handlereset}
              getDocsList={fetchRFPData}
              setValidityFilter={setValidityFilter}
              showStatusFilter={false}
              setApprovalFilter={setApprovalFilter}
            />
          </div>
        </div>

        {(permissions?.purchaserequest?.create || currentUser?.profileType === 'superadmin') && (
          <div className='card-toolbar'>
            <Link to={'/procurement/purchaserequest/add'} className='btn btn-sm btn-light-primary'>
              <KTIcon iconName='plus' className='fs-2' />
              Add Purchase Request
            </Link>
          </div>
        )}
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        {isLoading ? (
          <div className='d-flex align-items-center justify-center'>
            <div className='loader'></div>
          </div>
        ) : (
          <div className='table-responsive overflow-visible'>
            {/* begin::Table */}
            <table className='table align-middle gs-0 gy-4 table-row-bordered'>
              {/* begin::Table head */}
              <thead>
                <tr className='fw-bold text-muted bg-light'>
                  <th className='ps-4 min-w-50px rounded-start'>ID</th>
                  <th className='min-w-150px'>Employee</th>
                  <th className='min-w-150px'>Vendor</th>
                  <th className='min-w-100px'>Approval</th>
                  {(permissions?.purchaserequest?.update ||
                    currentUser?.profileType === 'superadmin') && (
                    <th className='min-w-150px text-center'>Actions</th>
                  )}
                </tr>
              </thead>
              <tbody>
                {filteredRFPList?.map((lead: any, index: number) => (
                  <tr key={lead?._id?.toString()}>
                    <td>
                      <Link
                        to={`/procurement/purchaserequest/${lead._id}`}
                        className='ps-4 text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                      >
                        {lead?.id}
                      </Link>
                    </td>
                    <td>
                      <Link
                        to={`/procurement/purchaserequest/${lead._id}`}
                        className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                      >
                        {lead?.employee?.firstName}
                      </Link>
                    </td>
                    <td>
                      <Link
                        to={`/procurement/purchaserequest/${lead._id}`}
                        className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                      >
                        {lead?.vendor?.displayName}
                      </Link>
                    </td>
                    <td>
                      <Link
                        to={`/procurement/purchaserequest/${lead._id}`}
                        className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                      >
                        <span
                          className={`badge  fs-7 fw-semibold ${
                            lead?.approval === 'pending'
                              ? 'badge-light-primary'
                              : lead?.approval === 'accepted'
                              ? 'badge-light-success'
                              : 'badge-light-danger'
                          }`}
                        >
                          {lead?.approval}
                        </span>
                      </Link>
                    </td>
                    {(permissions?.purchaserequest?.update ||
                      currentUser?.profileType === 'superadmin') && (
                      <td className='relative '>
                        <div className='d-flex justify-content-center flex-shrink-0'>
                          <button
                            onClick={() => handleClick(index)}
                            className=' bg-gray-100 hover:bg-lime-50 px-6 py-2 flex items-center rounded self-end'
                          >
                            Actions <RiArrowDropDownLine />
                          </button>
                        </div>
                        {dropdown[index] && (
                          <div className='absolute flex flex-col bg-white  w-40 shadow-[0_16px_16px_6px_rgba(128,128,128,0.1)] top-16 right-[70px] rounded p-3 z-50 space-y-2'>
                            <Link to={`edit/${lead._id}`}>
                              <button className='hover:bg-lime-50  hover:w-full  p-2  text-start rounded'>
                                Edit
                              </button>
                            </Link>
                            <Link to={`revised/${lead._id}`}>
                              <button className='hover:bg-lime-50  hover:w-full  p-2  text-start rounded'>
                                Revised
                              </button>
                            </Link>
                            {!lead?.valid ? (
                              <button
                                className='hover:bg-lime-50 p-2 text-start rounded'
                                onClick={() => handleActivation(lead._id, lead.valid)}
                              >
                                Valid
                              </button>
                            ) : (
                              <button
                                className='hover:bg-lime-50 p-2 text-start rounded'
                                onClick={() => handleActivation(lead._id, lead.valid)}
                              >
                                Invalid
                              </button>
                            )}
                          </div>
                        )}
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
              {/* end::Table body */}
            </table>
            {/* end::Table */}
          </div>
        )}
        {/* end::Table container */}
      </div>

      {/* Pagination */}
      <Pagination
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        totalPages={totalPages}
      />
    </div>
  )
}

export default RFPList
