import React, {lazy} from 'react'
import {Navigate, Route, Routes} from 'react-router-dom'
import {AddCustomer} from './Pages/AddCustomer/AddCustomer'
import Createquote from './Components/Quote/CreateQuote'
import QuotesList from './Pages/QuotesList'
import InvoiceList from './Components/Invoice/InvoiceList'
import CreateInvoice from './Components/Invoice/CreateInvoice'
import SalesOrders from './Pages/SalesOrders'
import AddSalesOrder from './Pages/AddSalesOrder'
import PaymentReceivedList from './Pages/PaymentReceivedList'
import AddPaymentReceived from './Pages/AddPaymentReceived'
import InvoiceSelection from './Pages/InvoiceSelection'
import QuoteSelection from './Pages/QuoteSelection'
import OrderManagement from './Pages/OrderManagement'
import OrderManagementHeader from './Pages/OrderManagementHeader'
import CreateQuoteToOrder from './Components/CreateQuoteToOrder'
import CreateInvoiceToOrder from './Components/CreateInvoiceToOrder'
import AddSalesOrderToOrder from './Pages/AddSalesOrderToOrder'
import SalesOrderDetails from './Pages/SalesOrderDetails'
import PaymentReceivedDetails from './Pages/PaymentReceivedDetails'
import ViewCustomer from './Pages/ViewCustomer'
import DeliveryChallanPage from './Pages/DeliveryChallanPage'
import DeliveryNotes from './Components/DeliveryNotes'
import DeliveryChallanDetail from './Pages/DeliveryChallanDetail'
import {Customer} from './Pages/Customer/Customer'
import {EditCustomer} from './Pages/Customer/EditCustomer'
import ApprovalPage from './Pages/ApprovalPage'
import AddPaymentReceivedToOrder from './Pages/AddPaymentReceivedToOrder'
import DeliveryNotesToOrder from './Components/DeliveryNotesToOrder'
import EditQuote from './Components/Quote/EditQuote'
import EditSalesOrder from './Pages/EditSalesOrder'
import EditDeliveryNotes from './Components/EditDeliveryNotes'
import SalesPerformance from '../reports/components/SalesPerformance'
import AddPartialDeliveryNote from './Components/AddPartialDeliveryNote'
import EmailComposerInvoice from './Components/EmailComposerInvoice'
import EmailComposerQuotation from './Components/EmailComposerQuotation'
import EmailComposerSalesOrder from './Components/EmailComposerSalesOrder'
import EmailComposerDeliveryNote from './Components/EmailComposerDeliveryNote'
import EmailComposerPaymentReceived from './Components/EmailComposerPaymentReceived'
import AddSalesOrderWithQuote from './Pages/AddSalesOrderWithQuote'
import AddDeliveryNoteWithSalesOrder from './Components/AddDeliveryNoteWithSalesOrder'
import CreateInvoiceWithSalesOrder from './Components/Invoice/CreateInvoiceWithSalesOrder'
import AddPaymentReceivedWithInvoice from './Pages/AddPaymentReceivedWithInvoice'
import GroupInvoice from './Pages/GroupInvoice'
import EmailComposerCustomer from './Components/EmailComposerCustomer'
import EmailComposerGroupInvoice from './Components/EmailComposerGroupInvoice'
import {SuspensedView} from '../../routing/PrivateRoutes'
import RevisedQuote from './Components/Quote/RevisedQuote'
import RevisedSalesOrder from './Pages/RevisedSalesOrder'
import RevisedDeliveryNotes from './Components/RevisedDeliveryNotes'
import RevisedInvoice from './Components/Invoice/RevisedInvoice'
import EditPaymentReceived from './Pages/EditPaymentReceived'
import RevisedPaymentReceived from './Pages/RevisedPaymentReceived'

export default function SalesPage() {
  const EditInvoice = lazy(() => import('./Components/Invoice/EditInvoice'))
  return (
    <Routes>
      <Route path='order-management' element={<OrderManagement />} />
      <Route path='/order-management/orderdetails/:id' element={<OrderManagementHeader />} />
      <Route path='/customer' element={<Customer />} />
      <Route path='/customer/addCustomer' element={<AddCustomer />} />
      <Route path='/customer/edit/:id' element={<EditCustomer />} />

      {/* <Route path='/newCustomer' element={<NewCustomer />} /> */}
      <Route path='/customer/:customerId' element={<ViewCustomer />} />
      <Route
        path='/customer/:customerId/email-composer/:dateFilter'
        element={<EmailComposerCustomer />}
      />

      <Route path='/salesorders' element={<SalesOrders />} />
      <Route path='/salesorders/:id' element={<SalesOrderDetails />} />
      <Route path='/salesorders/:id/email-composer' element={<EmailComposerSalesOrder />} />
      <Route path='/salesorders/add' element={<AddSalesOrder />} />
      <Route path='/salesorders/edit/:id' element={<EditSalesOrder />} />
      <Route path='/salesorders/revised/:id' element={<RevisedSalesOrder />} />
      <Route path='/salesorders/quote/:id' element={<AddSalesOrderWithQuote />} />

      <Route path='/paymentReceived' element={<PaymentReceivedList />} />
      <Route path='/paymentReceived/add' element={<AddPaymentReceived />} />
      <Route path='/paymentReceived/edit/:id' element={<EditPaymentReceived />} />
      <Route path='/paymentReceived/revised/:id' element={<RevisedPaymentReceived />} />
      <Route path='/paymentReceived/:id' element={<PaymentReceivedDetails />} />
      <Route path='/paymentReceived/invoice/:id' element={<AddPaymentReceivedWithInvoice />} />
      <Route
        path='/paymentReceived/:id/email-composer'
        element={<EmailComposerPaymentReceived />}
      />

      <Route path='/deliveryChallan' element={<DeliveryChallanPage />} />
      <Route path='/deliveryChallan/add' element={<DeliveryNotes />} />
      <Route path='/deliveryChallan/:id' element={<DeliveryChallanDetail />} />
      <Route path='/deliveryChallan/:id/email-composer' element={<EmailComposerDeliveryNote />} />
      <Route path='/deliveryChallan/edit/:id' element={<EditDeliveryNotes />} />
      <Route path='/deliveryChallan/revised/:id' element={<RevisedDeliveryNotes />} />
      <Route path='/deliveryChallan/add-partial/:id' element={<AddPartialDeliveryNote />} />
      <Route path='/deliveryChallan/salesorder/:id' element={<AddDeliveryNoteWithSalesOrder />} />

      <Route path='invoice' element={<InvoiceList />} />
      <Route path='invoice/add' element={<CreateInvoice />} />
      <Route path='invoice/:id' element={<InvoiceSelection />} />
      <Route
        path='invoice/edit/:id'
        element={
          <SuspensedView>
            <EditInvoice />
          </SuspensedView>
        }
      />
      <Route
        path='invoice/revised/:id'
        element={
          <SuspensedView>
            <RevisedInvoice />
          </SuspensedView>
        }
      />
      <Route path='invoice/group/:id' element={<GroupInvoice />} />

      <Route path='invoice/:id/email-composer' element={<EmailComposerInvoice />} />
      <Route path='invoice/group/:id/email-composer' element={<EmailComposerGroupInvoice />} />
      <Route path='invoice/:type/:id' element={<CreateInvoiceWithSalesOrder />} />

      <Route path='quotes' element={<Navigate to='list/pending' />} />
      <Route path='quotes/list/:status' element={<QuotesList />} />
      <Route path='quotes/add' element={<Createquote />} />
      <Route path='quotes/:id' element={<QuoteSelection />} />
      <Route path='quotes/edit/:id' element={<EditQuote />} />
      <Route path='quotes/revised/:id' element={<RevisedQuote />} />
      <Route path='quotes/:id/email-composer' element={<EmailComposerQuotation />} />

      {/* <Route path='quotes/add-new-item' element={<AddProduct />} /> */}

      <Route path='invoicetoorder/add/:id' element={<CreateInvoiceToOrder />} />
      <Route path='quotetoorder/add/:id' element={<CreateQuoteToOrder />} />
      <Route path='salesorderstoorder/add/:id' element={<AddSalesOrderToOrder />} />
      <Route path='paymentreceivedtoorder/add/:id' element={<AddPaymentReceivedToOrder />} />
      <Route path='deliverynotetoorder/add/:id' element={<DeliveryNotesToOrder />} />
      <Route path='approval/*' element={<ApprovalPage />} />

      <Route path='/salesperformance' element={<SalesPerformance />} />

      {/* <Route path='deliverynotetoorder/add/:id' element={<DeliveryNotesToOrder />} /> */}
    </Routes>
  )
}
