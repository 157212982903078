import * as yup from 'yup';

interface Address {
  attention: string
  country: string
  region: string
  addressLine1: string
  addressLine2?: string
  city: string
  state: string
  postalCode: string
  phone: string
  faxNumber?: string
}

 interface ContactPerson {
     salutation?: string;
     firstName: string;
     lastName: string;
     emailAddress?: string;
     workPhone?: string;
     mobile?: string;
     designation?: string;
     department?: string;
 }

export interface CustomerInterface {
  //  _id:string;
  customerType: string
  primaryContact: {
    salutation: string
    firstName: string
    lastName: string
  }
  companyName: string
  displayName: string
  vatNumber:string
  emailAddress?: string
  contactNumbers?: {
    workPhone?: string
    mobilePhone?: string
  }
  currency: string
  openingBalance?: number
  paymentTerms?: string
  portalEnabled: boolean
  portalLanguage?: string
  document?: string
  billingAddress: Address
  shippingAddress: Address
  contactPersons: ContactPerson[];
  createdAt?: Date
  updatedAt?: Date
  sameAsBillingAddress: boolean

  // contactSalutation: string
  // contactFirstName: string
  // contactLastName: string
  // contactEmail: string
  // contactWorkPhone: number
  // contactPersonalMobile: number
  // contactDesignation: string
  // contactDepartment: string
}
export interface SearchFilter {
  search?: string
  agent?: string
  filter?: Filtertype
}
export interface ValidFilter {
  filter?: Filtertype2
  search_query?: string
}
export interface Filtertype {
  // status?: string
  isActive?: boolean
}
export interface Filtertype2 {
  isValid?: boolean
  status?: string; 
  approval: string;
  order?: string
}

export const initialSearchFilter: SearchFilter = {
  search: '',
  agent: '',
  filter: {
    isActive: true,
  },
}

export const customerSchema = yup.object().shape({
  customerType: yup.string().required(),
  primaryContact: yup.object().shape({
    salutation: yup.string().required(),
    firstName: yup.string().required("first Name is required"),
    lastName: yup.string().required("last name is required"),
  }),
  companyName: yup.string().required("company name is required"),
  displayName: yup.string().required("display name is required"),
  // vatNo: yup.string().required("VAT Number is required"),
  emailAddress: yup.string().email(),
  contactNumbers: yup.object().shape({
    workPhone: yup.string(),
    mobilePhone: yup.string(),
  }),
});


//redux type define
interface CustomerState {
  customerData: any[];  
  loading: boolean;
  error: string | null;
}
interface OrderManagementState {
  orderManagementData: any[];  
  loading: boolean;
  error: string | null;
}
interface quotationState {
  quotationData: any[];  
  loading: boolean;
  error: string | null;
}
interface salesOrderState {
  salesOrderData: any[];  
  loading: boolean;
  error: string | null;
}
interface deliveryNoteState {
  deliveryNoteData: any[];  
  loading: boolean;
  error: string | null;
}
interface invoiceState {
  invoiceData: any[];  
  loading: boolean;
  error: string | null;
}
interface paymentReceivedState {
  paymentReceivedData: any[];  
  loading: boolean;
  error: string | null;
}
interface salesPerformanceState {
  salesPerformanceData: any[];  
  loading: boolean;
  error: string | null;
}
interface quotationApprovalState {
  quotationApprovalData: any[];  
  loading: boolean;
  error: string | null;
}
interface invoiceApprovalState {
  invoiceApprovalData: any[];  
  loading: boolean;
  error: string | null;
}
interface salesOrderApprovalState {
  salesOrderApprovalData: any[];  
  loading: boolean;
  error: string | null;
}
interface deliveryNoteApprovalState {
  deliveryNoteApprovalData: any[];  
  loading: boolean;
  error: string | null;
}
interface paymentReceivedApprovalState {
  paymentReceivedApprovalData: any[];  
  loading: boolean;
  error: string | null;
}
interface salesState {
  customer:CustomerState
  orderManagement:OrderManagementState
  quotation:quotationState
  salesOrder:salesOrderState
  deliveryNote:deliveryNoteState
  invoice:invoiceState
  paymentReceived:paymentReceivedState
  salesPerformance:salesPerformanceState
  quotationApproval:quotationApprovalState
  invoiceApproval:invoiceApprovalState
  salesOrderApproval:salesOrderApprovalState
  deliveryNoteApproval:deliveryNoteApprovalState
  paymentReceivedApproval:paymentReceivedApprovalState

}
export interface RootState {
  sales: salesState;
}