import axios, {Axios, AxiosResponse} from 'axios'
import {ID, Response} from '../../../../_metronic/helpers'
import { Manager, Project} from './_models'
 
/* import { Agent, Clientinfo } from '../../agent/core/_models';  */

const API_URL = process.env.REACT_APP_THEME_API_URL
const EXCEL_IMPORT_URL = process.env.REACT_APP_EXCEL_IMPORT_URL

// const GET_PROJECT_URL = `${API_URL}/project`
// const ADD_PROJECT_URL = `${API_URL}/project`
// const DELETE_PROJECT_URL  = `${API_URL}/project`
const GET_MANAGER_URL = `${API_URL}/manager`
const PROJECT_URL=`${API_URL}/project`
const POST_DOCS_URL = `${API_URL}/uploaddocs/`
const SALES_ORDER = `${API_URL}/sales/salesOrder`
const REPORTS_URL=`${API_URL}/reports`
const PROJECT_PERFORMANCE_URL=`${REPORTS_URL}/projectperformance`
const TRANSACTIONS_URL = `${API_URL}/transactions`
const ORDER_MANAGEMENT_URL = `${API_URL}/sales/orderManagement`
const PURCHASE_ORDER_MANAGEMENT_URL = `${API_URL}/purchases/orderManagement`
const PROJECT_TIMESHEET_URL = `${API_URL}/project/projectTimesheet`
const TIMESHEET_PAYROLL_URL = `${API_URL}/project/timesheetpayroll`
const COMPANY_URL = `${API_URL}/company`


  // export async function getProject(): Promise<any>  {
  //   try {
  //     const response: AxiosResponse<any> = await axios.get(`${GET_PROJECT_URL}`);
  //     console.log("ddd",response.data)
  //     return response.data;
  //   } catch (error) {
  //     // Handle errors here
  //     console.error("Get project error:", error);
  //     throw error;
  //   }
  // }

  // export async function addProject(formData: any){
  //   try {
  //     const response: AxiosResponse<any> = await axios.post(`${ADD_PROJECT_URL}`, formData);
  //     return response.data;
  //   } catch (error) {
  //     // Handle errors here
  //     console.error("Add Project error:", error);
  //     throw error;
  //   }
  // }

  // export async function deleteProject(projectId: string): Promise<any> {
  //   try {
  //     const response: AxiosResponse<any> = await axios.delete(`${DELETE_PROJECT_URL}/${projectId}`);
  //     return response.data;
  //   } catch (error) {
  //     console.error('Delete Project error:', error);
  //     throw error;
  //   }
  // }
  

  // export const getProjectById = async (projectId) => {
  //   try {
  //     const response = await axios.get(`${ADD_PROJECT_URL}/${projectId}`);
  //     return response.data; // Assuming your API returns the project data in the response
  //   } catch (error) {
  //     console.error('Error fetching project by ID:', error);
  //     throw error;
  //   }
  // };

  // export const updateProject = async (projectId, updatedProjectData) => {
  //   try {
  //     const response = await axios.put(`${ADD_PROJECT_URL}/${projectId}`, updatedProjectData);
  //     return response.data;
  //   } catch (error) {
  //     console.error('Error updating project:', error);
  //     throw error;
  //   }
  // };


/* view project */
  export const getManager = async():Promise<Array<Manager>> => {
    try {
    const response : AxiosResponse<Manager[]> = await axios.get(`${GET_MANAGER_URL}`)
    return response.data
} catch (error) {
    // Handle errors here
    console.error("Add Manager error:", error);
    throw error;
  }    
  }

//get Project
export const getProject = async (orgid: string) => {
  try {
    console.log(PROJECT_URL);
    const response = await axios.get(`${PROJECT_URL}/getAllProjects/${orgid}`)
    console.log("Response of Project", response.data);
    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

export async function bulkUploadEmployees(data, company, organization) {
  try {
    const response = await axios.post(`${EXCEL_IMPORT_URL}/bulk-upload/project/${company}/${organization}`, data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    return response.data
  } catch (error) {
    console.error('Bulk upload Employee error:', error)
    throw error
  }
}

//get Project
export const getProjectByAgentId = async (agentid: string) => {
  try {
    console.log(PROJECT_URL);
    const response = await axios.get(`${PROJECT_URL}/agent/${agentid}`)
    console.log("Response of Project", response.data);
    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

//get Project By id
export const getProjectById = async (projectId) => {
  try {
    const response = await axios.get(`${PROJECT_URL}/projectById/${projectId}`)

    console.log("Project by id data", response.data);
    return response.data
    
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

export async function getFilteredProject(query: string, orgid: string): Promise<Array<any>> {
  try {
    const response: AxiosResponse<any[]> = await axios.get(`${PROJECT_URL}/filter/${orgid}?${query}`)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add property error:', error)
    throw error
  }
}

export async function getFilteredProjectByAgentId(query: string, agentId): Promise<Array<any>> {
  try {
    const response: AxiosResponse<any[]> = await axios.get(`${PROJECT_URL}/agent/filter/${agentId}?${query}`)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add property error:', error)
    throw error
  }
}

//Post Project
export async function postProject(data): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.post(`${PROJECT_URL}`, data)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Post Payment Received error:', error)
    throw error
  }
}
  
// Delete||disable Project By id

export const deleteProjectById = async (projectId) => {
  try {
    const response = await axios.put(`${PROJECT_URL}/delete/${projectId}`)
    console.log(
      response.data
    );
    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

//update project
export const editProjectById = async (data, id) => {
  try {
    const response = await axios.put(`${PROJECT_URL}/edit/${id}`, data)
    console.log(response.data)

    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}
  

export const getTeamsByProjectId = async(projectId) => {
  try {
    const response = await axios.get(`${PROJECT_URL}/team/${projectId}`)
    console.log(response.data)
    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

console.log("target",`${PROJECT_URL}/addTarget/`)

export const postTarget = async(projectId, data) => {
  try {
    const response = await axios.post(`${PROJECT_URL}/addTarget/${projectId}`, data)
    console.log(response.data)
    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

export const editTarget = async(targetId, data) => {
  try {
    const response = await axios.put(`${PROJECT_URL}/editTarget/${targetId}`, data)
    console.log(response.data)
    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

export const getTargetByProjectId = async(projectId) => {
  try {
    const response = await axios.get(`${PROJECT_URL}/gettarget/${projectId}`)
    console.log(response.data)
    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

export const changeTargetStatus = async(status, targetId) => {
  try {
    const response = await axios.put(`${PROJECT_URL}/updateTargetStatus/${targetId}`, {status})
    console.log(response.data)
    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

console.log(`${PROJECT_URL}/gettargetbyid`)

export const getTargetById = async(targetId) => {
  try {
    console.log(`${PROJECT_URL}/gettargetbyid/${targetId}`)
    const response = await axios.get(`${PROJECT_URL}/gettargetbyid/${targetId}`)
    console.log(response.data)
    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

export const getTargetByEmpId = async(employeeId) => {
  try {
    const response = await axios.get(`${PROJECT_URL}/targets/${employeeId}`)
    console.log(response.data)
    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}


export const deleteTargetById = async(targetId) => {
  try {
    const response = await axios.delete(`${PROJECT_URL}/deletetarget/${targetId}`)
    console.log(response.data)
    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error 
  }
}

export async function uploaddocs(formData: any, id:string): Promise<any> {
  try {
    console.log(`${POST_DOCS_URL}${id}`)
    const response: AxiosResponse<any> = await axios.post(`${POST_DOCS_URL}${id}`, formData);
    return response.data;
  } catch (error) {
    // Handle errors here
    console.error("Add client error:", error);
    throw error;
  }
}

export async function getDocs(docid: string): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.get(`${PROJECT_URL}/files/${docid}`);
    return response.data;
  } catch (error) {
    // Handle errors here
    console.error("Add Client error:", error);
    throw error;
  }
}

export const deletedocs = async(id:string, docid:string) => {
  try {
  const response  = await axios.delete(`${PROJECT_URL}/files/${id}/${docid}`)
  return response.data
} catch (error) {
  // Handle errors here
  console.error("Add client error:", error);
  throw error;
}
}

export const editdocs = async(data, id:string, docid:string) => {
  try {
  const response  = await axios.put(`${PROJECT_URL}/files/${id}/${docid}`, data)
  return response.data
} catch (error) {
  // Handle errors here
  console.error("Add client error:", error);
  throw error;
}
}

export const getProjectPerformance = async (orgid: string) => {
  try {
    const response = await axios.get(`${PROJECT_PERFORMANCE_URL}/projectreports/${orgid}`)
    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

export const postSalesOrder = async (data) => {
  try {
    const response = await axios.post(`${SALES_ORDER}/add-sales-order-in-project`, data)
    console.log("Response of project performance", response.data);
    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

export const getTransactionsByProjectId = async (id) => {
  try {
    const response = await axios.get(`${TRANSACTIONS_URL}/project/${id}`)
    console.log("Response of project performance", response.data);
    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

//get Project Report (fetch Inspection form data)
export async function getProjectReports(orgid) {
  try {
    const response = await axios.get(`${PROJECT_URL}/projectReport/getAllprojectReport/${orgid}`);
    return response.data;
  } catch (error) {
    console.error("Add Client error:", error);
    throw error;
  }
}

//Post Project Report (submit Inspection form data)
export async function postProjectReport(data) {
  try {
    const response = await axios.post(`${PROJECT_URL}/projectReport`, data)
    console.log('data submitted successfully:', response.data)
    return response.data
  } catch (error) {
    console.error('Error submitting data:', error)
  }
}

export async function getOrdersByProjectId(projectid: string): Promise<Array<any>> {
  try {
    const response: AxiosResponse<any[]> = await axios.get(`${ORDER_MANAGEMENT_URL}/getordersbyproject/${projectid}`)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add property error:', error)
    throw error
  }
}

export async function getPurchaseOrdersByProjectId(projectid: string): Promise<Array<any>> {
  try {
    const response: AxiosResponse<any[]> = await axios.get(`${PURCHASE_ORDER_MANAGEMENT_URL}/getordersbyproject/${projectid}`)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add property error:', error)
    throw error
  }
}

export async function getProjectReportSubmissionCount(orgid: string) {
  try {
    const response= await axios.get(`${PROJECT_URL}/projectReport/project-submission-count/${orgid}`)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add client error:', error)
    throw error
  }
}

//get Project Report for a particular project (fetch Inspection form data)
export async function getProjectReportsByProjectId(id) {
  try {
    const response = await axios.get(`${PROJECT_URL}/projectReport/project/${id}`);
    return response.data;
  } catch (error) {
    console.error("Add Client error:", error);
    throw error;
  }
}

//Post Project Report Submission (submit Inspection form Answers)
export async function postProjectReportSubmission(data) {
  try {
    const response = await axios.post(`${PROJECT_URL}/projectReportSubmission`, data)
    console.log('data submitted successfully:', response.data)
    return response.data
  } catch (error) {
    console.error('Error submitting data:', error)
  }
}

//get all submissions by projectReport id
export async function getProjectReportSubmissionByReportId(reportId) {
  try {
    const response= await axios.get(`${PROJECT_URL}/projectReportSubmission/projectReport/${reportId}/submissions`)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add client error:', error)
    throw error
  }
}

//get submission details by id
export async function getProjectReportSubmissionById(id) {
  try {
    const response= await axios.get(`${PROJECT_URL}/projectReportSubmission/submission/${id}`)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add client error:', error)
    throw error
  }
}
//change Project Status
export const changeProjectStatus = async(status, projectId) => {
  try {
    const response = await axios.put(`${PROJECT_URL}/updateStatus/${projectId}`, {status})
    console.log(response.data)
    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

//get Project Summary
export async function getProjectSummary(orgid: string) {
  try {
    const response = await axios.get(`${PROJECT_URL}/projectsSummary/${orgid}`);
    return response.data;
  } catch (error) {
    console.error("Add Client error:", error);
    throw error;
  }
}

//get Project Targets details
export async function getProjectTargets(orgid) {
  try {
    const response = await axios.get(`${PROJECT_URL}/projects-with-targets/${orgid}`);
    return response.data;
  } catch (error) {
    console.error("Add Client error:", error);
    throw error;
  }
}

//get Project Budgets details
export async function getProjectBudgets(orgid) {
  try {
    const response = await axios.get(`${PROJECT_URL}/projectBudget/${orgid}`);
    return response.data;
  } catch (error) {
    console.error("Add Client error:", error);
    throw error;
  }
}

export async function postProjectTimesheet(data: any) {
  try {
    const response = await axios.post(`${PROJECT_TIMESHEET_URL}/add`, data);
    return response.data;
  } catch (error) {
    console.error("Add Client error:", error);
    throw error;
  }
}

export async function postTimesheetPayroll(data: any) {
  try {
    const response = await axios.post(`${TIMESHEET_PAYROLL_URL}`, data);
    return response.data;
  } catch (error) {
    console.error("Add Client error:", error);
    throw error;
  }
}

export async function getTimesheetPayrollByOrg(orgid: string) {
  try {
    const response = await axios.get(`${TIMESHEET_PAYROLL_URL}/project/${orgid}`);
    return response.data;
  } catch (error) {
    console.error("Add Client error:", error);
    throw error;
  }
}

export async function getTimesheetPayrollById(id: string) {
  try {
    const response = await axios.get(`${TIMESHEET_PAYROLL_URL}/gettimesheetpayrollbyid/${id}`);
    return response.data;
  } catch (error) {
    console.error("Add Client error:", error);
    throw error;
  }
}

export async function getTimesheetPayrollByProject(id: string) {
  try {
    const response = await axios.get(`${TIMESHEET_PAYROLL_URL}/payrollbyproject/${id}`);
    return response.data;
  } catch (error) {
    console.error("Add Client error:", error);
    throw error;
  }
}

export async function getProjectTimesheet(projectid: string) {
  try {
    const response = await axios.get(`${PROJECT_TIMESHEET_URL}/get/${projectid}`);
    return response.data;
  } catch (error) {
    console.error("Add Client error:", error);
    throw error;
  }
}

export async function getAllProjectTimesheetByOrg(orgid: string) {
  try {
    const response = await axios.get(`${PROJECT_TIMESHEET_URL}/get/all/${orgid}`);
    return response.data;
  } catch (error) {
    console.error("Add Client error:", error);
    throw error;
  }
}

export async function editProjectTimesheet(projectid: string, data: any) {
  try {
    const response = await axios.put(`${PROJECT_TIMESHEET_URL}/update/${projectid}`, data);
    return response.data;
  } catch (error) {
    console.error("Add Client error:", error);
    throw error;
  }
}

export async function getCategoryTimesheet(projectid: string) {
  try {
    const response = await axios.get(`${PROJECT_TIMESHEET_URL}/category-summary/${projectid}`);
    return response.data;
  } catch (error) {
    console.error("Add Client error:", error);
    throw error;
  }
}

export async function getIndividualTimesheet(projectid: string) {
  try {
    const response = await axios.get(`${PROJECT_TIMESHEET_URL}/individual-summary/${projectid}`);
    return response.data;
  } catch (error) {
    console.error("Add Client error:", error);
    throw error;
  }
}

export async function getProjectInfo(projectid: string) {
  try {
    const response = await axios.get(`${PROJECT_TIMESHEET_URL}/project-data/${projectid}`);
    return response.data;
  } catch (error) {
    console.error("Add Client error:", error);
    throw error;
  }
}

export async function updateProjectFeature(companyid: string, data: any) {
  try {
    const response = await axios.put(`${COMPANY_URL}/project-feature/${companyid}`, data);
    return response.data;
  } catch (error) {
    console.error("Add Client error:", error);
    throw error;
  }
}

export async function updateInvoiceFeature(companyid: string, data: any) {
  try {
    const response = await axios.put(`${COMPANY_URL}/invoice-feature/${companyid}`, data);
    return response.data;
  } catch (error) {
    console.error("Add Client error:", error);
    throw error;
  }
}

//get Project Status details
export async function getProjectStatus(orgid: string) {
  try {
    const response = await axios.get(`${PROJECT_URL}/project-status/${orgid}`);
    return response.data;
  } catch (error) {
    console.error("Add Client error:", error);
    throw error;
  }
}