import axios, {AxiosResponse} from 'axios'
const API_URL = process.env.REACT_APP_THEME_API_URL
const EXCEL_IMPORT_URL = process.env.REACT_APP_EXCEL_IMPORT_URL
const VENDOR_URL = `${API_URL}/purchases`
const EMPLOYEE_URL = `${API_URL}/employee`
const SALES_URL = `${API_URL}/sales`
const RFQ_URL = `${API_URL}/purchases/rfq`
const RFP_URL = `${API_URL}/purchases/rfp`
const ORDER_MANAGEMENT_URL = `${API_URL}/purchases/orderManagement`
const ORDER_ID = `${API_URL}/lastInsertedId`
const BILL_URL = `${API_URL}/purchases/bills`
const PAYMENT_MADE_URL = `${API_URL}/purchases/paymentMade`
const SEARCH_USERS_URL = `${API_URL}/agent/search`
const ACCOUNTS_URL = `${API_URL}/accounts`

const REPORTS_URL = `${API_URL}/reports`
const VENDOR_PERFORMANCE_URL = `${REPORTS_URL}/vendorperformance`
const NOTES_URL = `${API_URL}/notes`
const TERMS_URL = `${API_URL}/terms`

export const getNotes = async (name: string) => {
  try {
    const response = await axios.get(`${NOTES_URL}/${name}`)
    console.log(response.data)
    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}
export const getTerms = async (name: string) => {
  try {
    const response = await axios.get(`${TERMS_URL}/${name}`)
    console.log(response.data)
    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

export const getEmployee = async (orgid: string) => {
  try {
    const response = await axios.get(`${EMPLOYEE_URL}/${orgid}`)
    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

export const getCustomer = async (query: string) => {
  try {
    const response = await axios.get(`${SALES_URL}/customer?${query}`)
    console.log(response.data)

    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

export async function bulkUploadVendor(data, company, organization) {
  try {
    const response = await axios.post(
      `${EXCEL_IMPORT_URL}/bulk-upload/vendor/${company}/${organization}`,
      data,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    )
    return response.data
  } catch (error) {
    console.error('Bulk Upload Vendor error:', error)
    throw error
  }
}

export const getVendor = async (query: string, orgid: string) => {
  try {
    console.log(`${VENDOR_URL}/vendor/${orgid}/?${query}`)
    const response = await axios.get(`${VENDOR_URL}/vendor/${orgid}/?${query}`)
    console.log(response.data)

    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

export const addVendorType = async (orgid: string, data: any) => {
  try {
    const response = await axios.put(`${VENDOR_URL}/vendor/add-vendor-type/${orgid}`, data)
    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

export const getVendorType = async (orgid: string) => {
  try {
    const response = await axios.get(`${VENDOR_URL}/vendor/get-vendor-type/${orgid}`)
    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

export const getVendorByAgentId = async (query: string, agentid: string) => {
  try {
    const response = await axios.get(`${VENDOR_URL}/vendor/getvendorbyagent/${agentid}/?${query}`)
    console.log(response.data)

    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}
console.log(`${VENDOR_URL}/vendor/`)
export const getVendorById = async (id) => {
  try {
    const response = await axios.get(`${VENDOR_URL}/vendor/getvendorbyid/${id}`)
    console.log(response.data)

    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}
export const deactivateVendor = async (id) => {
  try {
    const response = await axios.put(`${VENDOR_URL}/vendor/deactivate/${id}`)
    console.log(response.data)

    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

export const postVendor = async (data) => {
  try {
    const response = await axios.post(`${VENDOR_URL}/vendor`, data)
    console.log(response.data)

    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}
export const editVendor = async (data, id) => {
  try {
    const response = await axios.put(`${VENDOR_URL}/vendor/${id}`, data)
    console.log(response.data)

    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

export const getVendorDetails = async (id) => {
  try {
    const response = await axios.get(`${VENDOR_URL}/vendor/getvendorbyid/${id}`)
    console.log(response.data)

    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

// export async function getInvoicebyids(id: string): Promise<any> {
//   try {
//     const response: AxiosResponse<any> = await axios.get(`${INVOICE_URL}/invoicebyid/${id}`)
//     return response.data
//   } catch (error) {
//     // Handle errors here
//     console.error('Add property error:', error)
//     throw error
//   }
// }

export const getPurchaseQuotation = async (orgid: string) => {
  try {
    const response = await axios.get(`${VENDOR_URL}/quotation/${orgid}`)
    console.log(response.data)

    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

export async function getPurchaseQuotationByAgentId(agentId): Promise<Array<any>> {
  try {
    const response: AxiosResponse<any[]> = await axios.get(
      `${VENDOR_URL}/quotation/agent/${agentId}`
    )
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add property error:', error)
    throw error
  }
}

export async function getFilteredPurchaseQuotation(
  query: string,
  orgid: string,
  page = 1,
  limit = 25,
  date = null
): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.get(
      `${VENDOR_URL}/quotation/filter/${orgid}?${query}&page=${page}&limit=${limit}&startDate=${date?.startDate}&endDate=${date?.endDate}`
    )
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add property error:', error)
    throw error
  }
}
export async function getFilteredPurchaseQuotationByAgentId(
  query: string,
  agentId,
  page = 1,
  limit = 25,
  date = null
): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.get(
      `${VENDOR_URL}/quotation/agent/filter/${agentId}?${query}&page=${page}&limit=${limit}&startDate=${date?.startDate}&endDate=${date?.endDate}`
    )
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add property error:', error)
    throw error
  }
}

export async function invalidatePurchaseOrder(poId: string): Promise<Array<any>> {
  try {
    const response: AxiosResponse<any> = await axios.put(
      `${VENDOR_URL}/quotation/invalidate/${poId}`
    )
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add property error:', error)
    throw error
  }
}

export async function invalidatePurchaseReceive(id: string): Promise<Array<any>> {
  try {
    const response: AxiosResponse<any> = await axios.put(
      `${VENDOR_URL}/purchaseReceived/invalidate/${id}`
    )
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add property error:', error)
    throw error
  }
}

export async function invalidateBills(id: string): Promise<Array<any>> {
  try {
    const response: AxiosResponse<any> = await axios.put(`${VENDOR_URL}/bills/invalidate/${id}`)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add property error:', error)
    throw error
  }
}

export async function invalidatePaymentMade(id: string): Promise<Array<any>> {
  try {
    const response: AxiosResponse<any> = await axios.put(
      `${VENDOR_URL}/paymentMade/invalidate/${id}`
    )
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add property error:', error)
    throw error
  }
}

export const getPurchaseQuotationById = async (id) => {
  try {
    const response = await axios.get(`${VENDOR_URL}/quotation/quotationbyid/${id}`)
    console.log(response.data)

    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

export const postPurchaseQuotation = async (data, orderid: string) => {
  try {
    const response = await axios.post(`${VENDOR_URL}/quotation/${orderid}`, data)
    console.log(response.data)

    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

export const editPurchaseOrder = async (data, poid: string) => {
  try {
    const response = await axios.put(`${VENDOR_URL}/quotation/${poid}`, data)

    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

export const revisedPurchaseOrder = async (data, poid: string) => {
  try {
    const response = await axios.put(`${VENDOR_URL}/quotation/revised/${poid}`, data)

    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

export const postPurchaseOrderWithProjectId = async (data) => {
  try {
    const response = await axios.post(`${VENDOR_URL}/quotation/add-purchase-order-in-project`, data)
    console.log(response.data)

    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

export const getPurchaseQuotationLength = async () => {
  try {
    const response = await axios.get(`${VENDOR_URL}/quotation/quotationlength`)
    console.log(response.data)

    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}
export const getPurchaseReceive = async (orgid: string) => {
  try {
    const response = await axios.get(`${VENDOR_URL}/purchaseReceived/${orgid}`)
    console.log(response.data)

    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

export async function getPurchaseReceiveByAgentId(agentId): Promise<Array<any>> {
  try {
    const response: AxiosResponse<any[]> = await axios.get(
      `${VENDOR_URL}/purchaseReceived/agent/${agentId}`
    )
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add property error:', error)
    throw error
  }
}

export async function getFilteredPurchaseReceive(
  query: string,
  orgid: string,
  page = 1,
  limit = 25,
  date = null
): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.get(
      `${VENDOR_URL}/purchaseReceived/filter/${orgid}?${query}&page=${page}&limit=${limit}&startDate=${date?.startDate}&endDate=${date?.endDate}`
    )
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add property error:', error)
    throw error
  }
}
export async function getFilteredPurchaseReceiveByAgentId(
  query: string,
  agentId,
  page = 1,
  limit = 25,
  date = null
): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.get(
      `${VENDOR_URL}/purchaseReceived/agent/filter/${agentId}?${query}&page=${page}&limit=${limit}&startDate=${date?.startDate}&endDate=${date?.endDate}`
    )
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add property error:', error)
    throw error
  }
}

export const getPurchaseReceiveById = async (id) => {
  try {
    const response = await axios.get(`${VENDOR_URL}/purchaseReceived/getpurchasereceivebyid/${id}`)
    console.log(response.data)

    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

export const getPurchaseReceiveByPurchaseId = async (id) => {
  try {
    const response = await axios.get(
      `${VENDOR_URL}/purchaseReceived/getpurchasereceivebypurchaseid/${id}`
    )
    console.log(response.data)

    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

export const postPurchaseReceive = async (data, orderid: string) => {
  try {
    const response = await axios.post(`${VENDOR_URL}/purchaseReceived/${orderid}`, data)
    console.log(response.data)

    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

export const postPartialPurchaseReceive = async (data) => {
  try {
    const response = await axios.post(`${VENDOR_URL}/purchaseReceived/partial`, data)
    console.log(response.data)

    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

export const getPurchaseReceiveLength = async () => {
  try {
    const response = await axios.get(`${VENDOR_URL}/purchaseReceived/purchaseReceiveLength`)
    console.log(response.data)

    return response.data + 1
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

export async function getRFQLength(): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.get(`${RFQ_URL}/rfqlength`)
    return response.data + 1
  } catch (error) {
    // Handle errors here
    console.error('Add property error:', error)
    throw error
  }
}
export async function getRFQLastId(orgid: string): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.get(`${ORDER_ID}/${orgid}/rfq`)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add property error:', error)
    throw error
  }
}
export async function getRFPLastId(orgid: string): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.get(`${ORDER_ID}/${orgid}/rfp`)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add property error:', error)
    throw error
  }
}
export async function getPurchaseOrderLastId(orgid: string): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.get(`${ORDER_ID}/${orgid}/purchaseQuotation`)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add property error:', error)
    throw error
  }
}
export async function getPurchaseReceiveLastId(orgid: string): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.get(`${ORDER_ID}/${orgid}/purchaseReceived`)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add property error:', error)
    throw error
  }
}

export const getStocks = async (orgid: string) => {
  try {
    const response = await axios.get(`${API_URL}/products/${orgid}`)
    console.log(response.data)

    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

export const postRFQ = async (quote: any, orderId: string): Promise<any> => {
  try {
    const response: AxiosResponse<any> = await axios.post(`${RFQ_URL}/${orderId}`, quote)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add client error:', error)
    throw error
  }
}

export const postRFP = async (quote: any, orderId: string): Promise<any> => {
  try {
    const response: AxiosResponse<any> = await axios.post(`${RFP_URL}/${orderId}`, quote)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add client error:', error)
    throw error
  }
}

export const editRFQ = async (quote: any, id: string): Promise<any> => {
  try {
    const response: AxiosResponse<any> = await axios.put(`${RFQ_URL}/${id}`, quote)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add client error:', error)
    throw error
  }
}

export const editRFP = async (quote: any, id: string): Promise<any> => {
  try {
    const response: AxiosResponse<any> = await axios.put(`${RFP_URL}/${id}`, quote)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add client error:', error)
    throw error
  }
}

export const revisedRFP = async (quote: any, id: string): Promise<any> => {
  try {
    const response: AxiosResponse<any> = await axios.put(`${RFP_URL}/revised/${id}`, quote)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add client error:', error)
    throw error
  }
}

export const revisedRFQ = async (quote: any, id: string): Promise<any> => {
  try {
    const response: AxiosResponse<any> = await axios.put(`${RFQ_URL}/revised/${id}`, quote)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add client error:', error)
    throw error
  }
}

export async function getRFQ(orgid: string): Promise<Array<any>> {
  try {
    const response: AxiosResponse<any[]> = await axios.get(`${RFQ_URL}/${orgid}`)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add property error:', error)
    throw error
  }
}

export async function getFilteredRFQ(
  query: string,
  orgid: string,
  page = 1,
  limit = 25,
  date
): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.get(
      `${RFQ_URL}/filter/${orgid}?${query}&page=${page}&limit=${limit}&startDate=${date?.startDate}&endDate=${date?.endDate}`
    )
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add property error:', error)
    throw error
  }
}

export async function getRFQByAgentId(agentId): Promise<Array<any>> {
  try {
    const response: AxiosResponse<any[]> = await axios.get(`${RFQ_URL}/agent/${agentId}`)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add property error:', error)
    throw error
  }
}

export async function getFilteredRFP(
  query: string,
  orgid: string,
  page = 1,
  limit = 25,
  date
): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.get(
      `${RFP_URL}/filter/${orgid}?${query}&page=${page}&limit=${limit}&startDate=${date?.startDate}&endDate=${date?.endDate}`
    )
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add property error:', error)
    throw error
  }
}

export async function getRFPByProjectId(projectId: string): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.get(`${RFP_URL}/project/${projectId}`)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add property error:', error)
    throw error
  }
}

export async function getFilteredRFQByAgentId(
  query: string,
  agentId,
  page = 1,
  limit = 25,
  date
): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.get(
      `${RFQ_URL}/agent/filter/${agentId}?${query}&page=${page}&limit=${limit}&startDate=${date?.startDate}&endDate=${date?.endDate}`
    )
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add property error:', error)
    throw error
  }
}

export async function getFilteredRFPByAgentId(
  query: string,
  agentId,
  page = 1,
  limit = 25,
  date
): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.get(
      `${RFP_URL}/agent/filter/${agentId}?${query}&page=${page}&limit=${limit}&startDate=${date?.startDate}&endDate=${date?.endDate}`
    )
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add property error:', error)
    throw error
  }
}

export async function getRFQById(id): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.get(`${RFQ_URL}/getrfqbyid/${id}`)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add property error:', error)
    throw error
  }
}

export async function getRFPById(id): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.get(`${RFP_URL}/getrfpbyid/${id}`)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add property error:', error)
    throw error
  }
}

export async function getOrders(orgid: string): Promise<Array<any>> {
  try {
    const response: AxiosResponse<any[]> = await axios.get(
      `${ORDER_MANAGEMENT_URL}/getorders/${orgid}`
    )
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add property error:', error)
    throw error
  }
}

export async function getOrdersWithProject(orgid: string): Promise<Array<any>> {
  try {
    const response: AxiosResponse<any[]> = await axios.get(
      `${ORDER_MANAGEMENT_URL}/getorders/with-project/${orgid}`
    )
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add property error:', error)
    throw error
  }
}

export async function getOrdersByAgentId(agentid: string): Promise<Array<any>> {
  try {
    const response: AxiosResponse<any[]> = await axios.get(
      `${ORDER_MANAGEMENT_URL}/getorders/agent/${agentid}`
    )
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add property error:', error)
    throw error
  }
}

export const postorder = async (data: any): Promise<any> => {
  try {
    const response: AxiosResponse<any> = await axios.post(`${ORDER_MANAGEMENT_URL}`, data)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add client error:', error)
    throw error
  }
}

export const editorder = async (id: string, data: any): Promise<any> => {
  try {
    const response: AxiosResponse<any> = await axios.put(`${ORDER_MANAGEMENT_URL}/${id}`, data)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add client error:', error)
    throw error
  }
}

export async function getOrderById(id: string): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.get(
      `${ORDER_MANAGEMENT_URL}/orderdetails/${id}`
    )
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add property error:', error)
    throw error
  }
}

export async function getOrderDetailsById(companyid: string, orderId: string): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.get(`${ORDER_MANAGEMENT_URL}/${orderId}`)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add property error:', error)
    throw error
  }
}

export async function changeOrderStatus(past, present, orderId) {
  const body = {past, present, orderId}
  try {
    const response = await axios.put(`${ORDER_MANAGEMENT_URL}/changeorderstatus`, body)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add Company error:', error)
    throw error
  }
}

export async function getPurchaseOrderByVendorId(id: string): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.get(
      `${ORDER_MANAGEMENT_URL}/purchaseQuotations/${id}`
    )
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add property error:', error)
    throw error
  }
}
export async function getPurchaseReceiveByVendorId(id: string): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.get(
      `${VENDOR_URL}/purchaseReceived/vendorId/${id}`
    )
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add property error:', error)
    throw error
  }
}

export async function setRFQStatusApprove(id: string) {
  try {
    const response = await axios.put(`${VENDOR_URL}/rfq/statusapprove/${id}`)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add Account error:', error)
    throw error
  }
}

export async function setRFQStatusReject(id: string) {
  try {
    const response = await axios.put(`${VENDOR_URL}/rfq/statusreject/${id}`)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add Account error:', error)
    throw error
  }
}

export async function setRFPStatusApprove(id: string) {
  try {
    const response = await axios.put(`${VENDOR_URL}/rfp/statusapprove/${id}`)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add Account error:', error)
    throw error
  }
}

export async function setRFPStatusReject(id: string) {
  try {
    const response = await axios.put(`${VENDOR_URL}/rfp/statusreject/${id}`)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add Account error:', error)
    throw error
  }
}

export async function setPurchaseOrderStatusApprove(id: string) {
  try {
    const response = await axios.put(`${VENDOR_URL}/quotation/statusapprove/${id}`)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add Account error:', error)
    throw error
  }
}

export async function setPurchaseOrderStatusReject(id: string) {
  try {
    const response = await axios.put(`${VENDOR_URL}/quotation/statusreject/${id}`)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add Account error:', error)
    throw error
  }
}

export async function setPurchaseReceiveStatusApprove(id: string) {
  try {
    const response = await axios.put(`${VENDOR_URL}/purchaseReceived/statusapprove/${id}`)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add Account error:', error)
    throw error
  }
}

export async function setPurchaseReceiveStatusReject(id: string) {
  try {
    const response = await axios.put(`${VENDOR_URL}/purchaseReceived/statusreject/${id}`)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add Account error:', error)
    throw error
  }
}

export async function setBillsStatusApprove(id: string) {
  try {
    const response = await axios.put(`${VENDOR_URL}/bills/statusapprove/${id}`)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add Account error:', error)
    throw error
  }
}

export async function setBillsStatusReject(id: string) {
  try {
    const response = await axios.put(`${VENDOR_URL}/bills/statusreject/${id}`)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add Account error:', error)
    throw error
  }
}

export async function setPaymentMadeStatusApprove(id: string) {
  try {
    const response = await axios.put(`${VENDOR_URL}/paymentMade/statusapprove/${id}`)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add Account error:', error)
    throw error
  }
}

export async function setPaymentMadeStatusReject(id: string) {
  try {
    const response = await axios.put(`${VENDOR_URL}/paymentMade/statusreject/${id}`)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add Account error:', error)
    throw error
  }
}

export const getBills = async (orgid: string) => {
  try {
    const response = await axios.get(`${BILL_URL}/${orgid}`)
    console.log(response.data)

    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

export async function getBillsByAgentId(agentId): Promise<Array<any>> {
  try {
    const response: AxiosResponse<any[]> = await axios.get(`${BILL_URL}/agent/${agentId}`)
    return response.data
  } catch (error) {
    console.error('Add property error:', error)
    throw error
  }
}

export async function getFilteredBills(
  query: string,
  orgid: string,
  page = 1,
  limit = 25,
  date = null
): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.get(
      `${BILL_URL}/filter/${orgid}?${query}&page=${page}&limit=${limit}&startDate=${date?.startDate}&endDate=${date?.endDate}`
    )
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add property error:', error)
    throw error
  }
}
export async function getFilteredBillsByAgentId(
  query: string,
  agentId,
  page = 1,
  limit = 25,
  date = null
): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.get(
      `${BILL_URL}/agent/filter/${agentId}?${query}&page=${page}&limit=${limit}&startDate=${date?.startDate}&endDate=${date?.endDate}`
    )
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add property error:', error)
    throw error
  }
}

export const getBillsById = async (id) => {
  try {
    const response = await axios.get(`${BILL_URL}/billsById/${id}`)
    console.log(response.data)

    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

export const postBills = async (data, orderid: string) => {
  try {
    const response = await axios.post(`${BILL_URL}/${orderid}`, data)
    console.log(response.data)

    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

export const updateBills = async (data, id: string) => {
  try {
    const response = await axios.put(`${BILL_URL}/${id}`, data)
    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

export const revisedBills = async (data, id: string) => {
  try {
    const response = await axios.put(`${BILL_URL}/revised/${id}`, data)
    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}
//get bills of a particular vendor
export const getBillsByVenodrId = async (id) => {
  try {
    const response = await axios.get(`${BILL_URL}/billOfVendor/${id}`)
    console.log(response.data)

    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

export async function getBillsLastId(orgid: string): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.get(`${ORDER_ID}/${orgid}/bills`)
    console.log(response.data)

    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add property error:', error)
    throw error
  }
}

export async function getPaymentMadeLastId(orgid: string): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.get(`${ORDER_ID}/${orgid}/paymentMade`)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add property error:', error)
    throw error
  }
}
//get paymentMade
export const getPaymentMade = async (orgid: string) => {
  try {
    const response = await axios.get(`${PAYMENT_MADE_URL}/${orgid}`)
    console.log('paymentMade data', response.data)

    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

export const getPaymentMadeByAgentId = async (agentid: string) => {
  try {
    const response = await axios.get(`${PAYMENT_MADE_URL}/agent/${agentid}`)
    console.log(response.data)
    console.log('paymentMade data agent id', response.data)
    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

export async function getFilteredPaymentMade(
  query: string,
  orgid: string,
  page = 1,
  limit = 25,
  date = null
): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.get(
      `${PAYMENT_MADE_URL}/filter/${orgid}?${query}&page=${page}&limit=${limit}&startDate=${date?.startDate}&endDate=${date?.endDate}`
    )
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add property error:', error)
    throw error
  }
}
export async function getFilteredPaymentMadeByAgentId(
  query: string,
  agentId,
  page = 1,
  limit = 25,
  date = null
): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.get(
      `${PAYMENT_MADE_URL}/agent/filter/${agentId}?${query}&page=${page}&limit=${limit}&startDate=${date?.startDate}&endDate=${date?.endDate}`
    )
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add property error:', error)
    throw error
  }
}

export const getPaymentMadeById = async (id) => {
  try {
    const response = await axios.get(`${PAYMENT_MADE_URL}/paymentMadeById/${id}`)
    console.log(response.data)

    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

//post paymentMade
export const postPaymentMade = async (data, orderid: string): Promise<any> => {
  try {
    const response: AxiosResponse<any> = await axios.post(`${PAYMENT_MADE_URL}/${orderid}`, data)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add client error:', error)
    throw error
  }
}

export const getStocksForPurchaseOrder = async (orgid: string) => {
  try {
    const response = await axios.get(`${API_URL}/products/purchaseOrder/${orgid}`)
    console.log(response.data)

    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

export async function searchUseragent(searchTerm: string, orgid: string): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.get(
      `${SEARCH_USERS_URL}/${orgid}?search=${searchTerm}`
    )
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('search agent error:', error)
    throw error
  }
}

export async function linkUserToVendor(ids: any): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.put(`${VENDOR_URL}/vendor/userassign`, ids)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('link agent error:', error)
    throw error
  }
}

export async function updateRFQValidationStatus(id: string, data: any): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.put(`${RFQ_URL}/changevalidation/${id}`, data)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Quote update error:', error)
    throw error
  }
}

export async function updateRFPValidationStatus(id: string, data: any): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.put(`${RFP_URL}/changevalidation/${id}`, data)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Quote update error:', error)
    throw error
  }
}

export async function getRFQByRFQId(id: string): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.get(`${RFQ_URL}/getrfqbyid/${id}`)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('RFQ fetch error:', error)
    throw error
  }
}

//get Vendor Performance
export const getVendorPerformance = async (orgid: string) => {
  try {
    const response = await axios.get(`${VENDOR_PERFORMANCE_URL}/vendorreports/${orgid}`)
    console.log('Response of sales performance', response.data)
    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

//PurchaseOrder mail
export const sendPurchaseOrderMailByID = async (id, data) => {
  try {
    const response = await axios.post(`${API_URL}/sendPDF/purchaseorder/${id}`, data)
    console.log(response.data)

    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

export const sendPaymentMadeMailByID = async (id, data) => {
  try {
    const response = await axios.post(`${API_URL}/sendPDF/paymentMade/${id}`, data)
    console.log(response.data)

    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

// send RFQ mail
export const sendRFQMailByID = async (id, data) => {
  try {
    const response = await axios.post(`${API_URL}/sendPDF/rfq/${id}`, data)
    console.log(response.data)

    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

// send PurchaseReceive mail
export const sendPurchaseReceiveMailByID = async (id, data) => {
  try {
    const response = await axios.post(`${API_URL}/sendPDF/purchaseReceived/${id}`, data)
    console.log(response.data)

    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

// send Bills mail
export const sendBillsMailByID = async (id, data) => {
  try {
    const response = await axios.post(`${API_URL}/sendPDF/bills/${id}`, data)
    console.log(response.data)

    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

// send Vendor Statement mail
export const sendVendorStatementMailByID = async (id, data) => {
  try {
    const response = await axios.post(`${API_URL}/sendPDF/vendorStatement/${id}`, data)
    console.log(response.data)

    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

export const getStatementsByVendorId = async (id, date) => {
  try {
    const response = await axios.get(
      `${ACCOUNTS_URL}/statementofaccounts/vendor/${id}?date=${date}`
    )
    console.log(response.data)

    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

export const getmailsByVendorId = async (id) => {
  try {
    const response = await axios.get(`${VENDOR_URL}/vendor/mails/${id}`)
    console.log(response.data)

    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

export const getIncoTerms = async (orgid: string) => {
  try {
    const response = await axios.get(`${VENDOR_URL}/quotation/get-inco-term/${orgid}`)
    console.log('Response of Inco Terms', response.data)
    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

export const addIncoTerms = async (orgid: string, data) => {
  try {
    const response = await axios.put(`${VENDOR_URL}/quotation/add-inco-term/${orgid}`, data)
    console.log('Response of Inco Terms', response.data)
    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}
export const getPaymentTerms = async (orgid: string) => {
  try {
    const response = await axios.get(`${VENDOR_URL}/quotation/get-payment-terms/${orgid}`)
    console.log('Response of Inco Terms', response.data)
    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

export const addPaymentTerms = async (orgid: string, data) => {
  try {
    const response = await axios.put(`${VENDOR_URL}/quotation/add-payment-term/${orgid}`, data)
    console.log('Response of Inco Terms', response.data)
    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}
