import React, {useState} from 'react'
import {useNavigate, useParams} from 'react-router-dom'
import {useAuth} from '../../../modules/auth'
import {createCategory} from '../Core/_categoryRequest'
import {useDispatch} from 'react-redux'
import {addCategory} from '../../../redux/inventory/category/categorySlice'

export default function AddCategory() {
  const navigate = useNavigate()
  const {currentUser} = useAuth()
  const dispatch = useDispatch()
  const [formData, setFormData] = useState({
    categoryName: '',
    description: '',
    type: 'service',
  })

  const handleChange = (e) => {
    setFormData({...formData, [e.target.name]: e.target.value})
  }

  const handleSubmit = async () => {
    if (!formData.categoryName.trim() || !formData.description.trim()) {
      alert('Name and Description are required fields.')
      return
    }
    const data = {
      ...formData,
      company: currentUser?.company,
      organization: currentUser?.organization,
    }
    const res = await createCategory(data)
    if (res) {
      dispatch(
        addCategory({
          categoryName: formData.categoryName,
          description: formData.description,
          type: formData.type,
          _id: res?._id,
        })
      )
      // navigate('/category')
      navigate(-1)
    }
  }

  return (
    <div className='container'>
      <div className='card'>
        <div className='card-header d-flex align-items-center'>
          <h2 className='fw-bold'>Create New Category</h2>
        </div>
        <div className='card-body'>
          <form>
            <div className='mb-3'>
              <label htmlFor='name' className='form-label required'>
                Name
              </label>
              <input
                type='text'
                className='form-control form-control-solid w-50'
                id='categoryName'
                name='categoryName'
                value={formData.categoryName}
                onChange={handleChange}
              />
            </div>
            <div className='mb-3'>
              <label htmlFor='description' className='form-label required'>
                Description
              </label>
              <input
                type='text'
                className='form-control form-control-solid w-50'
                id='description'
                name='description'
                value={formData.description}
                onChange={handleChange}
              />
            </div>
            <div className='mb-3'>
              <label htmlFor='type' className='form-label'>
                Type
              </label>
              <select
                className='form-select form-control-solid w-50'
                id='type'
                name='type'
                value={formData.type}
                onChange={(e) => setFormData({...formData, type: e.target.value})}
              >
                {currentUser?.inventoryFeatures?.service && (
                  <option value='service'>Service</option>
                )}
                {currentUser?.inventoryFeatures?.goods && <option value='goods'>Goods</option>}
                {currentUser?.inventoryFeatures?.rentals && (
                  <option value='rentals'>Rentals</option>
                )}
                {currentUser?.inventoryFeatures?.consumables && (
                  <option value='consumables'>Consumables</option>
                )}
                {currentUser?.inventoryFeatures?.materials && (
                  <option value='materials'>Materials</option>
                )}
              </select>
            </div>
            <div className=''>
              <button type='button' className='btn btn-primary my-3' onClick={handleSubmit}>
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}
