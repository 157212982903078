import React, {useEffect} from 'react'
import {useState} from 'react'
import 'quill/dist/quill.snow.css' // Add css for snow theme
import Thumbnail from '../Components/Thumbnail'
import ProductDetails from '../Components/ProductDetails'
import Media from '../Components/Media'
import Shipping from '../Components/Shipping'
import {useNavigate} from 'react-router-dom'
import {
  createStock,
  getProductById,
  getStockAccounts,
  productImage,
  productMedia,
  updateProduct,
} from '../Core/_requests'
import AccountsInformation from '../Components/AccountsInformation'
import {useAuth} from '../../../modules/auth'
import {getCategoriesForProducts} from '../Core/_categoryRequest'
import {useParams} from 'react-router-dom'
import RentalDetails from '../Components/RentalDetails'
import CategoryChangeDetails from '../Components/CategoryChangeDetails'
import {useDispatch} from 'react-redux'

export default function EditProduct() {
  const {currentUser} = useAuth()
  const dispatch = useDispatch()
  const {id, categoryType} = useParams()
  const [loading, setLoading] = useState(false)
  const [previewImage, setPreviewImage] = useState(null) // Thumbnail
  const [thumbnail, setThumbnail] = useState(null)
  const [categories, setCategories] = useState('') //Product Details
  const [tags, setTags] = useState([])
  const [productName, setProductName] = useState('') //General
  const [productDescription, setProductDescription] = useState('')
  const [media, setMedia] = useState([]) //Media
  const [sku, setSku] = useState('')
  const [description, setDescription] = useState('')
  const [weight, setWeight] = useState('')
  const [width, setWidth] = useState('')
  const [height, setHeight] = useState('')
  const [length, setLength] = useState('')
  const [selectedImage, setSelectedImage] = useState(null)
  const [mediaName, setMediaName] = useState([])
  const [salesInfo, setSalesInfo] = useState(true)
  const [purchaseInfo, setPurchaseInfo] = useState(true)
  const [inventoryInfo, setInventoryInfo] = useState(true)
  const [salesAccount, setSalesAccount] = useState('')
  const [purchaseAccount, setPurchaseAccount] = useState('')
  const [inventoryAccount, setInventoryAccount] = useState('')
  const [inventoryAccounts, setInventoryAccounts] = useState([])
  const [categoriesOptions, setCategoriesOptions] = useState([])
  const [manufacturer, setManufacturer] = useState('')
  const [changeCategory, setChangeCategory] = useState(null)
  const [brand, setBrand] = useState('')
  const [upc, setUpc] = useState('')
  const [ean, setEan] = useState('')
  const [mpn, setMpn] = useState('')
  const [isbn, setIsbn] = useState('')
  const [unit, setUnit] = useState('')
  const [costPrice, setCostPrice] = useState('')
  const [sellingPrice, setSellingPrice] = useState('')
  const [openingStock, setOpeningStock] = useState('')
  const [openingStockRate, setOpeningStockRate] = useState('')
  const [reorderPoint, setReorderPoint] = useState('')
  const [mrq, setMrq] = useState('')
  const [mrqCheckbox, setMrqCheckbox] = useState(false)
  const [rentalType, setRentalType] = useState('vehicle')
  const navigate = useNavigate()

  const fetchData = async () => {
    const res = await getProductById(id)
    console.log('res', res)
    if (res) {
      setThumbnail(res.thumbnail)
      setCategories(res.category)
      setTags(res.tags)
      setProductName(res.productName)
      setProductDescription(res.productDescription)
      setSku(res.sku)
      setDescription(res.description)
      setWeight(res.weight)
      setWidth(res.width)
      setHeight(res.height)
      setLength(res.length)
      setRentalType(res?.rentalType)
      // setSalesInfo(res.salesInfo)
      // setPurchaseInfo(res.purchaseInfo)
      // setSalesAccount(res.salesAccount)
      // setPurchaseAccount(res.purchaseAccount)
      // setInventoryInfo(res.inventoryInfo)
      // setInventoryAccount(res.inventoryAccount)
      setManufacturer(res.manufacturer)
      setBrand(res.brand)
      setUpc(res.upc)
      setEan(res.ean)
      setMpn(res.mpn)
      setIsbn(res.isbn)
      setUnit(res.unit)
      setCostPrice(res.costPrice)
      setSellingPrice(res.price)
      setOpeningStock(res.inWarehouseQuantity)
      setOpeningStockRate(res.openingStockRate)
      setReorderPoint(res.reorderPoint)
      setCategories(res.category?._id)
      if (res.salesAccount) {
        setSalesAccount(res.salesAccount?._id)
        setSalesInfo(true)
      }
      if (res.purchaseAccount) {
        setPurchaseAccount(res.purchaseAccount?._id)
        setPurchaseInfo(true)
      }
      if (res.inventoryAccount) {
        console.log('res.inventoryAccount', res.inventoryAccount)
        setInventoryAccount(res.inventoryAccount?._id)
        setInventoryInfo(true)
      }
      if (res.thumbnail) {
        const s3ImageUrl = `https://qf-assets.s3.me-central-1.amazonaws.com/${res.thumbnail}`

        setPreviewImage(s3ImageUrl)
      }
    }
  }

  const fetchInventoryAccounts = async () => {
    const res = await getStockAccounts(currentUser?.organization)
    const data = res.map((item) => {
      return {
        value: item._id,
        label: item.accountName,
      }
    })
    setInventoryAccounts(data)
  }

  const fetchCategories = async () => {
    try {
      const response = await getCategoriesForProducts(currentUser.organization, categoryType)
      console.log('response', response)
      const categories = response.map((category) => ({
        value: category._id,
        label: category.categoryName,
      }))
      console.log('categories', categories)
      setCategoriesOptions(categories)
    } catch (error) {
      console.log('error fetching categories', error)
    }
  }

  useEffect(() => {
    fetchData()
    fetchCategories()
    fetchInventoryAccounts()
  }, [])

  const handleCancel = () => {
    navigate('/product')
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    if (salesAccount === '' || salesAccount === null) {
      alert('Please Select Sales Account')
      return
    }
    if (purchaseAccount === '' || purchaseAccount === null) {
      alert('Please Select Sales Account')
      return
    }
    if (inventoryAccount === '' || inventoryAccount === null) {
      alert('Please Select Sales Account')
      return
    }

    console.log('Form submitted!')
    // setLoading(true)
    const imageData = selectedImage ? new FormData() : null

    // if (imageData) {
    //   console.log(thumbnail)
    //   imageData.append('name', thumbnail)
    //   imageData.append('image', selectedImage)
    // }

    const mediaData = new FormData()
    media.forEach((file, index) => {
      const currentMediaName = mediaName[index]
      mediaData.append('name', currentMediaName)
      mediaData.append('media', file)
    })

    const formData = {
      thumbnail,
      category: changeCategory !== null ? changeCategory : categories,
      tags,
      productName,
      productDescription,
      mediaName,
      price: sellingPrice,
      sku,
      inWarehouseQuantity: openingStock || 0,
      length,
      width,
      height,
      weight,
      description,
      salesInfo,
      purchaseInfo,
      salesAccount,
      purchaseAccount,
      manufacturer,
      brand,
      upc,
      ean,
      mpn,
      isbn,
      costPrice,
      openingStock,
      openingStockRate,
      reorderPoint,
      unit,
      inventoryInfo,
      inventoryAccount,
      mrq,
      mrqCheckbox,
      rentalType,
      // changeCategory:
      //   changeCategory !== null || changeCategory !== undefined ? changeCategory : null,
    }
    console.log('formData', formData)
    if (selectedImage) {
      imageData.append('name', thumbnail)
      imageData.append('image', selectedImage)
      await productImage(imageData)
    }
    const data = {
      ...formData,
      organization: currentUser?.organization,
      company: currentUser?.company,
      thumbnail: previewImage ? thumbnail : categoryType,
    }
    await updateProduct(id, data).then(async (req) => {
      // const data = {
      //   ...formData,
      //   organization: currentUser?.organization,
      //   company: currentUser?.company,
      // }
      // await createStock(data).then((response) => {
      //   navigate('/product')
      // })
      navigate(`/product/${categoryType}`)
    })
  }

  return (
    <form onSubmit={handleSubmit}>
      <div className='container-fluid bg-gray-50 p-4'>
        <div className='row my-4 '>
          <div className='col-xl-4 space-y-6'>
            <Thumbnail
              previewImage={previewImage}
              setPreviewImage={setPreviewImage}
              thumbnail={thumbnail}
              setThumbnail={setThumbnail}
              selectedImage={selectedImage}
              setSelectedImage={setSelectedImage}
            />

            {categoryType === 'rentals' && (
              <RentalDetails rentalType={rentalType} setRentalType={setRentalType} />
            )}

            <ProductDetails
              categoriesOptions={categoriesOptions}
              categories={categories}
              setCategories={setCategories}
              tags={tags}
              setTags={setTags}
              showTagModal={true}
            />

            <CategoryChangeDetails
              changeCategory={changeCategory}
              setChangeCategory={setChangeCategory}
            />
          </div>
          <div className='col-xl-8 mt-4'>
            <div className='d-flex flex-column flex-row-fluid gap-7 gap-lg-10'>
              <ul className='nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-semibold mb-n2'>
                <li className='nav-item'>
                  <a
                    className='nav-link text-active-primary pb-4 active'
                    data-bs-toggle='tab'
                    href='#kt_ecommerce_add_product_general'
                  >
                    Details
                  </a>
                </li>
                <li className='nav-item'>
                  <a
                    className='nav-link text-active-primary pb-4'
                    data-bs-toggle='tab'
                    href='#kt_ecommerce_add_product_accounts'
                  >
                    Accounts
                  </a>
                </li>
              </ul>
              <div className='tab-content'>
                <div
                  className='tab-pane fade show active'
                  id='kt_ecommerce_add_product_general'
                  role='tab-panel'
                >
                  <div className='d-flex flex-column gap-7 gap-lg-10'>
                    <div className='card card-flush py-4'>
                      <div className='card-header'>
                        <div className='card-title'>
                          <h2>Item Details</h2>
                        </div>
                      </div>

                      <div className='card-body pt-0'>
                        <div className='mb-10 fv-row'>
                          <label className='required form-label'>Item Name</label>

                          <input
                            type='text'
                            name='name'
                            className='form-control mb-2'
                            placeholder='Item name'
                            value={productName}
                            onChange={(e) => setProductName(e.target.value)}
                          />

                          <div className='text-muted fs-7'>
                            A item name is required and recommended to be unique.
                          </div>
                        </div>

                        <div className='mb-10 fv-row'>
                          <label className='form-label'>Description</label>
                          <div className='w-full rounded-lg h-full'>
                            <textarea
                              name='description'
                              value={description}
                              onChange={(e) => setDescription(e.target.value)}
                              placeholder='type your text here..'
                              rows={4}
                              cols={50}
                              className='w-full rounded border px-4 py-2'
                            ></textarea>
                          </div>

                          <div className='text-muted fs-7'>
                            Set a description to the item for better visibility.
                          </div>
                        </div>

                        <div className='mb-10 fv-row'>
                          <label className='required form-label'>SKU</label>

                          <input
                            type='text'
                            name='sku'
                            className='form-control mb-2'
                            placeholder='SKU Number'
                            value={sku}
                            onChange={(e) => setSku(e.target.value)}
                          />

                          <div className='text-muted fs-7'>Enter the item SKU.</div>
                        </div>

                        <div className='mb-10 w-50'>
                          <label className='form-label required'>Unit</label>
                          <select
                            className='form-select form-control-solid'
                            id='unit'
                            name='unit'
                            value={unit}
                            onChange={(e) => setUnit(e.target.value)}
                          >
                            <option value=''>Select Unit</option>
                            <option value='box'>box</option>
                            <option value='cm'>cm</option>
                            <option value='dz'>dz</option>
                            <option value='ft'>ft</option>
                            <option value='g'>g</option>
                            <option value='in'>in</option>
                            <option value='kg'>kg</option>
                            <option value='km'>km</option>
                            <option value='lb'>lb</option>
                            <option value='mg'>mg</option>
                            <option value='ml'>ml</option>
                            <option value='m'>m</option>
                            <option value='pcs'>pcs</option>
                          </select>
                        </div>

                        <div className='mb-5 fv-row form-check form-switch'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            id='mrqCheckbox'
                            checked={mrqCheckbox}
                            onChange={(e) => setMrqCheckbox(e.target.checked)}
                          />
                          <label className='form-check-label' htmlFor='mrqCheckbox'>
                            Enable MRQ
                          </label>
                        </div>

                        <div className='mb-10 fv-row'>
                          <label className='required form-label'>MRQ</label>

                          <input
                            type='number'
                            name='mrq'
                            className='form-control mb-2'
                            placeholder='Minimun Required Quantity'
                            value={mrq}
                            onChange={(e) => setMrq(e.target.value)}
                            disabled={!mrqCheckbox}
                          />

                          <div className='text-muted fs-7'>Enter the item MRQ.</div>
                        </div>
                      </div>
                    </div>
                    <Media
                      media={media}
                      setMedia={setMedia}
                      mediaName={mediaName}
                      setMediaName={setMediaName}
                    />

                    <div className='card card-flush py-4'>
                      <div className='card-header'>
                        <div className='card-title'>
                          <h2>Other Details</h2>
                        </div>
                      </div>

                      <div className='card-body pt-0'>
                        <div className='mb-10'>
                          <label className='form-label'>Manufacturer</label>

                          <input
                            type='text'
                            className='form-control mb-2'
                            name='meta_title'
                            placeholder='Enter manufacturer name'
                            value={manufacturer}
                            onChange={(e) => setManufacturer(e.target.value)}
                          />
                        </div>
                        <div className='mb-10'>
                          <label className='form-label'>Brand</label>

                          <input
                            type='text'
                            className='form-control mb-2'
                            name='meta_title'
                            placeholder='Enter brand name'
                            value={brand}
                            onChange={(e) => setBrand(e.target.value)}
                          />
                        </div>
                        <div className='mb-10'>
                          <label className='form-label'>UPC</label>

                          <input
                            type='text'
                            className='form-control mb-2'
                            name='meta_title'
                            placeholder='Enter UPC code'
                            value={upc}
                            onChange={(e) => setUpc(e.target.value)}
                          />
                        </div>
                        <div className='mb-10'>
                          <label className='form-label'>EAN</label>

                          <input
                            type='text'
                            className='form-control mb-2'
                            name='meta_title'
                            placeholder='Enter EAN code'
                            value={ean}
                            onChange={(e) => setEan(e.target.value)}
                          />
                        </div>
                        <div className='mb-10'>
                          <label className='form-label'>MPN</label>

                          <input
                            type='text'
                            className='form-control mb-2'
                            name='meta_title'
                            placeholder='Enter MPN code'
                            value={mpn}
                            onChange={(e) => setMpn(e.target.value)}
                          />
                        </div>
                        <div className='mb-10'>
                          <label className='form-label'>ISBN</label>

                          <input
                            type='text'
                            className='form-control mb-2'
                            name='meta_title'
                            placeholder='Enter ISBN code'
                            value={isbn}
                            onChange={(e) => setIsbn(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>

                    <Shipping
                      weight={weight}
                      setWeight={setWeight}
                      width={width}
                      setWidth={setWidth}
                      height={height}
                      setHeight={setHeight}
                      length={length}
                      setLength={setLength}
                    />
                  </div>
                </div>

                <div
                  className='tab-pane fade'
                  id='kt_ecommerce_add_product_accounts'
                  role='tab-panel'
                >
                  <div className='d-flex flex-column gap-7 gap-lg-10'>
                    <AccountsInformation
                      sellingPrice={sellingPrice}
                      setSellingPrice={setSellingPrice}
                      costPrice={costPrice}
                      setCostPrice={setCostPrice}
                      salesInfo={salesInfo}
                      setSalesInfo={setSalesInfo}
                      purchaseInfo={purchaseInfo}
                      setPurchaseInfo={setPurchaseInfo}
                      salesAccount={salesAccount}
                      setSalesAccount={setSalesAccount}
                      purchaseAccount={purchaseAccount}
                      setPurchaseAccount={setPurchaseAccount}
                    />
                    <div className='card card-flush py-4'>
                      <div className='card-header'>
                        <div className='card-title'>
                          <input
                            type='checkbox'
                            checked={inventoryInfo}
                            className='form-check-input w-20px h-20px me-4'
                            onChange={(e) => {
                              setInventoryInfo(e.target.checked)
                              setInventoryAccount('')
                            }}
                          />
                          <h2>Inventory Information</h2>
                        </div>
                      </div>

                      <div className='card-body pt-0'>
                        <div className='mb-10 fv-row'>
                          <label className='required form-label'>Account</label>

                          <select
                            name='accountType'
                            onChange={(e) => setInventoryAccount(e.target.value)}
                            className='form-control form-control-solid w-75'
                            disabled={!inventoryInfo}
                            value={inventoryAccount}
                          >
                            <option value=''>Select an account</option>
                            <optgroup label='Stock'>
                              {inventoryAccounts?.map((item) => (
                                <option key={item.value} value={item.value}>
                                  {item.label}
                                </option>
                              ))}
                            </optgroup>
                          </select>
                        </div>
                        <div className='mb-10 fv-row'>
                          <label className='form-label'>Opening Stock</label>
                          <input
                            type='number'
                            name='quantity'
                            min='0'
                            className='form-control mb-2 form-control-solid w-75'
                            placeholder=''
                            value={openingStock}
                            onChange={(e) => setOpeningStock(e.target.value)}
                          />
                        </div>
                        <div className='mb-10 fv-row'>
                          <label className='form-label'>Opening Stock Rate Per Unit</label>
                          <input
                            type='number'
                            name='quantity'
                            min='0'
                            className='form-control mb-2 form-control-solid w-75'
                            placeholder=''
                            value={openingStockRate}
                            onChange={(e) => setOpeningStockRate(e.target.value)}
                          />
                        </div>
                        <div className='mb-10 fv-row'>
                          <label className='form-label'>Reorder Point</label>
                          <input
                            type='number'
                            name='quantity'
                            min='0'
                            className='form-control mb-2 form-control-solid w-75'
                            placeholder=''
                            value={reorderPoint}
                            onChange={(e) => setReorderPoint(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className='d-flex justify-content-end'>
                <button
                  href='../../demo17/dist/apps/ecommerce/catalog/products.html'
                  id='kt_ecommerce_add_product_cancel'
                  className='btn btn-light me-5'
                  onClick={handleCancel}
                >
                  Cancel
                </button>

                <button
                  type='submit'
                  id='kt_ecommerce_add_product_submit'
                  className='btn btn-warning'
                  disabled={loading}
                >
                  <span className='indicator-label'>Save Changes</span>
                  <span className='indicator-progress'>
                    Please wait...
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  )
}
