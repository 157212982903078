import React, {ChangeEvent, useEffect, useRef, useState} from 'react'
import Select from 'react-select'
// import DatePicker from 'react-datepicker';
// import styled from 'styled-components';
import 'react-datepicker/dist/react-datepicker.css'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

// import '~flatpickr/dist/flatpickr.css';
// import Flatpickr from "flatpickr";
import Flatpickr from 'react-flatpickr'
import 'flatpickr/dist/themes/material_blue.css' // Choose a theme from the flatpickr themes folder
import 'flatpickr/dist/flatpickr.min.css' // Import the Flatpickr CSS
// import { getAgent, getClient, getClientbyagent, getClientbycompany, getInvoiceid, getProperty, getQuoteid, postquote } from '../core/_requests';
// import { Client } from '../core/_models';

import {useAuth} from '../../../modules/auth/core/Auth'
// import { Agentlist } from '../../agent/components/Agentlist';
import {KTIcon} from '../../../../_metronic/helpers'
import {useNavigate} from 'react-router-dom'
import {
  getEmployee,
  getRFQ,
  getRFQLastId,
  getRFQLength,
  getStocks,
  getVendor,
  getVendorByAgentId,
  postRFQ,
} from '../Core/_requests'
import {getItems} from '../../Inventory/Core/_requests'
import {getFilteredStocks} from '../../sales/Core/_requests'
import {getNotes, getTerms} from '../../account_settings/core/_request'
import {useDispatch, useSelector} from 'react-redux'
import {setVendorDataList} from '../../../redux/procurement/vendor/vendorSlice'
import {setStock} from '../../../redux/inventory/products/stockSlice'
import {RootState} from '../../../redux/store'
import {setServices} from '../../../redux/inventory/products/servicesSlice'
import {setGoodsData} from '../../../redux/inventory/products/goodsSlice'
import {setRentalsData} from '../../../redux/inventory/products/rentalsSlice'
import {setConsumablesData} from '../../../redux/inventory/products/consumablesSlice'
import {setRFQDataFetch} from '../../../redux/procurement/rfq/rfqSlice'
import {unitsList} from '../../../utils/units'
import {getEmployeeByStatus} from '../../hrm/core/_requests'
import {setMaterialsData} from '../../../redux/inventory/products/materialsSlice'

function AddRFQ() {
  const {currentUser} = useAuth()
  const navigate = useNavigate()
  const serviceList = useSelector((state: RootState) => state.inventory.services.services)
  const stockData = useSelector((state: RootState) => state.inventory.stock.stock)
  const goodsList = useSelector((state: RootState) => state.inventory.goods.goodsData)
  const rentalsList = useSelector((state: RootState) => state.inventory.rentals.rentalsData)
  const consumablesList = useSelector(
    (state: RootState) => state.inventory.consumables.consumablesData
  )
  const materialsList = useSelector((state: RootState) => state.inventory.materials.materialsData)
  const [loading, setLoading] = useState(false)
  const [contactPersonList, setContactPersonList] = useState<any>([])
  const [categoryType, setCategoryType] = useState('')
  const [permissions, setPermissions] = useState<any>({})
  const [customerList, setCustomerList] = useState<any>([])
  const [employeeList, setEmployeeList] = useState<any>([])
  const [productList, setProductList] = useState<any>([])
  const [itemList, setItemList] = useState<any>([{}])
  const [selectedCurrency, setSelectedCurrency] = useState(null)
  const [selectedBillFrom, setselectedBillFrom] = useState(null)
  const [selectedBillTo, setselectedBillTo] = useState(null)
  const [selectedItem, setselectedItem] = useState(null)
  const [defaultAgent, setDefaultAgent] = useState()
  const [submitted, setSubmitted] = useState(false)
  const [selectFromInventory, setSelectFromInventory] = useState(false)
  const vendorDataList = useSelector((state: RootState) => state.procurement.vendor.vendorData)
  const dispatch = useDispatch()
  const [newItems, setNewItems] = useState<any>([
    {
      productName: '',
      quantity: 0,
      unit: '',
    },
  ])

  //useState for Custom RFQ no
  const [showModal, setShowModal] = useState(false)
  const [salesOrderNumber, setSalesOrderNumber] = useState('')
  const [selectYearAndMonth, setSelectYearAndMonth] = useState(new Date())
  const [selectedOption, setSelectedOption] = useState('autoGenerate') // Default selected option
  const [prefix, setPrefix] = useState('')
  const [nextNumber, setNextNumber] = useState('')
  const [customID, setCustomID] = useState('')
  const [isEditingNotes, setIsEditingNotes] = useState(false)
  const [isEditingTerms, setIsEditingTerms] = useState(false)
  const [termsTextareaHeight, setTermsTextareaHeight] = useState('6rem')
  const [notesTextareaHeight, setNotesTextareaHeight] = useState('6rem')

  const [quote, setQuote] = useState({
    date: new Date(),
    employee: '',
    vendor: '',
    contactPerson: '',
    id: '',
    subtotal: 0,
    tax: 0,
    total: 0,
    notes: '',
    termsNCondition: ' ',
  })
  const [items, setItems] = useState([
    {
      itemId: '',
      price: '',
      quantity: 0,
      discount: 0,
      amount: '',
      selectedOption: {
        value: '',
        label: '',
        inWarehouseQuantity: 0,
      },
      type: 'product',
    },
  ])

  useEffect(() => {
    if (currentUser?.permissions) {
      setPermissions(currentUser?.permissions)
    }
  }, [currentUser])

  const currencyOptions = [
    {
      value: 'SAR',
      label: 'SAR - United Arab Emirates dirham',
      flag: 'flags/united-arab-emirates.svg',
    },
    {value: 'USD', label: 'USD - USA dollar', flag: 'flags/united-states.svg'},
    {value: 'GBP', label: 'GBP - British pound', flag: 'flags/united-kingdom.svg'},
    {value: 'AUD', label: 'AUD - Australian dollar', flag: 'flags/australia.svg'},
    {value: 'JPY', label: 'JPY - Japanese yen', flag: 'flags/japan.svg'},
    {value: 'SEK', label: 'SEK - Swedish krona', flag: 'flags/sweden.svg'},
    {value: 'CAD', label: 'CAD - Canadian dollar', flag: 'flags/canada.svg'},
    {value: 'CHF', label: 'CHF - Swiss franc', flag: 'flags/switzerland.svg'},
    // Add other options similarly
  ]
  const defaultCurrency = currencyOptions.find((option) => option.value === 'SAR')

  const getVendorList = async () => {
    try {
      const res = await getVendor('', currentUser?.organization)
      console.log(res)
      setCustomerList(
        res.map((r) => ({
          value: r?._id,
          label: r?.displayName, // Corrected the typo here from "labe" to "label"
          contactPersons: r?.contactPersons,
        }))
      )
      dispatch(setVendorDataList(res))
    } catch (error) {
      console.error('Error fetching client list:', error)
    }
  }

  const getVendorListForAgent = async () => {
    try {
      const res = await getVendorByAgentId('', currentUser?._id)
      console.log(res)
      setCustomerList(
        res.map((r) => ({
          value: r?._id,
          label: r?.displayName, // Corrected the typo here from "labe" to "label"
          contactPersons: r?.contactPersons,
        }))
      )
      dispatch(setVendorDataList(res))
    } catch (error) {
      console.error('Error fetching client list:', error)
    }
  }

  useEffect(() => {
    if (quote.termsNCondition === '') {
      setTermsTextareaHeight('6rem')
    }
  }, [quote.termsNCondition])

  useEffect(() => {
    if (quote.notes === '') {
      setNotesTextareaHeight('6rem')
    }
  }, [quote.notes])

  const getEmployeeList = async () => {
    try {
      const res = await getEmployeeByStatus('filter_status=true', currentUser?.organization)
      console.log(res)
      setEmployeeList(
        res.map((r) => ({
          value: r._id,
          label: r.firstName, // Corrected the typo here from "labe" to "label"
        }))
      )
    } catch (error) {
      console.error('Error fetching client list:', error)
    }
  }

  useEffect(() => {
    const fetchQuote = async () => {
      try {
        const RFQNotes = await getNotes(currentUser?.organization, 'RFQ')
        const RFQTerms = await getTerms(currentUser?.organization, 'RFQ')

        setQuote({
          ...quote,
          notes: RFQNotes[0]?.notes || '',
          termsNCondition: RFQTerms?.terms || '',
        })
      } catch (error) {
        console.error('Error fetching delivery notes:', error)
      }
    }
    fetchQuote()
  }, [])

  // useEffect(() => {
  //   const fetchQuote = async () => {
  //     try {
  //       const QuotesList = await getQuotes()
  //       console.log('list', QuotesList)

  //       if (QuotesList && QuotesList.length > 0) {
  //         const firstQuote = QuotesList[0]

  //         setQuote({
  //           ...quote,
  //           notes: firstQuote.notes || '',
  //           termsNCondition: firstQuote.termsNCondition || '',
  //         })
  //       }
  //     } catch (error) {
  //       console.error('Error fetching delivery notes:', error)
  //     }
  //   }
  //   fetchQuote()
  // }, [])

  const handleEditClick = (e, field) => {
    e.preventDefault()
    if (field === 'notes') {
      setIsEditingNotes(!isEditingNotes)
      setIsEditingTerms(false) // Make sure only one is in edit mode at a time
    } else if (field === 'termsNCondition') {
      setIsEditingTerms(!isEditingTerms)
      setIsEditingNotes(false) // Make sure only one is in edit mode at a time
    }
  }

  const getProductlist = async () => {
    try {
      let data = []
      if (data.length === 0) {
        const res = await getStocks(currentUser?.organization)
        console.log(res)
        data = res.map((r) => ({
          value: r._id,
          label: r.productName,
          price: r.price,
          inWarehouseQuantity: r.inWarehouseQuantity,
          type: 'product',
        }))
        dispatch(setStock(data))
      } else {
        data = stockData
      }
      let serviceData = []
      if (serviceList.length === 0) {
        const resp = await getItems(currentUser?.organization)
        dispatch(setServices(resp))
        serviceData = resp.map((r) => ({
          value: r._id,
          label: r.name,
          price: r.price,
          inWarehouseQuantity: 1,
          type: 'item',
        }))
      } else {
        serviceData = serviceList.map((r) => ({
          value: r._id,
          label: r.name,
          price: r.price,
          inWarehouseQuantity: 1,
          type: 'item',
        }))
      }
      setProductList((prev) => {
        const updatedData = [...data, ...serviceData]
        return updatedData
      })
    } catch (error) {
      console.error('Error fetching client list:', error)
    }
  }

  const getRFQID = async () => {
    try {
      const res = await getRFQLastId(currentUser?.organization)
      console.log('ye hai res', res)
      setNextNumber(res.lastId + 1)
      const lastPrefix = res?.prefix.slice(0, res?.prefix?.trim()?.length - 7)
      setPrefix(lastPrefix)
      setSalesOrderNumber(`${res?.prefix}${res?.lastId + 1}`)
    } catch (error) {
      console.error('Error fetching client list:', error)
    }
  }

  useEffect(() => {
    if (vendorDataList.length === 0) {
      if (permissions?.rfq?.admin === false) {
        getVendorListForAgent()
      } else if (permissions?.rfq?.admin === true || currentUser?.profileType === 'superadmin') {
        getVendorList()
      }
    } else {
      setCustomerList(
        vendorDataList.map((r) => ({
          value: r?._id,
          label: r?.displayName, // Corrected the typo here from "labe" to "label"
          contactPersons: r?.contactPersons,
        }))
      )
    }
  }, [permissions])

  useEffect(() => {
    getEmployeeList()
    getProductlist()
    getRFQID()
  }, [])

  const handleChange = (e): void => {
    e.preventDefault()
    const {name, value} = e.target
    if (name === 'notes' && value.trim() === '') {
      setQuote((prev) => ({
        ...prev,
        notes: '• ' + value,
      }))
    } else
      setQuote((prevInvoice) => ({
        ...prevInvoice,
        [name]: value,
        // You can add other properties if needed
      }))
    if (name === 'termsNCondition') {
      setTermsTextareaHeight(`${e.target.scrollHeight}px`)
    } else if (name === 'notes') {
      setNotesTextareaHeight(`${e.target.scrollHeight}px`)
    }
  }

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      setQuote((prev) => ({
        ...prev,
        notes: prev.notes + '\n• ',
      }))

      setNotesTextareaHeight(`${event.target.scrollHeight}px`)
    }
  }

  const handleChangerows = (index, e) => {
    const {name, value} = e.target
    setItems((prevItems) => {
      const updatedItems = [...prevItems]
      updatedItems[index] = {
        ...updatedItems[index],
        [name]: value,
      }
      return updatedItems
    })
    setItems((prevItems) => {
      const updatedItems = [...prevItems]
      const data: any = (
        Number(prevItems[index].price) * Number(prevItems[index].quantity) -
        Number(prevItems[index].discount)
      ).toFixed(2)

      updatedItems[index] = {
        ...updatedItems[index],
        amount: data,
      }
      return updatedItems
    })
  }

  const handleSelectChangeInRows = (index, selectedOption) => {
    console.log(selectedOption)
    setItems((prevItems) => {
      const updatedItems = [...prevItems]
      updatedItems[index] = {
        ...updatedItems[index],
        itemId: selectedOption.value,
        selectedOption,
        price: selectedOption.price,
        amount: selectedOption.price,
        quantity: 1,
      }
      return updatedItems
    })
  }

  useEffect(() => {
    setQuote((prevInvoice) => {
      let sum = Number(items.reduce((acc, item) => acc + Number(item.quantity), 0))
      sum += Number(newItems.reduce((acc, item) => acc + Number(item.quantity), 0))
      return {
        ...prevInvoice,
        subtotal: sum,
        // You can add other properties if needed
      }
    })
  }, [items, newItems])

  // useEffect(() => {
  //   setQuote((prevInvoice) => {
  //     const subtotal = prevInvoice.subtotal || 0
  //     const tax = prevInvoice.tax || 0

  //     const total = (subtotal * (1 + tax / 100)).toFixed(2)

  //     return {
  //       ...prevInvoice,
  //       total: parseFloat(total),
  //       // You can add other properties if needed
  //     }
  //   })
  // }, [items, quote.tax])

  useEffect(() => {
    setDefaultAgent(employeeList.find((option) => option.value === currentUser?._id))
  }, [employeeList])

  const handleBillToChange = (selectedOption) => {
    setselectedBillTo(selectedOption)
    console.log(selectedOption)
    setContactPersonList(
      selectedOption.contactPersons?.map((person) => ({
        value: person?._id,
        label: person?.firstName,
      }))
    )
    setQuote((prevInvoice) => ({
      ...prevInvoice,
      vendor: selectedOption.value,
      contactPerson: selectedOption.contactPersons[0]?._id,
    }))
  }

  const handleBillFromChange = (selectedOption) => {
    console.log(selectedOption)
    setselectedBillFrom(selectedOption)
    setQuote((prevInvoice) => ({
      ...prevInvoice,
      employee: selectedOption.value,
    }))
  }

  const handleDateChange = (selectedDate) => {
    setQuote((prevInvoice) => ({
      ...prevInvoice,
      date: selectedDate,
    }))
  }

  const handleSubmit = async () => {
    if (quote.employee === '') {
      alert('Please select employee')
      return
    } else if (quote.vendor === '') {
      alert('Please select vendor')
      return
    } else if (selectFromInventory && items[0].itemId === '') {
      alert('Please select item')
      return
    } else if (!selectFromInventory && newItems[0].productName === '') {
      alert('Please enter item name')
      return
    }
    setLoading(true)
    const itemsList = items.map((item) => {
      return {
        itemId: item.type === 'product' ? item.itemId : null,
        itemsId: item.type === 'item' ? item.itemId : null,
        productName: item.selectedOption.label,
        quantity: item.quantity,
        type: item.type,
      }
    })

    const year = String(selectYearAndMonth.getFullYear() - 2000).padStart(2, '0')
    const month = String(selectYearAndMonth.getMonth() + 1).padStart(2, '0')
    const dNM = `${prefix}-${year}-${month}-`

    const data = {
      ...quote,
      items: selectFromInventory ? itemsList : newItems,
      prefix: dNM,
      customID: customID,
      id: nextNumber,
      approval: permissions?.rfq?.enable ? 'accepted' : 'pending',
      company: currentUser?.company,
      organization: currentUser?.organization,
      agent: currentUser?._id,
    }
    console.log(data)
    try {
      await postRFQ(data, '0').then((res) => {
        setSubmitted(true)
        console.log('res', res)
        // navigate(`/vendor/rfq/${res.id}`)
        dispatch(setRFQDataFetch())
        navigate(`/procurement/rfq`)
      })
    } catch (error) {
      console.log(error)
    }
  }

  const additem = () => {
    setItems([
      ...items,
      {
        itemId: '',
        price: '',
        quantity: 0,
        discount: 0,
        amount: '',
        selectedOption: {
          value: '',
          label: '',
          inWarehouseQuantity: 0,
        },
        type: 'product',
      },
    ])
  }

  const removeitem = (index) => {
    if (items.length === 1) {
      setItems([
        {
          itemId: '',
          price: '',
          quantity: 0,
          discount: 0,
          amount: '',
          selectedOption: {
            value: '',
            label: '',
            inWarehouseQuantity: 0,
          },
          type: 'product',
        },
      ])
      return
    }
    const temp = [...items]
    temp.splice(index, 1)
    setItems(temp)
  }

  //functions for Custom RFQ no
  const handleCloseModal = () => {
    setShowModal(false)
    // setCustomID('')
    // setPrefix('')
    // setNextNumber('')
    // getRFQID()
  }

  const handleSaveChanges = () => {
    let newSalesOrderNumber = ''
    if (selectedOption === 'autoGenerate') {
      setPrefix(prefix)
      console.log(prefix, nextNumber)
      setNextNumber(nextNumber)
      setCustomID('')
      const year = String(selectYearAndMonth.getFullYear() - 2000).padStart(2, '0')
      const month = String(selectYearAndMonth.getMonth() + 1).padStart(2, '0')
      const dNM = `${year}-${month}`
      newSalesOrderNumber = `${prefix}-${dNM}-${nextNumber}`
    } else if (selectedOption === 'manualInput') {
      setCustomID(salesOrderNumber)
      setPrefix('')
      setNextNumber('')
      newSalesOrderNumber = salesOrderNumber
    }
    setSalesOrderNumber(newSalesOrderNumber)
    setShowModal(false)
  }

  // const getSalesOrderID = async () => {
  //   try {
  //     const res = await getSalesOrderLastId()
  //     setNextNumber(res.lastId + 1)
  //     setPrefix(res.prefix)
  //     console.log(res)
  //   } catch (error) {
  //     console.error('Error fetching client list:', error)
  //   }
  // }

  useEffect(() => {
    if (customID) {
      setSalesOrderNumber(customID)
    }
  }, [customID])

  const handleCategoryChange = async (e) => {
    setCategoryType(e.target.value)
    if (e.target.value === 'service') {
      if (serviceList.length === 0) {
        const res = await getItems(currentUser?.organization)
        const data = res.map((r) => ({
          value: r._id,
          label: r.name,
          price: r.price,
          inWarehouseQuantity: 1,
          type: 'item',
        }))
        setProductList(data)
        dispatch(setServices(res))
      } else {
        const data = serviceList.map((r) => ({
          value: r._id,
          label: r.name,
          price: r.price,
          inWarehouseQuantity: 1,
          type: 'item',
        }))
        setProductList(data)
      }
    } else {
      const category = e.target.value
      if (category === 'goods') {
        if (goodsList.length === 0) {
          const res = await getFilteredStocks(currentUser?.organization, e.target.value)
          const data = res.map((r) => ({
            value: r._id,
            label: r.productName,
            price: r.price,
            inWarehouseQuantity: r.inWarehouseQuantity,
            type: 'product',
          }))
          setProductList(data)
          dispatch(setGoodsData(res))
        } else {
          const data = goodsList.map((r) => ({
            value: r._id,
            label: r.productName,
            price: r.price,
            inWarehouseQuantity: r.inWarehouseQuantity,
            type: 'product',
          }))
          setProductList(data)
        }
      } else if (category === 'rentals') {
        if (rentalsList.length === 0) {
          const res = await getFilteredStocks(currentUser?.organization, e.target.value)
          const data = res.map((r) => ({
            value: r._id,
            label: r.productName,
            price: r.price,
            inWarehouseQuantity: r.inWarehouseQuantity,
            type: 'product',
          }))
          setProductList(data)
          dispatch(setRentalsData(res))
        } else {
          const data = rentalsList.map((r) => ({
            value: r._id,
            label: r.productName,
            price: r.price,
            inWarehouseQuantity: r.inWarehouseQuantity,
            type: 'product',
          }))
          setProductList(data)
        }
      } else if (category === 'consumables') {
        if (consumablesList.length === 0) {
          const res = await getFilteredStocks(currentUser?.organization, e.target.value)
          const data = res.map((r) => ({
            value: r._id,
            label: r.productName,
            price: r.price,
            inWarehouseQuantity: r.inWarehouseQuantity,
            type: 'product',
          }))
          setProductList(data)
          dispatch(setConsumablesData(res))
        } else {
          const data = consumablesList.map((r) => ({
            value: r._id,
            label: r.productName,
            price: r.price,
            inWarehouseQuantity: r.inWarehouseQuantity,
            type: 'product',
          }))
          setProductList(data)
        }
      } else if (category === 'materials') {
        if (materialsList.length === 0) {
          const res = await getFilteredStocks(currentUser?.organization, e.target.value)
          const data = res.map((r) => ({
            value: r._id,
            label: r.productName,
            price: r.price,
            inWarehouseQuantity: r.inWarehouseQuantity,
            type: 'product',
          }))
          setProductList(data)
          dispatch(setMaterialsData(res))
        } else {
          const data = materialsList.map((r) => ({
            value: r._id,
            label: r.productName,
            price: r.price,
            inWarehouseQuantity: r.inWarehouseQuantity,
            type: 'product',
          }))
          setProductList(data)
        }
      }
    }
  }

  return (
    <div id='kt_app_content' className='app-content flex-column-fluid'>
      {/* begin::Content container */}
      <div id='kt_app_content_container' className='app-container container-xxl'>
        {/* begin::Layout */}
        <div className='d-flex flex-column flex-lg-row'>
          {/* begin::Content */}
          <div className='flex-lg-row-fluid mb-10 mb-lg-0 me-lg-7 me-xl-10'>
            {/* begin::Card */}
            <div className='d-flex justify-end mb-2'>
              <button
                className='btn btn-light-primary me-3'
                data-kt-menu-trigger='click'
                data-kt-menu-placement='bottom-end'
                data-kt-menu-flip='top-end'
              >
                <KTIcon iconName='filter' className='fs-2' />
                Filter
              </button>
              <div
                className='menu menu-sub menu-sub-dropdown w-400px w-md-400px p-5'
                data-kt-menu='true'
                style={{zIndex: 1000000}}
              >
                <div className='px-7 py-2'>
                  <div className='fs-5 text-dark fw-bolder'>Filter Options</div>
                </div>
                <div className='separator border-gray-200'></div>
                <div className='px-7 py-5' data-kt-user-table-filter='form'>
                  <div className='mb-5 d-flex flex-row align-content-center justify-content-center'>
                    <label className='form-label fs-6 fw-bold mt-2 w-100px me-5'>Category:</label>
                    <select
                      className='form-select form-select-solid fw-bolder w-75'
                      onChange={handleCategoryChange}
                      value={categoryType}
                    >
                      <option value=''>Select an option</option>
                      {currentUser?.inventoryFeatures?.goods && (
                        <option value='goods'>Goods</option>
                      )}
                      {currentUser?.inventoryFeatures?.rentals && (
                        <option value='rentals'>Rentals</option>
                      )}
                      {currentUser?.inventoryFeatures?.consumables && (
                        <option value='consumables'>Consumables</option>
                      )}
                      {currentUser?.inventoryFeatures?.materials && (
                        <option value='materials'>Materials</option>
                      )}
                      {currentUser?.inventoryFeatures?.service && (
                        <option value='service'>Services</option>
                      )}
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div className='card'>
              {/* begin::Card body */}
              <div className='card-body p-12'>
                {/* begin::Form */}
                <form action='' id='kt_quote_form'>
                  {/* begin::Wrapper */}
                  <div className='d-flex flex-column align-items-start flex-xxl-row'>
                    {/* begin::Input group */}
                    <div
                      className='d-flex align-items-center flex-equal fw-row me-4 order-2'
                      data-bs-toggle='tooltip'
                      data-bs-trigger='hover'
                      title='Specify quote date'
                    >
                      {/* begin::Date */}
                      <div className='fs-6 fw-bold text-gray-700 text-nowrap'>Date:</div>
                      {/* Date */}
                      {/* begin::Input */}
                      <div className='position-relative d-flex align-items-center w-150px'>
                        {/* begin::Datepicker */}
                        <Flatpickr
                          value={quote.date}
                          onChange={handleDateChange}
                          className='form-control form-control-solid'
                          placeholder='Pick date'
                          options={{
                            dateFormat: 'd-m-Y',
                          }}
                        />
                        {/* Datepicker */}
                        {/* begin::Icon */}
                        {/* <i className='ki-duotone ki-down fs-4 position-absolute ms-4 end-0'></i> */}
                        {/* Icon */}
                      </div>
                      {/* Input */}
                    </div>
                    {/* Input group */}
                    {/* begin::Input group */}
                    <div
                      className='d-flex flex-center flex-equal fw-row text-nowrap order-1 order-xxl-2 me-4'
                      data-bs-toggle='tooltip'
                      data-bs-trigger='hover'
                      title='Enter RFQ number'
                    >
                      <span className='fs-2x fw-bold text-gray-800'>RFQ #</span>
                      {/* <input
                        type='text'
                        className='form-control form-control-flush fw-bold text-muted fs-3 w-125px'
                        value={quote.id}
                        placeholder='...'
                        disabled
                      /> */}
                      <input
                        type='text'
                        className='form-control form-control-flush fw-bold text-muted fs-3 w-200px'
                        value={salesOrderNumber}
                        placeholder='...'
                        readOnly
                      />

                      <button
                        className='btn btn-light ms-2'
                        onClick={(e) => {
                          e.preventDefault()
                          setShowModal(true)
                        }}
                      >
                        <KTIcon iconName='gear' />
                      </button>

                      <div>
                        {showModal && (
                          <div
                            className='modal'
                            tabIndex={-1}
                            role='dialog'
                            style={{display: 'block', backgroundColor: 'rgba(0, 0, 0, 0.5)'}}
                          >
                            <div className='modal-dialog modal-dialog-centered' role='document'>
                              <div className='modal-content'>
                                <div className='modal-header'>
                                  <h5 className='modal-title'>Customize RFQ No.</h5>
                                  <button
                                    type='button'
                                    className='btn-close'
                                    onClick={handleCloseModal}
                                  ></button>
                                </div>
                                <div className='modal-body'>
                                  {/* Your modal body content goes here */}
                                  <p>How would you like to RFQ numbers?</p>
                                  <div className='form-check'>
                                    <input
                                      className='form-check-input'
                                      type='radio'
                                      name='salesOrderOption'
                                      id='autoGenerate'
                                      value='autoGenerate'
                                      checked={selectedOption === 'autoGenerate'}
                                      onChange={() => setSelectedOption('autoGenerate')}
                                    />
                                    <label className='form-check-label' htmlFor='autoGenerate'>
                                      Continue auto-generating RFQ numbers
                                    </label>
                                  </div>
                                  <div className='form-check'>
                                    <input
                                      className='form-check-input'
                                      type='radio'
                                      name='salesOrderOption'
                                      id='manualInput'
                                      value='manualInput'
                                      checked={selectedOption === 'manualInput'}
                                      onChange={() => setSelectedOption('manualInput')}
                                    />
                                    <label className='form-check-label' htmlFor='manualInput'>
                                      Enter RFQ numbers manually
                                    </label>
                                  </div>

                                  {/* Additional input fields for manual input */}
                                  {selectedOption === 'autoGenerate' && (
                                    <div className='mt-3'>
                                      <div className='mb-3'>
                                        <label className='form-label'>Prefix:</label>
                                        <input
                                          type='text'
                                          className='form-control'
                                          value={prefix}
                                          onChange={(e) => setPrefix(e.target.value)}
                                          placeholder='Enter Prefix'
                                        />
                                      </div>
                                      <div className='mb-3'>
                                        <label className='form-label'>Month & Year:</label>
                                        <br />
                                        <DatePicker
                                          selected={selectYearAndMonth}
                                          onChange={(date) => setSelectYearAndMonth(date)}
                                          dateFormat='yyyy/MM'
                                          showMonthYearPicker
                                          className='form-control'
                                        />
                                      </div>
                                      <div className='mb-3'>
                                        <label className='form-label'>Next Number:</label>
                                        <input
                                          type='number'
                                          className='form-control'
                                          value={nextNumber}
                                          onChange={(e) => setNextNumber(e.target.value)}
                                          placeholder='Enter Next Number'
                                        />
                                      </div>
                                    </div>
                                  )}
                                  {selectedOption === 'manualInput' && (
                                    <div className='mt-3'>
                                      <div className='mb-3'>
                                        <label className='form-label'>RFQ NO:</label>
                                        <input
                                          type='text'
                                          className='form-control'
                                          value={salesOrderNumber}
                                          onChange={(e) => setSalesOrderNumber(e.target.value)}
                                          placeholder='Enter RFQ Number'
                                        />
                                      </div>
                                    </div>
                                  )}
                                </div>
                                <div className='modal-footer'>
                                  <button
                                    type='button'
                                    className='btn btn-secondary'
                                    onClick={handleCloseModal}
                                  >
                                    Close
                                  </button>
                                  <button
                                    type='button'
                                    className='btn btn-primary'
                                    onClick={handleSaveChanges}
                                  >
                                    Save changes
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        {/* End of modal content */}
                      </div>
                    </div>
                    {/* Input group */}
                    {/* begin::Input group */}
                    <div
                      className='d-flex align-items-center justify-content-end flex-equal order-3 fw-row'
                      data-bs-toggle='tooltip'
                      data-bs-trigger='hover'
                      title='Specify quote due date'
                    ></div>
                    {/* Input group */}
                  </div>
                  {/* Top */}
                  {/* begin::Separator */}
                  <div className='separator separator-dashed my-10'></div>
                  {/* Separator */}
                  {/* begin::Wrapper */}
                  <div className='mb-0'>
                    {/* begin::Row */}
                    <div className='row gx-10 mb-5'>
                      {/* begin::Col */}
                      <div className='col-lg-6'>
                        <label className='form-label required fs-6 fw-bold text-gray-700 mb-3'>
                          From
                        </label>
                        {/* begin::Input group */}
                        <Select
                          name='employee'
                          aria-label='Select a Employee'
                          placeholder='Select Employee'
                          options={employeeList}
                          defaultValue={{value: currentUser?._id, label: currentUser?.fullName}}
                          isSearchable={true}
                          className='form-control form-control-solid'
                          onChange={handleBillFromChange}
                          value={selectedBillFrom}
                        />
                        {/* Input group */}
                      </div>
                      {/* Col */}
                      {/* begin::Col */}
                      <div className='col-lg-6'>
                        <label className='form-label required fs-6 fw-bold text-gray-700 mb-3'>
                          To
                        </label>
                        {/* begin::Input group */}
                        <Select
                          name='vendor'
                          aria-label='Select a Vendor'
                          placeholder='Select a Vendor'
                          options={customerList}
                          isSearchable={true}
                          className='form-control form-control-solid'
                          onChange={handleBillToChange}
                          value={selectedBillTo}
                        />
                      </div>
                      {/* Col */}
                    </div>
                    {/* Row */}
                    {/* begin::Table wrapper */}

                    <div className='row'>
                      <div className='col-lg-6'>
                        <div className='fs-6 fw-bold text-gray-700 mb-3'>Contact Person</div>
                        <Select
                          name='contactPerson'
                          aria-label='Select a Contact Person'
                          placeholder='Select a Person'
                          options={contactPersonList}
                          isSearchable={true}
                          className='form-control form-control-solid'
                          onChange={(e) => {
                            setQuote((prevInvoice) => ({
                              ...prevInvoice,
                              contactPerson: e.value,
                            }))
                          }}
                          value={contactPersonList?.find(
                            (option) => option.value === quote.contactPerson
                          )}
                        />
                      </div>
                    </div>

                    <div className='d-flex align-items-center justify-content-start gap-5 mt-5'>
                      <button
                        type='button'
                        className={`btn btn-light-success ${selectFromInventory && 'active'}`}
                        onClick={() => {
                          setSelectFromInventory(true)
                          setNewItems([])
                          setItems([
                            {
                              itemId: '',
                              price: '',
                              quantity: 0,
                              discount: 0,
                              amount: '',
                              selectedOption: {
                                value: '',
                                label: '',
                                inWarehouseQuantity: 0,
                              },
                              type: 'product',
                            },
                          ])
                        }}
                      >
                        Select from Inventory
                      </button>
                      <button
                        type='button'
                        className={`btn btn-light-warning ${!selectFromInventory && 'active'}`}
                        onClick={() => {
                          setSelectFromInventory(false)
                          setItems([])
                          setNewItems([{productName: '', quantity: 0, unit: ''}])
                        }}
                      >
                        Add New Item
                      </button>
                    </div>

                    <div className='fs-6 fw-bold text-gray-700 mb-3 mt-5'>Item Table</div>
                    <div className='table-responsive mb-10'>
                      {/* begin::Table */}
                      <table
                        className='table g-5 gs-0 mb-0 fw-bold text-gray-700'
                        data-kt-element='items'
                      >
                        {/* begin::Table head */}
                        <thead>
                          <tr className='border-bottom fs-7 fw-bold text-gray-700 text-uppercase'>
                            <th className='min-w-100px w-500px'>Item</th>
                            {!selectFromInventory && <th className='min-w-50px'>Unit</th>}
                            <th className='min-w-150px w-250px'>Quantity</th>
                            {/* <th className='min-w-50px w-250px'>Rate</th> */}
                            {/* <th className='min-w-70px w-150px'>Discount</th> */}
                            {/* <th className='min-w-70px w-250px'>Amount</th> */}
                            <th className='min-w-70px w-200px'>Action</th>
                          </tr>
                        </thead>
                        {/* Table head */}
                        {/* begin::Table body */}
                        <tbody>
                          {selectFromInventory &&
                            items.map((item, index) => (
                              <tr
                                className='border-bottom border-bottom-dashed'
                                data-kt-element='item'
                                key={index}
                              >
                                <td className='absolute w-[250px] pe-7'>
                                  {/* begin::Input group */}
                                  <Select
                                    name='itemId'
                                    aria-label='Select a Product'
                                    placeholder='Select a Product'
                                    options={productList}
                                    isSearchable={true}
                                    className='form-control form-control-solid'
                                    onChange={(e) => handleSelectChangeInRows(index, e)}
                                    value={item.selectedOption}
                                    required
                                  />
                                </td>
                                <td className='pe-7'>
                                  {/* begin::Input group */}

                                  <input
                                    type='number'
                                    className='form-control form-control-solid'
                                    name='quantity'
                                    placeholder='0.00'
                                    //   max={item?.selectedOption?.inWarehouseQuantity}
                                    min={0}
                                    value={item.quantity}
                                    onChange={(e) => handleChangerows(index, e)}
                                  />
                                </td>

                                <td>
                                  <div
                                    className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-3'
                                    onClick={additem}
                                  >
                                    <KTIcon iconName='plus-square' className='fs-3' />
                                  </div>
                                  <div
                                    className='btn btn-icon btn-bg-light btn-active-color-danger btn-sm '
                                    onClick={() => removeitem(index)}
                                  >
                                    <KTIcon iconName='trash' className='fs-3' />
                                  </div>
                                </td>
                              </tr>
                            ))}
                          {!selectFromInventory &&
                            newItems.map((item, index) => (
                              <tr
                                className='border-bottom border-bottom-dashed'
                                data-kt-element='item'
                                key={index}
                              >
                                <td className='absolute w-[250px] pe-7'>
                                  {/* begin::Input group */}
                                  <input
                                    type='text'
                                    className='form-control form-control-solid'
                                    name='productName'
                                    placeholder='Product Name'
                                    value={item.productName}
                                    onChange={(e) => {
                                      const {name, value} = e.target
                                      setNewItems((prevItems) => {
                                        const updatedItems = [...prevItems]
                                        updatedItems[index] = {
                                          ...updatedItems[index],
                                          [name]: value,
                                        }
                                        return updatedItems
                                      })
                                    }}
                                    required
                                  />
                                </td>
                                <td className='pe-7 w-150px'>
                                  {/* begin::Input group */}
                                  <select
                                    name='unit'
                                    id=''
                                    className='form-control form-control-solid'
                                    value={item.unit}
                                    onChange={(e) => {
                                      const {name, value} = e.target
                                      setNewItems((prevItems) => {
                                        const updatedItems = [...prevItems]
                                        updatedItems[index] = {
                                          ...updatedItems[index],
                                          [name]: value,
                                        }
                                        return updatedItems
                                      })
                                    }}
                                  >
                                    <option value=''>unit</option>
                                    {unitsList.map((unit, index) => (
                                      <option value={unit} key={unit}>
                                        {unit}
                                      </option>
                                    ))}
                                  </select>
                                </td>
                                <td className='pe-7'>
                                  {/* begin::Input group */}

                                  <input
                                    type='number'
                                    className='form-control form-control-solid'
                                    name='quantity'
                                    placeholder='0.00'
                                    min={0}
                                    value={item.quantity}
                                    onChange={(e) => {
                                      const {name, value} = e.target
                                      setNewItems((prevItems) => {
                                        const updatedItems = [...prevItems]
                                        updatedItems[index] = {
                                          ...updatedItems[index],
                                          [name]: value,
                                        }
                                        return updatedItems
                                      })
                                    }}
                                  />
                                </td>
                                <td>
                                  <div
                                    className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-3'
                                    onClick={() => {
                                      setNewItems([
                                        ...newItems,
                                        {productName: '', quantity: 0, unit: ''},
                                      ])
                                    }}
                                  >
                                    <KTIcon iconName='plus-square' className='fs-3' />
                                  </div>
                                  <div
                                    className='btn btn-icon btn-bg-light btn-active-color-danger btn-sm '
                                    onClick={() => {
                                      if (newItems.length === 1) {
                                        setNewItems([{productName: '', quantity: 0, unit: ''}])
                                        return
                                      }
                                      const temp = [...newItems]
                                      temp.splice(index, 1)
                                      setNewItems(temp)
                                    }}
                                  >
                                    <KTIcon iconName='trash' className='fs-3' />
                                  </div>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                        {/* Table body */}
                        {/* begin::Table foot */}
                        <tfoot>
                          <tr className='border-top border-top-dashed align-top fs-6 fw-bold text-gray-700'>
                            <th className='text-primary'></th>
                            <th className='text-primary'></th>
                            <th className='border-bottom border-bottom-dashed ps-0'>
                              <div className='d-flex flex-column align-items-start'>
                                <div className='fs-5'>Total Quantity</div>
                              </div>
                            </th>
                            <th className='border-bottom border-bottom-dashed'>
                              <span data-kt-element='sub-total' className=' '>
                                {quote.subtotal || 0}
                              </span>
                            </th>
                          </tr>
                        </tfoot>
                        {/* Table foot */}
                      </table>
                    </div>
                    {/* Table */}
                    {/* begin::Item template */}

                    <table className='table d-none' data-kt-element='empty-template'>
                      <tr data-kt-element='empty'>
                        <th colSpan={5} className='text-muted text-center py-10'>
                          No items
                        </th>
                      </tr>
                    </table>
                    {/* Item template */}
                    {/* begin::Notes */}

                    <div className='mb-0 position-relative'>
                      <label className='form-label fs-6 fw-bold text-gray-700'>Notes</label>
                      <textarea
                        name='notes'
                        className={`form-control form-control-solid mb-0 h-10 ${
                          isEditingNotes ? 'border border-dark-subtle' : ''
                        }`}
                        placeholder='Enter Notes'
                        value={quote.notes}
                        onChange={handleChange}
                        readOnly={!isEditingNotes}
                        onKeyDown={handleKeyPress}
                        style={{
                          minHeight: '6rem',
                          height: notesTextareaHeight,
                          resize: 'none',
                        }}
                      />
                      <button onClick={(e) => handleEditClick(e, 'notes')}>
                        <KTIcon
                          iconName='pencil'
                          className='position-absolute text-black bg-gray-100 right-8 top-[3rem] fs-3'
                        />
                      </button>
                    </div>
                    <div className='mb-10 h-100 position-relative '>
                      <label className='form-label fw-bold fs-6 text-gray-700'>
                        Terms and Conditions
                      </label>
                      <textarea
                        name='termsNCondition'
                        style={{
                          minHeight: '6rem',
                          height: termsTextareaHeight,
                          resize: 'none',
                        }}
                        className={` form-control form-control-solid mb-0 ${
                          isEditingTerms ? 'border border-dark-subtle' : ''
                        }`}
                        placeholder='Enter terms and conditions'
                        value={quote.termsNCondition}
                        onChange={handleChange}
                        readOnly={!isEditingTerms}
                      />
                      <button onClick={(e) => handleEditClick(e, 'termsNCondition')}>
                        <KTIcon
                          iconName='pencil'
                          className='absolute text-black bg-gray-100 right-8 top-[3rem] fs-3'
                        />
                      </button>
                    </div>
                    {/* Notes */}
                  </div>
                  {/* Wrapper */}
                </form>
                {/* Form */}
              </div>
              {/* Card body */}
            </div>
            {/* Card */}
          </div>
          {/* Content */}
          {/* begin::Sidebar */}
          <div className='flex-lg-auto min-w-lg-300px'>
            {/* begin::Card */}
            <div
              className='card'
              data-kt-sticky='true'
              data-kt-sticky-name='quote'
              data-kt-sticky-offset="{default: false, lg: '200px'}"
              data-kt-sticky-width="{lg: '250px', lg: '300px'}"
              data-kt-sticky-left='auto'
              data-kt-sticky-top='150px'
              data-kt-sticky-animation='false'
              data-kt-sticky-zindex='95'
            >
              {/* begin::Card body */}
              <div className='card-body p-10'>
                {/* begin::Input group */}
                <div className='mb-10'>
                  <label className='form-label fw-bold fs-6 text-gray-700'>Currency</label>
                  <Select
                    name='currency'
                    aria-label='Select a Currency'
                    placeholder='Select currency'
                    options={currencyOptions}
                    defaultValue={defaultCurrency}
                    isSearchable={true}
                    className=''
                  />
                </div>
                {/* <!--::Input group-->
                        <!--begin::Separator--> */}

                {/* <!--::Separator-->
                        <!--begin::Input group--> */}
                <div className='mb-8'>
                  {/* <!--begin::Option--> */}
                  <label className='form-check form-switch form-switch-sm form-check-custom form-check-solid flex-stack mb-5'>
                    <span className='form-check-label ms-0 fw-bold fs-6 text-gray-700'>
                      Payment method
                    </span>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      defaultChecked={true}
                      value=''
                    />
                  </label>

                  {/* <!--::Option-->
                            <!--begin::Option--> */}
                  <label className='form-check form-switch form-switch-sm form-check-custom form-check-solid flex-stack mb-5'>
                    <span className='form-check-label ms-0 fw-bold fs-6 text-gray-700'>
                      Late fees
                    </span>
                    <input className='form-check-input' type='checkbox' value='' />
                  </label>
                  {/* <!--::Option-->
                            <!--begin::Option--> */}
                  <label className='form-check form-switch form-switch-sm form-check-custom form-check-solid flex-stack'>
                    <span className='form-check-label ms-0 fw-bold fs-6 text-gray-700'>Notes</span>
                    <input className='form-check-input' type='checkbox' value='' />
                  </label>
                  {/* <!--::Option--> */}
                </div>
                {/* <!--::Input group-->
                        <!--begin::Separator--> */}
                <div className='separator separator-dashed mb-8'></div>
                {/* <!--::Separator-->
                        <!--begin::Actions--> */}
                <div className='mb-0'>
                  {/* <!--begin::Row--> */}

                  {/* <!--::Row--> */}
                  {!submitted ? (
                    <button
                      className='btn btn-primary w-100'
                      id='kt_quote_submit_button'
                      onClick={handleSubmit}
                      disabled={loading}
                    >
                      <i className='ki-duotone ki-triangle fs-3'>
                        <span className='path1'></span>
                        <span className='path2'></span>
                        <span className='path3'></span>
                      </i>
                      Create RFQ
                    </button>
                  ) : (
                    <button className='btn btn-danger w-100' id='kt_quote_submit_button'>
                      <i className='ki-duotone ki-triangle fs-3'>
                        <span className='path1'></span>
                        <span className='path2'></span>
                        <span className='path3'></span>
                      </i>
                      Print Quotation
                    </button>
                  )}
                </div>
                {/* <!--::Actions--> */}
              </div>
              {/* <!--::Card body--> */}
            </div>
            {/* <!--::Card--> */}
          </div>
          {/* <!--::Sidebar--> */}
        </div>
        {/* <!--::Layout--> */}
      </div>
      {/* <!--::Content container--> */}
    </div>
  )
}

export default AddRFQ
