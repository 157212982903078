import React, {useEffect, useState} from 'react'
import Flatpickr from 'react-flatpickr'
import 'flatpickr/dist/themes/material_blue.css'
import 'flatpickr/dist/flatpickr.min.css'

import {useAuth} from '../../../modules/auth/core/Auth'
import {KTIcon} from '../../../../_metronic/helpers'
import {useNavigate} from 'react-router-dom'
import {getAllAccounts, getJournalLastId, postJournal} from '../core/_requests'
function AddJournal() {
  const {currentUser} = useAuth()
  const navigate = useNavigate()
  const [showModal, setShowModal] = useState(false)
  const [selectedOption, setSelectedOption] = useState('autoGenerate')
  const [prefix, setPrefix] = useState('')
  const [nextNumber, setNextNumber] = useState('')
  const [salesOrderNumber, setSalesOrderNumber] = useState('')
  const [customID, setCustomID] = useState('')
  const [submitted, setSubmitted] = useState(false)
  const [accountsList, setAccountsList] = useState({})
  const [loading, setLoading] = useState(false)
  const [disabled, setDisabled] = useState(false)
  const [items, setItems] = useState([
    {
      accountId: '',
      description: '',
      debit: 0,
      credit: 0,
    },
    {
      accountId: '',
      description: '',
      debit: 0,
      credit: 0,
    },
  ])

  const [journal, setJournal] = useState({
    date: new Date(),
    difference: 0,
    subtotal: 0,
    reference: '',
    notes: '',
  })

  const getJournalId = async () => {
    try {
      const res = await getJournalLastId(currentUser?.organization)
      setNextNumber(res.lastId + 1)
      setPrefix(res.prefix)
    } catch (error) {
      console.error('Error fetching client list:', error)
    }
  }

  useEffect(() => {
    if (customID) {
      setSalesOrderNumber(customID)
    } else {
      setSalesOrderNumber(`${prefix}${nextNumber}`)
    }
  }, [prefix, nextNumber, customID])

  const fetchAccounts = async () => {
    const res = await getAllAccounts(currentUser?.organization)
    let data = {
      'Other Asset': [],
      'Other Current Asset': [],
      Cash: [],
      Bank: [],
      'Fixed Asset': [],
      Stock: [],
      'Payment Clearing': [],
      'Other Current Liability': [],
      'Credit Card': [],
      'Long Term Liability': [],
      'Other Liability': [],
      Equity: [],
      Income: [],
      'Other Income': [],
      Expense: [],
      'Cost of Goods Sold': [],
      'Other Expense': [],
    }

    res?.forEach((r) => {
      if (r.accountType === 'otherasset') {
        data['Other Asset'].push({
          value: r?._id,
          label: r?.accountName,
        })
      } else if (r.accountType === 'othercurrentasset') {
        data['Other Current Asset'].push({
          value: r?._id,
          label: r?.accountName,
        })
      } else if (r.accountType === 'cash') {
        data['Cash'].push({
          value: r?._id,
          label: r?.accountName,
        })
      } else if (r.accountType === 'bank') {
        data['Bank'].push({
          value: r?._id,
          label: r?.accountName,
        })
      } else if (r.accountType === 'fixedasset') {
        data['Fixed Asset'].push({
          value: r?._id,
          label: r?.accountName,
        })
      } else if (r.accountType === 'stock') {
        data['Stock'].push({
          value: r?._id,
          label: r?.accountName,
        })
      } else if (r.accountType === 'paymentclearing') {
        data['Payment Clearing'].push({
          value: r?._id,
          label: r?.accountName,
        })
      } else if (r.accountType === 'othercurrentliability') {
        data['Other Current Liability'].push({
          value: r?._id,
          label: r?.accountName,
        })
      } else if (r.accountType === 'creditcard') {
        data['Credit Card'].push({
          value: r?._id,
          label: r?.accountName,
        })
      } else if (r.accountType === 'longtermliability') {
        data['Long Term Liability'].push({
          value: r?._id,
          label: r?.accountName,
        })
      } else if (r.accountType === 'otherliability') {
        data['Other Liability'].push({
          value: r?._id,
          label: r?.accountName,
        })
      } else if (r.accountType === 'equity') {
        data['Equity'].push({
          value: r?._id,
          label: r?.accountName,
        })
      } else if (r.accountType === 'income') {
        data['Income'].push({
          value: r?._id,
          label: r?.accountName,
        })
      } else if (r.accountType === 'otherincome') {
        data['Other Income'].push({
          value: r?._id,
          label: r?.accountName,
        })
      } else if (r.accountType === 'expense') {
        data['Expense'].push({
          value: r?._id,
          label: r?.accountName,
        })
      } else if (r.accountType === 'costofgoodssold') {
        data['Cost of Goods Sold'].push({
          value: r?._id,
          label: r?.accountName,
        })
      } else if (r.accountType === 'otherexpense') {
        data['Other Expense'].push({
          value: r?._id,
          label: r?.accountName,
        })
      }
    })
    setAccountsList(data)
  }

  useEffect(() => {
    fetchAccounts()
  }, [])

  useEffect(() => {
    setJournal((prev) => {
      return {
        ...prev,
        subtotal: items.reduce((accumulator, currentItem) => {
          // Assuming that the 'amount' property is a number
          return accumulator + Number(currentItem.credit)
        }, 0),
        difference: items.reduce((accumulator, currentItem) => {
          // Assuming that the 'amount' property is a number
          return accumulator + Number(currentItem.debit) - Number(currentItem.credit)
        }, 0),
      }
    })

    items.forEach((item, index) => {
      if (item.accountId === '') {
        setDisabled(true)
      } else {
        setDisabled(false)
      }
    })
  }, [items])

  // const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>): void => {
  const handleChange = (e) => {
    e.preventDefault()
    const {name, value} = e.target
    setJournal((prevInvoice) => ({
      ...prevInvoice,
      [name]: value,
    }))
  }

  const handleChangerows = (index, e) => {
    e.preventDefault()
    const {name, value} = e.target
    const newItems = [...items]
    // Remove commas from value before saving
    const rawValue = value.replace(/,/g, '')

    if (name === 'debit') {
      newItems[index].credit = 0
    } else if (name === 'credit') {
      newItems[index].debit = 0
    }

    newItems[index][name] = rawValue
    setItems(newItems)
  }

  const formatNumber = (num) => {
    if (!num) return ''
    return Number(num).toLocaleString('en-US')
  }

  const handleBlur = (index, e) => {
    const {name, value} = e.target
    const newItems = [...items]
    newItems[index][name] = value.replace(/,/g, '') // Store the raw value without commas
    setItems(newItems)
  }

  const handleDateChange = (selectedDate) => {
    setJournal((prevInvoice) => ({
      ...prevInvoice,
      date: selectedDate[0], // Flatpickr returns an array of selected dates
    }))
  }

  const handleSaveChanges = () => {
    let newSalesOrderNumber = ''
    if (selectedOption === 'autoGenerate') {
      setPrefix(prefix)
      console.log(prefix, nextNumber)
      setNextNumber(nextNumber)
      setCustomID('')
      newSalesOrderNumber = `${prefix}${nextNumber}`
    } else if (selectedOption === 'manualInput') {
      setCustomID(salesOrderNumber)
      setPrefix('')
      setNextNumber('')
      newSalesOrderNumber = salesOrderNumber
    }
    setSalesOrderNumber(newSalesOrderNumber)
    setShowModal(false)
  }

  const handleCloseModal = () => {
    setShowModal(false)
    setCustomID('')
    setPrefix('')
    setNextNumber('')
    getJournalId()
  }

  const handleSubmit = async () => {
    setLoading(true)
    const data = {
      ...journal,
      items,
      prefix: prefix,
      customID: customID,
      id: nextNumber,
      company: currentUser?.company,
      organization: currentUser?.organization,
    }
    console.log(data)
    try {
      await postJournal(data).then((res) => {
        setSubmitted(true)
        console.log(res)
        setLoading(false)
        navigate(`/account/journals`)
      })
    } catch (error) {
      console.log(error)
    }
  }

  const additem = () => {
    setItems([
      ...items,
      {
        accountId: '',
        description: '',
        debit: 0,
        credit: 0,
      },
    ])
  }

  const removeitem = (index) => {
    if (items.length <= 2) {
      return
    }
    const newItems = [...items]
    newItems.splice(index, 1)
    setItems(newItems)
  }

  return (
    <div id='kt_app_content' className='app-content flex-column-fluid'>
      {/* begin::Content container */}
      <div id='kt_app_content_container' className='app-container container-xxl'>
        {/* begin::Layout */}
        <div className='d-flex flex-column flex-lg-row'>
          {/* begin::Content */}
          <div className='flex-lg-row-fluid mb-10 mb-lg-0 me-lg-7 me-xl-10'>
            {/* begin::Card */}
            <div className='card'>
              {/* begin::Card body */}
              <div className='card-body p-12'>
                {/* begin::Form */}
                <form action='' id='kt_quote_form'>
                  {/* begin::Wrapper */}
                  <div className='d-flex flex-column align-items-start flex-xxl-row'>
                    {/* begin::Input group */}
                    <div
                      className='d-flex align-items-center flex-equal fw-row me-4 order-2'
                      data-bs-toggle='tooltip'
                      data-bs-trigger='hover'
                      title='Specify journal date'
                    >
                      {/* begin::Date */}
                      <div className='fs-6 fw-bold text-gray-700 text-nowrap'>Date:</div>
                      {/* Date */}
                      {/* begin::Input */}
                      <div className='position-relative d-flex align-items-center w-150px'>
                        {/* begin::Datepicker */}
                        <Flatpickr
                          value={journal.date}
                          onChange={handleDateChange}
                          className='form-control form-control-solid'
                          placeholder='Pick date'
                          options={{dateFormat: 'd-m-Y'}}
                        />
                      </div>
                      {/* Input */}
                    </div>
                    {/* Input group */}
                    {/* begin::Input group */}
                    <div
                      className='d-flex flex-center flex-equal fw-row text-nowrap order-1 order-xxl-2 me-4'
                      data-bs-toggle='tooltip'
                      data-bs-trigger='hover'
                      title='Enter journal number'
                    >
                      <span className='fs-2x fw-bold text-gray-800'>Journal #</span>
                    </div>
                    <input
                      type='text'
                      className='form-control form-control-flush fw-bold text-muted fs-3 w-125px'
                      value={salesOrderNumber}
                      onChange={(e) => setSalesOrderNumber(e.target.value)}
                      placeholder='Journal #'
                      readOnly
                    />

                    <button
                      className='btn btn-light ms-2'
                      onClick={(e) => {
                        e.preventDefault()
                        setShowModal(true)
                      }}
                    >
                      <KTIcon iconName='gear' />
                    </button>
                    <div>
                      {showModal && (
                        <div
                          className='modal'
                          tabIndex={-1}
                          role='dialog'
                          style={{display: 'block', backgroundColor: 'rgba(0, 0, 0, 0.5)'}}
                        >
                          <div className='modal-dialog modal-dialog-centered' role='document'>
                            <div className='modal-content'>
                              <div className='modal-header'>
                                <h5 className='modal-title'>Customize Journal No.</h5>
                                <button
                                  type='button'
                                  className='btn-close'
                                  onClick={handleCloseModal}
                                ></button>
                              </div>
                              <div className='modal-body'>
                                {/* Your modal body content goes here */}
                                <p>How would you like to have Journal numbers?</p>
                                <div className='form-check'>
                                  <input
                                    className='form-check-input'
                                    type='radio'
                                    name='salesOrderOption'
                                    id='autoGenerate'
                                    value='autoGenerate'
                                    checked={selectedOption === 'autoGenerate'}
                                    onChange={() => setSelectedOption('autoGenerate')}
                                  />
                                  <label className='form-check-label' htmlFor='autoGenerate'>
                                    Continue auto-generating Journal numbers
                                  </label>
                                </div>
                                <div className='form-check'>
                                  <input
                                    className='form-check-input'
                                    type='radio'
                                    name='salesOrderOption'
                                    id='manualInput'
                                    value='manualInput'
                                    checked={selectedOption === 'manualInput'}
                                    onChange={() => setSelectedOption('manualInput')}
                                  />
                                  <label className='form-check-label' htmlFor='manualInput'>
                                    Enter Journal numbers manually
                                  </label>
                                </div>

                                {/* Additional input fields for manual input */}
                                {selectedOption === 'autoGenerate' && (
                                  <div className='mt-3'>
                                    <div className='mb-3'>
                                      <label className='form-label'>Prefix:</label>
                                      <input
                                        type='text'
                                        className='form-control'
                                        value={prefix}
                                        onChange={(e) => setPrefix(e.target.value)}
                                        placeholder='Enter Prefix'
                                      />
                                    </div>
                                    <div className='mb-3'>
                                      <label className='form-label'>Next Number:</label>
                                      <input
                                        type='number'
                                        className='form-control'
                                        value={nextNumber}
                                        onChange={(e) => setNextNumber(e.target.value)}
                                        placeholder='Enter Next Number'
                                      />
                                    </div>
                                  </div>
                                )}
                                {selectedOption === 'manualInput' && (
                                  <div className='mt-3'>
                                    <div className='mb-3'>
                                      <label className='form-label'>JOURNAL NO:</label>
                                      <input
                                        type='text'
                                        className='form-control'
                                        value={salesOrderNumber}
                                        onChange={(e) => setSalesOrderNumber(e.target.value)}
                                        placeholder='Enter Journal Number'
                                      />
                                    </div>
                                  </div>
                                )}
                              </div>
                              <div className='modal-footer'>
                                <button
                                  type='button'
                                  className='btn btn-secondary'
                                  onClick={handleCloseModal}
                                >
                                  Close
                                </button>
                                <button
                                  type='button'
                                  className='btn btn-primary'
                                  onClick={handleSaveChanges}
                                >
                                  Save changes
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {/* End of modal content */}
                    </div>
                    {/* Input group */}
                    {/* begin::Input group */}
                    <div
                      className='d-flex align-items-center justify-content-end flex-equal order-3 fw-row'
                      data-bs-toggle='tooltip'
                      data-bs-trigger='hover'
                      title='Specify journal due date'
                    ></div>
                    {/* Input group */}
                  </div>
                  {/* Top */}
                  {/* begin::Separator */}
                  <div className='separator separator-dashed my-10'></div>
                  {/* Separator */}
                  {/* begin::Wrapper */}
                  <div className='mb-0'>
                    {/* begin::Row */}

                    {/* input for amount */}
                    <div className='row gx-10 mb-5'>
                      {/* begin::Col */}
                      <div className='col-lg-6'>
                        <label className='form-label required fs-6 fw-bold text-gray-700 mb-3'>
                          Notes
                        </label>
                        {/* begin::Input group */}
                        <input
                          type='text'
                          name='notes'
                          className='form-control form-control-solid'
                          placeholder='Enter custom notes'
                          value={journal.notes}
                          onChange={handleChange}
                        />
                        {/* Input group */}
                      </div>
                      {/* Col */}
                      {/* begin::Col */}
                      <div className='col-lg-6'>
                        <label className='form-label fs-6 fw-bold text-gray-700 mb-3'>
                          Reference#
                        </label>
                        {/* begin::Input group */}
                        <input
                          type='text'
                          name='reference'
                          className='form-control form-control-solid'
                          placeholder='Enter reference'
                          value={journal.reference}
                          onChange={handleChange}
                        />
                      </div>
                      {/* Col */}
                    </div>

                    <div className='table-responsive mb-10'>
                      {/* begin::Table */}
                      <table
                        className='table g-5 gs-0 mb-0 fw-bold text-gray-700'
                        data-kt-element='items'
                      >
                        {/* begin::Table head */}
                        <thead>
                          <tr className='border-bottom fs-7 fw-bold text-gray-700 text-uppercase'>
                            <th className='min-w-100px w-250px'>Account</th>
                            <th className='min-w-150px w-250px'>Description</th>
                            <th className='min-w-50px w-200px'>Debits</th>
                            <th className='min-w-50px w-200px'>Credits</th>
                          </tr>
                        </thead>
                        {/* Table head */}
                        {/* begin::Table body */}
                        <tbody>
                          {items?.map((item, index) => (
                            <tr
                              className='border-bottom border-bottom-dashed'
                              data-kt-element='item'
                              key={index}
                            >
                              <td className='pe-7'>
                                {/* begin::Input group */}
                                <select
                                  className='form-select form-select-solid'
                                  value={item.accountId}
                                  name='accountId'
                                  onChange={(e) => handleChangerows(index, e)}
                                  required
                                >
                                  <option value=''>Select Account</option>
                                  {Object.keys(accountsList).map((key, index) => {
                                    return (
                                      <optgroup label={key} key={index}>
                                        {accountsList[key].map((item) => {
                                          return (
                                            <option value={item?.value} key={item?.value}>
                                              {item?.label}
                                            </option>
                                          )
                                        })}
                                      </optgroup>
                                    )
                                  })}
                                </select>
                              </td>
                              <td className='pe-7'>
                                {/* begin::Input group */}

                                <input
                                  type='text'
                                  className='form-control form-control-solid'
                                  name='description'
                                  placeholder='Description'
                                  value={item.description}
                                  onChange={(e) => handleChangerows(index, e)}
                                />
                              </td>

                              <td className='pe-7'>
                                {/* begin::Input group */}

                                <input
                                  type='text'
                                  className='form-control form-control-solid'
                                  name='debit'
                                  placeholder='0.00'
                                  value={formatNumber(item.debit)}
                                  onChange={(e) => handleChangerows(index, e)}
                                  onBlur={(e) => handleBlur(index, e)}
                                />
                              </td>

                              <td className='pe-7'>
                                {/* begin::Input group */}
                                <input
                                  type='text'
                                  className='form-control form-control-solid'
                                  name='credit'
                                  placeholder='0.00'
                                  value={formatNumber(item.credit)}
                                  onChange={(e) => handleChangerows(index, e)}
                                  onBlur={(e) => handleBlur(index, e)}
                                />
                              </td>
                              <td>
                                <div
                                  className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-3'
                                  onClick={additem}
                                >
                                  <KTIcon iconName='plus-square' className='fs-3' />
                                </div>
                                <div
                                  className='btn btn-icon btn-bg-light btn-active-color-danger btn-sm '
                                  onClick={() => removeitem(index)}
                                >
                                  <KTIcon iconName='trash' className='fs-3' />
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                        {/* Table body */}
                        {/* begin::Table foot */}
                        <tfoot>
                          <tr className='border-top border-top-dashed align-top fs-6 fw-bold text-gray-700'>
                            <th className='text-primary'></th>
                            <th className='border-bottom border-bottom-dashed ps-0'>
                              <div className='d-flex flex-column align-items-end'>
                                <div className='fs-5'>Subtotal</div>
                              </div>
                            </th>
                            <th className='border-bottom border-bottom-dashed text-center'>
                              <span data-kt-element='sub-total' className=' '>
                                {journal.subtotal.toLocaleString('en-US', {
                                  minimumFractionDigits: 2,
                                })}
                              </span>
                            </th>
                          </tr>
                          <tr className='align-top fw-bold text-gray-700'>
                            <th className='min-w-150px w-150px'></th>
                            <th className='fs-4 ps-0 min-w-50px w-50px text-end'>Total</th>
                            <th className=' fs-4 text-nowrap min-w-70px w-70px text-center'>
                              SAR
                              <span data-kt-element='grand-total'>
                                {' '}
                                {journal.subtotal.toLocaleString('en-US', {
                                  minimumFractionDigits: 2,
                                })}
                              </span>
                            </th>
                          </tr>
                          <tr className='align-top fw-bold text-gray-700'>
                            <th className='min-w-150px w-150px'></th>
                            <th className='fs-4 ps-0 min-w-50px w-50px text-end text-danger'>
                              Difference
                            </th>
                            <th className=' fs-4 text-nowrap min-w-70px w-70px text-center text-danger'>
                              <span data-kt-element='grand-total text-danger'>
                                {journal.difference.toLocaleString('en-US', {
                                  minimumFractionDigits: 2,
                                })}
                              </span>
                            </th>
                          </tr>
                        </tfoot>
                        {/* Table foot */}
                      </table>
                    </div>

                    <div className='mb-0'>
                      {/* <!--begin::Row--> */}

                      {/* <!--::Row--> */}
                      {!submitted ? (
                        <button
                          type='button'
                          className='btn btn-primary w-30'
                          id='kt_quote_submit_button'
                          onClick={handleSubmit}
                          disabled={loading || journal.difference !== 0 || disabled}
                        >
                          <i className='ki-duotone ki-triangle fs-3'>
                            <span className='path1'></span>
                            <span className='path2'></span>
                            <span className='path3'></span>
                          </i>
                          {loading ? 'Loading...' : 'Save Journal'}
                        </button>
                      ) : (
                        <button className='btn btn-danger w-100' id='kt_quote_submit_button'>
                          <i className='ki-duotone ki-triangle fs-3'>
                            <span className='path1'></span>
                            <span className='path2'></span>
                            <span className='path3'></span>
                          </i>
                          Print Expense
                        </button>
                      )}
                    </div>
                    {/* Notes */}
                  </div>
                  {/* Wrapper */}
                </form>
                {/* Form */}
              </div>
              {/* Card body */}
            </div>
            {/* Card */}
          </div>
        </div>
        {/* <!--::Layout--> */}
      </div>
      {/* <!--::Content container--> */}
    </div>
  )
}

export default AddJournal
