import React, {useEffect, useRef, useState} from 'react'

import {
  bulkUploadEmployees,
  deleteProjectById,
  getFilteredProject,
  getFilteredProjectByAgentId,
  getProject,
  getProjectByAgentId,
  getProjectById,
} from '../core/_requests'
import {Link, useNavigate} from 'react-router-dom'
import {IconUserModel} from '../../../modules/profile/ProfileModels'
import {useAuth} from '../../../modules/auth'
import {Filtertype, SearchFilter, initialSearchFilter} from '../core/_models'
import Filter from '../components/Filter'
import qs from 'qs'
import ProjectCard from './ProjectCard'
// import { AddProject } from '../components/AddProject';
import {useSelector, useDispatch} from 'react-redux'
import {RootState} from '../../../redux/store'
import {setProjects} from '../../../redux/projects/projectSlice'
import useExcelImport from '../../account/components/useExcelImport'
import {Spinner} from 'react-bootstrap'

const MyProjects = () => {
  const navigate = useNavigate()
  const {currentUser} = useAuth()
  const excelfileRef = useRef<HTMLInputElement>(null)
  const dispatch = useDispatch()
  const initialRef = useRef(true)
  const projectList = useSelector((state: any) => state.projects.projects.projects)
  const projectLoading = useSelector((state: any) => state.projects.projects.loading)
  const [permissions, setPermissions] = useState<any>({})
  const [projectData, setProjectData] = useState([])
  const [employeeNames, setEmployeeNames] = useState<string[]>([])
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [statusFilterIsActive, setStatusFilterIsActive] = useState<boolean>(true)
  const [excelImportLoading, setExcelImportLoading] = useState(false)
  const handleUploadExcel = useExcelImport()

  useEffect(() => {
    if (currentUser?.permissions) {
      setPermissions(currentUser?.permissions)
    }
  }, [currentUser])

  const formatCreatedAt = (createdAt: string | undefined) => {
    if (!createdAt) return 'N/A'
    const formattedDate = new Date(createdAt).toLocaleDateString('en-GB', {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
    })
    return formattedDate
  }

  let searchFilters: SearchFilter = initialSearchFilter

  const setSearchFilter = (updateValues: {
    search?: string
    agent?: string
    filter?: Partial<Filtertype>
  }): void => {
    searchFilters = {
      ...searchFilters,
      ...(updateValues.search && {search: updateValues.search}),
      ...(updateValues.agent && {agent: updateValues.agent}),
      ...(updateValues.filter && {
        filter: {
          ...searchFilters.filter,
          ...updateValues.filter,
        },
      }),
    }
  }

  const setStatusFilter = (isActive: boolean) => {
    setSearchFilter({
      filter: {
        isActive,
      },
    })
  }

  useEffect(() => {
    // Calculate the status of the filter
    const isActive = searchFilters.filter?.isActive ?? true
    setStatusFilterIsActive(isActive)
  }, [searchFilters])

  const stringifyObject = (obj: SearchFilter): string => {
    const queryString = qs.stringify(
      {
        search: obj.search,
        agent: obj.agent,
        filter_status: obj.filter.isActive,
        project_status: obj.filter.projectStatus,
      },
      {encodeValuesOnly: true, skipNulls: true, delimiter: '&'}
    )
    return queryString
  }

  const getProjectData = async () => {
    setSearchFilter({
      search: searchTerm,
    })

    try {
      const queryString = stringifyObject(searchFilters)
      console.log('query', queryString)
      const response = await getFilteredProject(queryString, currentUser?.organization)
      // Process the response data
      const processedData = response?.map((project) => {
        const teamNames = project?.team?.map((employee: any) => ({
          name: employee?.firstName,
          number: employee?._id,
        }))
        return {...project, teamNames}
      })
      setProjectData(processedData)

      const names: string[] = processedData?.flatMap((project) =>
        project?.teamNames?.map((teamMember: any) => teamMember?.name)
      )
      setEmployeeNames(names)
      return processedData
    } catch (e) {
      console.log(e)
    }
  }

  const getProjectDataForAgent = async () => {
    setSearchFilter({
      search: searchTerm,
    })
    try {
      const queryString = stringifyObject(searchFilters)
      console.log('query', queryString)
      const response = await getFilteredProjectByAgentId(queryString, currentUser?._id)
      // Process the response data
      const processedData = response?.map((project) => {
        const teamNames = project?.team.map((employee: any) => ({
          name: employee?.firstName,
          number: employee?._id,
        }))
        return {...project, teamNames}
      })
      setProjectData(processedData)

      const names: string[] = processedData?.flatMap((project) =>
        project?.teamNames?.map((teamMember: any) => teamMember?.name)
      )
      setEmployeeNames(names)
      return processedData
    } catch (e) {
      console.log(e)
    }
  }
  const handleAddNew = () => {
    navigate('/project/projectlist/add')
  }

  useEffect(() => {
    setProjectData(projectList)
    setEmployeeNames(
      projectList?.flatMap((project) =>
        project?.teamNames?.map((teamMember: any) => teamMember?.name)
      )
    )
  }, [projectList])

  const fetchProjects = () => {
    if (permissions?.myproject?.admin === false) {
      if (initialRef.current) {
        getProjectDataForAgent().then((data) => {
          dispatch(setProjects(data))
        })
        initialRef.current = false
      } else {
        getProjectDataForAgent()
      }
    } else if (
      permissions?.myproject?.admin === true ||
      currentUser?.profileType === 'superadmin'
    ) {
      if (initialRef.current) {
        getProjectData().then((data) => {
          dispatch(setProjects(data))
        })
        initialRef.current = false
      } else {
        getProjectData()
      }
    }
  }

  const handlereset = async () => {
    setSearchFilter(initialSearchFilter)
    // fetchProjects()
    setProjectData(projectList)
  }

  useEffect(() => {
    if (projectList?.length === 0) fetchProjects()
    else {
      setProjectData(projectList)
      setEmployeeNames(
        projectList?.flatMap((project) =>
          project?.teamNames?.map((teamMember: any) => teamMember?.name)
        )
      )
    }
  }, [permissions])

  const iconUsers: IconUserModel[] = employeeNames?.map((name) => ({name}))

  if (projectLoading) {
    return (
      <div className='d-flex align-items-center justify-center'>
        <div className='loader'></div>
      </div>
    )
  }

  return (
    <div>
      <div className='d-flex flex-wrap flex-stack mb-6'>
        <h3 className='fw-bolder my-2'>
          My Projects
          <span className='fs-6 text-gray-400 fw-bold ms-1'>Active</span>
        </h3>

        <div className='d-flex flex-wrap my-2'>
          <Filter
            searchFilters={searchFilters}
            setSearchFilter={setSearchFilter}
            handlereset={handlereset}
            getDocsList={getProjectData}
            setStatusFilter={setStatusFilter}
            // setStatusFilter={(isActive: boolean) => setSearchFilters({ ...searchFilters, filter: { isActive } })}
            // setIsFilterActive={(isActive: boolean) => {}}
          />
          {/* <div className='me-4'> */}
          {/* <select
              name='status'
              data-control='select2'
              data-hide-search='true'
              className='form-select form-select-sm form-select-white w-125px'
              defaultValue='Active'
            >
              <option value='Active'>Active</option>
              <option value='Approved'>In Progress</option>
              <option value='Declined'>To Do</option>
              <option value='In Progress'>Completed</option>
            </select> */}
          {/* </div> */}
          {(currentUser?.hierarchy === 1 || currentUser?.profileType === 'superadmin') && (
            <div className='me-4 card-toolbar'>
              <button
                className='btn btn-danger'
                onClick={() => {
                  excelfileRef.current.click()
                }}
              >
                {excelImportLoading ? (
                  <>
                    <Spinner animation='border' role='status' />
                  </>
                ) : (
                  'Import Projects'
                )}
              </button>
              <input
                type='file'
                accept='.xlsx, .xls'
                onChange={(e) =>
                  handleUploadExcel(e, bulkUploadEmployees, setExcelImportLoading, 'projects')
                }
                ref={excelfileRef}
                className='hidden'
              />
            </div>
          )}
          {(permissions?.myproject?.create === true ||
            currentUser?.profileType === 'superadmin') && (
            <button onClick={handleAddNew} className='btn btn-primary btn-sm'>
              New Project
            </button>
          )}
        </div>
      </div>
      <ProjectCard projectData={projectData} fetchProjects={fetchProjects} />
    </div>
  )
}

export default MyProjects
