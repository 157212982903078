import React, {FC, useEffect, useRef, useState} from 'react'
import {Step1} from '../Components/Steps/Step1'
import {KTIcon} from '../../../../_metronic/helpers'
import {StepperComponent} from '../../../../_metronic/assets/ts/components'
import {Form, Formik, FormikValues} from 'formik'
import {createAccountSchemas, ICreateAccount, inits} from '../core/CreateAccountWizardHelper'
import {Step2} from '../Components/Steps/Step2'
import {Step3} from '../Components/Steps/Step3'
import {Step4} from '../Components/Steps/Step4'
import {Step5} from '../Components/Steps/Step5'
import {Step6} from '../Components/Steps/Step6'
import {getEmployeeById, updateEmployee} from '../core/_requests'
import {useNavigate, useParams} from 'react-router-dom'
import {useAuth} from '../../../modules/auth'
import {CTC} from '../Components/Steps/CTC'

export default function EditEmployee() {
  const {id} = useParams()
  const {currentUser} = useAuth()
  const stepperRef = useRef<HTMLDivElement | null>(null)
  const stepper = useRef<StepperComponent | null>(null)
  const [currentSchema, setCurrentSchema] = useState(createAccountSchemas[0])
  const [initValues, setInitValues] = useState<ICreateAccount>()
  const [isSubmitButton, setSubmitButton] = useState(false)

  const navigate = useNavigate()

  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
  }

  const prevStep = () => {
    if (!stepper.current) {
      return
    }

    stepper.current.goPrev()

    setCurrentSchema(createAccountSchemas[stepper.current.currentStepIndex - 1])

    setSubmitButton(stepper.current.currentStepIndex === stepper.current.totalStepsNumber)
  }

  const submitStep = async (values: ICreateAccount, actions: FormikValues) => {
    if (!stepper.current) {
      return
    }
    if (stepper.current.currentStepIndex !== stepper.current.totalStepsNumber) {
      stepper.current.goNext()
    } else {
      let totalCTC = 0
      totalCTC += Number(values.monthlyPay) * 12
      const ctcItems = values.ctc
      for (const key in ctcItems) {
        if (ctcItems[key].enabled) {
          totalCTC += ctcItems[key].value * (ctcItems[key].type === 'month' ? 12 : 1)
        }
      }
      const mappedValues = {
        ...values,
        currentAddress: {
          addressLine1: values.presentAddressLine1,
          addressLine2: values.presentAddressLine2,
          city: values.presentCity,
          state: values.presentState,
          country: values.presentCountry,
          postalCode: values.presentPostalcode,
        },
        isPermanentSameAsCurrent: values.sameAsPresentAddress,
        permanentAddress: {
          addressLine1: values.permanentAddressLine1,
          addressLine2: values.permanentAddressLine2,
          city: values.permanentCity,
          state: values.permanentState,
          country: values.permanentCountry,
          postalCode: values.permanentPostalCode,
        },
        company: currentUser?.company,
        organization: currentUser?.organization,
        invoiceRate: values.needInvoiceRate ? values.invoiceRate : {},
        ctc: {
          accomodation: {
            type: values.ctc.accomodation.type,
            value: values.ctc.accomodation.value,
          },
          food: {
            type: values.ctc.food.type,
            value: values.ctc.food.value,
          },
          transportation: {
            type: values.ctc.transportation.type,
            value: values.ctc.transportation.value,
          },
          medical: {
            type: values.ctc.medical.type,
            value: values.ctc.medical.value,
          },
          airTickets: {
            type: values.ctc.airTickets.type,
            value: values.ctc.airTickets.value,
          },
        },
        totalCTC,
      }
      // stepper.current.goto(1)
      console.log('mappedValues', mappedValues)
      const response = await updateEmployee(id, mappedValues)
      console.log('submited', response)
      navigate('/hrm/employee')
      actions.resetForm()
    }

    setSubmitButton(stepper.current.currentStepIndex === stepper.current.totalStepsNumber)

    setCurrentSchema(createAccountSchemas[stepper.current.currentStepIndex - 1])
  }

  useEffect(() => {
    const fetchData = async () => {
      if (id) {
        try {
          const response = await getEmployeeById(id)
          console.log('response get employee', response)
          setInitValues({
            currentExperience: response.currentExperience || '',
            employeeId: response.employeeId || '',
            firstName: response.firstName || '',
            lastName: response.lastName || '',
            nickName: response.nickName || '',
            email: response.email || '',
            department: response.department?._id || '',
            location: response.location || '',
            designation: response.designation || '',
            role: response.role || '',
            employmentType: response.employmentType || '',
            contractType: response.contractType || '',
            employeeStatus: response.employeeStatus || '',
            sourceOfHire: response.sourceOfHire,
            dateOfJoining: response.dateOfJoining,
            reportingManager: response.reportingManager || '',
            dateOfBirth: response.dateOfBirth || '',
            age: response.age || 0,
            gender: response.gender || '',
            aboutMe: response.aboutMe || '',
            passportNumber: response.passportNumber || '',
            idNumber: response.idNumber || '',
            aadharNumber: response.aadharNumber || 0,
            workPhoneNo: response.workPhoneNo || 0,
            personalMobile: response.personalMobile || 0,
            extension: response.extension || 0,
            personalEmail: response.personalEmail || '',
            seatingLocation: response.seatingLocation || '',
            tags: response.tags || '',
            presentAddressLine1: response.currentAddress?.addressLine1 || '',
            presentAddressLine2: response.currentAddress?.addressLine2 || '',
            presentCity: response.currentAddress?.city || '',
            presentState: response.currentAddress?.state || '',
            presentCountry: response.currentAddress?.country || '',
            presentPostalcode: response.currentAddress?.postalCode || 0,
            maritalStatus: response.maritalStatus || '',
            permanentAddressLine1: response.permanentAddress?.addressLine1 || '',
            permanentAddressLine2: response.permanentAddress?.addressLine2 || '',
            permanentCountry: response.permanentAddress?.country || '',
            permanentState: response.permanentAddress?.state || '',
            permanentCity: response.permanentAddress?.city || '',
            permanentPostalCode: response.permanentAddress?.postalCode || 0,
            sameAsPresentAddress: response.isPermanentSameAsCurrent || false,
            yearlyPay: response.yearlyPay || 0,
            monthlyPay: response.monthlyPay || 0,
            hourlyPay: response.hourlyPay || 0,
            dailyPay: response.dailyPay || 0,
            dailyWorkingHours: response.dailyWorkingHours || 0,
            overtimePay: response.overtimePay || 0,
            addUser: response?.addUser || false,
            departmentID: response?.departmentID || '',
            roleID: response?.roleID || '',
            password: response?.password || '',
            needInvoiceRate: response?.invoiceRate?.dailyWorkingHours > 0 ? true : false,
            invoiceRate: {
              dailyWorkingHours: response?.dailyWorkingHours || 0,
              hourlyPay: response?.hourlyPay || 0,
              monthlyPay: response?.monthlyPay || 0,
              overtimePay: response?.overtimePay || 0,
            },
            ctc: {
              accomodation: {
                type: response?.ctc?.accomodation?.type,
                value: response?.ctc?.accomodation?.value,
                enabled: response?.ctc?.accomodation?.value > 0,
              },
              food: {
                type: response?.ctc?.food?.type,
                value: response?.ctc?.food?.value,
                enabled: response?.ctc?.food?.value > 0,
              },
              medical: {
                type: response?.ctc?.medical?.type,
                value: response?.ctc?.medical?.value,
                enabled: response?.ctc?.medical?.value > 0,
              },
              transportation: {
                type: response?.ctc?.transportation?.type,
                value: response?.ctc?.transportation?.value,
                enabled: response?.ctc?.transportation?.value > 0,
              },
              airTickets: {
                type: response?.ctc?.airTickets?.type,
                value: response?.ctc?.airTickets?.value,
                enabled: response?.ctc?.airTickets?.value > 0,
              },
            },
          })
        } catch (error) {
          console.error('Error fetching customer details:', error)
        }
      }
    }
    fetchData()
  }, [stepperRef])

  useEffect(() => {
    if (!stepperRef.current) {
      return
    }

    loadStepper()
  }, [stepperRef])

  useEffect(() => {
    console.log('initValues', initValues)
  }, [initValues])

  return (
    <div
      ref={stepperRef}
      className='stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid'
      id='kt_create_account_stepper'
    >
      <div className='card d-flex justify-content-center justify-content-xl-start flex-row-auto w-100 w-xl-300px w-xxl-400px h-100 me-9'>
        <div className='card-body xl:fixed px-6 px-lg-10 px-xxl-15 py-20'>
          <div className='stepper-nav'>
            <div className='stepper-item current' data-kt-stepper-element='nav'>
              <div className='stepper-wrapper'>
                <div className='stepper-icon w-40px h-40px'>
                  <i className='stepper-check fas fa-check'></i>
                  <span className='stepper-number'>1</span>
                </div>
                <div className='stepper-label'>
                  <h3 className='stepper-title-dark'>Basic Information</h3>
                </div>
              </div>

              <div className='stepper-line h-40px'></div>
            </div>

            <div className='stepper-item' data-kt-stepper-element='nav'>
              <div className='stepper-wrapper'>
                <div className='stepper-icon w-40px h-40px'>
                  <i className='stepper-check fas fa-check'></i>
                  <span className='stepper-number'>2</span>
                </div>

                <div className='stepper-label'>
                  <h3 className='stepper-title-dark'>Work Information</h3>
                </div>
              </div>

              <div className='stepper-line h-40px'></div>
            </div>

            <div className='stepper-item' data-kt-stepper-element='nav'>
              <div className='stepper-wrapper'>
                <div className='stepper-icon w-40px h-40px'>
                  <i className='stepper-check fas fa-check'></i>
                  <span className='stepper-number'>3</span>
                </div>

                <div className='stepper-label'>
                  <h3 className='stepper-title-dark'>Salary Information</h3>
                </div>
              </div>

              <div className='stepper-line h-40px'></div>
            </div>

            <div className='stepper-item' data-kt-stepper-element='nav'>
              <div className='stepper-wrapper'>
                <div className='stepper-icon w-40px h-40px'>
                  <i className='stepper-check fas fa-check'></i>
                  <span className='stepper-number'>4</span>
                </div>

                <div className='stepper-label'>
                  <h3 className='stepper-title-dark'>CTC Information</h3>
                </div>
              </div>

              <div className='stepper-line h-40px'></div>
            </div>

            <div className='stepper-item' data-kt-stepper-element='nav'>
              <div className='stepper-wrapper'>
                <div className='stepper-icon w-40px h-40px'>
                  <i className='stepper-check fas fa-check'></i>
                  <span className='stepper-number'>5</span>
                </div>

                <div className='stepper-label'>
                  <h3 className='stepper-title-dark'>Personal Details</h3>
                </div>
              </div>

              <div className='stepper-line h-40px'></div>
            </div>

            <div className='stepper-item' data-kt-stepper-element='nav'>
              <div className='stepper-wrapper'>
                <div className='stepper-icon w-40px h-40px'>
                  <i className='stepper-check fas fa-check'></i>
                  <span className='stepper-number'>6</span>
                </div>

                <div className='stepper-label'>
                  <h3 className='stepper-title-dark'>Indentity Information</h3>
                </div>
              </div>
            </div>

            <div className='stepper-line h-40px'></div>

            <div className='stepper-item' data-kt-stepper-element='nav'>
              <div className='stepper-wrapper'>
                <div className='stepper-icon w-40px h-40px'>
                  <i className='stepper-check fas fa-check'></i>
                  <span className='stepper-number'>7</span>
                </div>

                <div className='stepper-label'>
                  <h3 className='stepper-title-dark'>Contact Details</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='d-flex flex-row-fluid flex-center bg-body rounded'>
        {initValues && (
          <Formik validationSchema={currentSchema} initialValues={initValues} onSubmit={submitStep}>
            {() => (
              <Form className='mx-auto mw-600px w-100 pt-15 pb-10' id='kt_create_account_form'>
                <div className='current' data-kt-stepper-element='content'>
                  <Step1 />
                </div>

                <div data-kt-stepper-element='content'>
                  <Step2 />
                </div>

                <div data-kt-stepper-element='content'>
                  <Step3 />
                </div>

                <div data-kt-stepper-element='content'>
                  <CTC />
                </div>

                <div data-kt-stepper-element='content'>
                  <Step4 />
                </div>

                <div data-kt-stepper-element='content'>
                  <Step5 />
                </div>

                <div data-kt-stepper-element='content'>
                  <Step6 />
                </div>

                <div className='d-flex flex-stack pt-15'>
                  <div className='mr-2'>
                    <button
                      onClick={prevStep}
                      type='button'
                      className='btn btn-lg btn-light-primary me-3'
                      data-kt-stepper-action='previous'
                    >
                      <KTIcon iconName='arrow-left' className='fs-4 me-1' />
                      Back
                    </button>
                  </div>

                  <div>
                    <button type='submit' className='btn btn-lg btn-primary me-3'>
                      <span className='indicator-label'>
                        {!isSubmitButton && 'Continue'}
                        {isSubmitButton && 'Submit'}
                        <KTIcon iconName='arrow-right' className='fs-3 ms-2 me-0' />
                      </span>
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        )}
      </div>
    </div>
  )
}
