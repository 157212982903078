// import React, { useEffect, useState } from 'react'
// import { useParams } from 'react-router-dom'
// // import {getInvoicebyids} from '../core/_requests'
// import '../print.css'
// import html2pdf from 'html2pdf.js'
// import { isAccordionItemSelected } from 'react-bootstrap/esm/AccordionContext'
// import { getPurchaseReceiveById } from '../../Core/_requests'

// function PurchaseReceive({ companyLogo }) {

//   const [vat, setVat] = useState(0);
//   const [total, setTotal] = useState(0);
//   const {id} = useParams()
//   const [data, setPurchaseReceiveData] = useState<any>()

//   const getData = async () => {
//     try {
//       const res = await getPurchaseReceiveById(id)
//       setPurchaseReceiveData(res)
//       console.log("res", res)
//     } catch (error) {
//       console.error('Axios error:', error)
//       throw error
//     }
//   }
//   useEffect(() => {
//     getData()
//   }, [])

import React, {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
// import {getInvoicebyids} from '../core/_requests'
import '../print.css'
import {getPaymentMadeById} from '../../Core/_requests'
import {isAccordionItemSelected} from 'react-bootstrap/esm/AccordionContext'

function PaymentMade({companyLogo, color, setColor, minFooter}) {
  const [vat, setVat] = useState(0)
  const [total, setTotal] = useState(0)
  const [data, setPaymentMadeData] = useState<any>()
  const {id} = useParams()
  const [contactPerson, setContactPerson] = useState<any>()
  const [imageSize, setImageSize] = useState<any>(false)
  const getData = async () => {
    try {
      const res = await getPaymentMadeById(id)
      setPaymentMadeData(res)
      console.log('res', res)
      setColor(res?.company?.procurementColor)
      if (res?.contactPerson !== '') {
        setContactPerson(
          res?.vendor?.contactPersons?.find((person) => person?._id === res?.contactPerson) || {}
        )
      } else {
        setContactPerson(res?.vendor?.primaryContact)
      }
    } catch (error) {
      console.error('Axios error:', error)
      throw error
    }
  }
  useEffect(() => {
    getData()
  }, [])

  const formatCreatedAt = (createdAt: string | undefined) => {
    if (!createdAt) return 'N/A'
    const formattedDate = new Date(createdAt).toLocaleDateString('en-GB', {
      // day: '2-digit',
      // month: 'short',
      // year: 'numeric',
    })
    return formattedDate
  }

  useEffect(() => {
    if (data?.tax) {
      const tax = (data?.subtotal * data?.tax) / 100
      setVat(tax)
    }
  }, [data])

  const handleOnLoad = (e) => {
    const img = e.target
    if (img.naturalWidth > img.naturalHeight) {
      setImageSize(true)
    } else {
      setImageSize(false)
    }
  }

  const handlePrint = () => {
    window.print() // This will trigger the browser's print functionality
  }
  const PF = 'https://qf-assets.s3.me-central-1.amazonaws.com/'

  return (
    <>
      <div className='app-main flex-column flex-row-fluid ' id='kt_app_main'>
        {/*begin::Content wrapper*/}
        <div className='d-flex flex-column flex-column-fluid'>
          {/*begin::Toolbar*/}
          <div
            id='kt_app_toolbar'
            className='app-toolbar mb-6 '
            // data-kt-sticky="false"
            // data-kt-sticky-name="app-toolbar"
            // data-kt-sticky-offset="{default: 'false', lg: '300px'}"
          ></div>
          {/*end::Toolbar*/}
          {/*begin::Content*/}
          <div className='app-content flex-column-fluid '>
            {/*begin::Content container*/}
            <div
              id='kt_app_content_container'
              className='app-container  container-xxl print-content'
            >
              {/* begin::Invoice 3*/}
              <div
                id='pdf-content'
                className='card !rounded-none mx-auto'
                style={{
                  height: '297mm',
                  width: '210mm',
                }}
              >
                {/* begin::Body*/}
                <div className='card-body pt-8 pb-0'>
                  {/* begin::Wrapper*/}
                  <div className='mw-lg-1050px w-100'>
                    {/* begin::Header*/}
                    <div className='d-flex justify-content-between align-items-center flex-column flex-sm-row'>
                      {/*end::Logo*/}
                      <div className='text-sm-end'>
                        {/*begin::Logo*/}
                        <a
                          href='#'
                          className={`d-block ${imageSize ? 'mw-275px' : 'mw-150px'} ms-sm-auto`}
                        >
                          {companyLogo ? (
                            <img
                              alt='Logo'
                              src={URL.createObjectURL(companyLogo)}
                              className={imageSize ? 'w-100' : 'w-50'}
                              onLoad={handleOnLoad}
                            />
                          ) : (
                            <img
                              alt='thumbnail'
                              src={PF + data?.company?.companyLogo}
                              className={imageSize ? 'w-100' : 'w-50'}
                              onLoad={handleOnLoad}
                            />
                          )}
                        </a>
                        {/*end::Logo*/}
                      </div>
                      {/* <h4 className='fw-bold text-gray-700 fs-qx pb-7'>Company Address</h4> */}
                      <h4 className='fw-bold text-gray-800 xl:fs-1 d-flex flex-column  text-right header-text'>
                        <span
                          className='mb-1 text-right fs-1'
                          style={{
                            // fontFamily: 'Rubik',
                            fontFamily: 'Scheherazade New',
                            fontOpticalSizing: 'auto',
                            fontStyle: 'normal',
                            wordSpacing: '10px',
                          }}
                        >
                          {data?.company?.arabicName}
                        </span>
                        <span
                          className='mb-1 text-right fs-3 tracking-wider'
                          style={{
                            color: color,
                          }}
                        >
                          {data?.company?.name}
                        </span>
                        <span className='fs-6 text-muted'>
                          VAT {data?.company?.vat} <span style={{color: color}}>|</span> C.R.{' '}
                          {data?.company?.cr}
                        </span>
                      </h4>
                    </div>
                    {/*end::Header*/}
                    <div className='border-b-black border-b mx-9'></div>
                    {/*begin::Body*/}

                    <div className='fs-3 mt-5 uppercase fs-6 font-bold text-gray-800'>
                      Payment Made
                    </div>
                    <div className='pb-2 mt-5'>
                      {/*begin::Wrapper*/}
                      <div className='d-flex flex-column gap-7 gap-md-10'>
                        {/* <div className=" d-flex flex-root justify-content-between gap-7 gap-sm-0 g-5 flex-column  mb-5  fw-bold">
                            <div className="d-flex flex-md-root flex-column mb-8">
                              <span className="text-muted">Purchase Order</span>
                              <span className="fs-6">#{data?.purchaseOrder?.id}</span>
                            </div>
                            <div className="flex-md-root d-flex flex-column mb-8">
                              <span className="text-muted">Date</span>
                              <span className="fs-6">{formatCreatedAt(data?.createdAt)}</span>
                            </div>
                          </div> */}
                        <div
                          className='d-flex justify-content-between flex-column flex-sm-row fs-9'
                          style={{
                            textTransform: 'uppercase',
                          }}
                        >
                          <div className=' mb-5'>
                            <div className='d-flex  flex-column mb-5'>
                              <span className='fw-bold'>Payment</span>
                              <span className='fs-7'>{data?.id}</span>
                            </div>
                            <div className='d-flex flex-column mb-5'>
                              <span className='fw-bold'>Paid To</span>

                              <span className='fs-7 '>{data?.vendor?.companyName}</span>
                            </div>
                            <div className='d-flex flex-md-root flex-column mb-5'>
                              <span className='fw-bold mb-1'>Address</span>
                              <span className='fs-7 mw-200px'>
                                {data?.vendor?.billingAddress.addressLine1}{' '}
                                {data?.vendor?.billingAddress.addressLine2}
                                <br />
                                {data?.vendor?.billingAddress.city}{' '}
                                {data?.vendor?.billingAddress.postalCode}
                                <br />
                                {data?.vendor?.billingAddress.country}
                              </span>
                            </div>
                            {/* <div className="d-flex flex-column mb-5">
                              <span className="fw-bold">Contact Number</span>

                              <span className="fs-7 text-dark">{data?.vendor?.contactNumbers?.mobilePhone}</span>
                            </div> */}
                          </div>
                          <div className='  mb-5 '>
                            <div className='d-flex  flex-column mb-5'>
                              <span className='fw-bold'>Attention</span>
                              <span className='fs-7'>
                                {contactPerson?.salutation} {contactPerson?.firstName}{' '}
                                {contactPerson?.lastName}
                              </span>
                            </div>
                            <div className=' d-flex flex-column mb-5'>
                              <span className='fw-bold'>Payment Mode</span>
                              <span className='fs-7'>{data?.paymentMode}</span>
                            </div>
                            <div className=' d-flex flex-column  '>
                              <span className='fw-bold'>Paid Through</span>
                              <span className='fs-7'>{data?.paymentMode}</span>
                            </div>
                            {/* <div className="flex-root d-flex flex-column">
                                                    <span className="fw-bold">Shipment ID</span>
                                                    <span className="fs-5">#SHP-0025410</span>
                                                </div> */}
                          </div>
                          <div className='text-end  mb-5 '>
                            {/* <div className="d-flex flex-column mb-5">
                              <span className="fw-bold">Quotation Date</span>

                              <span className="fs-7">{formatCreatedAt(data?.createdAt)}</span>
                            </div>
                            <div className=" d-flex flex-column mb-5">
                              <span className="fw-bold">Quotation Number</span>

                              <span className="fs-7">{data?.id}</span>
                            </div> */}
                            <div className=' d-flex flex-column mb-5 '>
                              <span className='fw-bold'>Status</span>
                              <span className='fs-7'>{data?.approval}</span>
                            </div>
                            <div className=' d-flex flex-column  mb-5 '>
                              <span className='fw-bold'>Payment Date </span>

                              <span className='fs-7'>{formatCreatedAt(data?.paymentDate)}</span>
                            </div>
                            <div className=' d-flex flex-column '>
                              <span className='fw-bold'>Amount Paid</span>
                              <span className=''>
                                <span
                                  className='fs-5 badge badge-success'
                                  style={{
                                    padding: '0.5rem',
                                  }}
                                >
                                  {data?.vendor?.currency}{' '}
                                  {data?.amountPaid.toLocaleString('en-US', {
                                    minimumFractionDigits: 2,
                                  })}{' '}
                                </span>
                              </span>
                            </div>

                            {/* <div className="flex-root d-flex flex-column">
                                                    <span className="text-muted">Shipment ID</span>
                                                    <span className="fs-5">#SHP-0025410</span>
                                                </div> */}
                          </div>
                        </div>

                        {/*end::Order details*/}
                        {/*begin::Billing & shipping*/}
                        {/* <div className="d-flex flex-column flex-root mb-10 gap-7 gap-sm-20 justify-content-between ">
                            <div className="d-flex flex-md-root flex-column fw-bold">
                              <span className="text-muted mb-2">Vendor </span>
                              <span className='text-primary'>
                                {data?.vendor?.displayName}
                              </span>
                              <span className="fs-6">
                                {data?.vendor?.billingAddress.addressLine1}{" "}
                                {data?.vendor?.billingAddress.addressLine2}
                                <br />
                                {data?.vendor?.billingAddress.city}{" "}
                                {data?.vendor?.billingAddress.postalCode}
                                <br />
                                {data?.vendor?.billingAddress.country}

                              </span>
                            </div>
                            
                          </div> */}
                        {/* <div className="flex-column flex-root">
                            <div className="flex-md-root d-flex flex-column mb-8 fw-bold">
                              <span className="text-muted mb-4">Status</span>
                              <span className="">
                                <span className="badge fs-3 card bg-success text-white py-4 px-3">RECEIVE</span>
                              </span>
                            </div>
                          </div> */}

                        {/*end::Billing & shipping*/}
                        {/*begin:Order summary*/}
                        <div className='flex-grow-1'>
                          {/*begin::Table*/}
                          <div className='table-responsive border-bottom border-dashed mb-9'>
                            <table className='table table-bordered border-gray-700 m-0'>
                              <thead>
                                <tr
                                  className='fs-8 fw-bold uppercase'
                                  style={{
                                    backgroundColor: color,
                                  }}
                                >
                                  {/* <th className="min-w-50px text-white text-start pb-3 ">
                                    <span>Sr. No.</span>
                                  </th> */}
                                  <th className='min-w-80px text-white text-center pb-3 '>
                                    <span className='arabic-font'>رقم الفاتوره</span>
                                    <br />
                                    Bill Number
                                  </th>
                                  <th className='min-w-80px text-white text-center pb-3'>
                                    <span className='arabic-font'>تاريخ الفاتورة</span>
                                    <br />
                                    Bill Date
                                  </th>
                                  <th className='min-w-80px text-white text-center pb-3'>
                                    <span className='arabic-font'>مبلغ الفاتورة</span>
                                    <br />
                                    Bill Amount
                                  </th>
                                  <th className='min-w-80px text-white text-center pb-3'>
                                    <span className='arabic-font'>مبلغ الدفع</span>
                                    <br />
                                    Payment Amount
                                  </th>
                                </tr>
                              </thead>
                              <tbody className=''>
                                {data?.billsData?.map((items, index) => (
                                  <tr
                                    key={index}
                                    className='fw-semibold text-gray-600 fs-9 text-center'
                                  >
                                    <td>{items.billNo?.id}</td>
                                    <td className=''>{formatCreatedAt(items?.date)}</td>
                                    <td className='text-end'>
                                      {Number(items.billAmount).toLocaleString('en-US', {
                                        minimumFractionDigits: 2,
                                      })}
                                      {/* {data?.vendor?.currency} */}
                                    </td>
                                    <td className='text-end'>
                                      {Number(data?.amountPaid).toLocaleString('en-US', {
                                        minimumFractionDigits: 2,
                                      })}
                                      {/* {data?.vendor?.currency} */}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                          {/*end::Table*/}
                          {/*begin::Container*/}

                          {/*end::Container*/}
                        </div>
                        {/*end:Order summary*/}
                      </div>
                      {/*end::Wrapper*/}
                    </div>
                    {/*end::Body*/}
                    {/* begin::Footer*/}

                    {/* end::Footer*/}
                  </div>
                  {/* end::Wrapper*/}
                </div>
                {/* end::Body*/}
                <div id='pdfFooter' className='card-footer text-center py-2 px-0 fw-bold mb-1'>
                  <div className='bg-[#515560]  text-white'>
                    {/* <div className='skew-x-12 py-1'>Tel | Fax Number: {data?.company?.faxNumber} | P.O. Box : {data?.company?.pOBox} | {data?.company?.companyAddress}</div> */}
                    <div className='py-1'>
                      {!minFooter &&
                        `Mobile :${' '}
                  ${data?.company?.mobileNumber} | `}
                      E-mail : {data?.company?.companyEmail} | Website : {data?.company?.webURL}
                    </div>
                  </div>
                  <div
                    className=' text-white '
                    style={{
                      backgroundColor: `${color}`,
                    }}
                  >
                    <div className='py-1'>
                      P.O. Box : {data?.company?.pOBox} | Address: {data?.company?.companyAddress}
                    </div>
                  </div>
                </div>
                <div className='d-flex justify-center mb-4'>
                  <p className='fs-8 serif-font'>Page 1 of 1</p>
                </div>
              </div>
              {/* end::Invoice 1*/}{' '}
            </div>
            {/*end::Content container*/}
          </div>
          {/*end::Content*/}
        </div>
        {/*end::Content wrapper*/}
        {/*begin::Footer*/}
        {/*end::Footer*/}{' '}
      </div>
    </>
  )
}

export default PaymentMade
