import React, {useEffect, useRef, useState} from 'react'
import {Link} from 'react-router-dom'
import {KTIcon} from '../../../../../_metronic/helpers'
import {bulkUploadService, getItems} from '../../Core/_requests'
import {useAuth} from '../../../../modules/auth'
import {RiArrowDropDownLine} from 'react-icons/ri'
import Filter2 from '../Filter2'
import {useSelector, useDispatch} from 'react-redux'
import {RootState} from '../../../../redux/store'
import {setServices} from '../../../../redux/inventory/products/servicesSlice'
import useExcelImport from '../../../account/components/useExcelImport'
import {Spinner} from 'react-bootstrap'

const ItemList = () => {
  const {currentUser} = useAuth()
  const dispatch = useDispatch()
  const excelfileRef = useRef<HTMLInputElement>(null)
  const servicesList = useSelector((state: RootState) => state.inventory.services.services)
  const loading = useSelector((state: RootState) => state.inventory.services.loading)
  const [permissions, setPermissions] = useState<any>({})
  const [dropdown, setDropdown] = useState([])
  const [excelImportLoading, setExcelImportLoading] = useState(false)
  const handleUploadExcel = useExcelImport()
  const [filteredItemList, setFilteredItemList] = useState([])
  const [searchQuery, setSearchQuery] = useState('')
  const PF = 'https://qf-assets.s3.me-central-1.amazonaws.com/'

  useEffect(() => {
    if (currentUser) {
      setPermissions(currentUser.permissions)
    }
  }, [currentUser])

  const fetchData = async () => {
    const res = await getItems(currentUser?.organization)
    console.log(res)
    setFilteredItemList(res)
    setDropdown(Array(res.length).fill(false))
    dispatch(setServices(res))
  }

  useEffect(() => {
    if (servicesList.length === 0) fetchData()
    else {
      setFilteredItemList(servicesList)
      setDropdown(Array(servicesList.length).fill(false))
    }
  }, [])

  const formatCreatedAt = (createdAt: string | undefined) => {
    if (!createdAt) return 'N/A'
    const formattedDate = new Date(createdAt).toLocaleDateString('en-GB', {})
    return formattedDate
  }

  const handleClick = (index) => {
    const newDropdownState = dropdown.map((_, i) => (i === index ? !dropdown[i] : false))
    setDropdown(newDropdownState)
  }

  useEffect(() => {
    const filteredData = servicesList.filter(
      (item) =>
        item.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.employee?.firstName.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item?.id?.toLowerCase().includes(searchQuery.toLowerCase())
    )
    setFilteredItemList(filteredData)
  }, [searchQuery, servicesList])

  let searchFilters = {
    filter: {
      isValid: true,
      approval: '',
    },
  }
  const setSearchFilter = (updateValues) => {
    searchFilters = {
      ...searchFilters,
      ...(updateValues.filter && {
        filter: {
          ...searchFilters.filter,
          ...updateValues.filter,
        },
      }),
    }
  }
  const setValidityFilter = (isValid) => {
    setSearchFilter({
      filter: {
        isValid,
      },
    })
  }

  const handlereset = async () => {
    setSearchFilter({
      filter: {
        isValid: true,
      },
    })
    fetchData()
  }

  if (loading) {
    return (
      <div className='d-flex align-items-center justify-center'>
        <div className='loader'></div>
      </div>
    )
  }

  return (
    <div className='p-6 md:m-4  card  card-xxl-stretch'>
      <span className='card-label fw-bold fs-3 ps-15 pt-8 '>Services</span>
      <div className='d-flex  justify-between card-header py-5 '>
        <div className=' d-flex flex-row  card-title align-items-start'>
          <div className='d-flex  items-center relative space-x-3 mb-3 mb-md-0'>
            <form
              data-kt-search-element='form'
              className='w-100 position-relative '
              autoComplete='off'
            >
              <KTIcon
                iconName='magnifier'
                className='fs-2 text-lg-1 text-gray-500 position-absolute top-50 translate-middle-y ms-2'
              />

              <input
                type='text'
                className='form-control form-control-solid w-250px ps-14'
                name='search'
                placeholder='Search...'
                data-kt-search-element='input'
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    e.preventDefault()
                  }
                }}
              />
            </form>
          </div>

          <div className=' d-flex flex-row ml-5'>
            <Filter2
              searchFilters={searchFilters}
              setSearchFilter={setSearchFilter}
              handlereset={handlereset}
              getDocsList={fetchData}
              setValidityFilter={setValidityFilter}
              showStatusFilter={false}
            />
          </div>
        </div>

        {(currentUser?.hierarchy === 1 || currentUser?.profileType === 'superadmin') && (
          <div className='!ml-auto !mr-3 card-toolbar'>
            <button
              className='btn btn-danger btn-sm'
              onClick={() => {
                excelfileRef.current.click()
              }}
            >
              {excelImportLoading ? (
                <>
                  <Spinner animation='border' role='status' />
                </>
              ) : (
                'Import Services'
              )}
            </button>
            <input
              type='file'
              accept='.xlsx, .xls'
              onChange={(e) =>
                handleUploadExcel(e, bulkUploadService, setExcelImportLoading, 'services')
              }
              ref={excelfileRef}
              className='hidden'
            />
          </div>
        )}

        {(permissions?.items?.create || currentUser?.profileType === 'superadmin') && (
          <div className='card-toolbar'>
            <Link to={'/items/add'} className='btn btn-sm btn-light-primary'>
              <KTIcon iconName='plus' className='fs-2' />
              Add Service
            </Link>
          </div>
        )}
      </div>
      <div className='card-body py-3'>
        <div className='table-responsive overflow-visible'>
          <table className='table table-row-dashed table-row-gray-200 align-middle gs-0 gy-4'>
            <thead>
              <tr className='fw-bold text-muted'>
                {/* <th className='w-25px'>
                  <div className='form-check form-check-sm form-check-custom form-check-solid'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      value='1'
                      data-kt-check='true'
                      data-kt-check-target='.widget-9-check'
                      checked={masterCheckbox}
                      onChange={() => setMasterCheckbox(!masterCheckbox)}
                    />
                  </div>
                </th> */}
                {/* <th className='ps-4 min-w-50px rounded-start'>Date</th> */}
                <th className='min-w-150px'>Name</th>
                {/* <th className='min-w-150px'>Type</th> */}
                <th className='min-w-150px'>Price</th>
                {/* <th className='min-w-150px'>Unit</th> */}
                <th className='min-w-100px'>SKU</th>
                {(permissions?.items?.update || currentUser?.profileType === 'superadmin') && (
                  <th className='min-w-100px w-100px text-center'>Actions</th>
                )}
              </tr>
            </thead>
            <tbody>
              {filteredItemList?.map((item, i) => (
                <tr key={i}>
                  {/* <td>
                      <div className='form-check form-check-sm form-check-custom form-check-solid'>
                        <input
                          className='form-check-input widget-9-check'
                          type='checkbox'
                          value='1'
                          checked={item.isChecked}
                          onChange={() => {
                            const updatedTableData = [...tableData]
                            updatedTableData[i].isChecked = !updatedTableData[i].isChecked
                            setTableData(updatedTableData)
                          }}
                        />
                      </div>
                    </td> */}
                  {/* <td>
                    <Link
                      to={`/items/${item._id}`}
                      className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6 capitalize'
                    >
                      {formatCreatedAt(item?.createdAt)}
                    </Link>
                  </td> */}
                  <td className='d-flex align-items-center'>
                    <div className='symbol symbol-50px me-2'>
                      <span className='symbol-label'>
                        <img
                          src={`${PF}${item.thumbnail}`}
                          alt=''
                          className='h-50 align-self-center'
                        />
                      </span>
                    </div>
                    <div className='d-flex justify-content-start flex-column'>
                      <Link to={`/items/${item._id}`}>
                        <span className='text-dark fw-bold text-hover-primary fs-6'>
                          {item.name}
                        </span>
                      </Link>
                      <span className='text-muted fw-semibold text-muted d-block fs-7'>
                        {item.description}
                      </span>
                    </div>
                  </td>
                  {/* <td>{item.type}</td> */}
                  <td>{item.price}</td>
                  {/* <td>{item.unit}</td> */}
                  <td>{item.sku}</td>

                  {(permissions?.items?.update || currentUser?.profileType === 'superadmin') && (
                    <td className='relative '>
                      <div className='d-flex justify-content-end flex-shrink-0'>
                        <button
                          onClick={() => handleClick(i)}
                          className=' bg-gray-100 hover:bg-lime-50 px-6 py-2 flex items-center rounded  '
                        >
                          Actions <RiArrowDropDownLine />
                        </button>
                      </div>

                      {dropdown[i] && (
                        <div className='absolute flex flex-col bg-white  w-40 shadow-[0_16px_16px_6px_rgba(128,128,128,0.1)] top-16  right-[0px] rounded p-3 z-50 space-y-2'>
                          <Link to={`edit/${item._id}`}>
                            <button className='hover:bg-lime-50 p-2 w-full text-gray-700 text-start rounded'>
                              Edit
                            </button>
                          </Link>
                          {/* <button
                            className='hover:bg-lime-50 p-2 text-start rounded'
                            onClick={() => handleDeleteToggle(i)}
                            data-bs-toggle='modal'
                            data-bs-target='#myModal'
                          >
                            Delete
                          </button> */}
                          {/* <Link to={`view-category/${item._id}`}>
                            <button className='hover:bg-lime-50 text-gray-700 w-full p-2 text-start rounded'>
                              View Page
                            </button>
                          </Link> */}
                        </div>
                      )}
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {/* <div className='modal' id='myModal'>
        <div className='modal-dialog modal-dialog-centered '>
          <div className='modal-content'>
            <div className='modal-header'>
              <h4 className='modal-title mx-56 text-yellow-500 '>
                <CgDanger size={100} />
              </h4>
              <button type='button' className='btn-close' data-bs-dismiss='modal'></button>
            </div>

            <div className='modal-body text-center fs-6'>
              Are you sure you want to delete {data[selectedItem]?.name} ?
            </div>

            <div className='modal-footer text-center mx-auto'>
              <button
                type='button'
                className='btn btn-danger '
                data-bs-dismiss='modal'
                onClick={() => handleDelete(tableData[selectedItem]?._id)}
              >
                Delete
              </button>
              <button
                type='button'
                className='btn btn-light '
                data-bs-dismiss='modal'
                id='closeModalButtondelete'
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  )
}

export default ItemList
