import {useIntl} from 'react-intl'
import {MenuItem} from './MenuItem'
import {MenuInnerWithSub} from './MenuInnerWithSub'
import {MegaMenu} from './MegaMenu'
import {useLocation} from 'react-router'
import {useEffect, useState} from 'react'

export function MenuInner() {
  const {pathname} = useLocation()
  const [currentUrl, setCurrentUrl] = useState<string>('')

  useEffect(() => {
    // Check if the URL contains the substring "marketing"
    if (pathname.includes('marketing')) {
      setCurrentUrl('marketing')
    } else if (pathname.includes('event')) {
      setCurrentUrl('event')
    } else if (pathname.includes('sales/approval')) {
      setCurrentUrl('salesapproval')
    } else if (pathname.includes('procurement/approval')) {
      setCurrentUrl('vendorapproval')
    } else if (
      pathname.includes('project/calendar') ||
      pathname.includes('project/calendar/gantt-chart')
    ) {
      setCurrentUrl('projectcalendar')
    } else if (pathname.includes('maintenances')) {
      setCurrentUrl('maintenances')
    } else if (pathname.includes('sales/quotes/list')) {
      setCurrentUrl('salesquotes')
    } else if (pathname.includes('account/expenses') || pathname.includes('account/pcr')) {
      setCurrentUrl('expensenpcr')
    } else if (pathname.includes('hrm/payroll')) {
      setCurrentUrl('hrmpayroll')
    } else if (
      pathname.includes('project/invoice/summary') ||
      pathname.includes('project/invoice/vat')
    ) {
      setCurrentUrl('projectinvoice')
    } else {
      // If none of the conditions match, set a default value
      setCurrentUrl('none')
    }
  }, [pathname])

  return (
    <>
      {currentUrl === 'salesapproval' ? (
        <>
          <MenuItem title='Quotation' to='/sales/approval/quotes' />
          <MenuItem title='Invoice' to='/sales/approval/invoice' />
          <MenuItem title='Sales Order' to='/sales/approval/salesorders' />
          <MenuItem title='Delivery Note' to='/sales/approval/deliveryChallan' />
          <MenuItem title='Payment Received' to='/sales/approval/paymentReceived' />
        </>
      ) : currentUrl === 'salesquotes' ? (
        <>
          <MenuItem title='Accepted' to='/sales/quotes/list/accepted' />
          <MenuItem title='Pending' to='/sales/quotes/list/pending' />
          <MenuItem title='Rejected' to='/sales/quotes/list/rejected' />
        </>
      ) : currentUrl === 'vendorapproval' ? (
        <>
          <MenuItem title='RFQ' to='/procurement/approval/rfq' />
          <MenuItem title='Purchase Request' to='/procurement/approval/purchaserequest' />
          <MenuItem title='Purchase Order' to='/procurement/approval/purchaseOrder' />
          <MenuItem title='Purchase Receives' to='/procurement/approval/purchaseReceive' />
          <MenuItem title='Bills' to='/procurement/approval/billsList' />
          <MenuItem title='Payment Made' to='/procurement/approval/paymentMadeList' />
        </>
      ) : currentUrl === 'projectcalendar' ? (
        <>
          <MenuItem title='Calendar' to='/project/calendar/calendar' />
          <MenuItem title='Gantt Chart' to='/project/calendar/gantt-chart' />
        </>
      ) : currentUrl === 'maintenances' ? (
        <>
          <MenuItem title='Inspection' to='/maintenances/inspection' />
          <MenuItem title='Issues' to='/maintenances/issues' />
          <MenuItem title='Reminders' to='/maintenances/reminders' />
        </>
      ) : currentUrl === 'expensenpcr' ? (
        <>
          <MenuItem title='Expense' to='/account/expenses' />
          <MenuItem title='Petty Cash Request' to='/account/pcr' />
        </>
      ) : currentUrl === 'hrmpayroll' ? (
        <>
          <MenuItem title='Individual' to='/hrm/payroll' exactPath={true} />
          <MenuItem title='Department' to='/hrm/payroll/department' />
          <MenuItem title='Project' to='/hrm/payroll/project' />
        </>
      ) : currentUrl === 'projectinvoice' ? (
        <>
          <MenuItem
            title='Individual'
            to={
              pathname.includes('project/invoice/summary')
                ? '/project/invoice/summary/individual'
                : '/project/invoice/vat/individual'
            }
          />
          <MenuItem
            title='Category'
            to={
              pathname.includes('project/invoice/summary')
                ? '/project/invoice/summary/category'
                : '/project/invoice/vat/category'
            }
          />
        </>
      ) : (
        <></>
      )}
      {/* {currentUrl === 'marketing' ? (
        <>
          <MenuItem title='marketing1' to='/marketing/marketing1' />
          <MenuItem title='marketing2' to='/marketing/marketing2' />
          <MenuItem title='marketing3' to='/marketing/marketing3' />
        </>
      ) :
       currentUrl === 'hrm' ? (
        <>
          <MenuItem title='HRM1' to='/hrm/hrm1' />
          <MenuItem title='HRM2' to='/hrm/hrm2' />
          <MenuItem title='HRM3' to='/hrm/hrm3' />
        </>
      ) : currentUrl === 'event' ? (
        <>
          <MenuItem title='Road Show' to='/event/roadshow' />
          <MenuItem title='Launch' to='/event/launch' />
        </>
      ) : (
        <></>
      )} */}
    </>
  )
}
