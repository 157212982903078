import * as Yup from 'yup'

export interface InvoiceRate {
  monthlyPay: number
  hourlyPay: number
  overtimePay: number
  dailyWorkingHours: number
}

export interface ValueType {
  value: number
  enabled: boolean
  type: 'month' | 'year'
}

export interface CTC {
  accomodation: ValueType
  food: ValueType
  transportation: ValueType
  medical: ValueType
  airTickets: ValueType
}
export interface ICreateAccount {
  // accountType: string
  // accountTeamSize: string
  employeeId: string
  firstName: string
  lastName: string
  nickName: string
  email: string
  department: string
  location: string
  designation: string
  role: string
  employmentType: string
  contractType: string
  employeeStatus: string
  sourceOfHire: string
  dateOfJoining: string
  reportingManager: string
  dateOfBirth: string
  age: number
  gender: string
  aboutMe: string
  passportNumber: string
  idNumber: string
  aadharNumber: number
  workPhoneNo: number
  personalMobile: number
  extension: number
  personalEmail: string
  seatingLocation: string
  tags: string
  presentAddressLine1: string
  presentAddressLine2: string
  presentCity: string
  presentState: string
  presentCountry: string
  presentPostalcode: number
  permanentAddressLine1: string
  permanentAddressLine2: string
  permanentCountry: string
  permanentState: string
  permanentCity: string
  permanentPostalCode: number
  maritalStatus: string
  sameAsPresentAddress: boolean
  yearlyPay: number
  monthlyPay: number
  hourlyPay: number
  dailyPay: number
  dailyWorkingHours: number
  needInvoiceRate: boolean
  invoiceRate: InvoiceRate
  currentExperience: string
  overtimePay: number
  addUser: boolean
  departmentID: string
  roleID: string
  password: string
  ctc: CTC
}

const ctcItemSchema = Yup.object({
  enabled: Yup.boolean(),
  type: Yup.string().oneOf(['month', 'year']),
  value: Yup.number().when('enabled', {
    is: true,
    then: (schema) => schema.required().min(0),
    otherwise: (schema) => schema.notRequired(),
  }),
})

const createAccountSchemas = [
  Yup.object({
    employeeId: Yup.string().required().label('Employee Id'),
    firstName: Yup.string().required().label('First Name'),
    lastName: Yup.string().required().label('Last Name'),
    email: Yup.string().required().label(' Email'),
  }),
  Yup.object({
    // department: Yup.string().required().label('Department'),
    // location: Yup.string().required().label('Location'),
    // designation: Yup.string().required().label('Designation'),
    // role: Yup.string().required().label('Zoho Role'),
    // employementType: Yup.string().label('Employement Type'),
    // dateOfJoining: Yup.string().required().label('Date Of Joining'),
    // employementStatus: Yup.string().required().label('Employement Status'),
    // sourceHire: Yup.string().required().label('Source Hire'),
  }),
  Yup.object({
    hourlyPay: Yup.number().required().min(1).label('Hourly Pay'),
    dailyWorkingHours: Yup.number().required().min(1).label('Daily Working Hours'),
    overtimePay: Yup.number().min(1).label('Overtime Pay'),
  }),
  Yup.object({
    ctc: Yup.object({
      accommodation: ctcItemSchema.label('Accommodation'),
      food: ctcItemSchema.label('Food'),
      transportation: ctcItemSchema.label('Transportation'),
      medical: ctcItemSchema.label('Medical'),
      airTickets: ctcItemSchema.label('Air Tickets'),
    }),
  }),
  Yup.object({
    // dateOfBirth: Yup.string().required().label('Date Of Birth'),
    // age: Yup.number().required().label('Age'),
    // aboutMe: Yup.string().required().label('About Me'),
  }),

  Yup.object({
    // passportNumber: Yup.string().required().label('UAN Number'),
    // idNumber: Yup.string().required().label('PAN Number'),
    // aadharNumber: Yup.number().required().label('Aadhar Number'),
  }),
  Yup.object({
    // workPhone: Yup.number().required().label('Work Phone'),
  }),
]

const inits: ICreateAccount = {
  // accountType: 'personal',
  // accountTeamSize: '50+',
  employeeId: '',
  firstName: '',
  lastName: '',
  nickName: ' ',
  email: '',
  department: '',
  location: '',
  designation: '',
  role: '',
  employmentType: '',
  contractType: 'temporary',
  employeeStatus: '',
  sourceOfHire: '',
  dateOfJoining: '',
  reportingManager: '',
  dateOfBirth: '',
  age: 0,
  gender: ' ',
  aboutMe: '',
  passportNumber: '',
  idNumber: '',
  aadharNumber: 0,
  workPhoneNo: 0,
  personalMobile: 0,
  extension: 0,
  personalEmail: '',
  seatingLocation: '',
  tags: '',
  presentAddressLine1: '',
  presentAddressLine2: '',
  presentCity: '',
  presentState: '',
  presentCountry: '',
  presentPostalcode: 0,
  maritalStatus: ' ',
  permanentAddressLine1: ' ',
  permanentAddressLine2: ' ',
  permanentCountry: ' ',
  permanentState: ' ',
  permanentCity: ' ',
  permanentPostalCode: 0,
  sameAsPresentAddress: false,
  yearlyPay: 0,
  monthlyPay: 0,
  hourlyPay: 0,
  dailyPay: 0,
  dailyWorkingHours: 0,
  currentExperience: '',
  overtimePay: 0,
  addUser: false,
  departmentID: '',
  roleID: '',
  password: '',
  needInvoiceRate: false,
  invoiceRate: {
    dailyWorkingHours: 0,
    hourlyPay: 0,
    monthlyPay: 0,
    overtimePay: 0,
  },
  ctc: {
    accomodation: {
      enabled: false,
      type: 'month',
      value: 0,
    },
    food: {
      enabled: false,
      type: 'month',
      value: 0,
    },
    transportation: {
      enabled: false,
      type: 'month',
      value: 0,
    },
    medical: {
      enabled: false,
      type: 'month',
      value: 0,
    },
    airTickets: {
      enabled: false,
      type: 'month',
      value: 0,
    },
  },
}

export {createAccountSchemas, inits}
