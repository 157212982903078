import React, {useEffect, useRef, useState} from 'react'
import {Link} from 'react-router-dom'
import {KTIcon} from '../../../../_metronic/helpers'
import {getFilteredBills, getFilteredBillsByAgentId, invalidateBills} from '../Core/_requests'
import {useAuth} from '../../../modules/auth'
import Filter2 from '../Components/Filter2'
import {Filtertype2, ValidFilter} from '../Core/_model'
import qs from 'qs'
import {useDispatch, useSelector} from 'react-redux'
import {setBills} from '../../../redux/procurement/bills/billsSlice'
import {RootState} from '../../../redux/store'
import {RiArrowDropDownLine} from 'react-icons/ri'
import {setPurchaseReceiveDataFetch} from '../../../redux/procurement/purchaseReceive/purchaseReceiveSlice'
import debounce from 'lodash/debounce'
import Pagination from '../Components/Pagination'
import DateFilter from '../../account/components/DateFilter'

const BillsList = () => {
  const {currentUser} = useAuth()
  const initialRef = useRef(true)
  const [filteredBillsList, setFilteredBillsList] = useState([])
  const [dropdown, setDropdown] = useState([])
  const [searchQuery, setSearchQuery] = useState('')
  const [permissions, setPermissions] = useState<any>({})
  const [isLoading, setIsLoading] = useState(false)
  const dispatch = useDispatch()
  const billsData = useSelector((state: RootState) => state.procurement.bills.bills)
  const totalPagesStore = useSelector((state: RootState) => state.procurement.bills.totalPages)
  const reFetch = useSelector((state: RootState) => state.procurement.bills.fetch)
  const dateRange = useSelector((state: RootState) => state.procurement.bills.dateState)
  const [dateState, setDateState] = useState<any>(dateRange)
  // Pagination states
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)

  const stringifyObject = (obj: ValidFilter): string => {
    const queryString = qs.stringify(
      {
        filter_validity: obj.filter.isValid,
        filter_approval: obj.filter.approval,
        filter_status: obj.filter.status,
        search_query: searchQuery,
      },
      {encodeValuesOnly: true, skipNulls: true, delimiter: '&'}
    )

    return queryString
  }

  const handleClick = (index) => {
    const newDropdownState = dropdown.map((_, i) => (i === index ? !dropdown[i] : false))
    setDropdown(newDropdownState)
  }

  const getList = async () => {
    const queryString = stringifyObject(searchFilters)
    setIsLoading(true)
    try {
      const data = await getFilteredBills(
        queryString,
        currentUser?.organization,
        currentPage,
        25,
        dateState
      )
      setFilteredBillsList(data?.bills)
      setTotalPages(data?.totalPages)
      setDropdown(Array(data?.bills?.length)?.fill(false))
      return data
    } catch (error) {
      console.error('Axios error:', error)
      throw error
    } finally {
      setIsLoading(false)
    }
  }

  const getBillsByAgent = async () => {
    const queryString = stringifyObject(searchFilters)
    setIsLoading(true)
    try {
      const res = await getFilteredBillsByAgentId(
        queryString,
        currentUser?._id,
        currentPage,
        25,
        dateState
      )
      setFilteredBillsList(res?.bills)
      setTotalPages(res?.totalPages)
      setDropdown(Array(res?.bills?.length)?.fill(false))
      return res
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (currentUser?.permissions) {
      setPermissions(currentUser?.permissions)
    }
  }, [currentUser])

  const fetchBills = () => {
    if (dateState.startDate !== undefined && dateState.endDate !== undefined) {
      if (permissions?.bills?.admin === false) {
        getBillsByAgent()
      } else if (permissions?.bills?.admin === true || currentUser?.profileType === 'superadmin') {
        getList()
      }
    }
  }

  const fetchBillsData = () => {
    if (permissions?.bills?.admin === false) {
      getBillsByAgent().then((res) => dispatch(setBills(res)))
    } else if (permissions?.bills?.admin === true || currentUser?.profileType === 'superadmin') {
      getList().then((res) => dispatch(setBills(res)))
    }
  }

  useEffect(() => {
    if (billsData.length === 0 || reFetch) {
      fetchBillsData()
    } else {
      setFilteredBillsList(billsData)
      setDropdown(Array(billsData.length)?.fill(false))
      setTotalPages(totalPagesStore)
    }
  }, [permissions])

  const debounceSearch = debounce(fetchBills, 500)

  useEffect(() => {
    if (initialRef.current) {
      initialRef.current = false
      return
    }

    debounceSearch()

    return () => debounceSearch?.cancel()
  }, [searchQuery, currentPage, dateState.endDate])

  const formatCreatedAt = (createdAt: string | undefined) => {
    if (!createdAt) return 'N/A'
    const formattedDate = new Date(createdAt).toLocaleDateString('en-GB', {
      // day: '2-digit',
      // month: 'short',
      // year: 'numeric',
    })
    return formattedDate
  }

  const handleActivation = async (id) => {
    const res = await invalidateBills(id)

    if (res) {
      fetchBillsData()
      dispatch(setPurchaseReceiveDataFetch())
    }
  }

  let searchFilters: ValidFilter = {
    filter: {
      isValid: true,
      approval: '',
    },
  }

  const setSearchFilter = (updateValues: {filter?: Partial<Filtertype2>}): void => {
    searchFilters = {
      ...searchFilters,
      ...(updateValues.filter && {
        filter: {
          ...searchFilters.filter,
          ...updateValues.filter,
        },
      }),
    }
  }

  const setValidityFilter = (isValid: boolean) => {
    setSearchFilter({
      filter: {
        isValid,
        approval: '',
      },
    })
  }

  const handlereset = async () => {
    setSearchFilter({
      filter: {
        isValid: true,
      },
    })
    setFilteredBillsList(billsData)
    setDropdown(Array(billsData.length)?.fill(false))
    setCurrentPage(1)
  }

  const setApprovalFilter = (approval: string) => {
    setSearchFilter({
      filter: {
        isValid: searchFilters.filter.isValid,
        status: searchFilters.filter.status,
        approval: approval,
      },
    })
  }

  const setStatusFilter = (status: string) => {
    setSearchFilter({
      filter: {
        isValid: searchFilters.filter.isValid,
        approval: searchFilters.filter.approval,
        status: status,
      },
    })
  }

  return (
    <div className='card card-flush'>
      <span className='card-label fw-bold fs-3 ps-15 pt-8 '>Bills</span>
      <div className='card-header border-0 pt-5'>
        <div className='flex gap-5'>
          <div className='d-flex flex-row card-title align-items-center gap-4'>
            <form
              data-kt-search-element='form'
              className='w-100 position-relative '
              autoComplete='off'
            >
              <KTIcon
                iconName='magnifier'
                className='fs-2 text-lg-1 text-gray-500 position-absolute top-50 translate-middle-y ms-2'
              />
              <input
                type='text'
                className='form-control form-control-solid w-250px ps-14'
                name='search'
                placeholder='Search (Bill ID)'
                data-kt-search-element='input'
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </form>
            <DateFilter dateState={dateState} setDateState={setDateState} />
          </div>

          <div className=' d-flex flex-row  card-title align-items-center'>
            <Filter2
              searchFilters={searchFilters}
              setSearchFilter={setSearchFilter}
              handlereset={handlereset}
              getDocsList={fetchBills}
              setValidityFilter={setValidityFilter}
              statusOptions={[
                {value: 'paid', label: 'Paid'},
                {value: 'pending', label: 'Pending'},
              ]}
              setApprovalFilter={setApprovalFilter}
              setStatusFilter={setStatusFilter}
            />
          </div>
        </div>

        {(permissions?.bills?.create || currentUser?.profileType === 'superadmin') && (
          <div className='card-toolbar'>
            <Link to={'add'} className='btn btn-sm btn-light-primary'>
              <KTIcon iconName='plus' className='fs-2' />
              Create New Bill
            </Link>
          </div>
        )}
      </div>
      <div className='card-body py-3'>
        {isLoading ? (
          <div className='d-flex align-items-center justify-center'>
            <div className='loader'></div>
          </div>
        ) : (
          <div className='table-responsive'>
            <table className='table align-middle gs-0 gy-4 table-row-bordered'>
              <thead>
                <tr className='fw-bold text-muted bg-light'>
                  <th className='ps-4 min-w-50px rounded-start'>Date</th>
                  <th className='min-w-150px'>Bill#</th>
                  <th className='min-w-150px'>Reference</th>
                  <th className='min-w-150px'>Vendor</th>
                  <th className='min-w-100px'>Status</th>
                  <th className='min-w-100px'>Due Date</th>
                  <th className='min-w-100px'>Amount</th>
                  {/* <th className='min-w-100px'>Balance</th> */}
                  <th className='min-w-100px'>Approval</th>
                  {(permissions?.bills?.update || currentUser?.profileType === 'superadmin') && (
                    <th className='min-w-100px text-center'>Actions</th>
                  )}
                </tr>
              </thead>

              <tbody>
                {filteredBillsList?.map((item: any, index: number) => (
                  <tr key={item?._id?.toString()}>
                    <td>
                      <Link
                        to={`/procurement/billsList/${item._id}`}
                        className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                      >
                        {formatCreatedAt(item?.billDate)}
                      </Link>
                    </td>
                    <td>
                      <Link
                        to={`/procurement/billsList/${item._id}`}
                        className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                      >
                        {item?.id}
                      </Link>
                    </td>
                    <td>
                      <Link
                        to={`/procurement/billsList/${item._id}`}
                        className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                      >
                        {item?.orderNo?.id}
                      </Link>
                    </td>
                    <td>
                      <Link
                        to={`/procurement/billsList/${item._id}`}
                        className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                      >
                        {item?.vendor?.displayName}
                      </Link>
                    </td>
                    <td>
                      <Link
                        to={`/procurement/billsList/${item._id}`}
                        className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                      >
                        <span className={`badge  fs-6 fw-semibold `}> {item?.status}</span>
                      </Link>
                    </td>
                    <td>
                      <Link
                        to={`/procurement/billsList/${item._id}`}
                        className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                      >
                        <span className={`badge  fs-6 fw-semibold `}>
                          {formatCreatedAt(item?.dueDate)}
                        </span>
                      </Link>
                    </td>
                    <td>
                      <Link
                        to={`/procurement/billsList/${item._id}`}
                        className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                      >
                        <span className={`badge  fs-6 fw-semibold`}>
                          {item?.total
                            ? item?.total.toLocaleString('en-US', {
                                minimumFractionDigits: 2,
                              })
                            : 0}{' '}
                          {item?.vendor?.currency}
                        </span>
                      </Link>
                    </td>
                    {/* <td>
                      <Link
                        to={`/procurement/billsList/${item._id}`}
                        className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                      >
                        <span className={`badge  fs-6 fw-semibold `}>
                          {item?.subtotal
                            ? item?.subtotal.toLocaleString('en-US', {
                                minimumFractionDigits: 2,
                              })
                            : 0}{' '}
                          {item?.vendor?.currency}
                        </span>
                      </Link>
                    </td> */}
                    <td>
                      <Link
                        to={`/procurement/billsList/${item._id}`}
                        className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                      >
                        <span
                          className={`badge  fs-7 fw-semibold ${
                            item?.approval === 'pending'
                              ? 'badge-light-primary'
                              : item?.approval === 'accepted'
                              ? 'badge-light-success'
                              : 'badge-light-danger'
                          }`}
                        >
                          {item?.approval}
                        </span>
                      </Link>
                    </td>
                    {(permissions?.bills?.update || currentUser?.profileType === 'superadmin') && (
                      <td className='relative'>
                        <div className='d-flex justify-content-center flex-shrink-0'>
                          <button
                            onClick={() => handleClick(index)}
                            className=' bg-gray-100 hover:bg-lime-50 px-6 py-2 flex items-center rounded self-end'
                          >
                            Actions <RiArrowDropDownLine />
                          </button>
                        </div>
                        {dropdown[index] && (
                          <div className='absolute flex flex-col top-16 right-[0px] z-50  bg-white  w-40 shadow-[0_16px_16px_6px_rgba(128,128,128,0.1)] rounded p-3 space-y-2'>
                            {item?.status === 'pending' && item?.valid && (
                              <>
                                <Link to={`edit/${item._id}`}>
                                  <button className='hover:bg-lime-50 p-2  text-start rounded'>
                                    Edit
                                  </button>
                                </Link>
                                <Link to={`revised/${item._id}`}>
                                  <button className='hover:bg-lime-50 p-2  text-start rounded'>
                                    Revised
                                  </button>
                                </Link>
                                <button
                                  className='hover:bg-lime-50 p-2 text-start rounded'
                                  onClick={() => handleActivation(item._id)}
                                >
                                  Invalid
                                </button>
                              </>
                            )}
                          </div>
                        )}
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
              {/* end::Table body */}
            </table>
            {/* end::Table */}
          </div>
        )}
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
      {/* Pagination */}
      <Pagination
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        totalPages={totalPages}
      />
    </div>
  )
}

export default BillsList
