import React, {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import './print.css'
import {getJournalSlipById} from '../core/_requests'
import {MdOutlineMailOutline} from 'react-icons/md'
import {GoGlobe} from 'react-icons/go'

function JournalDetails({minFooter = true}) {
  const [data, setPayrollData] = useState(null)
  const {id} = useParams()
  const [imageSize, setImageSize] = useState(false)
  const [color, setColor] = useState('#cococo')

  useEffect(() => {
    const getData = async () => {
      try {
        const res = await getJournalSlipById(id)
        setPayrollData(res)
        setColor(res?.company?.procurementColor)
      } catch (error) {
        console.error('Axios error:', error)
        throw error
      }
    }

    getData()
  }, [id])

  const formatDate = (date) => {
    if (!date) return 'N/A'
    return new Date(date).toLocaleDateString('en-GB')
  }

  const handleOnLoad = (e) => {
    const img = e.target
    setImageSize(img.naturalWidth > img.naturalHeight)
  }

  const NUMBERS = new Map([
    [1e9, 'Billion'],
    [1e6, 'Million'],
    [1e3, 'Thousand'],
    [1e2, 'Hundred'],
    [90, 'Ninety'],
    [80, 'Eighty'],
    [70, 'Seventy'],
    [60, 'Sixty'],
    [50, 'Fifty'],
    [40, 'Forty'],
    [30, 'Thirty'],
    [20, 'Twenty'],
    [19, 'Nineteen'],
    [18, 'Eighteen'],
    [17, 'Seventeen'],
    [16, 'Sixteen'],
    [15, 'Fifteen'],
    [14, 'Fourteen'],
    [13, 'Thirteen'],
    [12, 'Twelve'],
    [11, 'Eleven'],
    [10, 'Ten'],
    [9, 'Nine'],
    [8, 'Eight'],
    [7, 'Seven'],
    [6, 'Six'],
    [5, 'Five'],
    [4, 'Four'],
    [3, 'Three'],
    [2, 'Two'],
    [1, 'One'],
    [0, 'Zero'],
  ])

  const numberToWords = (num) => {
    for (const [intValue, textValue] of NUMBERS as any) {
      if (num >= intValue) {
        const prefix = num >= 100 ? numberToWords(Math.trunc(num / intValue)) : ''
        const suffix = num % intValue > 0 ? numberToWords(num % intValue) : ''
        return `${prefix} ${textValue} ${suffix}`.trim()
      }
    }
    return ''
  }

  const convertSARAmountToWords = (amount: number) => {
    const [riyals, halalas] = amount?.toString()?.split('.')?.map(Number)
    const riyalsInWords = numberToWords(riyals)
    const halalasInWords = halalas ? numberToWords(halalas?.toString()?.padEnd(2, '0')) : ''

    return `${riyalsInWords} Riyals${halalasInWords ? ` and ${halalasInWords} Halalas` : ''} Only`
  }

  const PF = 'https://qf-assets.s3.me-central-1.amazonaws.com/'

  return (
    <div className='app-main flex-column flex-row-fluid' id='kt_app_main'>
      <div className='d-flex flex-column flex-column-fluid'>
        {/* <div id='kt_app_toolbar' className='app-toolbar mb-6'></div> */}
        <div className='app-content flex-column-fluid'>
          <div id='kt_app_content_container' className=' container-xxl print-content'>
            <div
              id='pdf-content'
              className='card !rounded-none mx-auto min-h-700px'
              // style={{
              //   height: '297mm',
              //   width: '210mm',
              // }}
            >
              <div className='card-body pt-8 pb-0'>
                <div className='mw-lg-1050px w-100'>
                  {/* Header */}
                  <div className='d-flex justify-content-between align-items-center flex-column flex-sm-row'>
                    <div className='text-sm-end'>
                      <a
                        href='#'
                        className={`d-block ${imageSize ? 'mw-275px' : 'mw-150px'} ms-sm-auto`}
                      >
                        {/* {data?.company?.companyLogo ? (
                          <img
                            alt='Logo'
                            src={URL.createObjectURL(data?.company?.companyLogo)}
                            className={imageSize ? 'w-100' : 'w-50'}
                            onLoad={handleOnLoad}
                          />
                        ) : ( */}
                        <img
                          alt='thumbnail'
                          src={PF + data?.company?.companyLogo}
                          className={imageSize ? 'w-100' : 'w-50'}
                          onLoad={handleOnLoad}
                        />
                        {/* )} */}
                      </a>
                    </div>
                    <h4 className='fw-bold text-gray-800 xl:fs-1 d-flex flex-column text-right header-text'>
                      <span
                        className='mb-1 text-right fs-1'
                        style={{
                          fontFamily: 'Scheherazade New',
                          fontOpticalSizing: 'auto',
                          fontStyle: 'normal',
                          wordSpacing: '10px',
                        }}
                      >
                        {data?.company?.arabicName}
                      </span>
                      <span className='mb-1 text-right fs-3 tracking-wider' style={{color: color}}>
                        {data?.company?.name}
                      </span>
                      <span className='fs-6 text-muted'>
                        VAT {data?.company?.vat} <span style={{color: color}}>|</span> C.R.{' '}
                        {data?.company?.cr}
                      </span>
                    </h4>
                  </div>
                  <div className='border-b-black border-b mx-9'></div>

                  {/* <div className="container"> */}
                  <h2 className='text-2xl font-bold mb-4 mt-5'>Journal Entry {data?.id}</h2>
                  <div className='grid grid-cols-1 md:grid-cols-3 gap-4 mb-6'>
                    <div>
                      {data?.reference && (
                        <div>
                          <span className='font-semibold'>Reference:</span>
                          <span className='ml-2'>{data?.Reference}</span>
                        </div>
                      )}
                    </div>
                    <div>
                      <span className='font-semibold'>Date:</span>
                      <span className='ml-2'>{formatDate(data?.date)}</span>
                    </div>
                    <div>
                      <span className='font-semibold'>Total:</span>
                      <span className='ml-2 bg-green-100 text-green-800 px-2 py-1 rounded'>
                        SAR {data?.total?.toLocaleString('en-US', {minimumFractionDigits: 2})}
                      </span>
                    </div>
                  </div>

                  <table className='w-full mb-6'>
                    <thead>
                      <tr className='bg-gray-100'>
                        <th className='py-2 px-4 text-left'>Account</th>
                        <th className='py-2 px-4 text-left'>Description</th>
                        <th className='py-2 px-4 text-right'>Debits</th>
                        <th className='py-2 px-4 text-right'>Credits</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data?.items?.map((item, index) => (
                        <tr key={index} className={index % 2 === 0 ? 'bg-gray-50' : ''}>
                          <td className='py-2 px-4'>{item.accountId?.accountName}</td>
                          <td className='py-2 px-4'>{item.description}</td>
                          <td className='py-2 px-4 text-right'>
                            {item.debit?.toLocaleString('en-US', {minimumFractionDigits: 2})}
                          </td>
                          <td className='py-2 px-4 text-right'>
                            {item.credit?.toLocaleString('en-US', {minimumFractionDigits: 2})}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>

                  {data?.notes && (
                    <div className='mt-6'>
                      <h3 className='text-lg font-semibold mb-2'>Notes:</h3>
                      <p className='text-gray-700'>{data.notes}</p>
                    </div>
                  )}
                  {/* </div> */}
                </div>
              </div>

              {/* Footer */}
              <div id='pdfFooter' className='card-footer text-center py-2 px-0 fw-bold mb-1'>
                <div className={`bg-[#515560] ${!minFooter && '-skew-x-12'} text-white`}>
                  <div className={`${!minFooter && 'skew-x-12'} py-1`}>
                    {!minFooter &&
                      `Mobile :${' '}
        ${data?.company?.mobileNumber} | `}
                    <MdOutlineMailOutline
                      style={{
                        color,
                      }}
                      className='inline mr-2'
                    />
                    {data?.company?.companyEmail}
                    <GoGlobe
                      className='inline mr-2 ml-4'
                      style={{
                        color,
                      }}
                    />
                    {data?.company?.webURL}
                  </div>
                </div>
                <div
                  className={`${!minFooter && '-skew-x-12'} text-white`}
                  style={{
                    backgroundColor: `${color}`,
                  }}
                >
                  <div className={`${!minFooter && 'skew-x-12'} py-1`}>
                    P.O. Box : {data?.company?.pOBox} | Address: {data?.company?.companyAddress}
                  </div>
                </div>
              </div>
              {/* <div className='d-flex justify-center mb-4'>
                <p className='fs-8 serif-font'>Page 1 of 1</p>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default JournalDetails
