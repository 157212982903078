import React, {useEffect, useState} from 'react'
import Stat1 from '../../StoreAnalytics/stat1'
import StatBar from '../../StoreAnalytics/statBar'
import {useAuth} from '../../../modules/auth'
import {getReceivablesNPayables, getTopExpenseAccounts} from '../core/_requests'
import {Chart3} from '../../Inventory/Components/Chart3'
import {TopExpenseChart} from '../components/TopExpenseChart'
import CashFlowChart from '../components/CashFlowChart'
import {StatisticsWidget7} from '../../../../_metronic/partials/widgets'

const Dashboard = () => {
  const {currentUser} = useAuth()
  const [receivablesNPayables, setReceivablesNPayables] = useState<any>({
    accountsReceivable: 'Loading',
    accountsPayables: 'Loading',
    accountsAssets: 'Loading',
    accountsLiabilities: 'Loading',
    accountsEquity: 'Loading',
    pettyCash: 'Loading',
  })
  const [topExpense, setTopExpense] = useState({
    value: [],
    labels: [],
  })

  useEffect(() => {
    const fetchData = async () => {
      await getReceivablesNPayables(currentUser.organization).then((res) => {
        setReceivablesNPayables({
          accountsReceivable: res?.accountsReceivable?.toLocaleString('en-US', {
            minimumFractionDigits: 2,
          }),
          accountsPayables: res?.accountsPayables?.toLocaleString('en-US', {
            minimumFractionDigits: 2,
          }),
          accountsAssets: res?.accountsAssets?.toLocaleString('en-US', {
            minimumFractionDigits: 2,
          }),
          accountsLiabilities: res?.accountsLiabilities?.toLocaleString('en-US', {
            minimumFractionDigits: 2,
          }),
          accountsEquity: res?.accountsEquity?.toLocaleString('en-US', {
            minimumFractionDigits: 2,
          }),
          pettyCash: res?.pettyCash?.toLocaleString('en-US', {
            minimumFractionDigits: 2,
          }),
        })
      })

      await getTopExpenseAccounts(currentUser.organization).then((res) => {
        setTopExpense(res)
      })
    }

    if (currentUser.organization !== undefined) fetchData()
  }, [currentUser])

  return (
    <div>
      {/*  */}
      <div className='content d-flex flex-column flex-column-fluid' id='kt_content'>
        <div className='container-xxl' id='kt_content_container'>
          {/* <div className='row g-5 g-xl-10'>
            <div className='col-md-4 col-xl-4 mb-xxl-10'>
              <div className='card h-md-50 mb-5 mb-xl-10'>
                <Stat1
                  className={''}
                  chartColor={'success'}
                  chartHeight={''}
                  unit='SAR'
                  title='Total Receivables'
                  value={receivablesNPayables?.accountsReceivable}
                />
              </div>
            </div>
            <div className='col-md-4 col-xl-4 mb-xxl-10'>
              <div className='card h-md-50 mb-5 mb-xl-10'>
                <Stat1
                  className={''}
                  chartColor={'danger'}
                  chartHeight={''}
                  unit='SAR'
                  title='Total Payables'
                  value={receivablesNPayables?.accountsPayables}
                />
              </div>
            </div>
            <div className='col-md-4 col-xl-4 mb-xxl-10'>
              <div className='card h-md-50 mb-5 mb-xl-10'>
                <Stat1
                  className={''}
                  chartColor={'warning'}
                  chartHeight={''}
                  unit='SAR'
                  title='Total Assets'
                  value={receivablesNPayables?.accountsAssets}
                />
              </div>
            </div>
          </div> */}
          <div className='row g-5 g-xl-10'>
            <div className='col-md-4 col-xl-4 mb-xxl-10'>
              <div className='card h-md-50 mb-xl-10'>
                <StatisticsWidget7
                  className={''}
                  header='Total Receivables'
                  title={receivablesNPayables?.accountsReceivable}
                  badgeColor='success'
                  color={'success-subtle'}
                  svgIcon={''}
                  iconColor={''}
                  borderColor='success'
                />
              </div>
            </div>
            <div className='col-md-4 col-xl-4 mb-xxl-10'>
              <div className='card h-md-50 mb-xl-10'>
                <StatisticsWidget7
                  className={''}
                  header='Total Payables'
                  title={receivablesNPayables?.accountsPayables}
                  badgeColor='danger'
                  color='danger-subtle'
                  borderColor='danger'
                />
              </div>
            </div>
            <div className='col-md-4 col-xl-4 mb-xxl-10'>
              <div className='card h-md-50 mb-xl-10'>
                <StatisticsWidget7
                  className={''}
                  header='Total Assets'
                  title={receivablesNPayables?.accountsAssets}
                  badgeColor='warning'
                  color='warning-subtle'
                  borderColor='warning'
                />
              </div>
            </div>
          </div>
          <div className='row g-5 g-xl-10'>
            <div className='col-md-4 col-xl-4 mb-xxl-10'>
              <div className='card h-md-50 mb-xl-10'>
                <StatisticsWidget7
                  className={''}
                  header='Total Liabilities'
                  title={receivablesNPayables?.accountsLiabilities}
                  badgeColor='secondary'
                  color={'secondary'}
                  svgIcon={''}
                  iconColor={''}
                  borderColor='dark'
                />
              </div>
            </div>
            <div className='col-md-4 col-xl-4 mb-xxl-10'>
              <div className='card h-md-50 mb-xl-10'>
                <StatisticsWidget7
                  className={''}
                  header='Total Equity'
                  title={receivablesNPayables?.accountsEquity}
                  badgeColor='primary'
                  color='primary-subtle'
                  borderColor='primary'
                />
              </div>
            </div>
            <div className='col-md-4 col-xl-4 mb-xxl-10'>
              <div className='card h-md-50 mb-xl-10'>
                <StatisticsWidget7
                  className={''}
                  header='Total Petty Cash'
                  title={receivablesNPayables?.pettyCash}
                  badgeColor='info'
                  color='info-subtle'
                  borderColor='info'
                />
              </div>
            </div>
          </div>

          <div className='row g-5 g-xl-10 g-xl-10'>
            <div className='mb-xl-10'>
              <CashFlowChart />
            </div>
          </div>

          <div className='row g-5 g-xl-10'>
            <div className='col-xxl-6 mb-xxl-10'>
              {/* <!--begin::List widget 7--> */}
              <Chart3 className=' mb-xl-9' />
              {/* <!--end::List widget 7--> */}
            </div>

            <div className='col-xxl-6 mb-5 mb-xl-10'>
              <TopExpenseChart labels={topExpense.labels} value={topExpense.value} className='' />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Dashboard
