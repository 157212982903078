import React, {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import './print.css'
import {getPCRSlipById} from '../core/_requests'
import {MdOutlineMailOutline} from 'react-icons/md'
import {GoGlobe} from 'react-icons/go'

function PCRDetails({minFooter = true}) {
  const [data, setPayrollData] = useState(null)
  const {id} = useParams()
  const [imageSize, setImageSize] = useState(false)
  const [color, setColor] = useState('#cococo')

  useEffect(() => {
    const getData = async () => {
      try {
        const res = await getPCRSlipById(id)
        setPayrollData(res)
        setColor(res?.company?.procurementColor)
      } catch (error) {
        console.error('Axios error:', error)
        throw error
      }
    }

    getData()
  }, [id])

  const formatDate = (date) => {
    if (!date) return 'N/A'
    return new Date(date).toLocaleDateString('en-GB')
  }

  const handleOnLoad = (e) => {
    const img = e.target
    setImageSize(img.naturalWidth > img.naturalHeight)
  }

  const NUMBERS = new Map([
    [1e9, 'Billion'],
    [1e6, 'Million'],
    [1e3, 'Thousand'],
    [1e2, 'Hundred'],
    [90, 'Ninety'],
    [80, 'Eighty'],
    [70, 'Seventy'],
    [60, 'Sixty'],
    [50, 'Fifty'],
    [40, 'Forty'],
    [30, 'Thirty'],
    [20, 'Twenty'],
    [19, 'Nineteen'],
    [18, 'Eighteen'],
    [17, 'Seventeen'],
    [16, 'Sixteen'],
    [15, 'Fifteen'],
    [14, 'Fourteen'],
    [13, 'Thirteen'],
    [12, 'Twelve'],
    [11, 'Eleven'],
    [10, 'Ten'],
    [9, 'Nine'],
    [8, 'Eight'],
    [7, 'Seven'],
    [6, 'Six'],
    [5, 'Five'],
    [4, 'Four'],
    [3, 'Three'],
    [2, 'Two'],
    [1, 'One'],
    [0, 'Zero'],
  ])

  const numberToWords = (num) => {
    for (const [intValue, textValue] of NUMBERS as any) {
      if (num >= intValue) {
        const prefix = num >= 100 ? numberToWords(Math.trunc(num / intValue)) : ''
        const suffix = num % intValue > 0 ? numberToWords(num % intValue) : ''
        return `${prefix} ${textValue} ${suffix}`.trim()
      }
    }
    return ''
  }

  const convertSARAmountToWords = (amount: number) => {
    const [riyals, halalas] = amount?.toString()?.split('.')?.map(Number)
    const riyalsInWords = numberToWords(riyals)
    const halalasInWords = halalas ? numberToWords(halalas?.toString()?.padEnd(2, '0')) : ''

    return `${riyalsInWords} Riyals${halalasInWords ? ` and ${halalasInWords} Halalas` : ''} Only`
  }

  const PF = 'https://qf-assets.s3.me-central-1.amazonaws.com/'

  return (
    <div className='app-main flex-column flex-row-fluid' id='kt_app_main'>
      <div className='d-flex flex-column flex-column-fluid'>
        {/* <div id='kt_app_toolbar' className='app-toolbar mb-6'></div> */}
        <div className='app-content flex-column-fluid'>
          <div id='kt_app_content_container' className=' container-xxl print-content'>
            <div
              id='pdf-content'
              className='card !rounded-none mx-auto min-h-700px'
              // style={{
              //   height: '297mm',
              //   width: '210mm',
              // }}
            >
              <div className='card-body pt-8 pb-0'>
                <div className='mw-lg-1050px w-100'>
                  {/* Header */}
                  <div className='d-flex justify-content-between align-items-center flex-column flex-sm-row'>
                    <div className='text-sm-end'>
                      <a
                        href='#'
                        className={`d-block ${imageSize ? 'mw-275px' : 'mw-150px'} ms-sm-auto`}
                      >
                        {/* {data?.company?.companyLogo ? (
                          <img
                            alt='Logo'
                            src={URL.createObjectURL(data?.company?.companyLogo)}
                            className={imageSize ? 'w-100' : 'w-50'}
                            onLoad={handleOnLoad}
                          />
                        ) : ( */}
                        <img
                          alt='thumbnail'
                          src={PF + data?.company?.companyLogo}
                          className={imageSize ? 'w-100' : 'w-50'}
                          onLoad={handleOnLoad}
                        />
                        {/* )} */}
                      </a>
                    </div>
                    <h4 className='fw-bold text-gray-800 xl:fs-1 d-flex flex-column text-right header-text'>
                      <span
                        className='mb-1 text-right fs-1'
                        style={{
                          fontFamily: 'Scheherazade New',
                          fontOpticalSizing: 'auto',
                          fontStyle: 'normal',
                          wordSpacing: '10px',
                        }}
                      >
                        {data?.company?.arabicName}
                      </span>
                      <span className='mb-1 text-right fs-3 tracking-wider' style={{color: color}}>
                        {data?.company?.name}
                      </span>
                      <span className='fs-6 text-muted'>
                        VAT {data?.company?.vat} <span style={{color: color}}>|</span> C.R.{' '}
                        {data?.company?.cr}
                      </span>
                    </h4>
                  </div>
                  <div className='border-b-black border-b mx-9'></div>

                  {/* Payroll Details */}
                  <div className='fs-1 mt-5 uppercase font-bold text-gray-800'>
                    Petty Cash Request
                  </div>
                  <div className='pb-2 mt-5'>
                    <div className='d-flex flex-column gap-7 gap-md-10'>
                      <div
                        className='d-flex justify-content-between flex-column flex-sm-row fs-5'
                        style={{textTransform: 'uppercase'}}
                      >
                        <div className='mb-5'>
                          <div className='d-flex flex-column mb-5'>
                            <span className='fw-bold'>Account</span>
                            <span className='fs-4'>{data?.pcrAccount?.accountName}</span>
                          </div>
                          <div className='d-flex flex-column mb-5'>
                            <span className='fw-bold'>Description</span>
                            <span className='fs-4'>{data?.description}</span>
                          </div>
                          <div className='d-flex flex-column mb-5'>
                            <span className='fw-bold'>Expense Amount (in words)</span>
                            <span className='fs-4'>
                              {data?.amount && convertSARAmountToWords(data?.amount)}
                            </span>
                          </div>
                        </div>
                        <div className='mb-5'>
                          <div className='d-flex flex-column mb-5'>
                            <span className='fw-bold'>Employee Name</span>
                            <span className='fs-4'>{data?.employeeName}</span>
                          </div>
                        </div>
                        <div className='text-end mb-5'>
                          <div className='d-flex flex-column mb-5'>
                            <span className='fw-bold'>Date</span>
                            <span className='fs-4'>{formatDate(data?.date)}</span>
                          </div>
                          <div className='d-flex flex-column'>
                            <span className='fw-bold'>Amount</span>
                            <span className=''>
                              <span
                                className='fs-5 badge badge-success'
                                style={{padding: '0.5rem'}}
                              >
                                SAR{' '}
                                {data?.amount?.toLocaleString('en-US', {minimumFractionDigits: 2})}
                              </span>
                            </span>
                          </div>
                        </div>
                      </div>

                      {/* Notes Section */}
                      {data?.notes && (
                        <div className='d-flex flex-column mb-5'>
                          <span className='fw-bold fs-6 mb-2'>Notes:</span>
                          <span className='fs-4'>{data.notes}</span>
                        </div>
                      )}

                      <div className='d-flex  justify-content-between'>
                        <h6 className='mb-8 text-black text-center fw-bold fs-5'>
                          <span className='fs-6 arabic-font fw-normal'>أُعدت بواسطة</span>
                          <br />
                          Prepared By
                          {data?.agent &&
                            data?.agent?.signature &&
                            data?.agent?.signature !== '' && (
                              <img
                                src={PF + data?.agent?.signature}
                                alt='signature'
                                className='w-80px h-80px mt-3'
                              />
                            )}
                        </h6>

                        <h6 className='mb-4 text-black relative text-center fw-bold fs-5'>
                          <span className='fs-6 arabic-font fw-normal'>اعتمدت بواسة</span>
                          <br />
                          Approved By
                          {data?.approval === 'accepted' &&
                            data?.company?.companySeal &&
                            data?.company?.companySeal !== '' && (
                              <img
                                src={PF + data?.company?.companySeal}
                                alt='company seal'
                                className='w-80px mt-3 h-80px aboslute top-5 right-5 z-1'
                              />
                            )}
                          {data?.approval === 'accepted' &&
                            data?.company?.companySignature &&
                            data?.company?.companySignature !== '' && (
                              <img
                                src={PF + data?.company?.companySignature}
                                alt='company signature'
                                className='w-80px h-80px absolute top-20 right-10 z-10'
                              />
                            )}
                        </h6>

                        <h6 className='mb-4 text-black text-center fs-5 fw-bold'>
                          <span className='fs-6 arabic-font fw-normal'>استلمت من قبل</span>
                          <br />
                          Received By
                        </h6>
                        {/* end::Invoice 2 sidebar */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Footer */}
              <div id='pdfFooter' className='card-footer text-center py-2 px-0 fw-bold mb-1'>
                <div className={`bg-[#515560] ${!minFooter && '-skew-x-12'} text-white`}>
                  <div className={`${!minFooter && 'skew-x-12'} py-1`}>
                    {!minFooter &&
                      `Mobile :${' '}
        ${data?.company?.mobileNumber} | `}
                    <MdOutlineMailOutline
                      style={{
                        color,
                      }}
                      className='inline mr-2'
                    />
                    {data?.company?.companyEmail}
                    <GoGlobe
                      className='inline mr-2 ml-4'
                      style={{
                        color,
                      }}
                    />
                    {data?.company?.webURL}
                  </div>
                </div>
                <div
                  className={`${!minFooter && '-skew-x-12'} text-white`}
                  style={{
                    backgroundColor: `${color}`,
                  }}
                >
                  <div className={`${!minFooter && 'skew-x-12'} py-1`}>
                    P.O. Box : {data?.company?.pOBox} | Address: {data?.company?.companyAddress}
                  </div>
                </div>
              </div>
              {/* <div className='d-flex justify-center mb-4'>
                <p className='fs-8 serif-font'>Page 1 of 1</p>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PCRDetails
