import React, {useEffect, useRef, useState} from 'react'
import Select from 'react-select' // Make sure to import Select component from 'react-select' package
import Flatpickr from 'react-flatpickr'
import {KTIcon, KTSVG} from '../../../../_metronic/helpers'
import {useAuth} from '../../../modules/auth/core/Auth'
import {getFilteredStocks} from '../../sales/Core/_requests'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd'
import {
  getPurchaseOrderLastId,
  getVendor,
  getStocksForPurchaseOrder,
  postPurchaseQuotation,
  getVendorByAgentId,
  getIncoTerms,
  addIncoTerms,
  getPaymentTerms,
  addPaymentTerms,
  getOrdersWithProject,
} from '../Core/_requests'
import {useNavigate} from 'react-router-dom'
import {getItems, uploaddocsinqf} from '../../Inventory/Core/_requests'
import {getNotes, getTerms} from '../../account_settings/core/_request'
import {useDispatch, useSelector} from 'react-redux'
import {setVendorDataList} from '../../../redux/procurement/vendor/vendorSlice'
import {RootState} from '../../../redux/store'
import {setPurchaseStock} from '../../../redux/inventory/products/stockSlice'
import {setConsumablesData} from '../../../redux/inventory/products/consumablesSlice'
import {setRentalsData} from '../../../redux/inventory/products/rentalsSlice'
import {setGoodsData} from '../../../redux/inventory/products/goodsSlice'
import {setServices} from '../../../redux/inventory/products/servicesSlice'
import {setPurchaseOrderDataFetch} from '../../../redux/procurement/purchaseOrder/purchaseOrderSlice'
import {unitsList} from '../../../utils/units'
import {getEmployeeByStatus} from '../../hrm/core/_requests'
import {setMaterialsData} from '../../../redux/inventory/products/materialsSlice'

export const AddQuotation = () => {
  const {currentUser} = useAuth()
  const navigate = useNavigate()
  const serviceList = useSelector((state: RootState) => state.inventory.services.services)
  const purchaseStock = useSelector((state: RootState) => state.inventory.stock.purchaseStock)
  const goodsList = useSelector((state: RootState) => state.inventory.goods.goodsData)
  const rentalsList = useSelector((state: RootState) => state.inventory.rentals.rentalsData)
  const consumablesList = useSelector(
    (state: RootState) => state.inventory.consumables.consumablesData
  )
  const materialsList = useSelector((state: RootState) => state.inventory.materials.materialsData)
  const [contactPersonList, setContactPersonList] = useState([])
  const [categoryType, setCategoryType] = useState('')
  const [loading, setLoading] = useState(false)
  const [isLoading, setisLoading] = useState(false)
  const [filename, setfilename] = useState('')
  const [excelFile, setExcelFile] = useState(null)
  const fileInputRef = useRef(null)
  const dispatch = useDispatch()
  const vendorDataList = useSelector((state: RootState) => state.procurement.vendor.vendorData)

  const [incoTermsOption, setIncoTermsOption] = useState([])
  const [paymentTermsOption, setPaymentTermsOption] = useState([])
  const [projectOrder, setProjectOrder] = useState([])
  const [orderNo, setOrderNo] = useState('0')
  const [name, setName] = useState('')
  const [permissions, setPermissions] = useState<any>({})
  const [selectedVendorCurrency, setSelectedVendorCurrency] = useState('')
  const [selectFromInventory, setSelectFromInventory] = useState(false)
  const [newItems, setNewItems] = useState<any>([
    {
      productName: '',
      unit: '',
      price: '',
      quantity: 0,
      discount: 0,
      amount: '',
    },
  ])
  const [newProductCategory, setNewProductCategory] = useState('materials')
  const [purchaseQuotation, setPurchaseQuotation] = useState({
    vendor: '',
    contactPerson: '',
    date: new Date(),
    id: '', // Initialize with appropriate value
    subtotal: 0, // Initialize with appropriate value
    tax: 15, // Initialize with appropriate value
    freightCharges: 0,
    discount: 0,
    total: 0, // Initialize with appropriate value
    notes: '', // Initialize with appropriate value
    termsCondition: '',
    reference: '',
    expectedDeliveryDate: '',
    paymentTerms: '',
    shipmentPreferences: '',
    document: '',
    items: '',
    docAttached: '',
    // Initialize with appropriate value
  })

  useEffect(() => {
    if (currentUser?.permissions) {
      setPermissions(currentUser?.permissions)
    }
  }, [currentUser])

  useEffect(() => {
    if (purchaseQuotation.termsCondition === '') {
      setTermsTextareaHeight('6rem')
    }
  }, [purchaseQuotation.termsCondition])

  useEffect(() => {
    if (purchaseQuotation.notes === '') {
      setNotesTextareaHeight('6rem')
    }
  }, [purchaseQuotation.notes])

  const [items, setItems] = useState([
    {
      itemId: '',
      price: '',
      quantity: 0,
      discount: 0,
      amount: '',
      selectedOption: {
        value: '',
        label: '',
        inWarehouseQuantity: 0,
      },
      type: 'product',
      productName: '',
    },
  ])
  const [selectedBillFrom, setSelectedBillFrom] = useState(null) // Initialize with appropriate value
  const [submitted, setSubmitted] = useState(false)
  const [vendorList, setVendorList] = useState([])
  const [selectedVendor, setSelectedVendor] = useState(null)

  //useState for Custom Quotation no
  const [showModal, setShowModal] = useState(false)
  const [salesOrderNumber, setSalesOrderNumber] = useState('')
  const [selectYearAndMonth, setSelectYearAndMonth] = useState(new Date())
  const [selectedOption, setSelectedOption] = useState('autoGenerate') // Default selected option
  const [prefix, setPrefix] = useState('')
  const [nextNumber, setNextNumber] = useState('')
  const [customID, setCustomID] = useState('')
  const [isEditingNotes, setIsEditingNotes] = useState(false)
  const [isEditingTerms, setIsEditingTerms] = useState(false)
  const [termsTextareaHeight, setTermsTextareaHeight] = useState(' ')
  const [notesTextareaHeight, setNotesTextareaHeight] = useState(' ')

  const handleDateChange = (date) => {
    setPurchaseQuotation({...purchaseQuotation, date: date[0]})
  }
  const handleExpectedDeliveryDateChange = (date) => {
    setPurchaseQuotation({...purchaseQuotation, expectedDeliveryDate: date[0]})
  }
  const handleShipmentPreferenceChange = (selectedOption) => {
    setPurchaseQuotation((prevQuotation) => ({
      ...prevQuotation,
      shipmentPreferences: selectedOption?.value || '', // Assuming the value is a string, adjust accordingly
    }))
  }

  const handleFileChange = (event) => {
    const file = event.target.files[0]
    setfilename(event.target.files[0].name)
    setExcelFile(file)
  }

  const handleDragEnd = (result) => {
    if (!result.destination) return // dropped outside the list

    if (items.length > 1) {
      const itemsCopy = [...items]
      const [reorderedItem] = itemsCopy.splice(result.source.index, 1)
      itemsCopy.splice(result.destination.index, 0, reorderedItem)

      // Update your state with the reordered items
      setItems(itemsCopy)
    } else if (newItems.length > 1) {
      const itemsCopy = [...newItems]
      const [reorderedItem] = itemsCopy.splice(result.source.index, 1)
      itemsCopy.splice(result.destination.index, 0, reorderedItem)

      // Update your state with the reordered items
      setNewItems(itemsCopy)
    }
  }

  const handleUpload = async () => {
    if (!excelFile) {
      console.error('No file selected')
      return
    }

    const timestamp = Date.now()

    const formData = new FormData()
    formData.append('file', excelFile)
    formData.append('name', purchaseQuotation.reference)
    formData.append('filename', timestamp + filename)
    try {
      setisLoading(true)
      const response = await uploaddocsinqf(formData)

      if (response === 'success') {
        console.log('File uploaded successfully')
        alert('File uploaded successfully')
        setPurchaseQuotation((prev) => {
          return {
            ...prev,
            docAttached: timestamp + filename,
          }
        })
        // Optionally, update UI to indicate successful upload
      } else {
        alert('failed to upload please try again')
        // Optionally, update UI to indicate failure
      }
    } catch (error) {
      console.error('Error:', error)
      setisLoading(false)
      // Opti
    }
  }

  const handlePaymentTermsChange = (selectedOption) => {
    setPurchaseQuotation((prevQuotation) => ({
      ...prevQuotation,
      paymentTerms: selectedOption?.value || '', // Assuming the value is a string, adjust accordingly
    }))
  }

  const handleVendorChange = (selectedOption) => {
    setSelectedVendor(selectedOption)
    setContactPersonList(
      selectedOption.contactPersons?.map((person) => ({
        value: person?._id,
        label: person?.firstName,
      })) || []
    )

    setPurchaseQuotation((prevQuotation) => ({
      ...prevQuotation,
      contactPerson: selectedOption.contactPersons?.[0]?._id || '',
    }))

    const selectedVendor = vendorList.find((vendor) => vendor.value === selectedOption.value)
    if (selectedVendor) {
      setSelectedVendorCurrency(selectedVendor.currency)
    }
    console.log('vendor', selectedOption, 'currency', selectedVendorCurrency)
  }
  // const handleVendorChange = (selectedOption) => {
  //   setSelectedVendor(selectedOption); // Update selected vendor
  //   setPurchaseQuotation((prevQuotation) => ({
  //     ...prevQuotation,
  //     vendor: selectedOption?.value || '', // Update vendor in purchase quotation
  //   }));
  // }

  const getVendorList = async () => {
    try {
      const query = null
      const data = await getVendor(query, currentUser?.organization)
      console.log('Vendor List:', data)
      setVendorList(
        data.map((r) => ({
          value: r._id,
          label: r.displayName, // Assuming name is the property containing the vendor's name
          currency: r.currency,
          contactPersons: r.contactPersons,
        }))
      )
      dispatch(setVendorDataList(data))
    } catch (error) {
      console.error('Error fetching vendor list:', error)
    }
  }

  const getVendorListForAgent = async () => {
    try {
      const res = await getVendorByAgentId('', currentUser?._id)
      console.log(res)
      setVendorList(
        res.map((r) => ({
          value: r?._id,
          label: r?.displayName, // Corrected the typo here from "labe" to "label"
          currency: r.currency,
          contactPersons: r.contactPersons,
        }))
      )
      dispatch(setVendorDataList(res))
    } catch (error) {
      console.error('Error fetching client list:', error)
    }
  }

  const getOrderWithProject = async () => {
    try {
      const res = await getOrdersWithProject(currentUser?.organization)
      const data = res?.map((r) => ({
        value: r?._id,
        label: r?.orderName,
      }))
      data.unshift({value: '0', label: 'None'})

      setProjectOrder(data)
    } catch (error) {
      console.error('Error fetching client list:', error)
    }
  }

  const handleChange = (e) => {
    const {name, value} = e.target
    if (name === 'notes' && value.trim() === '') {
      setPurchaseQuotation((prev) => ({
        ...prev,
        notes: '• ' + value,
      }))
    } else {
      setPurchaseQuotation((prev) => ({...prev, [name]: value}))
    }
    if (name === 'termsCondition') {
      setTermsTextareaHeight(`${e.target.scrollHeight}px`)
    } else if (name === 'notes') {
      setNotesTextareaHeight(`${e.target.scrollHeight}px`)
    }
  }

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      setPurchaseQuotation((prev) => ({
        ...prev,
        notes: prev.notes + '\n• ',
      }))

      setNotesTextareaHeight(`${event.target.scrollHeight}px`)
    }
  }

  useEffect(() => {
    setPurchaseQuotation((prevInvoice) => {
      let sum =
        items.reduce((acc, item) => {
          return acc + parseFloat(item.amount || '0')
        }, 0) || 0
      sum +=
        newItems.reduce((acc, item) => {
          return acc + parseFloat(item.amount || '0')
        }, 0) || 0
      return {
        ...prevInvoice,
        subtotal: sum,
      }
    })
  }, [items, newItems])

  useEffect(() => {
    setPurchaseQuotation((prevInvoice) => {
      const subtotal =
        Number(prevInvoice.subtotal || 0) +
        Number(prevInvoice.freightCharges || 0) -
        Number(prevInvoice.discount || 0)
      const tax = prevInvoice.tax || 0

      const total = (subtotal * (1 + tax / 100)).toFixed(2)

      return {
        ...prevInvoice,
        total: parseFloat(total),
        // You can add other properties if needed
      }
    })
  }, [
    items,
    purchaseQuotation.tax,
    newItems,
    purchaseQuotation.freightCharges,
    purchaseQuotation.discount,
  ])

  const handleSelectChangeInRows = (index, selectedOption) => {
    console.log(selectedOption)
    setItems((prevItems) => {
      const updatedItems = [...prevItems]
      updatedItems[index] = {
        ...updatedItems[index],
        itemId: selectedOption.value,
        productName: selectedOption.label,
        selectedOption,
        price: selectedOption.price,
        amount: selectedOption.price,
        quantity: 1,
      }
      return updatedItems
    })
  }

  const handleChangerows = (index, e) => {
    const {name, value} = e.target
    // if (name === 'quantity' && items[index].type !== 'item') {
    //   if (value > items[index].selectedOption.inWarehouseQuantity) {
    //     return
    //   }
    // }
    setItems((prevItems) => {
      const updatedItems = [...prevItems]
      updatedItems[index] = {
        ...updatedItems[index],
        [name]: value,
      }
      return updatedItems
    })
    setItems((prevItems) => {
      const updatedItems = [...prevItems]
      const data: any = (
        Number(prevItems[index].price) * Number(prevItems[index].quantity) -
        Number(prevItems[index].discount)
      ).toFixed(2)

      updatedItems[index] = {
        ...updatedItems[index],
        amount: data,
      }
      return updatedItems
    })
    if (name === 'termsCondition') {
      setTermsTextareaHeight(`${e.target.scrollHeight}px`)
    } else if (name === 'notes') {
      setNotesTextareaHeight(`${e.target.scrollHeight}px`)
    }
  }

  const handleChangeNewItem = (index, e) => {
    const {name, value} = e.target
    setNewItems((prevItems) => {
      const updatedItems = [...prevItems]
      updatedItems[index] = {
        ...updatedItems[index],
        [name]: value,
      }
      return updatedItems
    })
    setNewItems((prevItems) => {
      const updatedItems = [...prevItems]
      const data: any = (
        Number(prevItems[index].price) * Number(prevItems[index].quantity) -
        Number(prevItems[index].discount)
      ).toFixed(2)

      updatedItems[index] = {
        ...updatedItems[index],
        amount: data,
      }
      return updatedItems
    })
  }

  const handleEditClick = (e, field) => {
    e.preventDefault()
    if (field === 'notes') {
      setIsEditingNotes(!isEditingNotes)
      setIsEditingTerms(false) // Make sure only one is in edit mode at a time
    } else if (field === 'termsCondition') {
      setIsEditingTerms(!isEditingTerms)
      setIsEditingNotes(false) // Make sure only one is in edit mode at a time
    }
  }

  const additem = () => {
    setItems([
      ...items,
      {
        itemId: '',
        price: '',
        quantity: 0,
        discount: 0,
        amount: '',
        selectedOption: {
          value: '',
          label: '',
          inWarehouseQuantity: 0,
        },
        type: 'product',
        productName: '',
      },
    ])
  }

  const removeitem = (index) => {
    if (items.length === 1) {
      setItems([
        {
          itemId: '',
          price: '',
          quantity: 0,
          discount: 0,
          amount: '',
          selectedOption: {
            value: '',
            label: '',
            inWarehouseQuantity: 0,
          },
          type: 'product',
          productName: '',
        },
      ])
      return
    }
    const temp = [...items]
    temp.splice(index, 1)
    setItems(temp)
  }

  //   const handleSubmit = () => {
  //     // Implement your submission logic here
  //     setSubmitted(true);
  //   };

  const handleSubmit = async () => {
    if (!excelFile) {
      // console.error('No file selected')
      // return
      setPurchaseQuotation({...purchaseQuotation, reference: ''})
    }
    if (selectedVendor === null || selectedVendor === '') {
      alert('Please select a vendor')
      return
    } else if (selectFromInventory && items[0].itemId === '') {
      alert('Please select an item')
      return
    } else if (purchaseQuotation.expectedDeliveryDate === '') {
      alert('Please select expected delivery date')
      return
    } else if (!selectFromInventory && newItems[0].productName === '') {
      alert('Please enter item name')
      return
    }

    setLoading(true)

    const itemsList = items.map((item) => ({
      itemId: item.type === 'product' ? item.itemId : null,
      itemsId: item.type === 'item' ? item.itemId : null,
      productName: item.productName,
      price: item.price,
      quantity: item.quantity,
      discount: item.discount,
      amount: item.amount,
      type: item.type,
    }))

    const year = String(selectYearAndMonth.getFullYear() - 2000).padStart(2, '0')
    const month = String(selectYearAndMonth.getMonth() + 1).padStart(2, '0')
    const dNM = `${prefix}-${year}-${month}-`

    const data = {
      ...purchaseQuotation,
      vendor: selectedVendor.value,
      items: selectFromInventory ? itemsList : newItems,
      newProductCategory,
      itemsFromInventory: selectFromInventory,
      prefix: dNM,
      customID: customID,
      id: nextNumber,
      approval: permissions?.purchaseorder?.enable ? 'accepted' : 'pending',
      company: currentUser?.company,
      organization: currentUser?.organization,
      agent: currentUser?._id,
    }
    console.log('purchase quotation data', data)
    try {
      await postPurchaseQuotation(data, orderNo).then((res) => {
        console.log('Quotation submitted successfully:', res)
        dispatch(setPurchaseOrderDataFetch())
        navigate(`/procurement/purchaseOrdersList`)
      })
    } catch (error) {
      console.error('Error submitting quotation:', error)
    }
  }

  const handleButtonClick = () => {
    fileInputRef.current.click()
  }

  useEffect(() => {
    const fetchPurchaseOrder = async () => {
      try {
        const PurchaseOrderNotes = await getNotes(currentUser?.organization, 'Purchase Orders')
        const PurchaseOrderTerms = await getTerms(currentUser?.organization, 'Purchase Orders')

        setPurchaseQuotation({
          ...purchaseQuotation,
          notes: PurchaseOrderNotes[0]?.notes || '',
          termsCondition: PurchaseOrderTerms?.terms || '',
        })
      } catch (error) {
        console.error('Error fetching delivery notes:', error)
      }
    }
    fetchPurchaseOrder()
  }, [])

  const paymentTermsOptions = [
    {value: 'net-30', label: 'Net 30 Days'},
    {value: 'net-60', label: 'Net 60 Days'},
    {value: 'net-90', label: 'Net 90 Days'},
  ]
  const shipmentPreferenceOptions = [
    {value: 'normal', label: 'Normal Delivery'},
    {value: 'third-party', label: 'Third Party Delivery'},
    {value: 'express', label: 'Express Delivery'},
    // Add more options as needed
  ]
  const [employeeList, setEmployeeList] = useState<any>([])
  const [productList, setProductList] = useState<any>([])

  const getEmployeeList = async () => {
    try {
      const res = await getEmployeeByStatus('filter_status=true', currentUser?.organization)
      setEmployeeList(
        res?.map((r) => ({
          value: r._id,
          label: r.firstName, // Corrected the typo here from "labe" to "label"
        }))
      )
    } catch (error) {
      console.error('Error fetching client list:', error)
    }
  }
  const getProductlist = async () => {
    try {
      let purchaseStockList = []
      if (purchaseStock.length === 0) {
        const res = await getStocksForPurchaseOrder(currentUser?.organization)
        console.log(res)
        purchaseStockList = res.map((r) => ({
          value: r._id,
          label: r.productName, // Corrected the typo here from "labe" to "label"
          price: r.price,
          inWarehouseQuantity: r.inWarehouseQuantity,
          type: 'product',
        }))
        dispatch(setPurchaseStock(purchaseStockList))
      } else {
        purchaseStockList = purchaseStock
      }
      let serviceData = []
      if (serviceList.length === 0) {
        const resp = await getItems(currentUser?.organization)
        serviceData = resp.map((r) => ({
          value: r._id,
          label: r.name, // Corrected the typo here from "labe" to "label"
          price: r.price,
          inWarehouseQuantity: 1,
          type: 'item',
        }))
        dispatch(setServices(resp))
      } else {
        serviceData = serviceList.map((r) => ({
          value: r._id,
          label: r.name, // Corrected the typo here from "labe" to "label"
          price: r.price,
          inWarehouseQuantity: 1,
          type: 'item',
        }))
      }
      setProductList((prev) => {
        const updatedData = [...purchaseStockList, ...serviceData]
        return updatedData
      })
    } catch (error) {
      console.error('Error fetching client list:', error)
    }
  }

  const getQuotationID = async () => {
    try {
      const res = await getPurchaseOrderLastId(currentUser?.organization)
      console.log('ye hai res', res)
      setNextNumber(res.lastId + 1)
      const lastPrefix = res?.prefix.slice(0, res?.prefix?.trim()?.length - 7)
      setPrefix(lastPrefix)
      setSalesOrderNumber(`${res?.prefix}${res?.lastId + 1}`)
    } catch (error) {
      console.error('Error fetching client list:', error)
    }
  }

  useEffect(() => {
    if (vendorDataList?.length === 0) {
      if (permissions?.purchaseorder?.admin === false) {
        getVendorListForAgent()
      } else if (
        permissions?.purchaseorder?.admin === true ||
        currentUser?.profileType === 'superadmin'
      ) {
        getVendorList()
      }
    } else {
      setVendorList(
        vendorDataList.map((r) => ({
          value: r._id,
          label: r.displayName, // Assuming name is the property containing the vendor's name
          currency: r.currency,
          contactPersons: r.contactPersons,
        }))
      )
    }
  }, [permissions, currentUser])

  useEffect(() => {
    if (currentUser.organization !== undefined) {
      getEmployeeList()
      getProductlist()
      getOrderWithProject()
      getQuotationID()
      fetchIncoTerms()
      fetchPaymentTerms()
    }
  }, [currentUser.organization])

  //functions for Custom Quotation no
  const handleCloseModal = () => {
    setShowModal(false)
    // setCustomID('')
    // setPrefix('')
    // setNextNumber('')
    // getQuotationID()
  }

  const handleSaveChanges = () => {
    let newSalesOrderNumber = ''
    if (selectedOption === 'autoGenerate') {
      setPrefix(prefix)
      console.log(prefix, nextNumber)
      setNextNumber(nextNumber)
      setCustomID('')
      const year = String(selectYearAndMonth.getFullYear() - 2000).padStart(2, '0')
      const month = String(selectYearAndMonth.getMonth() + 1).padStart(2, '0')
      const dNM = `${year}-${month}`
      newSalesOrderNumber = `${prefix}-${dNM}-${nextNumber}`
    } else if (selectedOption === 'manualInput') {
      setCustomID(salesOrderNumber)
      setPrefix('')
      setNextNumber('')
      newSalesOrderNumber = salesOrderNumber
    }
    setSalesOrderNumber(newSalesOrderNumber)
    setShowModal(false)
  }

  // const getSalesOrderID = async () => {
  //   try {
  //     const res = await getSalesOrderLastId()
  //     setNextNumber(res.lastId + 1)
  //     setPrefix(res.prefix)
  //     console.log(res)
  //   } catch (error) {
  //     console.error('Error fetching client list:', error)
  //   }
  // }

  useEffect(() => {
    if (customID) {
      setSalesOrderNumber(customID)
    }
    // else {
    //   setSalesOrderNumber(`${prefix}${nextNumber}`)
    // }
  }, [customID])

  const handleCategoryChange = async (e) => {
    setCategoryType(e.target.value)
    if (e.target.value === 'service') {
      if (serviceList.length === 0) {
        const res = await getItems(currentUser?.organization)
        const data = res.map((r) => ({
          value: r._id,
          label: r.name,
          price: r.price,
          inWarehouseQuantity: 1,
          type: 'item',
        }))
        setProductList(data)
        dispatch(setServices(res))
      } else {
        const data = serviceList.map((r) => ({
          value: r._id,
          label: r.name,
          price: r.price,
          inWarehouseQuantity: 1,
          type: 'item',
        }))
        setProductList(data)
      }
    } else {
      const category = e.target.value
      if (category === 'goods') {
        if (goodsList.length === 0) {
          const res = await getFilteredStocks(currentUser?.organization, e.target.value)
          const data = res.map((r) => ({
            value: r._id,
            label: r.productName,
            price: r.price,
            inWarehouseQuantity: r.inWarehouseQuantity,
            type: 'product',
          }))
          setProductList(data)
          dispatch(setGoodsData(res))
        } else {
          const data = goodsList.map((r) => ({
            value: r._id,
            label: r.productName,
            price: r.price,
            inWarehouseQuantity: r.inWarehouseQuantity,
            type: 'product',
          }))
          setProductList(data)
        }
      } else if (category === 'rentals') {
        if (rentalsList.length === 0) {
          const res = await getFilteredStocks(currentUser?.organization, e.target.value)
          const data = res.map((r) => ({
            value: r._id,
            label: r.productName,
            price: r.price,
            inWarehouseQuantity: r.inWarehouseQuantity,
            type: 'product',
          }))
          setProductList(data)
          dispatch(setRentalsData(res))
        } else {
          const data = rentalsList.map((r) => ({
            value: r._id,
            label: r.productName,
            price: r.price,
            inWarehouseQuantity: r.inWarehouseQuantity,
            type: 'product',
          }))
          setProductList(data)
        }
      } else if (category === 'consumables') {
        if (consumablesList.length === 0) {
          const res = await getFilteredStocks(currentUser?.organization, e.target.value)
          const data = res.map((r) => ({
            value: r._id,
            label: r.productName,
            price: r.price,
            inWarehouseQuantity: r.inWarehouseQuantity,
            type: 'product',
          }))
          setProductList(data)
          dispatch(setConsumablesData(res))
        } else {
          const data = consumablesList.map((r) => ({
            value: r._id,
            label: r.productName,
            price: r.price,
            inWarehouseQuantity: r.inWarehouseQuantity,
            type: 'product',
          }))
          setProductList(data)
        }
      } else if (category === 'materials') {
        if (materialsList.length === 0) {
          const res = await getFilteredStocks(currentUser?.organization, e.target.value)
          const data = res.map((r) => ({
            value: r._id,
            label: r.productName,
            price: r.price,
            inWarehouseQuantity: r.inWarehouseQuantity,
            type: 'product',
          }))
          setProductList(data)
          dispatch(setMaterialsData(res))
        } else {
          const data = materialsList.map((r) => ({
            value: r._id,
            label: r.productName,
            price: r.price,
            inWarehouseQuantity: r.inWarehouseQuantity,
            type: 'product',
          }))
          setProductList(data)
        }
      }
    }
  }

  const fetchIncoTerms = async () => {
    try {
      const res = await getIncoTerms(currentUser?.organization)
      if (!res || !Array.isArray(res.incoTerm)) {
        console.error('Invalid response format from getIncoTerms:', res)
        return
      }
      const filteredIncoTerms = res.incoTerm.filter((item) => item !== null)
      const data = filteredIncoTerms.map((item) => ({value: item, label: item}))
      setIncoTermsOption(data)
    } catch (error) {
      console.error('Error fetching incoTerms:', error)
    }
  }

  const fetchPaymentTerms = async () => {
    try {
      const res = await getPaymentTerms(currentUser?.organization)
      if (!res || !Array.isArray(res.paymentTerms)) {
        console.error('Invalid response format from getIncoTerms:', res)
        return
      }
      const filteredPaymentTerms = res.paymentTerms.filter((item) => item !== null)
      const data = filteredPaymentTerms.map((item) => ({value: item, label: item}))
      setPaymentTermsOption(data)
    } catch (error) {
      console.error('Error fetching incoTerms:', error)
    }
  }

  const handleIncoTermsSubmit = () => {
    const data = {
      incoTerm: name,
    }
    const res = addIncoTerms(currentUser?.organization, data)
    if (res) {
      setName('')
      setIncoTermsOption([...incoTermsOption, {value: name, label: name}])
      const closeModalButton1 = document.getElementById('closeModalButton1')
      closeModalButton1.click()
    }
  }

  const handlePaymentTermsSubmit = () => {
    const data = {
      paymentTerm: name,
    }
    const res = addPaymentTerms(currentUser?.organization, data)
    if (res) {
      setName('')
      setPaymentTermsOption([...paymentTermsOption, {value: name, label: name}])
      const closeModalButton2 = document.getElementById('closeModalButton2')
      closeModalButton2.click()
    }
  }

  return (
    <div id='kt_app_content' className='app-content flex-column-fluid'>
      {/* begin::Content container */}
      <div id='kt_app_content_container' className='app-container container-xxl'>
        {/* begin::Layout */}
        <div className='d-flex flex-column flex-lg-row'>
          {/* begin::Content */}
          <div className='flex-lg-row-fluid mb-10 mb-lg-0 me-lg-7 me-xl-10'>
            {/* begin::Card */}
            <div className='d-flex gap-3 justify-end mb-2'>
              <button
                className='btn btn-light-primary me-3'
                data-kt-menu-trigger='click'
                data-kt-menu-placement='bottom-end'
                data-kt-menu-flip='top-end'
              >
                <KTIcon iconName='filter' className='fs-2' />
                Filter
              </button>
              <div
                className='menu menu-sub menu-sub-dropdown w-400px w-md-400px p-5'
                data-kt-menu='true'
                style={{zIndex: 1000000}}
              >
                <div className='px-7 py-2'>
                  <div className='fs-5 text-dark fw-bolder'>Filter Options</div>
                </div>
                <div className='separator border-gray-200'></div>
                <div className='px-7 py-5' data-kt-user-table-filter='form'>
                  <div className='mb-5 d-flex flex-row align-content-center justify-content-center'>
                    <label className='form-label fs-6 fw-bold mt-2 w-100px me-5'>Category:</label>
                    <select
                      className='form-select form-select-solid fw-bolder w-75'
                      onChange={handleCategoryChange}
                      value={categoryType}
                    >
                      <option value=''>Select an option</option>
                      {currentUser?.inventoryFeatures?.goods && (
                        <option value='goods'>Goods</option>
                      )}
                      {currentUser?.inventoryFeatures?.rentals && (
                        <option value='rentals'>Rentals</option>
                      )}
                      {currentUser?.inventoryFeatures?.consumables && (
                        <option value='consumables'>Consumables</option>
                      )}
                      {currentUser?.inventoryFeatures?.materials && (
                        <option value='materials'>Materials</option>
                      )}
                      {currentUser?.inventoryFeatures?.service && (
                        <option value='service'>Services</option>
                      )}
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div className='card'>
              {/* begin::Card body */}
              <div className='card-body p-12'>
                {/* begin::Form */}
                <form action='' id='kt_quote_form'>
                  {/* begin::Wrapper */}
                  <div className='d-flex flex-column align-items-start flex-xxl-row'>
                    {/* begin::Input group */}
                    <div
                      className='d-flex align-items-center flex-equal fw-row me-4 order-2'
                      data-bs-toggle='tooltip'
                      data-bs-trigger='hover'
                      title='Specify date'
                    >
                      {/* begin::Date */}
                      <div className='fs-6 fw-bold text-gray-700 text-nowrap'>Date:</div>
                      {/* Date */}
                      {/* begin::Input */}
                      <div className='position-relative d-flex align-items-center w-150px'>
                        {/* begin::Datepicker */}
                        <Flatpickr
                          value={purchaseQuotation.date}
                          onChange={handleDateChange}
                          className='form-control form-control-solid'
                          placeholder='Pick date'
                          options={{
                            dateFormat: 'd-m-Y',
                          }}
                        />
                        {/* Datepicker */}
                        {/* begin::Icon */}
                        {/* <i className='ki-duotone ki-down fs-4 position-absolute ms-4 end-0'></i> */}
                        {/* Icon */}
                      </div>
                      {/* Input */}
                    </div>
                    {/* Input group */}
                    {/* begin::Input group */}
                    <div
                      className='d-flex flex-center flex-equal fw-row text-nowrap order-1 order-xxl-2 me-4'
                      data-bs-toggle='tooltip'
                      data-bs-trigger='hover'
                      title='Enter Purchase Order number'
                    >
                      <span className='fs-2x fw-bold text-gray-800 '>
                        PURCHASE ORDERS #
                        <input
                          type='text'
                          className='form-control form-control-flush fw-bold text-muted fs-3 w-200px'
                          value={salesOrderNumber}
                          onChange={(e) => setSalesOrderNumber(e.target.value)}
                          placeholder='...'
                          readOnly
                        />
                      </span>
                      {/* <input
                        type='text'
                        className='form-control form-control-flush fw-bold text-muted fs-3 w-125px'
                        value={purchaseQuotation.id}
                        placeholder='...'
                        disabled
                      /> */}

                      <button
                        className='btn btn-light ms-5'
                        onClick={(e) => {
                          e.preventDefault()
                          setShowModal(true)
                        }}
                      >
                        <KTIcon iconName='gear' />
                      </button>

                      <div>
                        {showModal && (
                          <div
                            className='modal'
                            tabIndex={-1}
                            role='dialog'
                            style={{display: 'block', backgroundColor: 'rgba(0, 0, 0, 0.5)'}}
                          >
                            <div className='modal-dialog modal-dialog-centered' role='document'>
                              <div className='modal-content'>
                                <div className='modal-header'>
                                  <h5 className='modal-title'>Customize Quotation No.</h5>
                                  <button
                                    type='button'
                                    className='btn-close'
                                    onClick={handleCloseModal}
                                  ></button>
                                </div>
                                <div className='modal-body'>
                                  {/* Your modal body content goes here */}
                                  <p>How would you like to Quotation numbers?</p>
                                  <div className='form-check'>
                                    <input
                                      className='form-check-input'
                                      type='radio'
                                      name='salesOrderOption'
                                      id='autoGenerate'
                                      value='autoGenerate'
                                      checked={selectedOption === 'autoGenerate'}
                                      onChange={() => setSelectedOption('autoGenerate')}
                                    />
                                    <label className='form-check-label' htmlFor='autoGenerate'>
                                      Continue auto-generating Quotation numbers
                                    </label>
                                  </div>
                                  <div className='form-check'>
                                    <input
                                      className='form-check-input'
                                      type='radio'
                                      name='salesOrderOption'
                                      id='manualInput'
                                      value='manualInput'
                                      checked={selectedOption === 'manualInput'}
                                      onChange={() => setSelectedOption('manualInput')}
                                    />
                                    <label className='form-check-label' htmlFor='manualInput'>
                                      Enter Quotation numbers manually
                                    </label>
                                  </div>

                                  {/* Additional input fields for manual input */}
                                  {selectedOption === 'autoGenerate' && (
                                    <div className='mt-3'>
                                      <div className='mb-3'>
                                        <label className='form-label'>Prefix:</label>
                                        <input
                                          type='text'
                                          className='form-control'
                                          value={prefix}
                                          onChange={(e) => setPrefix(e.target.value)}
                                          placeholder='Enter Prefix'
                                        />
                                      </div>
                                      <div className='mb-3'>
                                        <label className='form-label'>Month & Year:</label>
                                        <br />
                                        <DatePicker
                                          selected={selectYearAndMonth}
                                          onChange={(date) => setSelectYearAndMonth(date)}
                                          dateFormat='yyyy/MM'
                                          showMonthYearPicker
                                          className='form-control'
                                        />
                                      </div>
                                      <div className='mb-3'>
                                        <label className='form-label'>Next Number:</label>
                                        <input
                                          type='number'
                                          className='form-control'
                                          value={nextNumber}
                                          onChange={(e) => setNextNumber(e.target.value)}
                                          placeholder='Enter Next Number'
                                        />
                                      </div>
                                    </div>
                                  )}
                                  {selectedOption === 'manualInput' && (
                                    <div className='mt-3'>
                                      <div className='mb-3'>
                                        <label className='form-label'>Quotation No. :</label>
                                        <input
                                          type='text'
                                          className='form-control'
                                          value={salesOrderNumber}
                                          onChange={(e) => setSalesOrderNumber(e.target.value)}
                                          placeholder='Enter Quotation Number'
                                        />
                                      </div>
                                    </div>
                                  )}
                                </div>
                                <div className='modal-footer'>
                                  <button
                                    type='button'
                                    className='btn btn-secondary'
                                    onClick={handleCloseModal}
                                  >
                                    Close
                                  </button>
                                  <button
                                    type='button'
                                    className='btn btn-primary'
                                    onClick={handleSaveChanges}
                                  >
                                    Save changes
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        {/* End of modal content */}
                      </div>
                    </div>
                    {/* Input group */}
                    {/* begin::Input group */}
                    <div
                      className='d-flex align-items-center justify-content-end flex-equal order-3 fw-row'
                      data-bs-toggle='tooltip'
                      data-bs-trigger='hover'
                      title='Specify Quotation due date'
                    ></div>
                    {/* Input group */}
                  </div>
                  {/* Top */}
                  {/* begin::Separator */}
                  <div className='separator separator-dashed my-10'></div>
                  {/* Separator */}
                  {/* begin::Wrapper */}
                  <div className='mb-0'>
                    {/* begin::Row */}
                    <div className='row gx-10 mb-5'>
                      {/* begin::Col */}
                      <div className='col-lg-6'>
                        <label className='form-label required fs-6 fw-bold text-gray-700 mb-3'>
                          Vendor Name
                        </label>
                        {/* begin::Input group */}
                        <Select
                          name='vendor'
                          aria-label='Select a Vendor'
                          placeholder='Select a Vendor'
                          options={vendorList}
                          isSearchable={true}
                          className='form-control form-control-solid'
                          onChange={handleVendorChange}
                          value={selectedVendor}
                        />
                      </div>
                      <div className='col-lg-3'>
                        <label className='form-label fs-6 fw-bold text-gray-700 mb-3'>
                          Reference#
                        </label>
                        <input
                          type='text'
                          className='form-control form-control-solid '
                          name='reference'
                          placeholder='Reference for Quotation'
                          value={purchaseQuotation.reference}
                          onChange={handleChange}
                        />
                      </div>
                      <div className='col-lg-3'>
                        <label className='form-label fs-6 fw-bold text-gray-700 mb-3'>
                          Project Order
                        </label>
                        <Select
                          options={projectOrder}
                          placeholder='Select Project Order'
                          isSearchable
                          onChange={(e) => setOrderNo(e.value)}
                        />
                      </div>
                      {/* Col */}
                      {/* begin::Col */}

                      {/* Col */}
                    </div>
                    {/* Row */}
                    <div className=''></div>

                    {/* <div className=''>
                      <label className='form-label fs-6 fw-bold text-gray-700 mb-3'>
                        Reference#
                      </label>
                      <input
                        type='text'
                        className='form-control form-control-solid '
                        name='reference'
                        placeholder='Reference for Quotation'
                        value={purchaseQuotation.reference}
                        onChange={handleChange}
                      />
                    </div> */}

                    <div className='row gx-10 mb-5'>
                      {/* begin::Col */}
                      <div className='col-lg-3'>
                        <label className='form-label required fs-6 fw-bold text-gray-700 mb-3'>
                          Expected Delivery Date
                        </label>
                        {/* begin::Input group */}
                        <Flatpickr
                          value={purchaseQuotation.expectedDeliveryDate}
                          onChange={handleExpectedDeliveryDateChange}
                          className='form-control form-control-solid'
                          placeholder='Pick date'
                          options={{
                            dateFormat: 'd-m-Y',
                          }}
                        />
                      </div>
                      <div className='col-lg-3'>
                        <label className='form-label required fs-6 fw-bold text-gray-700 mb-3'>
                          Contact Person
                        </label>
                        <Select
                          name='contactPerson'
                          aria-label='Select a Contact Person'
                          placeholder='Select a Person'
                          options={contactPersonList}
                          isSearchable={true}
                          className='form-control form-control-solid'
                          onChange={(selectedOption) => {
                            setPurchaseQuotation((prevQuotation) => ({
                              ...prevQuotation,
                              contactPerson: selectedOption.value,
                            }))
                          }}
                          value={contactPersonList.find(
                            (person) => person.value === purchaseQuotation.contactPerson
                          )}
                        />
                      </div>
                      <div className='col-lg-3 mb-3'>
                        <label className='form-label fw-bold fs-6 text-gray-700 mb-3'>
                          Payment Terms
                        </label>
                        <Select
                          name='paymentTerm'
                          aria-label='Select a Payment Term'
                          placeholder='Select a Payment Term'
                          options={paymentTermsOption}
                          isSearchable={true}
                          className=''
                          onChange={(selectedOption) => {
                            setPurchaseQuotation((prevInvoice) => ({
                              ...prevInvoice,
                              paymentTerms: selectedOption.value,
                            }))
                          }}
                        />
                        <div className='card-toolbar mt-5'>
                          <div
                            className='btn btn-sm btn-light-warning mb-10'
                            data-bs-toggle='modal'
                            data-bs-target='#kt_modal_2'
                          >
                            <i className='ki-duotone ki-plus fs-4'></i>Create New Payment Term
                          </div>
                        </div>
                        <div className='modal fade' tabIndex={-1} id='kt_modal_2'>
                          <div className='modal-dialog'>
                            <div className='modal-content'>
                              <div className='modal-header'>
                                <h5 className='modal-title'>Add New Payment Term</h5>
                                <div
                                  className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                                  data-bs-dismiss='modal'
                                  aria-label='Close'
                                >
                                  <KTSVG
                                    path='/media/icons/duotune/arrows/arr061.svg'
                                    className='svg-icon svg-icon-2x'
                                  />
                                </div>
                              </div>

                              <div className='modal-body'>
                                <form>
                                  <div className='row rowwidthclient'>
                                    <div className='form-group mb-3 col-md-12'>
                                      <label className='mb-3'>Payment Term</label>
                                      <input
                                        type='text'
                                        name='name'
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                        className='form-control'
                                      />
                                    </div>
                                  </div>
                                </form>
                              </div>

                              <div className='modal-footer'>
                                <button
                                  type='button'
                                  className='btn btn-light'
                                  data-bs-dismiss='modal'
                                  id='closeModalButton2'
                                >
                                  Close
                                </button>
                                <button
                                  type='button'
                                  className='btn btn-primary'
                                  onClick={handlePaymentTermsSubmit}
                                >
                                  Save changes
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='col-lg-3 mb-3'>
                        <label className='form-label fw-bold fs-6 text-gray-700 mb-3'>
                          Inco Terms
                        </label>
                        <Select
                          name='incoTerm'
                          aria-label='Select a Inco Term'
                          placeholder='Select a Inco Term'
                          options={incoTermsOption}
                          isSearchable={true}
                          className=''
                          onChange={(selectedOption) => {
                            setPurchaseQuotation((prevInvoice) => ({
                              ...prevInvoice,
                              incoTerms: selectedOption.value,
                            }))
                          }}
                        />
                        <div className='card-toolbar mt-5'>
                          <div
                            className='btn btn-sm btn-light-warning mb-10'
                            data-bs-toggle='modal'
                            data-bs-target='#kt_modal_1'
                          >
                            <i className='ki-duotone ki-plus fs-4'></i>Create New Inco Term
                          </div>
                        </div>
                        <div className='modal fade' tabIndex={-1} id='kt_modal_1'>
                          <div className='modal-dialog'>
                            <div className='modal-content'>
                              <div className='modal-header'>
                                <h5 className='modal-title'>Add New Inco Term</h5>
                                <div
                                  className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                                  data-bs-dismiss='modal'
                                  aria-label='Close'
                                >
                                  <KTSVG
                                    path='/media/icons/duotune/arrows/arr061.svg'
                                    className='svg-icon svg-icon-2x'
                                  />
                                </div>
                              </div>

                              <div className='modal-body'>
                                <form>
                                  <div className='row rowwidthclient'>
                                    <div className='form-group mb-3 col-md-12'>
                                      <label className='mb-3'>Inco Term</label>
                                      <input
                                        type='text'
                                        name='name'
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                        className='form-control'
                                      />
                                    </div>
                                  </div>
                                </form>
                              </div>

                              <div className='modal-footer'>
                                <button
                                  type='button'
                                  className='btn btn-light'
                                  data-bs-dismiss='modal'
                                  id='closeModalButton1'
                                >
                                  Close
                                </button>
                                <button
                                  type='button'
                                  className='btn btn-primary'
                                  onClick={handleIncoTermsSubmit}
                                >
                                  Save changes
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* Col */}
                    </div>

                    <div className='d-flex align-items-end justify-content-start gap-5 mt-5 mb-2'>
                      <button
                        type='button'
                        className={`btn btn-light-success ${selectFromInventory && 'active'}`}
                        onClick={() => {
                          setSelectFromInventory(true)
                          setNewItems([])
                          setItems([
                            {
                              itemId: '',
                              price: '',
                              quantity: 0,
                              discount: 0,
                              amount: '',
                              selectedOption: {
                                value: '',
                                label: '',
                                inWarehouseQuantity: 0,
                              },
                              type: 'product',
                              productName: '',
                            },
                          ])
                        }}
                      >
                        Select from Inventory
                      </button>
                      <button
                        type='button'
                        className={`btn btn-light-warning ${!selectFromInventory && 'active'}`}
                        onClick={() => {
                          setSelectFromInventory(false)
                          setItems([])
                          setNewItems([
                            {
                              productName: '',
                              price: '',
                              unit: '',
                              quantity: 0,
                              discount: 0,
                              amount: '',
                            },
                          ])
                        }}
                      >
                        Add New Item
                      </button>

                      {!selectFromInventory && (
                        <div className='flex flex-col justify-between gap-1 ms-3'>
                          <label className='form-label required fw-bold fs-6 text-gray-700'>
                            Product Type
                          </label>
                          <Select
                            options={[
                              {value: 'materials', label: 'Materials'},
                              {value: 'consumables', label: 'Consumables'},
                            ]}
                            defaultValue={{value: 'materials', label: 'Materials'}}
                            onChange={(e) => setNewProductCategory(e.value)}
                            placeholder='Select a Category'
                            className='form-control form-control-solid w-200px p-0'
                          />
                        </div>
                      )}
                    </div>

                    {/* begin::Table wrapper */}
                    <div className='fs-6 fw-bold text-gray-700 mb-3 mt-5'>Item Table</div>
                    <div className='table-responsive mb-10'>
                      {/* begin::Table */}
                      <table
                        className='table g-5 gs-0 mb-0 fw-bold text-gray-700'
                        data-kt-element='items'
                      >
                        {/* begin::Table head */}
                        <thead>
                          <tr className='border-bottom fs-7 fw-bold text-gray-700 text-uppercase'>
                            <th className='min-w-15px w-15px'>No.</th>
                            <th className='min-w-200px w-250px pe-0'>Item</th>
                            {!selectFromInventory && (
                              <th className='min-w-40px w-40px px-0'>Unit</th>
                            )}
                            <th className='min-w-100px w-100px'>Quantity</th>
                            <th className='min-w-100px w-100px'>Rate</th>
                            <th className='min-w-70px w-100px'>VAT</th>
                            <th className='min-w-100px w-100px'>Amount</th>
                            <th className='min-w-70px text-center'>Action</th>
                          </tr>
                        </thead>
                        {/* Table head */}
                        {/* begin::Table body */}
                        <DragDropContext onDragEnd={handleDragEnd}>
                          <Droppable droppableId='droppable'>
                            {(provided) => (
                              <tbody ref={provided.innerRef} {...provided.droppableProps}>
                                {selectFromInventory &&
                                  items.map((item, index) => (
                                    <Draggable
                                      key={index}
                                      draggableId={`item-${index}`}
                                      index={index}
                                    >
                                      {(provider) => (
                                        <tr
                                          className='border-bottom border-bottom-dashed'
                                          data-kt-element='item'
                                          key={index}
                                          ref={provider.innerRef}
                                          {...provider.draggableProps}
                                        >
                                          <td className='d-flex align-items-center ps-1'>
                                            {index + 1}
                                          </td>
                                          <td className='min-w-[200px] pe-7'>
                                            {/* begin::Input group */}
                                            <Select
                                              name='itemId'
                                              aria-label='Select a Product'
                                              placeholder='Select a Product'
                                              options={productList}
                                              isSearchable={true}
                                              className='form-control form-control-solid'
                                              onChange={(e) => handleSelectChangeInRows(index, e)}
                                              value={item.selectedOption}
                                              required
                                            />
                                          </td>
                                          <td className='pe-7'>
                                            {/* begin::Input group */}

                                            <input
                                              type='number'
                                              className='form-control form-control-solid'
                                              name='quantity'
                                              placeholder='0.00'
                                              // max={
                                              //   item?.type === 'product' &&
                                              //   item?.selectedOption?.inWarehouseQuantity
                                              // }
                                              min={0}
                                              value={item.quantity}
                                              onChange={(e) => handleChangerows(index, e)}
                                            />
                                          </td>

                                          <td className='pe-7'>
                                            {/* begin::Input group */}

                                            <input
                                              type='number'
                                              className='form-control form-control-solid'
                                              name='price'
                                              placeholder='0.00'
                                              value={item.price}
                                              onChange={(e) => handleChangerows(index, e)}
                                            />
                                          </td>

                                          <td className='pe-7'>
                                            {/* begin::Input group */}

                                            <input
                                              type='number'
                                              className='form-control form-control-solid'
                                              name='discount'
                                              placeholder='0.00'
                                              value={item.discount}
                                              onChange={(e) => handleChangerows(index, e)}
                                            />
                                          </td>

                                          <td>
                                            <input
                                              type='number'
                                              className='form-control form-control-solid text-end'
                                              name='amount'
                                              placeholder='0.00'
                                              value={item.amount}
                                              disabled
                                              // onChange={(e) => handleChangerows(index, e)}
                                            />
                                          </td>
                                          <td className='flex items-center md:flex-nowrap justify-end'>
                                            <div
                                              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-3'
                                              onClick={additem}
                                            >
                                              <KTIcon iconName='plus-square' className='fs-3' />
                                            </div>
                                            <div
                                              className='btn btn-icon btn-bg-light btn-active-color-danger btn-sm '
                                              onClick={() => removeitem(index)}
                                            >
                                              <KTIcon iconName='trash' className='fs-3' />
                                            </div>
                                            <div
                                              {...provider.dragHandleProps}
                                              className='fs-3 fw-bold text-gray-400 ms-3'
                                            >
                                              ...
                                            </div>
                                          </td>
                                        </tr>
                                      )}
                                    </Draggable>
                                  ))}
                                {provided.placeholder}
                                {!selectFromInventory &&
                                  newItems.map((item, index) => (
                                    <Draggable
                                      key={index}
                                      draggableId={`item-${index}`}
                                      index={index}
                                    >
                                      {(provider) => (
                                        <tr
                                          className='border-bottom border-bottom-dashed'
                                          data-kt-element='item'
                                          key={index}
                                          ref={provider.innerRef}
                                          {...provider.draggableProps}
                                        >
                                          <td className='d-flex align-items-center ps-1'>
                                            {index + 1}
                                          </td>
                                          <td className=' w-[200px] pe-0'>
                                            {/* begin::Input group */}
                                            <input
                                              type='text'
                                              className='form-control form-control-solid'
                                              name='productName'
                                              placeholder='Enter Product Name'
                                              value={item.productName}
                                              onChange={(e) => handleChangeNewItem(index, e)}
                                            />
                                          </td>
                                          <td className='px-2'>
                                            {/* begin::Input group */}
                                            <select
                                              name='unit'
                                              id=''
                                              className='form-control form-control-solid'
                                              value={item.unit}
                                              onChange={(e) => handleChangeNewItem(index, e)}
                                            >
                                              <option value=''>unit</option>
                                              {unitsList.map((unit, index) => (
                                                <option value={unit} key={unit}>
                                                  {unit}
                                                </option>
                                              ))}
                                            </select>
                                          </td>
                                          <td className='px-0'>
                                            {/* begin::Input group */}

                                            <input
                                              type='number'
                                              className='form-control form-control-solid'
                                              name='quantity'
                                              placeholder='0.00'
                                              value={item.quantity}
                                              onChange={(e) => handleChangeNewItem(index, e)}
                                            />
                                          </td>

                                          <td className='pe-0'>
                                            {/* begin::Input group */}

                                            <input
                                              type='number'
                                              className='form-control form-control-solid'
                                              name='price'
                                              placeholder='0.00'
                                              value={item.price}
                                              onChange={(e) => handleChangeNewItem(index, e)}
                                            />
                                          </td>

                                          <td className='pe-7'>
                                            {/* begin::Input group */}

                                            <input
                                              type='number'
                                              className='form-control form-control-solid'
                                              name='discount'
                                              placeholder='0.00'
                                              value={item.discount}
                                              onChange={(e) => handleChangeNewItem(index, e)}
                                            />
                                          </td>

                                          <td>
                                            <input
                                              type='number'
                                              className='form-control form-control-solid text-end'
                                              name='amount'
                                              placeholder='0.00'
                                              value={item.amount}
                                              disabled
                                              // onChange={(e) => handleChangerows(index, e)}
                                            />
                                          </td>
                                          <td className='flex items-center md:flex-nowrap justify-end'>
                                            <div
                                              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-3'
                                              onClick={() => {
                                                setNewItems([
                                                  ...newItems,
                                                  {
                                                    productName: '',
                                                    price: '',
                                                    unit: '',
                                                    quantity: 0,
                                                    discount: 0,
                                                    amount: '',
                                                  },
                                                ])
                                              }}
                                            >
                                              <KTIcon iconName='plus-square' className='fs-3' />
                                            </div>
                                            <div
                                              className='btn btn-icon btn-bg-light btn-active-color-danger btn-sm '
                                              onClick={() => {
                                                if (newItems.length === 1) {
                                                  setNewItems([
                                                    {
                                                      productName: '',
                                                      price: '',
                                                      unit: '',
                                                      quantity: 0,
                                                      discount: 0,
                                                      amount: '',
                                                    },
                                                  ])
                                                  return
                                                }
                                                const temp = [...newItems]
                                                temp.splice(index, 1)
                                                setNewItems(temp)
                                              }}
                                            >
                                              <KTIcon iconName='trash' className='fs-3' />
                                            </div>
                                            <div
                                              {...provider.dragHandleProps}
                                              className='fs-3 fw-bold text-gray-400 ms-3'
                                            >
                                              ...
                                            </div>
                                          </td>
                                        </tr>
                                      )}
                                    </Draggable>
                                  ))}
                              </tbody>
                            )}
                          </Droppable>
                        </DragDropContext>
                        {/* Table body */}
                        {/* begin::Table foot */}
                        <tfoot>
                          <tr className='border-top border-top-dashed align-top fs-6 fw-bold text-gray-700'>
                            <th></th>
                            <th></th>
                            {!selectFromInventory && <th></th>}
                            <th className='border-bottom border-bottom-dashed ps-0'>
                              <div className='d-flex flex-column align-items-start'>
                                <div className='fs-5'>Subtotal</div>
                                <div className='fs-5 my-3'>Freight Charges</div>
                                <div className='fs-5 my-3'>Discount</div>
                                <button
                                  className='btn btn-link '
                                  data-bs-toggle='tooltip'
                                  data-bs-trigger='hover'
                                  title='Tax in percentage'
                                >
                                  Add tax %
                                </button>
                              </div>
                            </th>
                            <th className='text-primary'></th>
                            <th className='border-bottom border-bottom-dashed text-end'>
                              <span data-kt-element='sub-total' className='text-end me-6'>
                                {purchaseQuotation?.subtotal?.toLocaleString('en-US', {
                                  minimumFractionDigits: 2,
                                })}
                                {''} {selectedVendorCurrency}
                              </span>
                              <input
                                type='number'
                                className='form-control form-control-solid text-end my-2'
                                name='freightCharges'
                                placeholder='0.00'
                                value={purchaseQuotation.freightCharges}
                                onChange={handleChange}
                              />
                              <input
                                type='number'
                                className='form-control form-control-solid text-end my-2'
                                name='discount'
                                placeholder='0.00'
                                value={purchaseQuotation.discount}
                                onChange={handleChange}
                              />
                              <input
                                type='number'
                                className='form-control form-control-solid text-end'
                                name='tax'
                                placeholder='0.00'
                                value={purchaseQuotation.tax}
                                onChange={handleChange}
                              />
                            </th>
                            <th className='w-100px'></th>
                            <th></th>
                          </tr>
                          <tr className='align-top fw-bold text-gray-700'>
                            <th className=' w-15px'></th>
                            <th className=' w-200px'></th>
                            {!selectFromInventory && <th className='w-100px'></th>}
                            <th className='fs-4 ps-0 min-w-100px w-100px'>Total </th>
                            <th className=''></th>
                            <th className='text-end fs-4 text-nowrap min-w-70px w-100px'>
                              <span data-kt-element='grand-total text-end'>
                                {' '}
                                {purchaseQuotation?.total?.toLocaleString('en-US', {
                                  minimumFractionDigits: 2,
                                })}
                              </span>{' '}
                              {selectedVendorCurrency}
                            </th>
                            <th className='min-w-100px w-100px'></th>
                            <th className='min-w-100px w-100px'></th>
                          </tr>
                        </tfoot>
                        {/* Table foot */}
                      </table>
                    </div>
                    {/* Table */}
                    {/* begin::Item template */}

                    <table className='table d-none' data-kt-element='empty-template'>
                      <tr data-kt-element='empty'>
                        <th colSpan={5} className='text-muted text-center py-10'>
                          No items
                        </th>
                      </tr>
                    </table>
                    {/* Item template */}
                    {/* begin::Notes */}
                    <div className='mb-0 position-relative '>
                      <label className='form-label fs-6 fw-bold text-gray-700'>Notes</label>
                      <textarea
                        name='notes'
                        className={` form-control form-control-solid mb-0 h-10 ${
                          isEditingNotes ? 'border border-dark-subtle' : ''
                        }`}
                        placeholder='Enter Notes'
                        value={purchaseQuotation.notes}
                        onChange={handleChange}
                        readOnly={!isEditingNotes}
                        onKeyDown={handleKeyPress}
                        style={{
                          minHeight: '6rem',
                          height: notesTextareaHeight,
                          resize: 'none',
                        }}
                      />
                      <button onClick={(e) => handleEditClick(e, 'notes')}>
                        <KTIcon
                          iconName='pencil'
                          className='position-absolute text-black bg-gray-100 right-8 top-[3rem] fs-3'
                        />
                      </button>
                    </div>

                    <div className='mb-10 position-relative'>
                      <label className='form-label fs-6 fw-bold text-gray-700'>
                        Terms & Conditions
                      </label>
                      <textarea
                        name='termsCondition'
                        style={{
                          minHeight: '8rem',
                          height: termsTextareaHeight,
                          resize: 'none',
                        }}
                        className={`  form-control form-control-solid mb-0 h-10 ${
                          isEditingTerms ? 'border border-dark-subtle' : ''
                        }`}
                        placeholder='Enter terms and conditions'
                        value={purchaseQuotation.termsCondition}
                        onChange={handleChange}
                        readOnly={!isEditingTerms}
                      />
                      <button onClick={(e) => handleEditClick(e, 'termsCondition')}>
                        <KTIcon
                          iconName='pencil'
                          className='absolute text-black bg-gray-100 right-8 top-[3rem] fs-3'
                        />
                      </button>
                    </div>
                    {/* <div className='mb-0'>
                      <label className='form-label fs-6 fw-bold text-gray-700'>Notes</label>
                      <input
                        type='textarea'
                        name='notes'
                        className='form-control form-control-solid'
                        placeholder='Thanks for your business'
                        value={purchaseQuotation.notes}
                        onChange={handleChange}
                      />
                    </div> */}
                    <div></div>
                    {/* Notes */}
                    {/* <div className='mb-0'>
                      <label className='form-label fs-6 fw-bold text-gray-700'>
                        Terms & Conditions
                      </label>
                      <input
                        type='textarea'
                        name='termsCondition'
                        className='form-control form-control-solid'
                        placeholder='Enter the terms and Conditions for your business to be displayed in your transaction'
                        value={purchaseQuotation.termsCondition}
                        onChange={handleChange}
                      />
                    </div> */}
                    <div className='gx-10 mb-5 d-flex justify-between'>
                      <div
                        className='card-toolbar me-5'
                        data-bs-toggle='modal'
                        data-bs-target='#kt_modal_11'
                      >
                        <a className='btn btn-sm btn-light-primary'>
                          <KTIcon iconName='plus' className='fs-2' />
                          Upload File
                        </a>
                      </div>
                      <div className='mb-0'>
                        {/* <!--begin::Row--> */}

                        {/* <!--::Row--> */}
                        {!submitted ? (
                          <button
                            type='button'
                            className='btn btn-primary'
                            id='kt_quote_submit_button'
                            onClick={handleSubmit}
                            disabled={loading}
                          >
                            <i className='ki-duotone ki-triangle fs-3'>
                              <span className='path1'></span>
                              <span className='path2'></span>
                              <span className='path3'></span>
                            </i>
                            Create Purchase Order
                          </button>
                        ) : (
                          <button
                            type='button'
                            className='btn btn-danger w-100'
                            id='kt_quote_submit_button'
                          >
                            <i className='ki-duotone ki-triangle fs-3'>
                              <span className='path1'></span>
                              <span className='path2'></span>
                              <span className='path3'></span>
                            </i>
                            Print Purchase Order
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                  {/* Wrapper */}
                </form>
                {/* Form */}
              </div>
              {/* Card body */}
            </div>
            {/* Card */}
          </div>
          {/* Content */}
          {/* begin::Sidebar */}
          {/* <!--::Sidebar--> */}
        </div>
        {/* <!--::Layout--> */}
      </div>
      <div className='modal fade' tabIndex={-1} id='kt_modal_11'>
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title'>Add File</h5>
              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
              </div>
            </div>

            <div className='modal-body'>
              <form>
                <div className='row rowwidthclient'>
                  <div className='card-toolbar'>
                    <a className='btn btn-sm btn-light-danger' onClick={handleButtonClick}>
                      <KTIcon iconName='plus' className='fs-2' />
                      Upload File
                    </a>
                  </div>

                  <input
                    type='file'
                    accept='.pdf, .jpg, .jpeg, .png, .gif, .doc, .docx, .xlsx, .xls'
                    onChange={handleFileChange}
                    ref={fileInputRef}
                    style={{display: 'none'}}
                  />
                  {excelFile && <p>Selected file: {excelFile.name}</p>}
                </div>
              </form>
            </div>

            <div className='modal-footer'>
              <button
                type='button'
                className='btn btn-light'
                data-bs-dismiss='modal'
                id='closeModalButton'
              >
                Close
              </button>
              <button
                type='button'
                className='btn btn-primary'
                disabled={isLoading}
                onClick={handleUpload}
              >
                Save changes
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* <!--::Content container--> */}
    </div>
  )
}

export default AddQuotation
