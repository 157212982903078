import React, {useEffect, useRef, useState} from 'react'
import {useParams} from 'react-router-dom'
import '../print.css'
import {getInvoicebyids} from '../../Core/_requests'
import {useAuth} from '../../../../modules/auth'
import {getCompanyById} from '../../../setting/core/_requests'
import {GoGlobe} from 'react-icons/go'
import {MdOutlineMailOutline} from 'react-icons/md'
import {toAbsoluteUrl} from '../../../../../_metronic/helpers'
interface IBankDetails {
  bankName: string
  accountNumber: string
  iBANNumber: string
}

function Invoice({companyLogo, color, qrCode, setColor, minFooter}) {
  const heightRef = useRef<HTMLDivElement>(null)
  const metalicaLogo = toAbsoluteUrl('/1721057513722meclogo2.jpeg')
  const {currentUser} = useAuth()
  const NUMBERS = new Map([
    [1e9, 'Billion'],
    [1e6, 'Million'],
    [1e3, 'Thousand'],
    [1e2, 'Hundred'],
    [90, 'Ninety'],
    [80, 'Eighty'],
    [70, 'Seventy'],
    [60, 'Sixty'],
    [50, 'Fifty'],
    [40, 'Forty'],
    [30, 'Thirty'],
    [20, 'Twenty'],
    [19, 'Nineteen'],
    [18, 'Eighteen'],
    [17, 'Seventeen'],
    [16, 'Sixteen'],
    [15, 'Fifteen'],
    [14, 'Fourteen'],
    [13, 'Thirteen'],
    [12, 'Twelve'],
    [11, 'Eleven'],
    [10, 'Ten'],
    [9, 'Nine'],
    [8, 'Eight'],
    [7, 'Seven'],
    [6, 'Six'],
    [5, 'Five'],
    [4, 'Four'],
    [3, 'Three'],
    [2, 'Two'],
    [1, 'One'],
    [0, 'Zero'],
  ])

  const [bank, setBank] = useState<IBankDetails>({
    bankName: '',
    accountNumber: '',
    iBANNumber: '',
  })
  const [arabicAddress, setArabicAddress] = useState('')
  const [arabicCustomerName, setArabicCustomerName] = useState('')

  useEffect(() => {
    const getCompany = async () => {
      const res = await getCompanyById(currentUser?.company)
      console.log(res)

      setColor(res?.salesColor)

      setBank({
        bankName: res?.bankName || '',
        accountNumber: res?.accountNumber || '',
        iBANNumber: res?.iBANNumber || '',
      })
    }

    getCompany()
  }, [])

  const numberToWords = (num) => {
    for (const [intValue, textValue] of NUMBERS as any) {
      if (num >= intValue) {
        const prefix = num >= 100 ? numberToWords(Math.trunc(num / intValue)) : ''
        const suffix = num % intValue > 0 ? numberToWords(num % intValue) : ''
        return `${prefix} ${textValue} ${suffix}`.trim()
      }
    }
    return ''
  }

  const convertSARAmountToWords = (amount) => {
    const [riyals, halalas] = amount.toString().split('.').map(Number)
    const riyalsInWords = numberToWords(riyals)
    const halalasInWords = halalas ? numberToWords(halalas.toString().padEnd(2, '0')) : ''

    return `${riyalsInWords} Riyals${halalasInWords ? ` and ${halalasInWords} Halalas` : ''} Only`
  }

  function toArabicNumerals(input) {
    // Check if the entire string contains only digits
    if (!/^\d+$/.test(input)) {
      return input
    }

    // Arabic numerals as a string array, index corresponds to the Western numeral
    const arabicNumerals = ['٠', '١', '٢', '٣', '٤', '٥', '٦', '٧', '٨', '٩']

    // Initialize an empty string to build the Arabic numeral representation
    let result = ''

    // Iterate over each character in the input string
    for (let char of input) {
      // Convert the character to the corresponding Arabic numeral
      result += arabicNumerals[parseInt(char)]
    }

    return result
  }

  const [vat, setVat] = useState(0)
  const [page, setPage] = useState(1)

  const id = useParams().id
  const [data, setdata] = useState<any>()

  // const translateText = async (text) => {
  //   const apiKey = 'AIzaSyCdccfjG437SajxmpfOodgbPxmXjjX2j70'
  //   const url = `https://translation.googleapis.com/language/translate/v2?key=${apiKey}`

  //   try {
  //     const response = await axios.post(url, {
  //       q: text,
  //       target: 'ar',
  //     })
  //     return response.data.data.translations[0].translatedText
  //   } catch (error) {
  //     console.error('Error translating text:', error)
  //   }
  // }

  useEffect(() => {
    const getinvoicedetails = async () => {
      await getInvoicebyids(id).then((res) => {
        setdata(res)
        if (res?.items?.length > 3) {
          const pages = Math.ceil((res?.items.length - 3) / 20)
          if ((res?.items.length - 10) / 20 > 10) {
            setPage(pages + 2)
          } else {
            setPage(pages + 1)
          }
        }
        // const tt = translateText(res?.customer?.companyName)
        // console.log(tt)
        setArabicCustomerName(res?.customer?.companyName)
        setArabicAddress(
          res?.customer?.billingAddress.addressLine1 +
            ' ' +
            data?.customer?.billingAddress.addressLine2 +
            ' ' +
            data?.customer?.billingAddress.city +
            ' ' +
            data?.customer?.billingAddress.postalCode +
            ' ' +
            data?.customer?.billingAddress.country
        )
      })
    }

    getinvoicedetails()
  }, [id])

  useEffect(() => {
    if (data?.tax) {
      const tax = (data?.subtotal * data?.tax) / 100
      setVat(tax)
    }
  }, [data])

  const [imageSize, setImageSize] = useState<boolean>(true)
  const handleOnLoad = (e) => {
    const img = e.target
    if (img.naturalWidth > img.naturalHeight) {
      setImageSize(true)
    } else {
      setImageSize(false)
    }
  }

  const formatCreatedAt = (createdAt: string | undefined) => {
    if (!createdAt) return 'N/A'
    const formattedDate = new Date(createdAt).toLocaleDateString('en-GB', {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
    })
    return formattedDate
  }

  const PF = 'https://qf-assets.s3.me-central-1.amazonaws.com/'

  const PageContent = Array.from({length: page}).map((_, idx) => (
    <div
      key={idx}
      id={`pdf-content-${idx}`}
      className='card overflow-hidden mx-auto !rounded-none'
      style={{
        height: '297mm',
        width: '210mm',
      }}
    >
      {/* begin::Body */}
      <div className='px-2 pb-0 mx-3 mt-2' id='pdfHeader'>
        <div className='d-flex justify-content-between align-items-center flex-column flex-sm-row'>
          {/*end::Logo*/}
          <div className='text-sm-end'>
            {/*begin::Logo*/}
            <a href='#' className={`d-block ${imageSize ? 'mw-275px' : 'mw-150px'} ms-sm-auto`}>
              {companyLogo ? (
                <img
                  alt='Logo'
                  src={URL.createObjectURL(companyLogo)}
                  className={imageSize ? 'w-100' : 'w-50'}
                  onLoad={handleOnLoad}
                />
              ) : (
                <img
                  alt='thumbnail'
                  src={
                    data?.company?.companyLogo === '17220654648841721057513722meclogo2.jpeg'
                      ? metalicaLogo
                      : PF + data?.company?.companyLogo
                  }
                  className={imageSize ? 'w-100' : 'w-50'}
                  onLoad={handleOnLoad}
                />
              )}
            </a>
            {/*end::Logo*/}
          </div>
          {/* <h4 className="fw-bold text-gray-800 fs-1  pb-7 ">Company Address</h4> */}
          <h4 className='fw-bold text-gray-800 xl:fs-1 d-flex flex-column text-right header-text'>
            <span
              className='mb-1 text-right fs-1 tracking-[1px]'
              style={{
                // fontFamily: 'Rubik',
                fontFamily: 'Scheherazade New',
                fontOpticalSizing: 'auto',
                fontStyle: 'normal',
                wordSpacing: '10px',
              }}
            >
              {data?.company?.arabicName}
            </span>
            <span
              className='mb-1 text-right fs-2 tracking-wider'
              style={{
                color: color,
              }}
            >
              {data?.company?.name}
            </span>
            <span className='fs-5 text-muted'>
              VAT {data?.company?.vat} <span style={{color: color}}>|</span> C.R.{' '}
              {data?.company?.cr}
            </span>
          </h4>
        </div>
      </div>

      {/* end::Top */}

      <div className='border-b-gray-700 border-b mx-9'></div>
      <div className='card-body px-lg-15 text-black'>
        {/* begin::Layout */}
        <div className='d-flex flex-column'>
          {/* begin::Content */}
          <div className='flex-lg-row-fluid mb-10 mb-xl-0'>
            {/* begin::Invoice 1 content */}
            <div className='mt-n1'>
              {/* begin::Top */}

              {/* begin::Wrapper */}
              <div className=''>
                {/*begin::Top*/}
                {idx === 0 && (
                  <div className='d-flex justify-content-between mb-6 fs-5 fw-bold text-gray-800'>
                    <div className='uppercase'>VAT Invoice</div>
                    <div
                      className='fs-4'
                      style={{
                        fontFamily: 'Rubik',
                      }}
                    >
                      فاتورة ضريبة{' '}
                    </div>
                  </div>
                )}
                {/*end::Top*/}
                {/*begin::Wrapper*/}
                <div className='m-0'>
                  {/*begin::Label*/}
                  {idx === 0 && (
                    <div
                      className='d-flex justify-content-between flex-column flex-sm-row fs-7'
                      style={{
                        textTransform: 'uppercase',
                      }}
                    >
                      <div className=' mb-5 '>
                        {/* <div className='d-flex  flex-column mb-7'>
                          <span className='fw-bold'>Issue Date</span>
                          <span className=''>{formatCreatedAt(data?.createdAt)}</span>
                        </div> */}
                        <div className='d-flex flex-column mb-7'>
                          <span className='fw-bold'>Company Name</span>

                          <span className=''>{data?.customer?.companyName}</span>
                        </div>
                        <div className='d-flex flex-column mb-7'>
                          <span className='fw-bold'>Company Address</span>
                          <span className='fs-7 mw-200px'>
                            {data?.customer?.billingAddress.addressLine1}{' '}
                            {data?.customer?.billingAddress.addressLine2}
                            <br />
                            {data?.customer?.billingAddress.city}{' '}
                            {data?.customer?.billingAddress.postalCode}{' '}
                            {data?.customer?.billingAddress.country}
                          </span>
                        </div>
                      </div>
                      <div className='  mb-5 '>
                        <div className='d-flex  flex-column mb-7'>
                          <span className='fw-bold min-w-100px'>Invoice Number</span>
                          <span className=''>{data?.id}</span>
                        </div>
                        <div className='d-flex  flex-column mb-7'>
                          <span className='fw-bold min-w-100px'>Invoice Date</span>
                          <span className=''>{formatCreatedAt(data?.date)}</span>
                        </div>
                        <div className=' d-flex flex-column mb-7'>
                          <span className='fw-bold'>PO Number</span>
                          <span className=''>{data?.lpo}</span>
                        </div>
                        {/* <div className=' d-flex flex-column mb-7'>
                          <span className='fw-bold'>VAT Number</span>
                          <span className='fs-7'>{data?.customer?.vatNumber}</span>
                        </div> */}
                      </div>
                      <div className='  mb-5  text-end'>
                        <div className='d-flex  flex-column mb-7'>
                          <span className='fw-bold arabic-font'>رقم الفاتورة</span>
                          <span className=''>{data?.id}</span>
                        </div>
                        <div className='d-flex  flex-column mb-7'>
                          <span className='fw-bold arabic-font'>تاريخ الفاتورة</span>
                          <span className=''>{formatCreatedAt(data?.date)}</span>
                        </div>
                        <div className=' d-flex flex-column mb-7'>
                          <span className='fw-bold arabic-font'>رقم أمر الشراء </span>
                          <span className='fs-6 arabic-font'>
                            {toArabicNumerals(data?.lpo?.trim())}
                          </span>
                        </div>
                        {/* <div className=' d-flex flex-column mb-7'>
                          <span className='fw-bold arabic-font'>رقم ضريبة </span>
                          <span className='fs-6 arabic-font'>
                            {toArabicNumerals(data?.customer?.vatNumber?.trim())}
                          </span>
                        </div> */}
                      </div>
                      <div className=' mb-5 text-end'>
                        {/* <div className='d-flex  flex-column mb-7'>
                          <span className='fw-bold arabic-font'>تاريخ الإصدار</span>
                          <span className=''>{formatCreatedAt(data?.createdAt)}</span>
                        </div> */}
                        <div className='d-flex flex-column mb-7'>
                          <span className='fw-bold arabic-font'>اسم الشركة</span>

                          <span className=' arabic-font'>
                            {data?.customer?._id === '6655c9a6de99757dd301bea7'
                              ? 'الحل النباتي'
                              : data?.customer?._id === '662e41a3058b891eed2c92c0'
                              ? 'شركة خدمات الصمامات الدقيقة'
                              : data?.customer?._id === '66b91ad25811c5aca7aca9c4' ||
                                data?.customer?._id === '66b918e65811c5aca7aca70c'
                              ? 'شركة الأدوات والتحكم المحدودة (ICCL)'
                              : data?.customer?._id === '66c456845811c5aca7ad11c7'
                              ? 'محمد علي رحان للهندسة'
                              : data?.customer?._id === '66d2c94a69e671e08139a249'
                              ? 'شركة الهندسة الدقيقة الحديثة'
                              : ''}
                          </span>
                        </div>
                        <div className='d-flex flex-column mb-7 '>
                          <span className='fw-bold arabic-font'>الشركة عنوان </span>
                          <span className='mw-200px arabic-font'>
                            {/* {data?.customer?.billingAddress.addressLine1}
                            {data?.customer?.billingAddress.addressLine2}
                            <br />
                            {data?.customer?.billingAddress.city}
                            {data?.customer?.billingAddress.postalCode}
                            {data?.customer?.billingAddress.country} */}
                            {data?.customer?._id === '6655c9a6de99757dd301bea7'
                              ? `أرامكو الخبري بالقرب من ملعب الفلاح للكريكيت
الجبيل ${toArabicNumerals('35441')} المملكة العربية السعودية`
                              : data?.customer?._id === '662e41a3058b891eed2c92c0'
                              ? `قطاع ٧/١٥٣ المنطقة الصناعية الخفيفة الجبيل - ٣١٩٦١
الجبيل ٣١٩٦١ المملكة العربية السعودية`
                              : data?.customer?._id === '66b91ad25811c5aca7aca9c4' ||
                                data?.customer?._id === '66b918e65811c5aca7aca70c'
                              ? `القطعة ٥٦، المجمع ٠٢، القسم G، الصناعات المساندة، الجبيل ٢، ص.ب ١١٣٠٠، الجبيل ٣١٩٦١. المملكة العربية السعودية
الجبيل، المملكة العربية السعودية`
                              : data?.customer?._id === '66c456845811c5aca7ad11c7'
                              ? `ص.ب ٧٣٣٣٤، الدمام ٣١٥٣٨، منطقة دلة الصناعية، المملكة العربية السعودية
الدمام، المملكة العربية السعودية`
                              : data?.customer?._id === '66d2c94a69e671e08139a249'
                              ? `الخبر العزيزية
الخبر، المملكة العربية السعودية`
                              : ''}
                          </span>
                        </div>
                      </div>
                      {/*end::Order details*/}
                      {/*begin::Billing & shipping*/}
                    </div>
                  )}
                  {/*end::Row*/}
                  {/*begin::Content*/}
                  <div className='flex-grow-1'>
                    {/*begin::Table*/}
                    <div className=''>
                      <table
                        className='table table-bordered m-0'
                        style={{
                          borderColor: '#020617',
                        }}
                      >
                        <thead>
                          <tr
                            className={`fs-7 fw-bold uppercase`}
                            style={{backgroundColor: `${color}`}}
                          >
                            <th className='min-w-50px text-white text-center pb-2  whitespace-nowrap'>
                              <span className='arabic-font'>رقم</span>
                              <br />
                              <span>S.No.</span>
                            </th>
                            <th className='min-w-175px text-white text-center pb-2  whitespace-nowrap'>
                              <span className='arabic-font'>وصف</span>
                              <br />
                              Description
                            </th>
                            <th className='min-w-80px text-white text-center pb-2 whitespace-nowrap'>
                              <span className='arabic-font'>وحدة </span>
                              <br />
                              UOM
                            </th>
                            <th className='min-w-70px text-white text-center pb-2 whitespace-nowrap'>
                              <span className='arabic-font'>كمية</span>
                              <br />
                              QTY
                            </th>
                            <th className='min-w-80px text-white text-center pb-2 whitespace-nowrap'>
                              <span className='arabic-font'>سعر الوحدة</span>
                              <br />
                              Unit Price
                            </th>
                            <th className='min-w-80px text-white text-center pb-2 whitespace-nowrap'>
                              <span className='arabic-font'>المجموع الفرعي</span>
                              <br />
                              Sub Total
                            </th>
                            <th className='min-w-80px text-white text-center pb-2 whitespace-nowrap'>
                              <span className='arabic-font'>قيمة الضريبة</span>
                              <br />
                              VAT Amount
                            </th>
                            <th className='min-w-80px text-white text-center pb-2 whitespace-nowrap'>
                              <span className='arabic-font'>مجموع</span>
                              <br />
                              Grand Total
                            </th>
                          </tr>
                        </thead>
                        <tbody className='' style={{height: '250px'}}>
                          {data?.items
                            ?.slice(
                              idx === 1 || idx === 0 ? idx * 10 : idx * 20 - 10,
                              idx === 0 ? 10 : idx * 20 + 10
                            )
                            .map((item, index) => (
                              <tr key={index} className='fw-semibold text-black fs-8 text-center'>
                                <td className='text-black'>
                                  {idx === 0
                                    ? index + 1
                                    : idx === 1
                                    ? index + 11
                                    : index + idx * 20 - 9}
                                </td>
                                <td className='text-black text-justify '>{item?.productName}</td>
                                <td className='text-black uppercase'>
                                  {item?.unit ||
                                    item?.itemId?.unit ||
                                    item?.itemsId?.unit ||
                                    'units'}
                                </td>
                                <td className='text-black'>{item.quantity}</td>
                                <td className='text-end text-black'>
                                  {Number(item?.price)?.toLocaleString('en-US', {
                                    minimumFractionDigits: 2,
                                  })}{' '}
                                </td>
                                <td className='text-end text-black'>
                                  {(item.quantity * item.price)?.toLocaleString('en-US', {
                                    minimumFractionDigits: 2,
                                  })}
                                </td>
                                <td className='text-end text-black'>
                                  {((item.amount * data?.tax) / 100)?.toLocaleString('en-US', {
                                    minimumFractionDigits: 2,
                                  })}
                                </td>
                                <td className='text-end text-black'>
                                  {Number(item?.amount || 0)?.toLocaleString('en-US', {
                                    minimumFractionDigits: 2,
                                  })}
                                </td>
                              </tr>
                            ))}
                          {(idx + 1 === page || page === 1) && (
                            <>
                              <tr className=''>
                                <td colSpan={4} className='p-0'>
                                  <table className='table w-full m-0 '>
                                    <tbody
                                      className='min-w-80px'
                                      style={{display: 'flex', flexDirection: 'column'}}
                                    >
                                      <tr className=''>
                                        <td colSpan={1.5} className='w-2/5 pe-0 py-0'>
                                          <div
                                            className='d-flex align-items-center'
                                            style={{
                                              height: heightRef?.current?.clientHeight * 3 + 'px',
                                            }}
                                          >
                                            <img
                                              src={
                                                data?.company?.vat?.length === 15
                                                  ? qrCode
                                                  : `/media/icons/duotune/layouts/lay001.svg`
                                              }
                                              alt=''
                                              className='h-130px w-150px p-5'
                                              // style={{
                                              //   width: 'auto',
                                              //   height: 'auto',
                                              //   padding: '1.5rem',
                                              // }}
                                            />
                                          </div>
                                        </td>
                                        <td className='text-start w-3/5 ps-0 py-0'>
                                          <div
                                            className='d-flex align-items-center'
                                            style={{
                                              height: heightRef?.current?.clientHeight * 3 + 'px',
                                            }}
                                          >
                                            <div className=''>
                                              <h6 className='mb-3 fw-bolder text-black fs-7'>
                                                BANK DETAILS
                                              </h6>
                                              <div className='mb-2 d-flex '>
                                                <div className='fw-bolder text-black fs-7 w-65px'>
                                                  Bank Name:
                                                </div>
                                                <div className='text-black fs-7'>
                                                  {bank.bankName}
                                                </div>
                                              </div>
                                              <div className='mb-2 d-flex '>
                                                <div className='fw-bolder text-black fs-7 w-65px'>
                                                  Account No:
                                                </div>
                                                <div className='text-black fs-7'>
                                                  {bank.accountNumber}
                                                </div>
                                              </div>
                                              <div className='mb-2 d-flex'>
                                                <div className='fw-bolder text-black fs-7 w-65px'>
                                                  IBAN No:
                                                </div>
                                                <div className='text-black fs-7'>
                                                  {bank.iBANNumber}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </td>
                                <td colSpan={2} className='p-0 text-sm'>
                                  <div className='d-flex flex-column justify-between w-100 h-100 uppercase fs-7 text-black'>
                                    <div
                                      className='border-b px-3 py-4 text-right'
                                      style={{
                                        borderColor: '#020617',
                                      }}
                                    >
                                      <p className='mb-0'>
                                        االمجموع الفرعي <br />
                                        Sub Total
                                      </p>
                                    </div>
                                    <div
                                      className='border-b px-3 py-4 text-right'
                                      style={{
                                        borderColor: '#020617',
                                      }}
                                    >
                                      <p className='mb-0'>
                                        القيمة الضريبية <br />
                                        Total VAT Amount ({data?.tax}%)
                                      </p>
                                    </div>
                                    <div className='px-3 py-4 text-right'>
                                      <p className='fw-bold mb-0'>
                                        المجموع الكلي <br />
                                        Grand Total
                                      </p>
                                    </div>
                                  </div>
                                </td>
                                <td colSpan={2} className='p-0 text-sm'>
                                  <div className='d-flex flex-column justify-between w-100 h-100 fs-7 text-black'>
                                    <div
                                      className='border-b  px-3 py-4  d-flex justify-between'
                                      ref={heightRef}
                                      style={{
                                        borderColor: '#020617',
                                      }}
                                    >
                                      <p className='ps-3 mb-0'>
                                        {}
                                        <br />
                                        {data?.customer?.currency}
                                      </p>
                                      <p className='text-end mb-0'>
                                        {}
                                        <br />
                                        {data?.subtotal?.toLocaleString('en-US', {
                                          minimumFractionDigits: 2,
                                        })}{' '}
                                      </p>
                                    </div>
                                    <div
                                      className='border-b  px-3 py-4  d-flex justify-between'
                                      style={{
                                        borderColor: '#020617',
                                      }}
                                    >
                                      <p className='ps-3 mb-0'>
                                        {}
                                        <br />
                                        {data?.customer?.currency}
                                      </p>
                                      <p className='text-end mb-0'>
                                        {}
                                        <br />
                                        {vat?.toLocaleString('en-US', {
                                          minimumFractionDigits: 2,
                                        })}
                                      </p>
                                    </div>
                                    <div className='px-3 py-4 d-flex justify-between'>
                                      <p className='ps-3 mb-0'>
                                        {}
                                        <br />
                                        {data?.customer?.currency}
                                      </p>
                                      <p className='text-end mb-0'>
                                        {}
                                        <br />
                                        {data?.total
                                          ? data?.total?.toLocaleString('en-US', {
                                              minimumFractionDigits: 2,
                                            })
                                          : 0}{' '}
                                      </p>
                                    </div>
                                  </div>
                                </td>
                                {/* Empty cell for design consistency */}
                              </tr>
                              <tr className='py-1'>
                                <td
                                  colSpan={8}
                                  className='fs-7'
                                  style={{
                                    height: heightRef?.current?.clientHeight + 'px',
                                  }}
                                >
                                  <div className='d-flex align-items-center h-full text-black'>
                                    <div>
                                      <span className='uppercase fw-bold'>Total in words</span>
                                      <br />
                                      <span className='fw-bold'>
                                        {data?.customer?.currency}
                                      </span>{' '}
                                      {/* {numberToWords(data?.total)}
                                      {data?.customer?.currency === 'SAR' && ' Riyals'} Only. */}
                                      {data?.customer?.currency === 'SAR'
                                        ? convertSARAmountToWords(data?.total)
                                        : `${numberToWords(data?.total)} Only.`}
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            </>
                          )}
                        </tbody>
                      </table>
                    </div>
                    {/*end::Table*/}
                    {/*begin::Container*/}
                    {/*end::Container*/}
                  </div>
                  {/*end::Content*/}
                </div>
                {/*end::Wrapper*/}
              </div>

              {/* end::Wrapper */}
            </div>
            {/* end::Invoice 2 content */}
          </div>
          {/* end::Content */}
          {/* begin::Sidebar */}
          {(idx + 1 === page || page === 1) && (
            <div className={`m-0 pt-8 px-10 py-5 `}>
              <div className='d-flex  justify-content-between '>
                <h6 className='mb-8 fw-bold text-black text-center'>
                  <span className='arabic-font fs-7 fw-normal'>أعدت بواسطة</span>
                  <br />
                  Prepared By
                  {data?.agent?.signature && data?.agent?.signature !== '' && (
                    <img
                      src={PF + data?.agent?.signature}
                      alt='signature'
                      className='w-80px h-80px mt-3'
                    />
                  )}
                </h6>

                <h6 className='mb-4 fw-bold text-black relative  text-center'>
                  <span className='arabic-font fw-normal fs-7'>اعتمدت بواسة</span>
                  <br />
                  Approved By
                  {data?.approval === 'accepted' &&
                    data?.company?.companySeal &&
                    data?.company?.companySeal !== '' && (
                      <img
                        src={PF + data?.company?.companySeal}
                        alt='company seal'
                        className='w-80px mt-3 h-80px aboslute top-5 right-5 z-1'
                      />
                    )}
                  {data?.approval === 'accepted' &&
                    data?.company?.companySignature &&
                    data?.company?.companySignature !== '' && (
                      <img
                        src={PF + data?.company?.companySignature}
                        alt='company signature'
                        className='w-80px h-80px absolute top-20 right-10 z-10'
                      />
                    )}
                </h6>

                <h6 className='mb-4 fw-bold text-black text-center'>
                  <span className='arabic-font fs-7 fw-normal'>استلمت من قبل</span>
                  <br />
                  Received By
                </h6>
              </div>
              {/* end::Invoice 2 sidebar */}
            </div>
          )}
          {/* end::Sidebar */}
        </div>
        {/* end::Layout */}
      </div>
      {/* end::Body */}
      <div id='pdfFooter' className='card-footer text-center py-2 px-0 fw-bold mb-1'>
        <div className={`bg-[#515560] ${minFooter && '-skew-x-12'} text-white`}>
          <div className={`${minFooter && 'skew-x-12'} py-1`}>
            <MdOutlineMailOutline
              style={{
                color,
              }}
              className='inline mr-2'
            />
            {data?.company?.companyEmail}
            <GoGlobe
              className='inline mr-2 ml-4'
              style={{
                color,
              }}
            />
            {data?.company?.webURL}
          </div>
        </div>
        <div
          className={`${minFooter && '-skew-x-12'} text-white `}
          style={{
            backgroundColor: `${color}`,
          }}
        >
          <div className={`${minFooter && 'skew-x-12'} py-1`}>
            P.O. Box : {data?.company?.pOBox} | Address: {data?.company?.companyAddress}
          </div>
        </div>
      </div>
      <div className='d-flex justify-center'>
        <p className='fs-7 mb-1'>
          Page {idx + 1} of {page}
        </p>
      </div>
    </div>
  ))

  return (
    <>
      <div className='app-content flex-column-fluid'>
        {/* begin::Content container */}

        <div id='kt_app_content_container' className='print-content container-xxl serif-font'>
          {/* begin::Invoice 2 main */}

          {PageContent}
          {/* end::Invoice 2 main */}
        </div>
        {/* end::Content container */}
      </div>
    </>
  )
}

export default Invoice
