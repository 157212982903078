import React, {useState, useEffect} from 'react'
import {KTIcon} from '../../../_metronic/helpers'
import {getExpenseBillsPayroll} from './core/_requests'
import {useAuth} from '../../modules/auth'
import {Link} from 'react-router-dom'

const ExpenseBillsPayroll = () => {
  const {currentUser} = useAuth()
  const [expenses, setExpenses] = useState([])
  const [payroll, setPayroll] = useState([])
  const [bills, setBills] = useState([])
  const [po, setPo] = useState([])
  const [pcrs, setPcrs] = useState([])

  const fetchData = async () => {
    try {
      const res = await getExpenseBillsPayroll(currentUser.organization)
      // const payrollResponse = await fetch('/api/payroll')
      // const billsResponse = await fetch('/api/bills')

      const expensesData = res?.expenses?.map((item) => ({
        description: item?.expenseAccount?.accountName,
        amount: item?.amount,
        date: item?.date,
        id: item?._id,
      }))
      const payrollData = res?.payrolls?.map((item) => ({
        employee: item?.employeeName,
        salary: item?.salary,
        date: item?.month,
        id: item?._id,
      }))
      const billsData = res?.bills?.map((item) => ({
        name: item?.id,
        amount: item?.total,
        date: item?.billDate,
        id: item?._id,
      }))
      const poData = res?.po?.map((item) => ({
        name: item?.id,
        amount: item?.total,
        date: item?.date,
        id: item?._id,
      }))
      const pcrData = res?.pcrs?.map((item) => ({
        employee: item?.employeeName,
        amount: item?.amount,
        date: item?.date,
        id: item?._id,
      }))

      setExpenses(expensesData)
      setPayroll(payrollData)
      setBills(billsData)
      setPo(poData)
      setPcrs(pcrData)
    } catch (error) {
      console.error('Error fetching data:', error)
    }
  }

  useEffect(() => {
    if (currentUser.organization !== undefined) fetchData()
  }, [currentUser])

  const formatCreatedAt = (createdAt: string | undefined) => {
    if (!createdAt) return 'N/A'
    const formattedDate = new Date(createdAt).toLocaleDateString('en-GB', {})
    return formattedDate
  }

  const ListItem = ({label, value, date, type, id}) => (
    <Link
      to={
        type === 'expense'
          ? '/account/expenses'
          : type === 'payroll'
          ? `/hrm/payroll/${date}`
          : type === 'bills'
          ? `/procurement/billsList/${id}`
          : type === 'purchaseOrder'
          ? `/procurement/purchaseOrdersList/${id}`
          : '/account/pcr'
      }
      className='flex items-center justify-between py-2 pl-2 border-b last:border-b-0'
    >
      <span className='text-gray-800'>{formatCreatedAt(date)}</span>
      <span className='text-gray-800'>{label}</span>
      <span className='font-semibold text-blue-600'>SAR {value.toFixed(2)}</span>
    </Link>
  )

  const Section = ({title, iconName, data, itemKey, valueKey, date = 'date', type, id = 'id'}) => (
    <section className='mb-6 bg-white rounded-lg shadow-md p-4'>
      <div className='flex items-center mb-3'>
        <KTIcon iconName={iconName} className='mr-2 fs-4 text-info' />
        <h3 className='text-lg font-semibold text-gray-800 mb-0'>{title}</h3>
      </div>
      {data?.length > 0 ? (
        <ul className='space-y-1 max-h-80 overflow-y-scroll'>
          {data?.map((item, index) => (
            <ListItem
              key={index}
              label={item[itemKey]}
              value={item[valueKey]}
              date={item[date]}
              type={type}
              id={item[id]}
            />
          ))}
        </ul>
      ) : (
        <div className='text-center text-gray-800 my-3'>No Data</div>
      )}
    </section>
  )

  return (
    <div className='card w-100 h-100 overflow-scroll p-6'>
      <h2 className='text-xl font-bold mb-4'>Financial Overview (Last 7 days)</h2>

      <Section
        title='Expenses'
        iconName='dollar'
        data={expenses}
        itemKey='description'
        valueKey='amount'
        type='expense'
      />
      <Section
        title='Payroll'
        iconName='user'
        data={payroll}
        itemKey='employee'
        valueKey='salary'
        type='payroll'
      />
      <Section
        title='Bills'
        iconName='file'
        data={bills}
        itemKey='name'
        valueKey='amount'
        type='bills'
      />
      <Section
        title='Purchase Order'
        iconName='notification-2'
        data={po}
        itemKey='name'
        valueKey='amount'
        type='purchaseOrder'
      />
      <Section
        title='Petty Cash Request'
        iconName='wallet'
        data={pcrs}
        itemKey='employee'
        valueKey='amount'
        type='pcr'
      />
    </div>
  )
}

export default ExpenseBillsPayroll
