import * as Yup from 'yup'

export interface ITeam {
  employee: string
  name: string
  email: string
  // role: 'guest' | 'owner' | 'canEdit';
}

export interface ITarget {
  targetTitle: string
  assignedTo: string // Assuming employee ID
  dueDate: Date
  targetDetails: string
  tags: string[]
  targetChangeInBudget: boolean
  targetNotification: {
    email: boolean
    phone: boolean
  }
}

export interface IProduct {
  itemId: string
  price: string
  quantity: number
  amount: string
  type: string
  selectedOption: any
  category?: string
}
export interface ICreateAccount {
  projectType: string
  customer: string
  currency: string
  projectName: string
  projectDescription: string
  startDate: Date
  releaseDate: Date
  notification: {
    email: boolean
    phone: boolean
  }
  budgetAmount: number
  changeInBudget: boolean
  team: ITeam[]
  target: ITarget[]
  assignedAgents: string[]
  items: IProduct[]
  rentals: IProduct[]
  consumables: IProduct[]
  materials: IProduct[]
  budgetNotes: string
  morningShiftValue: number
  eveningShiftValue: number
  morningShiftBoolean: boolean
  eveningShiftBoolean: boolean
  totalCalculatedHours: number
  totalCalculatedDays: number
  productSubTotal: number
  totalHourlyCharges: number
}

const createAccountSchemas = [
  Yup.object({
    projectType: Yup.string().required().label('Project Type'),
  }),
  Yup.object({
    customer: Yup.string().required().label('Customer Name'),
    projectName: Yup.string().required().label('Project Name'),
    projectDescription: Yup.string().required().label('Project Description'),
    startDate: Yup.date().required().label('Start Date'),
    releaseDate: Yup.date().required().label('Release Date'),

    morningShiftValue: Yup.number().when('morningShiftBoolean', (morningShiftBoolean, schema) => {
      console.log('Morning shift boolean value:', morningShiftBoolean)
      return morningShiftBoolean ? schema.required('Morning shift hours are required') : schema
    }),

    eveningShiftValue: Yup.number().when('eveningShiftBoolean', (eveningShiftBoolean, schema) => {
      return eveningShiftBoolean ? schema.required('Evening shift hours are required') : schema
    }),

    notification: Yup.object({
      email: Yup.boolean().label('Email'),
      phone: Yup.boolean().label('Phone'),
    }),
  }),
  Yup.object({
    budgetAmount: Yup.number().required().label('Budget Amount'),
    changeInBudget: Yup.boolean().required().label('Change In Budget'),
    status: Yup.string().default('In progress').label('Status'),
  }),
  Yup.object().shape({
    items: Yup.array().of(
      Yup.object().shape({
        itemId: Yup.string().required().label('Item ID'),
        price: Yup.string().required().label('Price'),
        quantity: Yup.number().required().label('Quantity'),
        amount: Yup.string().required().label('Amount'),
        type: Yup.string().required().label('Type'),
        selectedOption: Yup.object().shape({
          value: Yup.string().required().label('Value'),
          label: Yup.string().required().label('Label'),
          inWarehouseQuantity: Yup.number().required().label('In Warehouse Quantity'),
          price: Yup.string().required().label('Price'),
        }),
        category: Yup.string(),
      })
    ),
  }),
  Yup.object().shape({
    team: Yup.array().of(
      Yup.object().shape({
        name: Yup.string().required().label('name'),
        // role: Yup.string().oneOf(['guest', 'owner', 'canEdit']).required().label('Role'),
      })
    ),
    target: Yup.array().of(
      Yup.object().shape({
        targetTitle: Yup.string().label('Target Title'),
        assignedTo: Yup.string().required().label('Assigned To'),
        dueDate: Yup.date().required().label('Due Date'),
        targetDetails: Yup.string().label('Target Details'),
        tags: Yup.array().of(Yup.string()).label('Tags'),
        targetChangeInBudget: Yup.boolean().required().label('Target Change In Budget'),
        targetNotification: Yup.object({
          email: Yup.boolean().label('Email'),
          phone: Yup.boolean().label('Phone'),
        }).label('Target Notification'),
      })
    ),
  }),
  Yup.object().shape({
    assignedAgents: Yup.array().of(Yup.string()).label('Assigned Agents'),
  }),
]
const today = new Date()
const inits: ICreateAccount = {
  projectType: '',
  customer: '', // Changed from 'customerName'
  currency: '',
  projectName: '',
  projectDescription: '',
  startDate: null,
  releaseDate: null,

  notification: {
    email: false, // New field added
    phone: false, // New field added
  },
  budgetAmount: null,
  changeInBudget: false, // New field added
  team: [], // Renamed from 'teamMembers'
  target: [], // New field added
  assignedAgents: [],
  items: [],
  rentals: [],
  consumables: [],
  materials: [],
  budgetNotes: '',
  morningShiftValue: 0,
  eveningShiftValue: 0,
  morningShiftBoolean: false,
  eveningShiftBoolean: false,
  totalCalculatedHours: null,
  totalCalculatedDays: null,
  productSubTotal: 0,
  totalHourlyCharges: 0,
}

export {createAccountSchemas, inits}
