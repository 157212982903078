import React, {useEffect, useRef, useState} from 'react'
import Select from 'react-select'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd'
import Flatpickr from 'react-flatpickr'
import 'flatpickr/dist/themes/material_blue.css' // Choose a theme from the flatpickr themes folder
import 'flatpickr/dist/flatpickr.min.css'

import {useAuth} from '../../../modules/auth/core/Auth'
import {KTIcon, KTSVG} from '../../../../_metronic/helpers'
import {useNavigate, useParams} from 'react-router-dom'
import {
  getCustomer,
  getCustomerByStatusForAgent,
  getItemsByType,
  getOrderById,
  getQuoteLastId,
  getStocksForSales,
  postQuote,
} from '../Core/_requests'
import {getEmployeeByStatus} from '../../hrm/core/_requests'
import {uploaddocsinqf} from '../../Inventory/Core/_requests'
import {getNotes, getTerms} from '../../account_settings/core/_request'
import AddProduct from './Quote/AddProduct'
import {getProjectById} from '../../projects/core/_requests'
import {useSelector, useDispatch} from 'react-redux'
import {RootState} from '../../../redux/store'
import {setSalesStock} from '../../../redux/inventory/products/stockSlice'
import {setServices} from '../../../redux/inventory/products/servicesSlice'
import {unitsList} from '../../../utils/units'

function Createquote() {
  const {currentUser} = useAuth()
  const navigate = useNavigate()
  const params = useParams()
  const dispatch = useDispatch()
  const salesStock = useSelector((state: RootState) => state.inventory.stock.salesStock)
  const servicesList = useSelector((state: RootState) => state.inventory.services.services)
  const [isLoading, setisLoading] = useState(false)
  const [filename, setfilename] = useState('')
  const [excelFile, setExcelFile] = useState(null)
  const fileInputRef = useRef(null)
  const [permissions, setPermissions] = useState<any>({})
  const [customerList, setCustomerList] = useState<any>([])
  const [employeeList, setEmployeeList] = useState<any>([])
  const [productList, setProductList] = useState<any>([])
  const [selectedBillFrom, setselectedBillFrom] = useState(null)
  const [selectedBillTo, setselectedBillTo] = useState(null)
  const [isEditingNotes, setIsEditingNotes] = useState(false)
  const [isEditingTerms, setIsEditingTerms] = useState(false)
  const [isEditingDescription, setIsEditingDescription] = useState(false)
  const [termsTextareaHeight, setTermsTextareaHeight] = useState('3rem')
  const [notesTextareaHeight, setNotesTextareaHeight] = useState('3rem')
  const [loading, setLoading] = useState(false)
  const [selectedCustomerCurrency, setSelectedCustomerCurrency] = useState('')
  const [showModal, setShowModal] = useState(false)
  const [salesOrderNumber, setSalesOrderNumber] = useState('')
  const [selectedOption, setSelectedOption] = useState('autoGenerate') // Default selected option
  const [prefix, setPrefix] = useState('')
  const [nextNumber, setNextNumber] = useState('')
  const [selectYearAndMonth, setSelectYearAndMonth] = useState(new Date())
  const [customID, setCustomID] = useState('')
  const [textHeight, setTextHeight] = useState('6rem')
  const [termsTop, setTermsTop] = useState('3rem')
  const [contactPersonList, setContactPersonList] = useState<any>([])
  const [newItem, setNewItem] = useState<any>([
    {
      productName: '',
      price: '',
      unit: '',
      quantity: 0,
      discount: 0,
      amount: '',
      rfqDescription: '',
    },
  ])
  const [selectFromInventory, setSelectFromInventory] = useState(false)

  const [quote, setQuote] = useState({
    date: new Date(),
    employee: '',
    customer: '',
    contactPerson: '',
    subject: ' ',
    rfqNumber: '',
    description: '',
    id: '',
    subtotal: 0,
    tax: 0,
    discount: 0,
    total: 0,
    notes: ' ',
    termsNCondition: ' ',
    docAttached: '',
  })

  const [items, setItems] = useState([
    {
      itemId: '',
      price: '',
      quantity: 0,
      discount: 0,
      amount: '',
      selectedOption: {
        value: '',
        label: '',
        inWarehouseQuantity: 0,
      },
      type: 'product',
      rfqDescription: '',
      productName: '',
    },
  ])

  useEffect(() => {
    if (currentUser?.permissions) {
      setPermissions(currentUser?.permissions)
    }
  }, [currentUser])

  const handleDragEnd = (result) => {
    if (!result.destination) return // dropped outside the list

    if (items.length > 1) {
      const itemsCopy = [...items]
      const [reorderedItem] = itemsCopy.splice(result.source.index, 1)
      itemsCopy.splice(result.destination.index, 0, reorderedItem)

      // Update your state with the reordered items
      setItems(itemsCopy)
    } else if (newItem.length > 1) {
      const itemsCopy = [...newItem]
      const [reorderedItem] = itemsCopy.splice(result.source.index, 1)
      itemsCopy.splice(result.destination.index, 0, reorderedItem)

      // Update your state with the reordered items
      setNewItem(itemsCopy)
    }
  }

  const getOrderDetails = async () => {
    const response = await getOrderById(params.id)
    const res = response[0]
    console.log(res)
    setselectedBillFrom({value: res?.employee?._id, label: res?.employee?.firstName})
    setselectedBillTo({value: res?.customer?._id, label: res?.customer?.displayName})
    setContactPersonList(
      res?.customer?.contactPersons?.map((r) => ({value: r._id, label: r.firstName})) || []
    )

    setQuote((prev) => ({
      ...prev,
      employee: res?.employee?._id,
      customer: res?.customer?._id,
      contactPerson: res?.customer?.contactPersons?.[0]?._id,
    }))

    if (res?.project?._id) {
      const resp = await getProjectById(res.project?._id)
      const itemsList = resp.items?.map((r) => ({
        value: r?.itemId?._id,
        label: r?.itemId?.productName, // Corrected the typo here from "labe" to "label"
        price: r?.price,
        inWarehouseQuantity: r.quantity,
        type: 'product',
      }))
      setProductList(itemsList)
    } else {
      getProductlist()
    }
  }

  useEffect(() => {
    if (params.id !== undefined) getOrderDetails()
  }, [params.id])

  useEffect(() => {
    if (quote.termsNCondition === '') {
      setTermsTextareaHeight('6rem')
    }
  }, [quote.termsNCondition])

  useEffect(() => {
    if (quote.notes === '') {
      setNotesTextareaHeight('6rem')
      setTermsTop('3rem')
    }
  }, [quote.notes])

  useEffect(() => {
    if (quote.description === '') {
      setTextHeight('6rem')
    }
  }, [quote.description])

  const handleFileChange = (event) => {
    const file = event.target.files[0]
    setfilename(event.target.files[0].name)
    setExcelFile(file)
  }

  const handleUpload = async () => {
    if (!excelFile) {
      console.error('No file selected')
      return
    }

    const timestamp = Date.now()

    const formData = new FormData()
    formData.append('file', excelFile)
    formData.append('name', quote.rfqNumber)
    formData.append('filename', timestamp + filename)
    try {
      setisLoading(true)
      const response = await uploaddocsinqf(formData)

      if (response === 'success') {
        console.log('File uploaded successfully')
        alert('File uploaded successfully')
        setQuote((prev) => {
          return {
            ...prev,
            docAttached: timestamp + filename,
          }
        })
        // Optionally, update UI to indicate successful upload
      } else {
        alert('failed to upload please try again')
        // Optionally, update UI to indicate failure
      }
    } catch (error) {
      console.error('Error:', error)
      setisLoading(false)
    }
  }

  useEffect(() => {
    const fetchQuote = async () => {
      try {
        const QuotesNotes = await getNotes(currentUser?.organization, 'Quotation')
        const QuotesTerms = await getTerms(currentUser?.organization, 'Quotation')

        setQuote((prev) => {
          return {
            ...prev,
            notes: QuotesNotes[0]?.notes || '',
            termsNCondition: QuotesTerms?.terms || '',
          }
        })
      } catch (error) {
        console.error('Error fetching delivery notes:', error)
      }
    }

    fetchQuote()
  }, [])

  const getCustomerList = async () => {
    try {
      const res = await getCustomer('', currentUser?.organization)
      console.log(res)
      setCustomerList(
        res.map((r) => ({
          value: r?._id,
          label: r?.displayName,
          currency: r?.currency,
          contactPersons: r?.contactPersons,
        }))
      )
    } catch (error) {
      console.error('Error fetching client list:', error)
    }
  }

  const getCustomerForAgent = async () => {
    try {
      const res = await getCustomerByStatusForAgent('', currentUser?._id)
      console.log(res)
      setCustomerList(
        res.map((r) => ({
          value: r?._id,
          label: r?.displayName,
          currency: r?.currency,
          contactPersons: r?.contactPersons,
        }))
      )
    } catch (error) {
      console.error('Error fetching client list:', error)
    }
  }

  const getEmployeeList = async () => {
    try {
      const queryString = 'search=&agent=&filter_status=true'
      console.log(queryString)
      const res = await getEmployeeByStatus(queryString, currentUser?.organization)
      console.log(res)
      setEmployeeList(
        res.map((r) => ({
          value: r._id,
          label: r.firstName, // Corrected the typo here from "labe" to "label"
          contactPersons: r.contactPersons,
        }))
      )
    } catch (error) {
      console.error('Error fetching client list:', error)
    }
  }

  const getProductlist = async () => {
    try {
      let salesStockData = []
      if (salesStock.length === 0) {
        const res = await getStocksForSales(currentUser?.organization)
        console.log(res)
        salesStockData = res.map((r) => ({
          value: r._id,
          label: r.productName,
          price: r.price,
          inWarehouseQuantity: r.inWarehouseQuantity,
          type: 'product',
        }))
        dispatch(setSalesStock(res))
      } else {
        salesStockData = salesStock.map((r) => ({
          value: r._id,
          label: r.productName,
          price: r.price,
          inWarehouseQuantity: r.inWarehouseQuantity,
          type: 'product',
        }))
      }

      let serviceData = []
      if (servicesList.length === 0) {
        const resp = await getItemsByType('service', currentUser?.organization)
        serviceData = resp.map((r) => ({
          value: r._id,
          label: r.name,
          price: r.price,
          inWarehouseQuantity: 1,
          type: 'item',
        }))
        dispatch(setServices(resp))
      } else {
        serviceData = servicesList.map((r) => ({
          value: r._id,
          label: r.name,
          price: r.price,
          inWarehouseQuantity: 1,
          type: 'item',
        }))
      }
      setProductList((prev) => {
        const updatedData = [...salesStockData, ...serviceData]
        return updatedData
      })
    } catch (error) {
      console.error('Error fetching client list:', error)
    }
  }

  const getQuotesID = async () => {
    try {
      const res = await getQuoteLastId(currentUser?.organization)
      setNextNumber(res.lastId + 1)
      const lastPrefix = res?.prefix.slice(0, res?.prefix?.trim()?.length - 7)
      setPrefix(lastPrefix)
      setSalesOrderNumber(`${res?.prefix}${res?.lastId + 1}`)
    } catch (error) {
      console.error('Error fetching client list:', error)
    }
  }

  const handleEditClick = (e, field) => {
    e.preventDefault()
    if (field === 'notes') {
      setIsEditingNotes(!isEditingNotes)
      setIsEditingTerms(false)
      setIsEditingDescription(false)
    } else if (field === 'termsNCondition') {
      setIsEditingTerms(!isEditingTerms)
      setIsEditingNotes(false)
      setIsEditingDescription(false)
    } else if (field === 'description') {
      setIsEditingDescription(!isEditingDescription)
      setIsEditingTerms(!isEditingTerms)
      setIsEditingNotes(false)
    }
  }

  useEffect(() => {
    if (permissions?.quotation?.admin === false) {
      getCustomerForAgent()
    } else if (
      permissions?.quotation?.admin === true ||
      currentUser?.profileType === 'superadmin'
    ) {
      getCustomerList()
    }
  }, [permissions])

  useEffect(() => {
    getEmployeeList()
    getQuotesID()
  }, [])

  const handleChange = (e) => {
    e.preventDefault()
    const {name, value} = e.target
    if (name === 'notes' && value.trim() === '') {
      setQuote((prev) => ({
        ...prev,
        notes: '• ' + value,
      }))
    } else {
      setQuote((prevInvoice) => ({
        ...prevInvoice,
        [name]: value,
      }))
    }
    if (name === 'termsNCondition') {
      setTermsTextareaHeight(`${e.target.scrollHeight}px`)
    } else if (name === 'notes') {
      setNotesTextareaHeight(`${e.target.scrollHeight}px`)
    } else if (name == 'description') {
      setTextHeight(`${e.target.scrollHeight}px`)
    }
  }

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      setQuote((prev) => ({
        ...prev,
        notes: prev.notes + '\n• ',
      }))

      setNotesTextareaHeight(`${event.target.scrollHeight}px`)
    }
  }

  const handleChangerows = (index, e) => {
    const {name, value} = e.target
    setItems((prevItems) => {
      const updatedItems = [...prevItems]
      updatedItems[index] = {
        ...updatedItems[index],
        [name]: value,
      }
      return updatedItems
    })
    setItems((prevItems) => {
      const updatedItems = [...prevItems]
      const data: any = (
        Number(prevItems[index].price) * Number(prevItems[index].quantity) -
        Number(prevItems[index].discount)
      ).toFixed(2)

      updatedItems[index] = {
        ...updatedItems[index],
        amount: data,
      }
      return updatedItems
    })
  }

  const handleSelectChangeInRows = (index, selectedOption) => {
    setItems((prevItems) => {
      const updatedItems = [...prevItems]
      updatedItems[index] = {
        ...updatedItems[index],
        itemId: selectedOption.value,
        productName: selectedOption.label,
        selectedOption,
        price: selectedOption.price,
        amount: selectedOption.price,
        quantity: 1,
        type: selectedOption.type,
      }
      return updatedItems
    })
  }

  useEffect(() => {
    setQuote((prevInvoice) => {
      let sum = items.reduce((acc, item) => {
        return acc + parseFloat(item.amount || '0')
      }, 0)
      sum += newItem.reduce((acc, item) => {
        return acc + parseFloat(item.amount || '0')
      }, 0)
      return {
        ...prevInvoice,
        subtotal: sum,
      }
      // You can add other properties if needed
    })
  }, [items, newItem])

  useEffect(() => {
    setQuote((prevInvoice) => {
      const subtotal = prevInvoice.subtotal || 0
      const tax = prevInvoice.tax || 0

      const discount = prevInvoice.discount || 0
      const discountAmount = (subtotal * discount) / 100
      const taxAmount = (subtotal * tax) / 100
      const total = (subtotal + taxAmount - discountAmount).toFixed(2)
      return {
        ...prevInvoice,
        total: parseFloat(total),
        // You can add other properties if needed
      }
    })
  }, [items, quote.tax, newItem, quote.discount])

  const handleBillToChange = (selectedOption) => {
    setselectedBillTo(selectedOption)

    setContactPersonList(
      selectedOption.contactPersons?.map((r) => ({value: r._id, label: r.firstName})) || []
    )

    setQuote((prevInvoice) => ({
      ...prevInvoice,
      customer: selectedOption.value,
      contactPerson: selectedOption.contactPersons?.[0]?._id,
    }))

    const selectedCustomer = customerList.find(
      (customer) => customer.value === selectedOption.value
    )
    if (selectedCustomer) {
      setSelectedCustomerCurrency(selectedCustomer.currency)
    }
  }

  const handleBillFromChange = (selectedOption) => {
    setselectedBillFrom(selectedOption)
    setQuote((prevInvoice) => ({
      ...prevInvoice,
      employee: selectedOption.value,
    }))
  }

  const handleDateChange = (selectedDate) => {
    setQuote((prevInvoice) => ({
      ...prevInvoice,
      date: selectedDate[0], // Flatpickr returns an array of selected dates
    }))
  }

  const handleSubmit = async () => {
    if (!excelFile) {
      setQuote((prev) => {
        return {...prev, rfqNumber: ''}
      })
    }
    if (quote.customer === '') {
      alert('Please select a customer')
      return
    } else if (quote.employee === '') {
      alert('Please select an employee')
      return
    } else if (selectFromInventory && items[0].itemId === '') {
      alert('Please select an item')
      return
    } else if (!selectFromInventory && newItem[0].productName === '') {
      alert('Please enter an item name')
      return
    }

    setLoading(true)

    const itemsList = items.map((item) => {
      return {
        itemId: item.type === 'product' ? item.itemId : null,
        itemsId: item.type === 'item' ? item.itemId : null,
        productName: item.productName,
        price: item.price,
        quantity: item.quantity,
        discount: item.discount,
        amount: item.amount,
        rfqDescription: item.rfqDescription,
        type: item.type,
      }
    })

    const year = String(selectYearAndMonth.getFullYear() - 2000).padStart(2, '0')
    const month = String(selectYearAndMonth.getMonth() + 1).padStart(2, '0')
    const dNM = `${prefix}-${year}-${month}-`

    const data = {
      ...quote,
      items: selectFromInventory ? itemsList : newItem,
      itemsFromInventory: selectFromInventory,
      prefix: dNM,
      customID: customID,
      id: nextNumber,
      approval: permissions?.quotation?.enable ? 'accepted' : 'pending',
      organization: currentUser?.organization,
      company: currentUser?.company,
      agent: currentUser?._id,
    }
    console.log(data)
    try {
      await postQuote(data, params.id).then((res) => {
        console.log(res)
        navigate(`/sales/quotes/${res._id}`)
      })
    } catch (error) {
      console.log(error)
    }
  }

  const handleButtonClick = () => {
    fileInputRef.current.click()
  }

  const additem = () => {
    setItems([
      ...items,
      {
        itemId: '',
        price: '',
        quantity: 0,
        discount: 0,
        amount: '',
        selectedOption: {
          value: '',
          label: '',
          inWarehouseQuantity: 0,
        },
        type: 'product',
        rfqDescription: '',
        productName: '',
      },
    ])
  }

  const removeitem = (index) => {
    if (items.length === 1) {
      setItems([
        {
          itemId: '',
          price: '',
          quantity: 0,
          discount: 0,
          amount: '',
          selectedOption: {
            value: '',
            label: '',
            inWarehouseQuantity: 0,
          },
          type: 'product',
          rfqDescription: '',
          productName: '',
        },
      ])
      return
    }
    const temp = [...items]
    temp.splice(index, 1)
    setItems(temp)
  }

  //functions for Custom invoice no
  const handleCloseModal = () => {
    setShowModal(false)
  }

  const handleChangeinNewItem = (index, e) => {
    const {name, value} = e.target
    setNewItem((prevItems) => {
      const updatedItems = [...prevItems]
      updatedItems[index] = {
        ...updatedItems[index],
        [name]: value,
      }

      const total =
        updatedItems[index].price * updatedItems[index].quantity - updatedItems[index].discount
      updatedItems[index] = {
        ...updatedItems[index],
        amount: total,
      }

      return updatedItems
    })
  }

  const handleSaveChanges = () => {
    let newSalesOrderNumber = ''
    if (selectedOption === 'autoGenerate') {
      setPrefix(prefix)
      setNextNumber(nextNumber)
      setCustomID('')
      const year = String(selectYearAndMonth.getFullYear() - 2000).padStart(2, '0')
      const month = String(selectYearAndMonth.getMonth() + 1).padStart(2, '0')
      const dNM = `${year}-${month}`
      newSalesOrderNumber = `${prefix}-${dNM}-${nextNumber}`
    } else if (selectedOption === 'manualInput') {
      setCustomID(salesOrderNumber)
      setPrefix('')
      setNextNumber('')
      newSalesOrderNumber = salesOrderNumber
    }
    setSalesOrderNumber(newSalesOrderNumber)
    setShowModal(false)
  }

  useEffect(() => {
    if (customID) {
      setSalesOrderNumber(customID)
    }
  }, [customID])

  return (
    <div id='kt_app_content' className='app-content flex-column-fluid '>
      {/* begin::Content container */}
      <div id='kt_app_content_container' className='app-container container-xxl'>
        {/* begin::Layout */}
        <div className='d-flex flex-column flex-lg-row'>
          {/* begin::Content */}
          <div className='flex-lg-row-fluid mb-10 mb-lg-0 me-lg-7 me-xl-10'>
            {/* begin::Card */}

            <div className='card'>
              {/* begin::Card body */}
              <div className='card-body p-12'>
                {/* begin::Form */}
                <form action='' id='kt_quote_form'>
                  {/* begin::Wrapper */}
                  <div className='d-flex flex-column align-items-start flex-xxl-row'>
                    {/* begin::Input group */}
                    <div
                      className='d-flex align-items-center flex-equal fw-row me-4 order-2'
                      data-bs-toggle='tooltip'
                      data-bs-trigger='hover'
                      title='Specify quote date'
                    >
                      {/* begin::Date */}
                      <div className='fs-6 fw-bold text-gray-700 text-nowrap'>Date:</div>
                      {/* Date */}
                      {/* begin::Input */}
                      <div className='position-relative d-flex align-items-center w-150px'>
                        {/* begin::Datepicker */}
                        <Flatpickr
                          value={quote.date}
                          onChange={handleDateChange}
                          className='form-control form-control-solid'
                          placeholder='Pick date'
                          options={{
                            dateFormat: 'd-m-Y',
                          }}
                        />
                      </div>
                    </div>

                    <div
                      className='d-flex flex-center flex-equal fw-row text-nowrap order-1 order-xxl-2 me-4'
                      data-bs-toggle='tooltip'
                      data-bs-trigger='hover'
                      title='Enter quote number'
                    >
                      <span className='fs-2x fw-bold text-gray-800 '>Quotation #</span>

                      <input
                        type='text'
                        className='form-control form-control-flush fw-bold text-muted fs-3 w-200px'
                        value={salesOrderNumber}
                        placeholder='Quotation #'
                        readOnly
                      />

                      <button
                        className='btn btn-light ms-2'
                        onClick={(e) => {
                          e.preventDefault()
                          setShowModal(true)
                        }}
                      >
                        <KTIcon iconName='gear' />
                      </button>
                      <div>
                        {showModal && (
                          <div
                            className='modal'
                            tabIndex={-1}
                            role='dialog'
                            style={{display: 'block', backgroundColor: 'rgba(0, 0, 0, 0.5)'}}
                          >
                            <div className='modal-dialog modal-dialog-centered' role='document'>
                              <div className='modal-content'>
                                <div className='modal-header'>
                                  <h5 className='modal-title'>Customize Quotation No.</h5>
                                  <button
                                    type='button'
                                    className='btn-close'
                                    onClick={handleCloseModal}
                                  ></button>
                                </div>
                                <div className='modal-body'>
                                  {/* Your modal body content goes here */}
                                  <p>How would you like to Quotation numbers?</p>
                                  <div className='form-check'>
                                    <input
                                      className='form-check-input'
                                      type='radio'
                                      name='salesOrderOption'
                                      id='autoGenerate'
                                      value='autoGenerate'
                                      checked={selectedOption === 'autoGenerate'}
                                      onChange={() => setSelectedOption('autoGenerate')}
                                    />
                                    <label className='form-check-label' htmlFor='autoGenerate'>
                                      Continue auto-generating Quotation numbers
                                    </label>
                                  </div>
                                  <div className='form-check'>
                                    <input
                                      className='form-check-input'
                                      type='radio'
                                      name='salesOrderOption'
                                      id='manualInput'
                                      value='manualInput'
                                      checked={selectedOption === 'manualInput'}
                                      onChange={() => setSelectedOption('manualInput')}
                                    />
                                    <label className='form-check-label' htmlFor='manualInput'>
                                      Enter Quotation numbers manually
                                    </label>
                                  </div>

                                  {/* Additional input fields for manual input */}
                                  {selectedOption === 'autoGenerate' && (
                                    <div className='mt-3'>
                                      <div className='mb-3'>
                                        <label className='form-label'>Prefix:</label>
                                        <input
                                          type='text'
                                          className='form-control'
                                          value={prefix}
                                          onChange={(e) => setPrefix(e.target.value)}
                                          placeholder='Enter Prefix'
                                        />
                                      </div>
                                      <div className='mb-3'>
                                        <label className='form-label'>Month & Year:</label>
                                        <br />
                                        <DatePicker
                                          selected={selectYearAndMonth}
                                          onChange={(date) => setSelectYearAndMonth(date)}
                                          dateFormat='yyyy/MM'
                                          showMonthYearPicker
                                          className='form-control'
                                        />
                                      </div>
                                      <div className='mb-3'>
                                        <label className='form-label'>Next Number:</label>
                                        <input
                                          type='number'
                                          className='form-control'
                                          value={nextNumber}
                                          onChange={(e) => setNextNumber(e.target.value)}
                                          placeholder='Enter Next Number'
                                        />
                                      </div>
                                    </div>
                                  )}
                                  {selectedOption === 'manualInput' && (
                                    <div className='mt-3'>
                                      <div className='mb-3'>
                                        <label className='form-label'>QUOTATION NO:</label>
                                        <input
                                          type='text'
                                          className='form-control'
                                          value={salesOrderNumber}
                                          onChange={(e) => setSalesOrderNumber(e.target.value)}
                                          placeholder='Enter Quotation Number'
                                        />
                                      </div>
                                    </div>
                                  )}
                                </div>
                                <div className='modal-footer'>
                                  <button
                                    type='button'
                                    className='btn btn-secondary'
                                    onClick={handleCloseModal}
                                  >
                                    Close
                                  </button>
                                  <button
                                    type='button'
                                    className='btn btn-primary'
                                    onClick={handleSaveChanges}
                                  >
                                    Save changes
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        {/* End of modal content */}
                      </div>
                    </div>
                    {/* Input group */}
                    {/* begin::Input group */}
                    <div
                      className='d-flex align-items-center justify-content-end flex-equal order-3 fw-row'
                      data-bs-toggle='tooltip'
                      data-bs-trigger='hover'
                      title='Specify quote due date'
                    ></div>
                    {/* Input group */}
                  </div>
                  {/* Top */}
                  {/* begin::Separator */}
                  <div className='separator separator-dashed my-10'></div>
                  {/* Separator */}
                  {/* begin::Wrapper */}
                  <div className='mb-0'>
                    {/* begin::Row */}
                    <div className='row gx-10 mb-5'>
                      {/* begin::Col */}
                      <div className='col-lg-6'>
                        <label className='form-label required fs-6 fw-bold text-gray-700 mb-3'>
                          From
                        </label>
                        {/* begin::Input group */}
                        <Select
                          name='employee'
                          aria-label='Select a Employee'
                          placeholder='Select Employee'
                          options={employeeList}
                          defaultValue={{value: currentUser?._id, label: currentUser?.fullName}}
                          isSearchable={true}
                          required
                          className='form-control form-control-solid'
                          onChange={handleBillFromChange}
                          value={selectedBillFrom}
                        />
                      </div>

                      <div className='col-lg-6'>
                        <label className='form-label required fs-6 fw-bold text-gray-700 mb-3'>
                          To
                        </label>

                        <Select
                          name='customer'
                          aria-label='Select a Customer'
                          placeholder='Select a Customer'
                          options={customerList}
                          isSearchable={true}
                          required
                          className='form-control form-control-solid'
                          onChange={handleBillToChange}
                          value={selectedBillTo}
                        />
                      </div>
                      <div className='col-lg-6 mt-5'>
                        <label className='form-label fs-6 fw-bold text-gray-700 mb-3'>
                          Subject
                        </label>
                        <input
                          type='text'
                          className='form-control form-control-solid '
                          name='subject'
                          placeholder=' Subject'
                          value={quote.subject}
                          onChange={handleChange}
                        />
                      </div>
                      <div className='col-lg-3 mt-5'>
                        <label className='form-label fs-6 fw-bold text-gray-700 mb-3'>
                          RFQ No.
                        </label>
                        <input
                          type='text'
                          className='form-control form-control-solid '
                          name='rfqNumber'
                          placeholder=' RFQ Number'
                          value={quote.rfqNumber}
                          onChange={handleChange}
                        />
                      </div>
                      <div className='col-lg-3 mt-5'>
                        <label className='form-label fs-6 fw-bold text-gray-700 mb-3'>
                          Contact Person
                        </label>
                        <Select
                          name='contactPerson'
                          aria-label='Select a Contact Person'
                          placeholder='Select a Person'
                          options={contactPersonList}
                          isSearchable={true}
                          className='form-control form-control-solid'
                          onChange={(e) => {
                            setQuote((prev) => ({
                              ...prev,
                              contactPerson: e.value,
                            }))
                          }}
                          value={contactPersonList.find(
                            (option) => option.value === quote.contactPerson
                          )}
                        />
                      </div>

                      <div className='mb-0 mt-5 position-relative'>
                        <label className='form-label fs-6 fw-bold text-gray-700'>Description</label>
                        <textarea
                          name='description'
                          className={`  form-control form-control-solid mb-0 h-10 ${
                            isEditingDescription ? 'border border-dark-subtle' : ''
                          }`}
                          placeholder='Enter Description'
                          value={quote.description}
                          onChange={handleChange}
                          readOnly={!isEditingDescription}
                          style={{
                            minHeight: '6rem',
                            height: textHeight,
                            resize: 'none',
                          }}
                        />
                        <button onClick={(e) => handleEditClick(e, 'description')}>
                          <KTIcon
                            iconName='pencil'
                            className='absolute text-black bg-gray-100 right-20 top-[3rem] fs-3'
                          />
                        </button>
                      </div>
                    </div>

                    <div className='d-flex align-items-center justify-content-start gap-5 mt-5'>
                      <button
                        type='button'
                        className={`btn btn-light-success ${selectFromInventory && 'active'}`}
                        onClick={() => {
                          setSelectFromInventory(true)
                          setNewItem([])
                          setItems([
                            {
                              itemId: '',
                              price: '',
                              quantity: 0,
                              discount: 0,
                              amount: '',
                              selectedOption: {
                                value: '',
                                label: '',
                                inWarehouseQuantity: 0,
                              },
                              type: 'product',
                              rfqDescription: '',
                              productName: '',
                            },
                          ])
                        }}
                      >
                        Select from Inventory
                      </button>
                      <button
                        type='button'
                        className={`btn btn-light-warning ${!selectFromInventory && 'active'}`}
                        onClick={() => {
                          setSelectFromInventory(false)
                          setItems([])
                          setNewItem([
                            {
                              productName: '',
                              price: '',
                              quantity: 0,
                              discount: 0,
                              amount: '',
                              rfqDescription: '',
                            },
                          ])
                        }}
                      >
                        Add New Item
                      </button>
                    </div>

                    <div className='fs-6 fw-bold text-gray-700 mb-3 mt-5'>Item Table</div>
                    <div className='table-responsive mb-10'>
                      <table
                        className='table g-5 gs-0 mb-0 fw-bold text-gray-700'
                        data-kt-element='items'
                      >
                        <thead>
                          <tr className='border-bottom fs-7 fw-bold text-gray-700 text-uppercase'>
                            <th className='min-w-15px w-15px'>No.</th>
                            <th className='min-w-100px w-500px'>Item</th>
                            {!selectFromInventory && <th className='min-w-50px w-50px'>Unit</th>}
                            <th className='min-w-150px w-250px'>Quantity</th>
                            <th className='min-w-50px w-250px'>Rate</th>
                            <th className='min-w-70px w-150px'>VAT</th>
                            <th className='min-w-70px w-250px'>Amount</th>
                            <th className='min-w-70px w-200px'>Action</th>
                          </tr>
                        </thead>
                        {/* Table head */}
                        {/* begin::Table body */}
                        <DragDropContext onDragEnd={handleDragEnd}>
                          <Droppable droppableId='droppable'>
                            {(provided) => (
                              <tbody ref={provided.innerRef} {...provided.droppableProps}>
                                {selectFromInventory &&
                                  items.map((item, index) => (
                                    <Draggable
                                      key={index}
                                      draggableId={`item-${index}`}
                                      index={index}
                                    >
                                      {(provider) => (
                                        <>
                                          <tr
                                            data-kt-element='item'
                                            key={`${index}-row-1`}
                                            ref={provider.innerRef}
                                            {...provider.draggableProps}
                                          >
                                            <td className='d-flex align-items-center ps-1'>
                                              {index + 1}
                                            </td>
                                            <td className='pe-7'>
                                              {/* begin::Input group */}
                                              <Select
                                                name='itemId'
                                                aria-label='Select a Product'
                                                placeholder='Select a Product'
                                                options={productList}
                                                isSearchable={true}
                                                className='form-control form-control-solid'
                                                onChange={(e) => handleSelectChangeInRows(index, e)}
                                                value={item.selectedOption}
                                                required
                                              />
                                            </td>
                                            <td className='pe-7'>
                                              {/* begin::Input group */}

                                              <input
                                                type='number'
                                                className='form-control form-control-solid'
                                                name='quantity'
                                                placeholder='0.00'
                                                // max={
                                                //   item?.type === 'product' &&
                                                //   item?.selectedOption?.inWarehouseQuantity
                                                // }
                                                min={0}
                                                value={item.quantity}
                                                onChange={(e) => handleChangerows(index, e)}
                                              />
                                            </td>
                                            <td className='pe-7'>
                                              {/* begin::Input group */}

                                              <input
                                                type='number'
                                                className='form-control form-control-solid'
                                                name='price'
                                                placeholder='0.00'
                                                value={item.price}
                                                onChange={(e) => handleChangerows(index, e)}
                                              />
                                            </td>
                                            <td className='pe-7'>
                                              <input
                                                type='number'
                                                className='form-control form-control-solid'
                                                name='discount'
                                                placeholder='0.00'
                                                value={item.discount}
                                                onChange={(e) => handleChangerows(index, e)}
                                              />
                                            </td>
                                            <td>
                                              <input
                                                type='number'
                                                className='form-control form-control-solid text-end'
                                                name='amount'
                                                placeholder='0.00'
                                                value={item.amount}
                                                disabled
                                                // onChange={(e) => handleChangerows(index, e)}
                                              />
                                            </td>
                                            <td className='d-flex gap-1'>
                                              <div
                                                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-3'
                                                onClick={additem}
                                              >
                                                <KTIcon iconName='plus-square' className='fs-3' />
                                              </div>
                                              <div
                                                className='btn btn-icon btn-bg-light btn-active-color-danger btn-sm '
                                                onClick={() => removeitem(index)}
                                              >
                                                <KTIcon iconName='trash' className='fs-3' />
                                              </div>
                                              <div
                                                {...provider.dragHandleProps}
                                                className='fs-3 fw-bold text-gray-400 ms-3'
                                              >
                                                ...
                                              </div>
                                            </td>
                                          </tr>
                                          {/* add new row for input text area with full colspan for rfqDescription */}
                                          <tr
                                            className='border-bottom border-bottom-dashed'
                                            data-kt-element='item'
                                            key={`${index}-row-2`}
                                          >
                                            <td colSpan={6} className='pe-7'>
                                              <textarea
                                                name='rfqDescription'
                                                className='position-relative  form-control form-control-solid mb-0 h-10'
                                                placeholder='Enter RFQ Description'
                                                value={item.rfqDescription}
                                                onChange={(e) => handleChangerows(index, e)}
                                                style={{
                                                  minHeight: '3rem',
                                                  height: '3rem',
                                                  resize: 'none',
                                                }}
                                              />
                                            </td>
                                          </tr>
                                        </>
                                      )}
                                    </Draggable>
                                  ))}
                                {provided.placeholder}

                                {!selectFromInventory &&
                                  newItem.map((item, index) => (
                                    <Draggable
                                      key={index}
                                      draggableId={`item-${index}`}
                                      index={index}
                                    >
                                      {(provider) => (
                                        <>
                                          <tr
                                            key={`${index}-row-1`}
                                            ref={provider.innerRef}
                                            {...provider.draggableProps}
                                            data-kt-element='item'
                                          >
                                            <td className='d-flex align-items-center ps-1'>
                                              {index + 1}
                                            </td>
                                            <td className='pe-7'>
                                              {/* begin::Input group */}
                                              <input
                                                type='text'
                                                placeholder='Enter Item Name'
                                                className='form-control form-control-solid'
                                                value={item?.productName}
                                                onChange={(e) => {
                                                  const temp = [...newItem]
                                                  temp[index].productName = e.target.value
                                                  setNewItem(temp)
                                                }}
                                              />
                                            </td>
                                            <td>
                                              <select
                                                name='unit'
                                                id=''
                                                className='form-control form-control-solid'
                                                value={item.unit}
                                                onChange={(e) => handleChangeinNewItem(index, e)}
                                              >
                                                <option value=''>unit</option>
                                                {unitsList.map((unit, index) => (
                                                  <option value={unit} key={unit}>
                                                    {unit}
                                                  </option>
                                                ))}
                                              </select>
                                            </td>
                                            {/* begin::Input group */}

                                            <td className='pe-7'>
                                              {/* begin::Input group */}

                                              <input
                                                type='number'
                                                className='form-control form-control-solid'
                                                name='quantity'
                                                placeholder='0.00'
                                                min={0}
                                                value={item.quantity}
                                                onChange={(e) => handleChangeinNewItem(index, e)}
                                              />
                                            </td>
                                            <td className='pe-7'>
                                              {/* begin::Input group */}

                                              <input
                                                type='number'
                                                className='form-control form-control-solid'
                                                name='price'
                                                placeholder='0.00'
                                                value={item.price}
                                                onChange={(e) => handleChangeinNewItem(index, e)}
                                              />
                                            </td>
                                            <td className='pe-7'>
                                              <input
                                                type='number'
                                                className='form-control form-control-solid'
                                                name='discount'
                                                placeholder='0.00'
                                                value={item.discount}
                                                onChange={(e) => handleChangeinNewItem(index, e)}
                                              />
                                            </td>
                                            <td>
                                              <input
                                                type='number'
                                                className='form-control form-control-solid text-end'
                                                name='amount'
                                                placeholder='0.00'
                                                value={item.amount}
                                                disabled
                                              />
                                            </td>
                                            <td className='d-flex gap-1'>
                                              <div
                                                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-3'
                                                onClick={() => {
                                                  setNewItem([
                                                    ...newItem,
                                                    {
                                                      productName: '',
                                                      quantity: 0,
                                                      price: 0,
                                                      discount: 0,
                                                      amount: 0,
                                                      rfqDescription: '',
                                                    },
                                                  ])
                                                }}
                                              >
                                                <KTIcon iconName='plus-square' className='fs-3' />
                                              </div>
                                              <div
                                                className='btn btn-icon btn-bg-light btn-active-color-danger btn-sm '
                                                onClick={() => {
                                                  if (newItem.length === 1) {
                                                    setNewItem([
                                                      {
                                                        productName: '',
                                                        quantity: 0,
                                                        price: 0,
                                                        discount: 0,
                                                        amount: 0,
                                                        rfqDescription: '',
                                                      },
                                                    ])
                                                    return
                                                  }
                                                  const temp = [...newItem]
                                                  temp.splice(index, 1)
                                                  setNewItem(temp)
                                                }}
                                              >
                                                <KTIcon iconName='trash' className='fs-3' />
                                              </div>
                                              <div
                                                {...provider.dragHandleProps}
                                                className='fs-3 fw-bold text-gray-400 ms-3'
                                              >
                                                ...
                                              </div>
                                            </td>
                                          </tr>
                                          <tr
                                            className='border-bottom border-bottom-dashed'
                                            data-kt-element='item'
                                            key={`${index}-row-2`}
                                          >
                                            <td colSpan={6} className='pe-7'>
                                              <textarea
                                                name='rfqDescription'
                                                className='position-relative  form-control form-control-solid mb-0 h-10'
                                                placeholder='Enter RFQ Description'
                                                value={item.rfqDescription}
                                                style={{
                                                  minHeight: '3rem',
                                                  height: '3rem',
                                                  resize: 'none',
                                                }}
                                                onChange={(e) => {
                                                  const temp = [...newItem]
                                                  temp[index].rfqDescription = e.target.value
                                                  setNewItem(temp)
                                                }}
                                              />
                                            </td>
                                          </tr>
                                        </>
                                      )}
                                    </Draggable>
                                  ))}
                              </tbody>
                            )}
                          </Droppable>
                        </DragDropContext>
                        <tfoot>
                          <tr className='border-top border-top-dashed align-top fs-6 fw-bold text-gray-700'>
                            <th className='text-primary'></th>
                            <th className='text-primary'></th>
                            <th className='text-primary'></th>
                            <th className='border-bottom border-bottom-dashed ps-0 '>
                              <div className='d-flex flex-column align-items-center'>
                                <div className='fs-5'>Subtotal</div>
                                <button
                                  type='button'
                                  className='btn btn-link '
                                  data-bs-toggle='tooltip'
                                  data-bs-trigger='hover'
                                  title='Discount in percentage'
                                >
                                  Discount %
                                </button>
                                <button
                                  type='button'
                                  className='btn btn-link '
                                  data-bs-toggle='tooltip'
                                  data-bs-trigger='hover'
                                  title='Tax in percentage'
                                >
                                  VAT %
                                </button>
                              </div>
                            </th>
                            <th className='border-bottom border-bottom-dashed text-center'>
                              <span data-kt-element='sub-total' className=' '>
                                {quote.subtotal.toLocaleString('en-US', {
                                  minimumFractionDigits: 2,
                                }) || 0}{' '}
                                {selectedCustomerCurrency}
                              </span>
                              <input
                                type='number'
                                className='form-control form-control-solid text-center'
                                name='discount'
                                placeholder='0.00'
                                value={quote.discount}
                                onChange={handleChange}
                              />
                              <input
                                type='number'
                                className='form-control form-control-solid text-center'
                                name='tax'
                                placeholder='0.00'
                                value={quote.tax}
                                onChange={handleChange}
                              />
                            </th>
                          </tr>
                          <tr className='align-top fw-bold text-gray-700'>
                            <th className=''></th>
                            <th className='min-w-150px w-150px'></th>
                            <th className='min-w-100px w-100px'></th>
                            <th className='fs-4 ps-0 min-w-50px w-50px text-center'>Total</th>
                            <th className='text-center fs-4 text-nowrap min-w-70px w-70px'>
                              <span data-kt-element='grand-total text-center'>
                                {quote?.total.toLocaleString('en-US', {
                                  minimumFractionDigits: 2,
                                })}{' '}
                                {selectedCustomerCurrency}
                              </span>
                            </th>
                          </tr>
                        </tfoot>
                      </table>
                    </div>

                    <table className='table d-none' data-kt-element='empty-template'>
                      <tr data-kt-element='empty'>
                        <th colSpan={5} className='text-muted text-center py-10'>
                          No items
                        </th>
                      </tr>
                    </table>

                    <div className='mb-0 py-6 position-relative'>
                      <label className='form-label fs-6 fw-bold text-gray-700'>Notes</label>
                      <textarea
                        name='notes'
                        className={`  form-control form-control-solid mb-0 h-10 ${
                          isEditingNotes ? 'border border-dark-subtle' : ''
                        }`}
                        placeholder='Enter customer notes'
                        value={quote.notes}
                        onChange={handleChange}
                        readOnly={!isEditingNotes}
                        onKeyDown={handleKeyPress}
                        style={{
                          minHeight: '6rem',
                          height: notesTextareaHeight,
                          resize: 'none',
                        }}
                      />
                      <button onClick={(e) => handleEditClick(e, 'notes')}>
                        <KTIcon
                          iconName='pencil'
                          className='position-absolute text-black bg-gray-100 right-12
                           top-[5rem] fs-3'
                        />
                      </button>
                    </div>
                    <div className='mb-10 h-100 position-relative'>
                      <label className='form-label fw-bold fs-6 text-gray-700'>
                        Terms and Conditions
                      </label>
                      <textarea
                        name='termsNCondition'
                        style={{
                          minHeight: '6rem',
                          height: termsTextareaHeight,
                          resize: 'none',
                        }}
                        className={`  form-control form-control-solid mb-0 ${
                          isEditingTerms ? 'border border-dark-subtle' : ''
                        }`}
                        placeholder='Enter terms and conditions'
                        value={quote.termsNCondition}
                        onChange={handleChange}
                        readOnly={!isEditingTerms}
                      />
                      <button onClick={(e) => handleEditClick(e, 'termsNCondition')}>
                        <KTIcon
                          iconName='pencil'
                          className='absolute text-black bg-gray-100 right-12  fs-3 '
                          style={{top: termsTop}}
                        />
                      </button>
                    </div>
                    <div className='row gx-10 mb-5'>
                      <div
                        className='card-toolbar me-5'
                        data-bs-toggle='modal'
                        data-bs-target='#kt_modal_11'
                      >
                        <a className='btn btn-sm btn-light-primary'>
                          <KTIcon iconName='plus' className='fs-2' />
                          Upload File
                        </a>
                      </div>
                    </div>

                    <div className='mb-0'>
                      <button
                        type='button'
                        className='btn btn-primary w-30'
                        id='kt_quote_submit_button'
                        disabled={loading}
                        onClick={handleSubmit}
                      >
                        <i className='ki-duotone ki-triangle fs-3'>
                          <span className='path1'></span>
                          <span className='path2'></span>
                          <span className='path3'></span>
                        </i>
                        Create Quotation
                      </button>
                    </div>
                    {/* Notes */}
                  </div>
                  {/* Wrapper */}
                </form>
                {/* Form */}
              </div>
              {/* Card body */}
            </div>
            {/* Card */}
          </div>
          {/* Content */}
          {/* begin::Sidebar */}
        </div>
        {/* <!--::Layout--> */}
      </div>
      <div className='modal fade' tabIndex={-1} id='kt_modal_11'>
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title'>Add File</h5>
              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
              </div>
            </div>

            <div className='modal-body'>
              <form>
                <div className='row rowwidthclient'>
                  <div className='card-toolbar'>
                    <a className='btn btn-sm btn-light-danger' onClick={handleButtonClick}>
                      <KTIcon iconName='plus' className='fs-2' />
                      Upload File
                    </a>
                  </div>

                  <input
                    type='file'
                    accept='.pdf, .jpg, .jpeg, .png, .gif, .doc, .docx, .xlsx, .xls'
                    onChange={handleFileChange}
                    ref={fileInputRef}
                    style={{display: 'none'}}
                  />
                  {excelFile && <p>Selected file: {excelFile.name}</p>}
                </div>
              </form>
            </div>

            <div className='modal-footer'>
              <button
                type='button'
                className='btn btn-light'
                data-bs-dismiss='modal'
                id='closeModalButton'
              >
                Close
              </button>
              <button
                type='button'
                className='btn btn-primary'
                disabled={isLoading}
                onClick={handleUpload}
              >
                Save changes
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className='modal fade' id='kt_modal_123' tabIndex={-1} aria-hidden='true'>
        <div className='modal-dialog mw-800px'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title'>Add Item</h5>
              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
              </div>
            </div>
            <div className='modal-body'>
              <AddProduct setProductList={setProductList} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Createquote
