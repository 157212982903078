/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useRef, useState} from 'react'
import {useIntl} from 'react-intl'
// import {toAbsoluteUrl} from '../../../_metronic/helpers'
// import {PageTitle} from '../../../_metronic/layout/core'
import {
  // ListsWidget2,
  // ListsWidget3,
  // ListsWidget4,
  // ListsWidget6,
  // TablesWidget5,
  // TablesWidget10,
  // MixedWidget8,
  // CardsWidget7,
  // CardsWidget17,
  // CardsWidget20,
  ListsWidget26,
  // EngageWidget10,
  // ChartsWidget2,
  // ChartsWidget6,
  // ChartsWidget5,
  // MixedWidget13,
  // MixedWidget10,
  // MixedWidget11,
  // ListsWidget8,
  // ChartsWidget7,
  // ChartsWidget8,
  // ChartsWidget3,
  // ChartsWidget4,
  // TablesWidget14,
  // TablesWidget12,
  // TablesWidget8,
  ListsWidget27,
  // ListsWidget7,
  // ListsWidget11,
  // ChartsWidget11,
  // ListsWidget12,
  // TablesWidget16,
  // TablesWidget17,
  StatisticsWidget7,
} from '../../../_metronic/partials/widgets'
// import ProjectsSummaryCard from '../projects/Pages/ProjectsSummaryCard'
import {getProjectPerformance} from '../projects/core/_requests'
import {useAuth} from '../../modules/auth'
import {getSalesPerformance, getVendorPerformance} from '../reports/core/requests'
// import PieChart2 from '../Inventory/Components/PieChart2'
// import ChartsWidget15 from '../../../_metronic/partials/widgets/charts/ChartsWidget15'
// import {ListsWidget28} from '../../../_metronic/partials/widgets/_new/lists/ListsWidget28'
import {getProfitAndLoss} from '../account/core/_requests'
import {Stat1} from '../Inventory/Components/Stat1'
import EmployeeTable from './EmployeeTable'
import SalesSummaryCard from './SalesSummaryCard'
import VendorSummaryCard from './VendorSummaryCard'

const MarketingDashboard = ({customers}) => {
  const {currentUser} = useAuth()
  const [pnl, setPnl] = useState({
    gross: 0,
    operating: 0,
    net: 0,
    income: 0,
    expense: 0,
    goods: 0,
  })
  const [expense, setExpense] = useState('Loading')
  const [profit, setProfit] = useState('Loading')
  const [totalBusinessSum, setTotalBusinessSum] = useState('Loading')

  useEffect(() => {
    const fetchData = async () => {
      const res = await getProfitAndLoss(
        currentUser?.organization,
        // new Date(new Date().getFullYear(), new Date().getMonth(), 1).toISOString(),
        new Date(new Date().setMonth(new Date().getMonth() - 6)).toISOString(),
        new Date().toISOString()
      )
      let updatedPnl = {...pnl}

      res?.forEach((item) => {
        if (item['type'] === 'income') {
          updatedPnl.gross += item['total']
          updatedPnl.income += item['total']
        }
        if (item['type'] === 'costofgoodssold') {
          updatedPnl.gross -= item['total']
          updatedPnl.goods += item['total']
        }
        if (item['type'] === 'expense') {
          updatedPnl.operating += item['total']
          updatedPnl.expense += item['total']
        }
      })

      // Calculate net profit
      updatedPnl.net = updatedPnl.gross - updatedPnl.operating

      setPnl(updatedPnl)
      setExpense(
        updatedPnl.expense?.toLocaleString('en-US', {
          minimumFractionDigits: 2,
        })
      )
      setProfit(
        (updatedPnl.gross - updatedPnl.expense)?.toLocaleString('en-US', {
          minimumFractionDigits: 2,
        })
      )
    }
    // if (currentUser.organization !== undefined) fetchData()

    const timer = setTimeout(() => {
      if (currentUser.organization !== undefined) {
        fetchData()
      }
    }, 500)

    return () => {
      clearTimeout(timer)
    }
  }, [currentUser])

  useEffect(() => {
    const temp = customers
      .reduce((acc, curr) => acc + curr.totalBusiness, 0)
      ?.toLocaleString('en-US', {
        minimumFractionDigits: 2,
      })
    setTotalBusinessSum(temp)
  }, [customers])

  return (
    <>
      <div className=' row g-5 g-xl-8 mb-xl-2 pe-10'>
        <div className='col-md-4 col-lg-4 col-xl-4 col-xxl-4 mb-md-2'>
          <StatisticsWidget7
            className=''
            // header='Total Sales'
            header='Overall Revenue'
            title={totalBusinessSum}
            description='Compared to the previous quarter'
            badgeColor='success'
            color={'light-success'}
            svgIcon={''}
            iconColor={''}
            borderColor='success'
          />
        </div>
        <div className='col-md-4 col-lg-4 col-xl-4 col-xxl-4 mb-md-2'>
          <StatisticsWidget7
            className=''
            header='Overall Expense'
            title={expense}
            badgeColor='success'
            description='Compared to the previous quarter'
            color={'light-danger'}
            svgIcon={''}
            iconColor={''}
            borderColor='danger'
          />
        </div>
        <div className='col-md-4 col-lg-4 col-xl-4 col-xxl-4 mb-md-2'>
          <StatisticsWidget7
            className=''
            header='Company P/L'
            title={profit}
            badgeColor='danger'
            description='Compared to the previous quarter'
            color={'light-warning'}
            svgIcon={''}
            iconColor={''}
            borderColor='warning'
          />
        </div>
      </div>
      {/* begin::Row */}
      {/* end::Col */}
      {/* </div> */}
      {/* end::Row */}

      {/* begin::Row */}
      {/* <div className='row gx-5 gx-xl-10'> */}
      {/* begin::Col */}
      {/* <div className='col-xxl-6 mb-5 mb-xl-10'> */}
      {/* <app-new-charts-widget8 cssclassName="h-xl-100" chartHeight="275px" [chartHeightNumber]="275"></app-new-charts-widget8> */}
      {/* </div> */}
      {/* end::Col */}

      {/* begin::Col */}
      {/* // <div className='col-xxl-6 mb-5 mb-xl-10'> */}
      {/* <app-cards-widget18 cssclassName="h-xl-100" image="./assets/media/stock/600x600/img-65.jpg"></app-cards-widget18> */}
      {/* </div> */}
      {/* end::Col */}
      {/* </div> */}
      {/* end::Row */}

      {/* begin::Row */}
      {/* <div className='row gy-5 gx-xl-8'> */}
      {/* <div className='col-xl-4'> */}
      {/* <div className=''> */}
      {/* <MixedWidget11 className={'mb-5 mb-xl-5'} chartColor={'info'} chartHeight={''} /> */}
      {/* </div> */}
      {/* <div className=''> */}
      {/* <ListsWidget12 className='stretch mb-5 mb-xl-8' /> */}
      {/* </div> */}
      {/* </div> */}
      {/* <div className='col-xl-8 '> */}
      <div>{/* <TablesWidget10 className=' mb-xl-10' /> */}</div>
      <div>{/* <TablesWidget5 className=' mb-xl-10' /> */}</div>
      {/* </div> */}
      {/* </div> */}
      {/* end::Row */}

      {/* begin::Row */}
      {/*<div className='row gy-5 g-xl-8'>
      <div className='col-xl-4'>
        <ListsWidget2 className='card-xl-stretch mb-xl-8' />
      </div>
      <div className='col-xl-4'>
        <ListsWidget6 className='card-xl-stretch mb-xl-8' />
      </div>
      <div className='col-xl-4'>
        <ListsWidget4 className='card-xl-stretch mb-5 mb-xl-8' items={5} />
        {/* partials/widgets/lists/_widget-4', 'class' => 'card-xl-stretch mb-5 mb-xl-8', 'items' => '5' */}
      {/* </div>
    </div>} */}
      {/* end::Row */}

      {/* <div className='row g-5 gx-xxl-8'> */}
      {/* <div className='col-xxl-4'> */}
      {/* <MixedWidget8
          className='card-xxl-stretch mb-xl-3'
          chartColor='success'
          chartHeight='150px'
        /> */}
      {/* <EngageWidget10 className='card-xxl-stretch mb-xl-3' /> */}
      {/* </div> */}
      {/* <div className='col-xxl-8'> */}
      {/* <ChartsWidget11 className='card-xxl-stretch mb-5 mb-xxl-8' /> */}
      {/* </div> */}
      {/* </div> */}
    </>
  )
}

const MarketingDashboardWrapper: FC = () => {
  const intl = useIntl()
  const chartRef = useRef<HTMLDivElement | null>(null)
  const {currentUser} = useAuth()
  const [projectPerformanceData, setProjectPerformanceData] = useState([])
  const [salesPerformanceData, setSalesPerformanceData] = useState([])
  const [vendorsData, setVendorsData] = useState([])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getProjectPerformance(currentUser.organization)
        // Sort data by performance percentage in descending order
        data.sort((a, b) => b.performancePercentage - a.performancePercentage)
        setProjectPerformanceData(data)
      } catch (error) {
        console.error('Error fetching project performance data:', error)
      }
    }

    const fetchSalesData = async () => {
      try {
        const data = await getSalesPerformance(
          currentUser.organization,
          // new Date(new Date().getFullYear(), new Date().getMonth(), 1).toISOString(),
          new Date(new Date().setMonth(new Date().getMonth() - 6)).toISOString(),
          new Date().toISOString()
        )

        // Sort data by performance percentage in descending order
        data.sort((a, b) => b.performancePercentage - a.performancePercentage)

        setSalesPerformanceData(data)
      } catch (error) {
        console.error('Error fetching sales performance data:', error)
      }
    }

    const fetchVendorData = async () => {
      try {
        const data = await getVendorPerformance(
          currentUser.organization,
          new Date(new Date().setMonth(new Date().getMonth() - 6)).toISOString(),
          new Date().toISOString()
        )

        // Sort data by performance percentage in descending order
        data.sort((a, b) => b.performancePercentage - a.performancePercentage)

        setVendorsData(data)
      } catch (error) {
        console.error('Error fetching sales performance data:', error)
      }
    }

    const timer = setTimeout(() => {
      if (currentUser.organization !== undefined) {
        // fetchData()
        fetchSalesData()
        fetchVendorData()
      }
    }, 500)

    if (currentUser.organization !== undefined) {
      // fetchData()
      fetchSalesData()
      fetchVendorData()
    }

    return () => {
      clearTimeout(timer)
    }
  }, [currentUser])
  const [top5Projects, setTop5Projects] = useState([])

  useEffect(() => {
    const res = projectPerformanceData.filter((project) => !project.isDeleted).slice(0, 5)
    setTop5Projects(res)
  }, [projectPerformanceData])

  const top5Customers = salesPerformanceData
    .filter((sales) => sales.isActivated === true)
    .slice(0, 6)
  const top5Vendors = vendorsData.filter((vendor) => vendor.isActivated === true).slice(0, 5)

  return (
    <>
      <div
        id='kt_app_toolbar_container'
        className='app-container  container-xxl d-flex flex-stack mb-8 '
      >
        {/* <div className='d-flex align-items-center gap-2 gap-lg-3'>
          <a className='btn btn-sm fw-bold btn-secondary'>Add Customer </a>

          <a href='#' className='btn btn-sm fw-bold btn-primary'>
            New Campaign{' '}
          </a>
        </div> */}
      </div>
      <div className='flex flex-col gap-3'>
        <div>
          <MarketingDashboard customers={top5Customers} />
          <div className='row g-5 g-xl-10 mb-5 mb-xl-2'>
            {/* begin::Col */}
            {/* <div className='col-xxl-6 '> */}
            <div className='row h-md-100 g-5 g-xl-10 mb-xl-5'>
              {/* First Column */}
              <div className='col-md-8 col-lg-12 col-xl-12 col-xxl-8 mb-5'>
                <div className='row mb-6'>
                  <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-6'>
                    <ListsWidget27 className='' />
                  </div>
                  {/* Second Column */}
                  <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-6 '>
                    <ListsWidget26 className='' />
                  </div>
                </div>
                <div className='row'>
                  <div className=''>
                    {/* <PieChart2 className={''} /> */}
                    <Stat1 />
                  </div>
                </div>
              </div>
              <div className='col-xxl-4 mb-5 mb-xl-4'>
                <SalesSummaryCard customers={top5Customers} />
              </div>
              {/* Third Column */}
              {/* <div className='col-md-4 col-lg-4 col-xl-4 col-xxl-4 '>
                <ListsWidget28 className='' />
              </div> */}
            </div>
            {/* </div> */}
            {/* end::Col */}

            {/* begin::Col */}
            {/* <div className='col-xxl-6 mb-5'> */}
            {/* <ChartsWidget5 className='h-md-150 mb-5 mb-xl-10' /> */}
          </div>
        </div>
        {/* <div className='row gx-10 w-100'>
          <div className='col-lg-4'>
            <SalesSummaryCard customers={top5Customers} />
          </div>
        </div> */}
        <div className='row gx-10 w-100'>
          <div className='col-lg-8 py-5'>
            <EmployeeTable tableHeight={600} />
          </div>
          <div className='col-lg-4 py-5'>
            <VendorSummaryCard vendors={top5Vendors} />
          </div>
        </div>
        {/* <div className='row gx-10 w-100'>
          <div className='col-lg-4 pt-5 pb-5'>
            <ProjectsSummaryCard projects={top5Projects} />
          </div>
          <div className='col-lg-8 p-5'>
            <ChartsWidget15 />
          </div>
        </div>
        <div className='row gx-10 w-100'>
          <div className='col-lg-4 pb-5 pt-5'>
            <VendorSummaryCard vendors={top5Vendors} />
          </div>
          <div className='col-lg-8 p-5'>
            <TablesWidget17 className={''} newMember={''}></TablesWidget17>
          </div>
        </div> */}
      </div>
      {/* </div> */}
      {/* <div className='grid sm:grid-cols-3 gap-2 h-[400px]'>
        <div className='sm:col-span-1 pt-5 pb-5'>
          <ProjectsSummaryCard projects={top5Projects} />
        </div>
        <div className='sm:col-span-2 p-5'>
        <ChartsWidget15/>
          </div>
        <div className='md:col-span-2'><PieChart2 className={''} /></div>
        <div className='sm:col-span-1'>
          <SalesSummaryCard customers={top5Customers} />
        </div>
        <div className='sm:col-span-1 pb-5 pt-5'>
          <VendorSummaryCard vendors={top5Vendors} />
        </div>
        <div className='sm:col-span-2 p-5'><TablesWidget17 className={''} newMember={''}></TablesWidget17></div>
        </div>
        </div> */}
    </>
  )
}

export {MarketingDashboardWrapper}
