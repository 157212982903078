/* eslint-disable react/jsx-no-target-blank */
import React, {useContext, useEffect, useState} from 'react'
import {SidebarMenuItemWithSub} from './SidebarMenuItemWithSub'
import {SidebarMenuItem} from './SidebarMenuItem'
import {useAuth} from '../../../../../app/modules/auth'
import {SettingContext} from '../Sidebar'

const SidebarMenuMain = () => {
  const {currentUser} = useAuth()
  const [permissions, setPermissions] = useState<any>({})
  const [mainModule, setMainModule] = useState(null)
  const {setting} = useContext(SettingContext)

  useEffect(() => {
    if (currentUser?.permissions) {
      setPermissions(currentUser?.permissions)
    }
    if (currentUser?.mainModule) {
      setMainModule(currentUser?.mainModule || null)
    }
  }, [currentUser])

  useEffect(() => {
    const timer = setTimeout(() => {
      if (currentUser?.permissions) {
        setPermissions(currentUser?.permissions)
      }
      if (currentUser?.mainModule) {
        setMainModule(currentUser?.mainModule || null)
      }
    }, 500)

    return () => {
      clearTimeout(timer)
    }
  })

  return (
    <>
      {setting ? (
        <>
          <div className='menu-item'>
            <div className='menu-content pt-8 pb-2'>
              <span className='menu-section text-muted text-uppercase fs-8 ls-1'>
                Company Settings
              </span>
            </div>
          </div>
          <SidebarMenuItem
            to='/setting'
            // icon='element-4'
            title='Profile Setting'
            // fontIcon='bi-layers'
          />
          {/* taxes & compliance */}
          <SidebarMenuItem
            to='/dashboard'
            // icon='element-4'
            title='Taxes & Compliance'
            // fontIcon='bi-layers'
          />
          {/* billing & subscription */}
          <SidebarMenuItemWithSub
            to='pricing'
            title='Billing & Subscription'
            // fontIcon='bi-layers'
          >
            <SidebarMenuItem
              to='/pricing/columnPricing'
              icon='setting'
              title='Column Pricing'
              // fontIcon='bi-layers'
            />
            <SidebarMenuItem
              to='/pricing/tablePricing'
              icon='setting'
              title='Table Pricing'
              // fontIcon='bi-layers'
            />
          </SidebarMenuItemWithSub>
          {currentUser?.profileType !== 'superadmin' &&
            currentUser?.profileType?.toLowerCase()?.includes('admin') && (
              <SidebarMenuItemWithSub
                to='/dashboard'
                title='Administrator Settings'
                // fontIcon='element'
                // icon='element-12'
              >
                <SidebarMenuItem
                  to='/user-management/users'
                  icon='setting'
                  title='User management'
                  fontIcon='bi-layers'
                />
              </SidebarMenuItemWithSub>
            )}
          {currentUser?.profileType === 'superadmin' && (
            <SidebarMenuItemWithSub
              to='/dashboard'
              title='Administrator Settings'
              // fontIcon='element'
              // icon='element-12'
            >
              <>
                <SidebarMenuItem
                  to='/modules/module-list'
                  icon='setting'
                  title='Modules'
                  // fontIcon='element'
                />
                {/* industry preference */}
                <SidebarMenuItem
                  to='/industry'
                  icon='setting'
                  title='Industry Preference'
                  // fontIcon='bi-layers'
                />
                <SidebarMenuItem
                  to='/department/department-list'
                  icon='setting'
                  title='Department'
                  // fontIcon='element'
                />
                <SidebarMenuItem
                  to='/organization-management'
                  title='Organization'
                  icon='setting'
                  // fontIcon='element'
                />
                <SidebarMenuItem
                  to='/role-management'
                  title='Roles'
                  icon='setting'
                  // fontIcon='element'
                />
                <SidebarMenuItem
                  to='/superuser-management'
                  icon='setting'
                  title='User Preference'
                  // fontIcon='circle'
                />
              </>
            </SidebarMenuItemWithSub>
          )}
          <div className='menu-item'>
            <div className='menu-content pt-8 pb-2'>
              <span className='menu-section text-muted text-uppercase fs-8 ls-1'>App Setings</span>
            </div>
          </div>
          <SidebarMenuItemWithSub
            to='/dashboard'
            title='Project'
            // fontIcon='element'
            // icon='element-12'
          >
            <SidebarMenuItem
              to='/projectreports'
              icon='setting'
              title='Reports'
              // fontIcon='circle'
            />
            <SidebarMenuItem to='/projectfeatures' icon='setting' title='Features' />
            <SidebarMenuItem to='/invoicefeatures' icon='setting' title='Invoice' />
          </SidebarMenuItemWithSub>
          <SidebarMenuItemWithSub
            to='/dashboard'
            title='Sales'
            // fontIcon='element'
            // icon='element-12'
          ></SidebarMenuItemWithSub>
          <SidebarMenuItemWithSub
            to='/dashboard'
            title='Procurement'
            // fontIcon='element'
            // icon='element-12'
          ></SidebarMenuItemWithSub>
          <SidebarMenuItemWithSub
            to='/dashboard'
            title='Inventory'
            // fontIcon='element'
            // icon='element-12'
          >
            <SidebarMenuItem to='/product-type' icon='setting' title='Product Type' />
          </SidebarMenuItemWithSub>
          <SidebarMenuItemWithSub
            to='/dashboard'
            title='Maintenance'
            // fontIcon='element'
            // icon='element-12'
          >
            <SidebarMenuItem
              to='/repair-codes'
              title='Reason for Repair Codes'
              // fontIcon='element'
              icon='setting'
            />
            {/* Repair priority class codes */}
            <SidebarMenuItem
              to='/priority-class-codes'
              title='Repair Priority Class Codes'
              // fontIcon='element'
              icon='setting'
            />
            {/* system codes */}
            <SidebarMenuItem
              to='/system-codes'
              title='System Codes'
              // fontIcon='element'
              icon='setting'
            />
          </SidebarMenuItemWithSub>
          <SidebarMenuItemWithSub
            to='/dashboard'
            title='HRM'
            // fontIcon='element'
            // icon='element-12'
          >
            <SidebarMenuItem
              to='/reports'
              title='Reports'
              // fontIcon='element'
              icon='setting'
            />
            <SidebarMenuItem
              to='/attendance'
              title='Attendance'
              // fontIcon='element'
              icon='setting'
            />
          </SidebarMenuItemWithSub>
          <SidebarMenuItemWithSub
            to='/dashboard'
            title='Accounts'
            // fontIcon='element'
            // icon='element-12'
          ></SidebarMenuItemWithSub>
          <div className='menu-item'>
            <div className='menu-content pt-8 pb-2'>
              <span className='menu-section text-muted text-uppercase fs-8 ls-1'>
                Customisation
              </span>
            </div>
          </div>
          <SidebarMenuItem
            to='/customisation'
            // icon='element-4'
            title='Customisation'
            // fontIcon='bi-layers'
          />
          <SidebarMenuItem
            to='/dashboard'
            // icon='element-4'
            title='Reminder Setting'
            // fontIcon='bi-layers'
          />
          <SidebarMenuItem
            to='/dashboard'
            // icon='element-4'
            title='Notifications Settings'
            // fontIcon='bi-layers'
          />
          <div className='menu-item'>
            <div className='menu-content pt-8 pb-2'>
              <span className='menu-section text-muted text-uppercase fs-8 ls-1'>
                General Settings
              </span>
            </div>
          </div>
          <SidebarMenuItem
            to='/dashboard'
            // icon='element-4'
            title='Automations'
            // fontIcon='bi-layers'
          />
          <SidebarMenuItem
            to='/dashboard'
            // icon='element-4'
            title='Integrations'
            // fontIcon='bi-layers'
          />
          <SidebarMenuItem
            to='/dashboard'
            // icon='element-4'
            title='Payment Settings'
            // fontIcon='bi-layers'
          />
          <SidebarMenuItem
            to='/dashboard'
            // icon='element-4'
            title='System Backup'
            // fontIcon='bi-layers'
          />
          <SidebarMenuItem
            to='/supportCenter'
            // icon='element-4'
            title='Support Center'
            // fontIcon='bi-layers'
          />
          <SidebarMenuItem
            to='/dashboard'
            // icon='element-4'
            title='Help(Docs)'
            // fontIcon='bi-layers'
          />
        </>
      ) : (
        <>
          {/* <SidebarMenuItem to='/docs' icon='switch' title='Documentation' fontIcon='bi-layers' /> */}
          {currentUser?.profileType?.toLowerCase()?.includes('admin') && (
            <SidebarMenuItem
              to='/dashboards'
              icon='switch'
              title='Dashboard'
              fontIcon='bi-layers'
            />
          )}

          {/* Project */}
          {mainModule === 'project' && currentUser?.app?.project && (
            <>
              {(permissions?.myproject?.read || currentUser?.profileType === 'superadmin') && (
                <>
                  <SidebarMenuItem
                    to='/project-dashboard'
                    icon='element-4'
                    title='Dashboard'
                    fontIcon='bi-app-indicator'
                  />
                  {(permissions?.myproject?.read || currentUser?.profileType === 'superadmin') && (
                    <SidebarMenuItem
                      to='/project/projectlist'
                      icon='element-7'
                      title='My Project'
                      fontIcon='bi-layers'
                    />
                  )}
                  {(permissions?.documents?.read || currentUser?.profileType === 'superadmin') && (
                    <SidebarMenuItem
                      to='/project/documents'
                      icon='element-7'
                      title='Documents'
                      fontIcon='bi-layers'
                    />
                  )}
                  {(permissions?.projectcalendar?.read ||
                    currentUser?.profileType === 'superadmin') && (
                    <SidebarMenuItem
                      to='/project/calendar/calendar'
                      icon='element-7'
                      title='Calendar'
                      fontIcon='bi-layers'
                    />
                  )}

                  {(permissions?.projectanalytics?.read ||
                    currentUser?.profileType === 'superadmin') && (
                    <SidebarMenuItem
                      to='/project/performance'
                      icon='element-7'
                      title='Performance'
                      fontIcon='bi-layers'
                    />
                  )}

                  {(currentUser.profileType === 'superadmin' ||
                    permissions.projectinvoice?.read) && (
                    <SidebarMenuItemWithSub
                      to=''
                      title='Invoice'
                      fontIcon='element'
                      icon='element-12'
                    >
                      {(currentUser?.invoiceFeatures.vat ||
                        currentUser?.profileType === 'superadmin') && (
                        <SidebarMenuItem
                          to='/project/invoice/vat'
                          icon='switch'
                          title='VAT Invoice'
                          fontIcon='bi-layers'
                        />
                      )}
                      {(currentUser.invoiceFeatures.summary ||
                        currentUser?.profileType === 'superadmin') && (
                        <SidebarMenuItem
                          to='/project/invoice/summary'
                          icon='switch'
                          title='Summary Invoice'
                          fontIcon='bi-layers'
                        />
                      )}
                      {(currentUser.invoiceFeatures.perfoma ||
                        currentUser?.profileType === 'superadmin') && (
                        <SidebarMenuItem
                          to='/project/invoice/perfoma'
                          icon='switch'
                          title='Perfoma Invoice'
                          fontIcon='bi-layers'
                        />
                      )}
                    </SidebarMenuItemWithSub>
                  )}

                  <SidebarMenuItem
                    to='/project/reports'
                    icon='element-7'
                    title='Reports'
                    fontIcon='bi-layers'
                  />
                </>
              )}
            </>
          )}

          {/* Inventory */}
          {mainModule === 'inventory' && currentUser?.app?.inventory && (
            <>
              {
                <>
                  <SidebarMenuItem
                    to='/inventory/dashboard'
                    icon='element-7'
                    title='Dashboard'
                    fontIcon='bi-layers'
                  />
                  {(permissions?.category?.read || currentUser?.profileType === 'superadmin') && (
                    <SidebarMenuItem
                      to='/category'
                      icon='switch'
                      title='Category'
                      fontIcon='bi-layers'
                    />
                  )}
                  {(permissions?.products?.read || currentUser?.profileType === 'superadmin') &&
                    currentUser?.inventoryFeatures?.goods && (
                      <SidebarMenuItem
                        to='/product/goods'
                        icon='element-7'
                        title='Goods'
                        fontIcon='bi-layers'
                      />
                    )}
                  {(permissions?.items?.read || currentUser?.profileType === 'superadmin') &&
                    currentUser?.inventoryFeatures?.service && (
                      <SidebarMenuItem
                        to='/items'
                        icon='switch'
                        title='Services'
                        fontIcon='bi-layers'
                      />
                    )}
                  {(permissions?.products?.read || currentUser?.profileType === 'superadmin') &&
                    currentUser?.inventoryFeatures?.rentals && (
                      <SidebarMenuItem
                        to='/product/rentals'
                        icon='element-7'
                        title='Rentals'
                        fontIcon='bi-layers'
                      />
                    )}
                  {(permissions?.products?.read || currentUser?.profileType === 'superadmin') &&
                    currentUser?.inventoryFeatures?.consumables && (
                      <SidebarMenuItem
                        to='/product/consumables'
                        icon='element-7'
                        title='Consumables'
                        fontIcon='bi-layers'
                      />
                    )}
                  {(permissions?.products?.read || currentUser?.profileType === 'superadmin') &&
                    currentUser?.inventoryFeatures?.materials && (
                      <SidebarMenuItem
                        to='/product/materials'
                        icon='element-7'
                        title='Materials'
                        fontIcon='bi-layers'
                      />
                    )}
                  {(permissions?.inventoryadjustments?.read ||
                    currentUser?.profileType === 'superadmin') && (
                    <SidebarMenuItem
                      to='/inventory/adjustments'
                      icon='switch'
                      title='Inventory Adjustments'
                      fontIcon='bi-layers'
                    />
                  )}
                  {/* {(permissions?.inventorytracking?.read ||
                    currentUser?.profileType === 'superadmin') && (
                    <SidebarMenuItem
                      to='/error/404'
                      icon='switch'
                      title='Inventory Tracking'
                      fontIcon='bi-layers'
                    />
                  )} */}
                </>
              }
            </>
          )}

          {/* HRM */}
          {mainModule === 'hrm' && currentUser?.app?.hrm && (
            <>
              {
                <>
                  <SidebarMenuItem
                    to='/hrm/dashboard'
                    icon='element-7'
                    title='Dashboard'
                    fontIcon='bi-layers'
                  />
                  {(permissions?.payroll?.read || currentUser?.profileType === 'superadmin') && (
                    <SidebarMenuItem
                      to='/hrm/payroll'
                      icon='switch'
                      title='Payroll'
                      fontIcon='bi-layers'
                    />
                  )}
                  {(permissions?.employee?.read || currentUser?.profileType === 'superadmin') && (
                    <SidebarMenuItem
                      to='/hrm/empDepartment'
                      icon='switch'
                      title='Department'
                      fontIcon='bi-layers'
                    />
                  )}
                  {(permissions?.employee?.read || currentUser?.profileType === 'superadmin') && (
                    <SidebarMenuItem
                      to='/hrm/employee'
                      icon='switch'
                      title='Employees'
                      fontIcon='bi-layers'
                    />
                  )}
                  {(permissions?.attendance?.read || currentUser?.profileType === 'superadmin') && (
                    <SidebarMenuItem
                      to='/hrm/attendance'
                      icon='switch'
                      title='Attendance'
                      fontIcon='bi-layers'
                    />
                  )}
                  {(permissions?.timetracker?.read ||
                    currentUser?.profileType === 'superadmin') && (
                    <SidebarMenuItem
                      to='/hrm/time-tracker'
                      icon='switch'
                      title='Time Tracker'
                      fontIcon='bi-layers'
                    />
                  )}
                  {(permissions?.leavemanagement?.read ||
                    currentUser?.profileType === 'superadmin') && (
                    <SidebarMenuItem
                      to='/hrm/leaveManagement'
                      icon='switch'
                      title='Leave Management'
                      fontIcon='bi-layers'
                    />
                  )}
                  {(permissions?.performance?.read ||
                    currentUser?.profileType === 'superadmin') && (
                    <SidebarMenuItem
                      to='/hrm/performance'
                      icon='switch'
                      title='Performance'
                      fontIcon='bi-layers'
                    />
                  )}
                  {(permissions?.hrmanalytics?.read ||
                    currentUser?.profileType === 'superadmin') && (
                    <SidebarMenuItem
                      to='/hrm/submissionReports'
                      icon='switch'
                      title='Reports'
                      fontIcon='bi-layers'
                    />
                  )}
                  {(permissions?.hrmdocuments?.read ||
                    currentUser?.profileType === 'superadmin') && (
                    <SidebarMenuItem
                      to='/hrm/documents'
                      icon='switch'
                      title='Documents'
                      fontIcon='bi-layers'
                    />
                  )}
                </>
              }
            </>
          )}

          {/* Maintenance */}
          {mainModule === 'maintenance' && currentUser?.app?.maintenance && (
            <>
              {(permissions?.maintenance?.read || currentUser?.profileType === 'superadmin') && (
                <SidebarMenuItem to='' icon='element-7' title='Dashboard' fontIcon='bi-layers' />
              )}
              {(permissions?.vehicle?.read || currentUser?.profileType === 'superadmin') && (
                <SidebarMenuItem to='/vehicle' icon='switch' title='Vehicle' fontIcon='bi-layers' />
              )}
              {(permissions?.equipment?.read || currentUser?.profileType === 'superadmin') && (
                <SidebarMenuItem
                  to='/equipment'
                  icon='switch'
                  title='Equipment'
                  fontIcon='bi-layers'
                />
              )}
              {(permissions?.inspection?.read || currentUser?.profileType === 'superadmin') && (
                <SidebarMenuItemWithSub
                  to=''
                  title='Inspection'
                  fontIcon='element'
                  icon='element-12'
                >
                  <SidebarMenuItem
                    to='/inspection/history'
                    icon='switch'
                    title='Inspection history'
                    fontIcon='bi-layers'
                  />
                  <SidebarMenuItem
                    to='/inspection/item-failure'
                    icon='switch'
                    title='Item Failure'
                    fontIcon='bi-layers'
                  />
                  {/* <SidebarMenuItem
                    to='/inspection/schedule'
                    icon='switch'
                    title='Schedule'
                    fontIcon='bi-layers'
                  /> */}
                  {(permissions?.inspectionforms?.read ||
                    currentUser?.profileType === 'superadmin') && (
                    <SidebarMenuItem
                      to='/inspection/forms'
                      icon='switch'
                      title='Forms'
                      fontIcon='bi-layers'
                    />
                  )}
                </SidebarMenuItemWithSub>
              )}
              {(permissions?.issues?.read || currentUser?.profileType === 'superadmin') && (
                <SidebarMenuItemWithSub to='' title='Issues' fontIcon='element' icon='element-12'>
                  <SidebarMenuItem
                    to='/issues/issue-list'
                    icon='switch'
                    title='Issues'
                    fontIcon='bi-layers'
                  />
                  {/* <SidebarMenuItem
                    to='/issues/faults'
                    icon='switch'
                    title='Faults'
                    fontIcon='bi-layers'
                  />
                  <SidebarMenuItem
                    to='/issues/recalls'
                    icon='switch'
                    title='Recalls'
                    fontIcon='bi-layers'
                  /> */}
                </SidebarMenuItemWithSub>
              )}
              {(permissions?.reminders?.read || currentUser?.profileType === 'superadmin') && (
                <SidebarMenuItemWithSub
                  to='/reminders'
                  title='Reminders'
                  fontIcon='element'
                  icon='element-12'
                >
                  <SidebarMenuItem
                    to='/reminders/service'
                    icon='switch'
                    title='Service Reminders'
                    fontIcon='bi-layers'
                  />
                  <SidebarMenuItem
                    to='/reminders/vehicle-renewals'
                    icon='switch'
                    title='Vehicle Renewals'
                    fontIcon='bi-layers'
                  />
                </SidebarMenuItemWithSub>
              )}
              {(permissions?.maintenanceservice?.read ||
                currentUser?.profileType === 'superadmin') && (
                <SidebarMenuItemWithSub to='' title='Service' fontIcon='element' icon='element-12'>
                  <SidebarMenuItem
                    to='/service/history'
                    icon='switch'
                    title='Service history'
                    fontIcon='bi-layers'
                  />
                  {(permissions?.workorder?.read || currentUser?.profileType === 'superadmin') && (
                    <SidebarMenuItem
                      to='/maintenance/work-order'
                      icon='switch'
                      title='Work Order'
                      fontIcon='bi-layers'
                    />
                  )}
                  <SidebarMenuItem
                    to='/maintenance/tasks'
                    icon='switch'
                    title='Service Tasks'
                    fontIcon='bi-layers'
                  />
                </SidebarMenuItemWithSub>
              )}
              {(permissions?.parts?.read || currentUser?.profileType === 'superadmin') && (
                <SidebarMenuItem to='/parts' icon='switch' title='Inventory' fontIcon='bi-layers' />
              )}
              {(permissions?.maintenancedocuments?.read ||
                currentUser?.profileType === 'superadmin') && (
                <SidebarMenuItem
                  to='/maintenance/documents'
                  icon='switch'
                  title='Documents'
                  fontIcon='bi-layers'
                />
              )}
              {(permissions?.maintenancereports?.read ||
                currentUser?.profileType === 'superadmin') && (
                <SidebarMenuItem
                  to='/error/404'
                  icon='switch'
                  title='Reports'
                  fontIcon='bi-layers'
                />
              )}
            </>
          )}

          {/* Sales */}
          {mainModule === 'sales' && currentUser?.app?.sales && (
            <>
              {
                <>
                  {(permissions?.customers?.read || currentUser?.profileType === 'superadmin') && (
                    <SidebarMenuItem
                      to='/sales/customer'
                      icon='element-7'
                      title='Customers'
                      fontIcon='bi-layers'
                    />
                  )}
                  {(permissions?.salesordermanagement?.read ||
                    currentUser?.profileType === 'superadmin') && (
                    <SidebarMenuItem
                      to='/sales/order-management'
                      icon='switch'
                      title='Order Management'
                      fontIcon='bi-layers'
                    />
                  )}
                  {(permissions?.quotation?.read || currentUser?.profileType === 'superadmin') && (
                    <SidebarMenuItem
                      to='/sales/quotes'
                      icon='switch'
                      title='Quotation'
                      fontIcon='bi-layers'
                    />
                  )}
                  {(permissions?.salesorder?.read || currentUser?.profileType === 'superadmin') && (
                    <SidebarMenuItem
                      to='/sales/salesorders'
                      icon='switch'
                      title='Sales Orders'
                      fontIcon='bi-layers'
                    />
                  )}
                  {(permissions?.deliverynote?.read ||
                    currentUser?.profileType === 'superadmin') && (
                    <SidebarMenuItem
                      to='/sales/deliveryChallan'
                      icon='switch'
                      title='Delivery Note'
                      fontIcon='bi-layers'
                    />
                  )}
                  {(permissions?.invoice?.read || currentUser?.profileType === 'superadmin') && (
                    <SidebarMenuItem
                      to='/sales/invoice'
                      icon='switch'
                      title='Invoice '
                      fontIcon='bi-layers'
                    />
                  )}
                  {(permissions?.paymentreceived?.read ||
                    currentUser?.profileType === 'superadmin') && (
                    <SidebarMenuItem
                      to='/sales/paymentReceived'
                      icon='switch'
                      title='Payment Receipt'
                      fontIcon='bi-layers'
                    />
                  )}
                  {(permissions?.salesapproval?.read ||
                    currentUser?.profileType === 'superadmin') && (
                    <SidebarMenuItem
                      to='sales/salesperformance'
                      icon='switch'
                      title='Performance'
                      fontIcon='bi-layers'
                    />
                  )}
                  {(permissions?.salesapproval?.read ||
                    currentUser?.profileType === 'superadmin') && (
                    <SidebarMenuItem
                      to='sales/approval'
                      icon='switch'
                      title='Approvals'
                      fontIcon='bi-layers'
                    />
                  )}
                </>
              }
            </>
          )}

          {/* Procurement */}
          {mainModule === 'procurement' && currentUser?.app?.procurement && (
            <>
              {
                <>
                  {(permissions?.vendors?.read || currentUser?.profileType === 'superadmin') && (
                    <SidebarMenuItem
                      to='/procurement/vendor'
                      icon='element-7'
                      title='Vendors'
                      fontIcon='bi-layers'
                    />
                  )}
                  {(permissions?.procurementordermanagement?.read ||
                    currentUser?.profileType === 'superadmin') && (
                    <SidebarMenuItem
                      to='/procurement/order-management'
                      icon='switch'
                      title='Order Management'
                      fontIcon='bi-layers'
                    />
                  )}
                  {(permissions?.purchaserequest?.read ||
                    currentUser?.profileType === 'superadmin') && (
                    <SidebarMenuItem
                      to='/procurement/purchaserequest'
                      icon='switch'
                      title='Purchase Request'
                      fontIcon='bi-layers'
                    />
                  )}
                  {(permissions?.rfq?.read || currentUser?.profileType === 'superadmin') && (
                    <SidebarMenuItem
                      to='/procurement/rfq'
                      icon='switch'
                      title='RFQ'
                      fontIcon='bi-layers'
                    />
                  )}
                  {(permissions?.purchaseorder?.read ||
                    currentUser?.profileType === 'superadmin') && (
                    <SidebarMenuItem
                      to='/procurement/purchaseOrdersList'
                      icon='switch'
                      title='Purchase Order'
                      fontIcon='bi-layers'
                    />
                  )}
                  {(permissions?.purchasereceives?.read ||
                    currentUser?.profileType === 'superadmin') && (
                    <SidebarMenuItem
                      to='/procurement/purchaseReceiveList'
                      icon='switch'
                      title='Purchase Receives'
                      fontIcon='bi-layers'
                    />
                  )}
                  {(permissions?.bills?.read || currentUser?.profileType === 'superadmin') && (
                    <SidebarMenuItem
                      to='/procurement/billsList'
                      icon='switch'
                      title='Bills'
                      fontIcon='bi-layers'
                    />
                  )}
                  {(permissions?.paymentmade?.read ||
                    currentUser?.profileType === 'superadmin') && (
                    <SidebarMenuItem
                      to='/procurement/paymentMadeList'
                      icon='switch'
                      title='Payment Made'
                      fontIcon='bi-layers'
                    />
                  )}
                  {/* <SidebarMenuItem to='/hrm/hrm3' icon='switch' title='Vendor Credit' fontIcon='bi-layers' /> */}

                  {(permissions?.procurementapproval?.read ||
                    currentUser?.profileType === 'superadmin') && (
                    <SidebarMenuItem
                      to='/procurement/performance'
                      icon='switch'
                      title='Performance'
                      fontIcon='bi-layers'
                    />
                  )}

                  {(permissions?.procurementapproval?.read ||
                    currentUser?.profileType === 'superadmin') && (
                    <SidebarMenuItem
                      to='/procurement/approval'
                      icon='switch'
                      title='Approvals'
                      fontIcon='bi-layers'
                    />
                  )}
                </>
              }
            </>
          )}

          {/* Accounts */}
          {mainModule === 'accounts' && currentUser?.app?.accounts && (
            <>
              {
                <>
                  <SidebarMenuItem
                    to='/account/dashboard'
                    icon='element-7'
                    title='Dashboard'
                    fontIcon='bi-layers'
                  />
                  {(permissions?.expenses?.read || currentUser?.profileType === 'superadmin') && (
                    <SidebarMenuItem
                      to='/account/expenses'
                      icon='switch'
                      title='Expenses'
                      fontIcon='bi-layers'
                    />
                  )}
                  {(permissions?.accountant?.read ||
                    permissions?.journal?.read ||
                    currentUser?.profileType === 'superadmin') && (
                    <SidebarMenuItemWithSub
                      to=''
                      title='Accountant'
                      fontIcon='element'
                      icon='element-12'
                    >
                      {(permissions?.journal?.read ||
                        currentUser?.profileType === 'superadmin') && (
                        <SidebarMenuItem
                          to='/account/journals'
                          icon='switch'
                          title='Journal Entry'
                          fontIcon='bi-layers'
                        />
                      )}
                      {(permissions?.accountant?.read ||
                        currentUser?.profileType === 'superadmin') && (
                        <SidebarMenuItem
                          to='/account/chartofaccounts'
                          icon='switch'
                          title='Chart of accounts'
                          fontIcon='bi-layers'
                        />
                      )}
                      {(permissions?.accountant?.read ||
                        currentUser?.profileType === 'superadmin') && (
                        <SidebarMenuItem
                          to='/account/categoryofaccounts'
                          icon='switch'
                          title='Category of accounts'
                          fontIcon='bi-layers'
                        />
                      )}
                    </SidebarMenuItemWithSub>
                  )}
                  {(permissions?.reports?.read || currentUser?.profileType === 'superadmin') && (
                    <SidebarMenuItem
                      to='/account/reports'
                      icon='switch'
                      title='Reports'
                      fontIcon='bi-layers'
                    />
                  )}
                  {(permissions?.accountsdocuments?.read ||
                    currentUser?.profileType === 'superadmin') && (
                    <SidebarMenuItem
                      to='/account/documents'
                      icon='switch'
                      title='Documents'
                      fontIcon='bi-layers'
                    />
                  )}
                </>
              }
            </>
          )}

          <div className='menu-item'>
            <div className='menu-content pt-8 pb-2'>
              <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Apps</span>
            </div>
          </div>
          {mainModule !== 'project' && currentUser?.app?.project && (
            <>
              {(permissions?.myproject?.read ||
                permissions?.documents?.read ||
                permissions?.projectcalendar?.read ||
                permissions?.projectanalytics?.read ||
                currentUser?.profileType === 'superadmin') && (
                <SidebarMenuItemWithSub
                  to='/project'
                  title='Projects'
                  fontIcon='element'
                  icon='element-12'
                >
                  <SidebarMenuItem
                    to='/project-dashboard'
                    icon='element-4'
                    title='Dashboard'
                    fontIcon='bi-app-indicator'
                  />
                  {(permissions?.myproject?.read || currentUser?.profileType === 'superadmin') && (
                    <SidebarMenuItem
                      to='/project/projectlist'
                      icon='element-7'
                      title='My Project'
                      fontIcon='bi-layers'
                    />
                  )}
                  {(permissions?.documents?.read || currentUser?.profileType === 'superadmin') && (
                    <SidebarMenuItem
                      to='/project/documents'
                      icon='element-7'
                      title='Documents'
                      fontIcon='bi-layers'
                    />
                  )}
                  {(permissions?.projectcalendar?.read ||
                    currentUser?.profileType === 'superadmin') && (
                    <SidebarMenuItem
                      to='/project/calendar/calendar'
                      icon='element-7'
                      title='Calendar'
                      fontIcon='bi-layers'
                    />
                  )}

                  {(permissions?.projectanalytics?.read ||
                    currentUser?.profileType === 'superadmin') && (
                    <SidebarMenuItem
                      to='/project/performance'
                      icon='element-7'
                      title='Performance'
                      fontIcon='bi-layers'
                    />
                  )}

                  {(currentUser.profileType === 'superadmin' ||
                    permissions.projectinvoice?.read) && (
                    <SidebarMenuItemWithSub
                      to=''
                      title='Invoice'
                      fontIcon='element'
                      icon='element-12'
                    >
                      {(currentUser?.invoiceFeatures.vat ||
                        currentUser?.profileType === 'superadmin') && (
                        <SidebarMenuItem
                          to='/project/invoice/vat'
                          icon='switch'
                          title='VAT Invoice'
                          fontIcon='bi-layers'
                        />
                      )}
                      {(currentUser.invoiceFeatures.summary ||
                        currentUser?.profileType === 'superadmin') && (
                        <SidebarMenuItem
                          to='/project/invoice/summary'
                          icon='switch'
                          title='Summary Invoice'
                          fontIcon='bi-layers'
                        />
                      )}
                      {(currentUser.invoiceFeatures.perfoma ||
                        currentUser?.profileType === 'superadmin') && (
                        <SidebarMenuItem
                          to='/project/invoice/perfoma'
                          icon='switch'
                          title='Perfoma Invoice'
                          fontIcon='bi-layers'
                        />
                      )}
                    </SidebarMenuItemWithSub>
                  )}

                  <SidebarMenuItem
                    to='/project/reports'
                    icon='element-7'
                    title='Reports'
                    fontIcon='bi-layers'
                  />
                </SidebarMenuItemWithSub>
              )}
            </>
          )}

          {mainModule !== 'inventory' && currentUser?.app?.inventory && (
            <>
              {(permissions?.products?.read ||
                permissions?.items?.read ||
                permissions?.inventoryadjustments?.read ||
                permissions?.inventorytracking?.read ||
                permissions?.category?.read ||
                currentUser?.profileType === 'superadmin') && (
                <SidebarMenuItemWithSub
                  to=''
                  title='Inventory'
                  fontIcon='element'
                  icon='element-12'
                >
                  <SidebarMenuItem
                    to='/inventory/dashboard'
                    icon='element-7'
                    title='Dashboard'
                    fontIcon='bi-layers'
                  />
                  {(permissions?.category?.read || currentUser?.profileType === 'superadmin') && (
                    <SidebarMenuItem
                      to='/category'
                      icon='switch'
                      title='Category'
                      fontIcon='bi-layers'
                    />
                  )}
                  {(permissions?.products?.read || currentUser?.profileType === 'superadmin') &&
                    currentUser?.inventoryFeatures?.goods && (
                      <SidebarMenuItem
                        to='/product/goods'
                        icon='element-7'
                        title='Goods'
                        fontIcon='bi-layers'
                      />
                    )}
                  {(permissions?.items?.read || currentUser?.profileType === 'superadmin') &&
                    currentUser?.inventoryFeatures?.service && (
                      <SidebarMenuItem
                        to='/items'
                        icon='switch'
                        title='Services'
                        fontIcon='bi-layers'
                      />
                    )}
                  {(permissions?.products?.read || currentUser?.profileType === 'superadmin') &&
                    currentUser?.inventoryFeatures?.rentals && (
                      <SidebarMenuItem
                        to='/product/rentals'
                        icon='element-7'
                        title='Rentals'
                        fontIcon='bi-layers'
                      />
                    )}
                  {(permissions?.products?.read || currentUser?.profileType === 'superadmin') &&
                    currentUser?.inventoryFeatures?.consumables && (
                      <SidebarMenuItem
                        to='/product/consumables'
                        icon='element-7'
                        title='Consumables'
                        fontIcon='bi-layers'
                      />
                    )}
                  {(permissions?.products?.read || currentUser?.profileType === 'superadmin') &&
                    currentUser?.inventoryFeatures?.materials && (
                      <SidebarMenuItem
                        to='/product/materials'
                        icon='element-7'
                        title='Materials'
                        fontIcon='bi-layers'
                      />
                    )}
                  {(permissions?.inventoryadjustments?.read ||
                    currentUser?.profileType === 'superadmin') && (
                    <SidebarMenuItem
                      to='/inventory/adjustments'
                      icon='switch'
                      title='Inventory Adjustments'
                      fontIcon='bi-layers'
                    />
                  )}
                  {/* {(permissions?.inventorytracking?.read ||
                    currentUser?.profileType === 'superadmin') && (
                    <SidebarMenuItem
                      to='/error/404'
                      icon='switch'
                      title='Inventory Tracking'
                      fontIcon='bi-layers'
                    />
                  )} */}
                </SidebarMenuItemWithSub>
              )}
            </>
          )}

          {mainModule !== 'hrm' && currentUser?.app?.hrm && (
            <>
              {(permissions?.employee?.read ||
                permissions?.leavemanagement?.read ||
                permissions?.attendance?.read ||
                permissions?.payroll?.read ||
                permissions?.timetracker?.read ||
                permissions?.hrmanalytics?.read ||
                permissions?.performance?.read ||
                permissions?.hrmdocuments?.read ||
                currentUser?.profileType === 'superadmin') && (
                <SidebarMenuItemWithSub to='' title='HRM' fontIcon='element' icon='element-12'>
                  <SidebarMenuItem
                    to='/hrm/dashboard'
                    icon='element-7'
                    title='Dashboard'
                    fontIcon='bi-layers'
                  />
                  {(permissions?.payroll?.read || currentUser?.profileType === 'superadmin') && (
                    <SidebarMenuItem
                      to='/hrm/payroll'
                      icon='switch'
                      title='Payroll'
                      fontIcon='bi-layers'
                    />
                  )}
                  {(permissions?.employee?.read || currentUser?.profileType === 'superadmin') && (
                    <SidebarMenuItem
                      to='/hrm/empDepartment'
                      icon='switch'
                      title='Department'
                      fontIcon='bi-layers'
                    />
                  )}
                  {(permissions?.employee?.read || currentUser?.profileType === 'superadmin') && (
                    <SidebarMenuItem
                      to='/hrm/employee'
                      icon='switch'
                      title='Employees'
                      fontIcon='bi-layers'
                    />
                  )}
                  {(permissions?.attendance?.read || currentUser?.profileType === 'superadmin') && (
                    <SidebarMenuItem
                      to='/hrm/attendance'
                      icon='switch'
                      title='Attendance'
                      fontIcon='bi-layers'
                    />
                  )}
                  {(permissions?.timetracker?.read ||
                    currentUser?.profileType === 'superadmin') && (
                    <SidebarMenuItem
                      to='/hrm/time-tracker'
                      icon='switch'
                      title='Time Tracker'
                      fontIcon='bi-layers'
                    />
                  )}
                  {(permissions?.leavemanagement?.read ||
                    currentUser?.profileType === 'superadmin') && (
                    <SidebarMenuItem
                      to='/hrm/leaveManagement'
                      icon='switch'
                      title='Leave Management'
                      fontIcon='bi-layers'
                    />
                  )}
                  {(permissions?.performance?.read ||
                    currentUser?.profileType === 'superadmin') && (
                    <SidebarMenuItem
                      to='/hrm/performance'
                      icon='switch'
                      title='Performance'
                      fontIcon='bi-layers'
                    />
                  )}
                  {(permissions?.hrmanalytics?.read ||
                    currentUser?.profileType === 'superadmin') && (
                    <SidebarMenuItem
                      to='/hrm/submissionReports'
                      icon='switch'
                      title='Reports'
                      fontIcon='bi-layers'
                    />
                  )}
                  {(permissions?.hrmdocuments?.read ||
                    currentUser?.profileType === 'superadmin') && (
                    <SidebarMenuItem
                      to='/hrm/documents'
                      icon='switch'
                      title='Documents'
                      fontIcon='bi-layers'
                    />
                  )}
                </SidebarMenuItemWithSub>
              )}
            </>
          )}

          {mainModule !== 'maintenance' && currentUser?.app?.maintenance && (
            <>
              {(permissions?.vehicle?.read ||
                permissions?.equipment?.read ||
                permissions?.inspection?.read ||
                permissions?.issues?.read ||
                permissions?.reminders?.read ||
                permissions?.maintenanceservice?.read ||
                permissions?.parts?.read ||
                permissions?.maintenancedocuments?.read ||
                permissions?.maintenancereports?.read ||
                currentUser?.profileType === 'superadmin') && (
                <SidebarMenuItemWithSub
                  to=''
                  title='Maintenance'
                  fontIcon='element'
                  icon='element-12'
                >
                  <SidebarMenuItem
                    to='/dashboard'
                    icon='element-7'
                    title='Dashboard'
                    fontIcon='bi-layers'
                  />
                  {(permissions?.vehicle?.read || currentUser?.profileType === 'superadmin') && (
                    <SidebarMenuItem
                      to='/vehicle'
                      icon='switch'
                      title='Vehicle'
                      fontIcon='bi-layers'
                    />
                  )}
                  {(permissions?.equipment?.read || currentUser?.profileType === 'superadmin') && (
                    <SidebarMenuItem
                      to='/equipment'
                      icon='switch'
                      title='Equipment'
                      fontIcon='bi-layers'
                    />
                  )}
                  {(permissions?.inspection?.read || currentUser?.profileType === 'superadmin') && (
                    <SidebarMenuItemWithSub
                      to=''
                      title='Inspection'
                      fontIcon='element'
                      icon='element-12'
                    >
                      <SidebarMenuItem
                        to='/inspection/history'
                        icon='switch'
                        title='Inspection history'
                        fontIcon='bi-layers'
                      />
                      <SidebarMenuItem
                        to='/inspection/item-failure'
                        icon='switch'
                        title='Item Failure'
                        fontIcon='bi-layers'
                      />
                      {/* <SidebarMenuItem
                        to='/inspection/schedule'
                        icon='switch'
                        title='Schedule'
                        fontIcon='bi-layers'
                      /> */}
                      {(permissions?.inspectionforms?.read ||
                        currentUser?.profileType === 'superadmin') && (
                        <SidebarMenuItem
                          to='/inspection/forms'
                          icon='switch'
                          title='Forms'
                          fontIcon='bi-layers'
                        />
                      )}
                    </SidebarMenuItemWithSub>
                  )}
                  {(permissions?.issues?.read || currentUser?.profileType === 'superadmin') && (
                    <SidebarMenuItemWithSub
                      to=''
                      title='Issues'
                      fontIcon='element'
                      icon='element-12'
                    >
                      <SidebarMenuItem
                        to='/issues/issue-list'
                        icon='switch'
                        title='Issues'
                        fontIcon='bi-layers'
                      />
                      {/* <SidebarMenuItem
                        to='/issues/faults'
                        icon='switch'
                        title='Faults'
                        fontIcon='bi-layers'
                      />
                      <SidebarMenuItem
                        to='/issues/recalls'
                        icon='switch'
                        title='Recalls'
                        fontIcon='bi-layers'
                      /> */}
                    </SidebarMenuItemWithSub>
                  )}
                  {(permissions?.reminders?.read || currentUser?.profileType === 'superadmin') && (
                    <SidebarMenuItemWithSub
                      to='/reminders'
                      title='Reminders'
                      fontIcon='element'
                      icon='element-12'
                    >
                      <SidebarMenuItem
                        to='/reminders/service'
                        icon='switch'
                        title='Service Reminders'
                        fontIcon='bi-layers'
                      />
                      <SidebarMenuItem
                        to='/reminders/vehicle-renewals'
                        icon='switch'
                        title='Vehicle Renewals'
                        fontIcon='bi-layers'
                      />
                    </SidebarMenuItemWithSub>
                  )}
                  {(permissions?.maintenanceservice?.read ||
                    currentUser?.profileType === 'superadmin') && (
                    <SidebarMenuItemWithSub
                      to=''
                      title='Service'
                      fontIcon='element'
                      icon='element-12'
                    >
                      <SidebarMenuItem
                        to='/service/history'
                        icon='switch'
                        title='Service history'
                        fontIcon='bi-layers'
                      />
                      {(permissions?.workorder?.read ||
                        currentUser?.profileType === 'superadmin') && (
                        <SidebarMenuItem
                          to='/maintenance/work-order'
                          icon='switch'
                          title='Work Order'
                          fontIcon='bi-layers'
                        />
                      )}
                      <SidebarMenuItem
                        to='/maintenance/tasks'
                        icon='switch'
                        title='Service Tasks'
                        fontIcon='bi-layers'
                      />
                    </SidebarMenuItemWithSub>
                  )}
                  {(permissions?.parts?.read || currentUser?.profileType === 'superadmin') && (
                    <SidebarMenuItem
                      to='/parts'
                      icon='switch'
                      title='Inventory'
                      fontIcon='bi-layers'
                    />
                  )}
                  {(permissions?.maintenancedocuments?.read ||
                    currentUser?.profileType === 'superadmin') && (
                    <SidebarMenuItem
                      to='/maintenance/documents'
                      icon='switch'
                      title='Documents'
                      fontIcon='bi-layers'
                    />
                  )}
                  {(permissions?.maintenancereports?.read ||
                    currentUser?.profileType === 'superadmin') && (
                    <SidebarMenuItem
                      to='/error/404'
                      icon='switch'
                      title='Reports'
                      fontIcon='bi-layers'
                    />
                  )}
                </SidebarMenuItemWithSub>
              )}
            </>
          )}

          {mainModule !== 'sales' && currentUser?.app?.sales && (
            <>
              {(permissions?.quotation?.read ||
                permissions?.invoice?.read ||
                permissions?.salesorder?.read ||
                permissions?.deliverynote?.read ||
                permissions?.paymentreceived?.read ||
                permissions?.salesapproval?.read ||
                permissions?.customers?.read ||
                permissions?.salesordermanagement?.read ||
                currentUser?.profileType === 'superadmin') && (
                <SidebarMenuItemWithSub
                  to='/sales'
                  title='Sales'
                  fontIcon='element'
                  icon='element-12'
                >
                  {(permissions?.customers?.read || currentUser?.profileType === 'superadmin') && (
                    <SidebarMenuItem
                      to='/sales/customer'
                      icon='element-7'
                      title='Customers'
                      fontIcon='bi-layers'
                    />
                  )}
                  {(permissions?.salesordermanagement?.read ||
                    currentUser?.profileType === 'superadmin') && (
                    <SidebarMenuItem
                      to='/sales/order-management'
                      icon='switch'
                      title='Order Management'
                      fontIcon='bi-layers'
                    />
                  )}
                  {(permissions?.quotation?.read || currentUser?.profileType === 'superadmin') && (
                    <SidebarMenuItem
                      to='/sales/quotes'
                      icon='switch'
                      title='Quotation'
                      fontIcon='bi-layers'
                    />
                  )}
                  {(permissions?.salesorder?.read || currentUser?.profileType === 'superadmin') && (
                    <SidebarMenuItem
                      to='/sales/salesorders'
                      icon='switch'
                      title='Sales Orders'
                      fontIcon='bi-layers'
                    />
                  )}
                  {(permissions?.deliverynote?.read ||
                    currentUser?.profileType === 'superadmin') && (
                    <SidebarMenuItem
                      to='/sales/deliveryChallan'
                      icon='switch'
                      title='Delivery Note'
                      fontIcon='bi-layers'
                    />
                  )}
                  {(permissions?.invoice?.read || currentUser?.profileType === 'superadmin') && (
                    <SidebarMenuItem
                      to='/sales/invoice'
                      icon='switch'
                      title='Invoice '
                      fontIcon='bi-layers'
                    />
                  )}
                  {(permissions?.paymentreceived?.read ||
                    currentUser?.profileType === 'superadmin') && (
                    <SidebarMenuItem
                      to='/sales/paymentReceived'
                      icon='switch'
                      title='Payment Receipt'
                      fontIcon='bi-layers'
                    />
                  )}
                  {(permissions?.salesapproval?.read ||
                    currentUser?.profileType === 'superadmin') && (
                    <SidebarMenuItem
                      to='sales/salesperformance'
                      icon='switch'
                      title='Performance'
                      fontIcon='bi-layers'
                    />
                  )}
                  {(permissions?.salesapproval?.read ||
                    currentUser?.profileType === 'superadmin') && (
                    <SidebarMenuItem
                      to='sales/approval'
                      icon='switch'
                      title='Approvals'
                      fontIcon='bi-layers'
                    />
                  )}
                </SidebarMenuItemWithSub>
              )}
            </>
          )}

          {mainModule !== 'procurement' && currentUser?.app?.procurement && (
            <>
              {(permissions?.purchaseorder?.read ||
                permissions?.purchasereceives?.read ||
                permissions?.bills?.read ||
                permissions?.paymentmade?.read ||
                permissions?.vendors?.read ||
                permissions?.rfq?.read ||
                permissions?.purchaserequest?.read ||
                permissions?.procurementapproval?.read ||
                permissions?.procurementordermanagement?.read ||
                currentUser?.profileType === 'superadmin') && (
                <SidebarMenuItemWithSub
                  to='/procurement'
                  title='Procurement'
                  fontIcon='element'
                  icon='element-12'
                >
                  {(permissions?.vendors?.read || currentUser?.profileType === 'superadmin') && (
                    <SidebarMenuItem
                      to='/procurement/vendor'
                      icon='element-7'
                      title='Vendors'
                      fontIcon='bi-layers'
                    />
                  )}
                  {(permissions?.procurementordermanagement?.read ||
                    currentUser?.profileType === 'superadmin') && (
                    <SidebarMenuItem
                      to='/procurement/order-management'
                      icon='switch'
                      title='Order Management'
                      fontIcon='bi-layers'
                    />
                  )}
                  {(permissions?.purchaserequest?.read ||
                    currentUser?.profileType === 'superadmin') && (
                    <SidebarMenuItem
                      to='/procurement/purchaserequest'
                      icon='switch'
                      title='Purchase Request'
                      fontIcon='bi-layers'
                    />
                  )}
                  {(permissions?.rfq?.read || currentUser?.profileType === 'superadmin') && (
                    <SidebarMenuItem
                      to='/procurement/rfq'
                      icon='switch'
                      title='RFQ'
                      fontIcon='bi-layers'
                    />
                  )}
                  {(permissions?.purchaseorder?.read ||
                    currentUser?.profileType === 'superadmin') && (
                    <SidebarMenuItem
                      to='/procurement/purchaseOrdersList'
                      icon='switch'
                      title='Purchase Order'
                      fontIcon='bi-layers'
                    />
                  )}
                  {(permissions?.purchasereceives?.read ||
                    currentUser?.profileType === 'superadmin') && (
                    <SidebarMenuItem
                      to='/procurement/purchaseReceiveList'
                      icon='switch'
                      title='Purchase Receives'
                      fontIcon='bi-layers'
                    />
                  )}
                  {(permissions?.bills?.read || currentUser?.profileType === 'superadmin') && (
                    <SidebarMenuItem
                      to='/procurement/billsList'
                      icon='switch'
                      title='Bills'
                      fontIcon='bi-layers'
                    />
                  )}
                  {(permissions?.paymentmade?.read ||
                    currentUser?.profileType === 'superadmin') && (
                    <SidebarMenuItem
                      to='/procurement/paymentMadeList'
                      icon='switch'
                      title='Payment Made'
                      fontIcon='bi-layers'
                    />
                  )}
                  {/* <SidebarMenuItem to='/hrm/hrm3' icon='switch' title='Vendor Credit' fontIcon='bi-layers' /> */}

                  {(permissions?.procurementapproval?.read ||
                    currentUser?.profileType === 'superadmin') && (
                    <SidebarMenuItem
                      to='/procurement/performance'
                      icon='switch'
                      title='Performance'
                      fontIcon='bi-layers'
                    />
                  )}

                  {(permissions?.procurementapproval?.read ||
                    currentUser?.profileType === 'superadmin') && (
                    <SidebarMenuItem
                      to='/procurement/approval'
                      icon='switch'
                      title='Approvals'
                      fontIcon='bi-layers'
                    />
                  )}
                </SidebarMenuItemWithSub>
              )}
            </>
          )}

          {mainModule !== 'accounts' && currentUser?.app?.accounts && (
            <>
              {(permissions?.expenses?.read ||
                permissions?.accountant?.read ||
                permissions?.journal?.read ||
                permissions?.reports?.read ||
                permissions?.accountsdocuments?.read ||
                currentUser?.profileType === 'superadmin') && (
                <SidebarMenuItemWithSub to='' title='Accounts' fontIcon='element' icon='element-12'>
                  <SidebarMenuItem
                    to='/account/dashboard'
                    icon='element-7'
                    title='Dashboard'
                    fontIcon='bi-layers'
                  />
                  {(permissions?.expenses?.read || currentUser?.profileType === 'superadmin') && (
                    <SidebarMenuItem
                      to='/account/expenses'
                      icon='switch'
                      title='Expenses'
                      fontIcon='bi-layers'
                    />
                  )}
                  {(permissions?.accountant?.read ||
                    permissions?.journal?.read ||
                    currentUser?.profileType === 'superadmin') && (
                    <SidebarMenuItemWithSub
                      to=''
                      title='Accountant'
                      fontIcon='element'
                      icon='element-12'
                    >
                      {(permissions?.journal?.read ||
                        currentUser?.profileType === 'superadmin') && (
                        <SidebarMenuItem
                          to='/account/journals'
                          icon='switch'
                          title='Journal Entry'
                          fontIcon='bi-layers'
                        />
                      )}
                      {(permissions?.accountant?.read ||
                        currentUser?.profileType === 'superadmin') && (
                        <SidebarMenuItem
                          to='/account/chartofaccounts'
                          icon='switch'
                          title='Chart of accounts'
                          fontIcon='bi-layers'
                        />
                      )}
                      {(permissions?.accountant?.read ||
                        currentUser?.profileType === 'superadmin') && (
                        <SidebarMenuItem
                          to='/account/categoryofaccounts'
                          icon='switch'
                          title='Category of accounts'
                          fontIcon='bi-layers'
                        />
                      )}
                    </SidebarMenuItemWithSub>
                  )}
                  {(permissions?.reports?.read || currentUser?.profileType === 'superadmin') && (
                    <SidebarMenuItem
                      to='/account/reports'
                      icon='switch'
                      title='Reports'
                      fontIcon='bi-layers'
                    />
                  )}
                  {(permissions?.accountsdocuments?.read ||
                    currentUser?.profileType === 'superadmin') && (
                    <SidebarMenuItem
                      to='/account/documents'
                      icon='switch'
                      title='Documents'
                      fontIcon='bi-layers'
                    />
                  )}
                </SidebarMenuItemWithSub>
              )}
            </>
          )}

          {/* <SidebarMenuItem
        to='/StoreAnalytics '
        icon='element-7'
        title='StoreAnalytics'
        fontIcon='bi-layers'
      /> */}

          {/* <SidebarMenuItemWithSub
        to='/marketing'
        title='Marketing'
        fontIcon='element'
        icon='element-12'
      >
        <SidebarMenuItem
          to='/marketing/marketing1'
          icon='element-7'
          title='Marketing Dashboard'
          fontIcon='bi-layers'
        />
        <SidebarMenuItem
          to='/marketing/marketing2'
          icon='switch'
          title='Marketing2'
          fontIcon='bi-layers'
        />
        <SidebarMenuItem
          to='/marketing/marketing3'
          icon='switch'
          title='Marketing3'
          fontIcon='bi-layers'
        />
      </SidebarMenuItemWithSub> */}

          <div className='menu-item'>
            <div className='menu-content pt-8 pb-2'>
              <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Tools</span>
            </div>
          </div>

          {(permissions?.calendar?.read || currentUser?.profileType === 'superadmin') && (
            <SidebarMenuItem to='/calendar' icon='switch' title='Calendar' fontIcon='bi-layers' />
          )}
          {(permissions?.tasks?.read || currentUser?.profileType === 'superadmin') && (
            <SidebarMenuItem to='/tasks' icon='switch' title='Tasks' fontIcon='bi-layers' />
          )}
          {(permissions?.meetings?.read || currentUser?.profileType === 'superadmin') && (
            <SidebarMenuItem to='/meetings' icon='switch' title='Meetings' fontIcon='bi-layers' />
          )}
          {(permissions?.sprint?.read || currentUser?.profileType === 'superadmin') && (
            <SidebarMenuItem to='/sprint' icon='switch' title='Sprint' fontIcon='bi-layers' />
          )}
          {(permissions?.events?.read || currentUser?.profileType === 'superadmin') && (
            <SidebarMenuItem to='/event' icon='switch' title='Events' fontIcon='bi-layers' />
          )}
          {(permissions?.storage?.read || currentUser?.profileType === 'superadmin') && (
            <SidebarMenuItem to='/storage' icon='switch' title='Storage' fontIcon='bi-layers' />
          )}
          {(permissions?.report?.read || currentUser?.profileType === 'superadmin') && (
            <SidebarMenuItem to='/report' icon='switch' title='Reports' fontIcon='bi-layers' />
          )}

          {currentUser?.profileType !== 'superadmin' &&
            currentUser?.profileType?.toLowerCase()?.includes('admin') && (
              <div className='menu-item'>
                <div className='menu-content pt-8 pb-2'>
                  <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Admin</span>
                </div>
              </div>
            )}

          {/* show only if the profiletype has a keyword admin */}
          {currentUser?.profileType !== 'superadmin' &&
            currentUser?.profileType?.toLowerCase()?.includes('admin') && (
              <SidebarMenuItem
                to='/user-management/users'
                icon='abstract-28'
                title='User management'
                fontIcon='bi-layers'
              />
            )}

          {currentUser?.profileType === 'superadmin' && (
            <div className='menu-item'>
              <div className='menu-content pt-8 pb-2'>
                <span className='menu-section text-muted text-uppercase fs-8 ls-1'>
                  Super Admin
                </span>
              </div>
            </div>
          )}

          {currentUser?.profileType === 'superadmin' && (
            <>
              <SidebarMenuItem
                to='/superuser-management'
                icon='element-7'
                title='User Management'
                fontIcon='element'
              />
              <SidebarMenuItem
                to='/role-management'
                title='Role Management'
                icon='element-7'
                fontIcon='element'
              />
              <SidebarMenuItem
                to='/organization-management'
                title='Organization'
                icon='element-7'
                fontIcon='element'
              />
              <SidebarMenuItem
                to='/department/department-list'
                icon='element-7'
                title='Department'
                fontIcon='element'
              />
              <SidebarMenuItem
                to='/modules/module-list'
                icon='element-7'
                title='Modules'
                fontIcon='element'
              />
            </>
          )}

          {/* <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Admin</span>
        </div>
      </div>
      <SidebarMenuItem to='/user-management/users' icon='abstract-28' title='User management' fontIcon='bi-layers'/>
 
      <SidebarMenuItem
        to='/dashboard'
        icon='element-11'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        fontIcon='bi-app-indicator'
      />
      <SidebarMenuItem to='/builder' icon='switch' title='Layout Builder' fontIcon='bi-layers' />
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Crafted</span>
        </div>
      </div>
      <SidebarMenuItemWithSub
        to='/crafted/pages'
        title='Pages'
        fontIcon='bi-archive'
        icon='element-plus'
      >
        <SidebarMenuItemWithSub to='/crafted/pages/profile' title='Profile' hasBullet={true}>
          <SidebarMenuItem to='/crafted/pages/profile/overview' title='Overview' hasBullet={true} />
          <SidebarMenuItem to='/crafted/pages/profile/projects' title='Projects' hasBullet={true} />
          <SidebarMenuItem
            to='/crafted/pages/profile/campaigns'
            title='Campaigns'
            hasBullet={true}
          />
          <SidebarMenuItem
            to='/crafted/pages/profile/documents'
            title='Documents'
            hasBullet={true}
          />
          <SidebarMenuItem
            to='/crafted/pages/profile/connections'
            title='Connections'
            hasBullet={true}
          />
        </SidebarMenuItemWithSub>

        <SidebarMenuItemWithSub to='/crafted/pages/wizards' title='Wizards' hasBullet={true}>
          <SidebarMenuItem
            to='/crafted/pages/wizards/horizontal'
            title='Horizontal'
            hasBullet={true}
          />
          <SidebarMenuItem to='/crafted/pages/wizards/vertical' title='Vertical' hasBullet={true} />
        </SidebarMenuItemWithSub>
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub
        to='/crafted/accounts'
        title='Accounts'
        icon='profile-circle'
        fontIcon='bi-person'
      >
        <SidebarMenuItem to='/crafted/account/overview' title='Overview' hasBullet={true} />
        <SidebarMenuItem to='/crafted/account/settings' title='Settings' hasBullet={true} />
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub to='/error' title='Errors' fontIcon='bi-sticky' icon='cross-circle'>
        <SidebarMenuItem to='/error/404' title='Error 404' hasBullet={true} />
        <SidebarMenuItem to='/error/500' title='Error 500' hasBullet={true} />
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub
        to='/crafted/widgets'
        title='Widgets'
        icon='element-7'
        fontIcon='bi-layers'
      >
        <SidebarMenuItem to='/crafted/widgets/lists' title='Lists' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/statistics' title='Statistics' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/charts' title='Charts' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/mixed' title='Mixed' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/tables' title='Tables' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/feeds' title='Feeds' hasBullet={true} />
      </SidebarMenuItemWithSub>
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Apps</span>
        </div>
      </div>
      <SidebarMenuItemWithSub
        to='/apps/chat'
        title='Chat'
        fontIcon='bi-chat-left'
        icon='message-text-2'
      >
        <SidebarMenuItem to='/apps/chat/private-chat' title='Private Chat' hasBullet={true} />
        <SidebarMenuItem to='/apps/chat/group-chat' title='Group Chart' hasBullet={true} />
        <SidebarMenuItem to='/apps/chat/drawer-chat' title='Drawer Chart' hasBullet={true} />
      </SidebarMenuItemWithSub>
      <SidebarMenuItem
        to='/apps/user-management/users'
        icon='abstract-28'
        title='User management'
        fontIcon='bi-layers'
      />
      <div className='menu-item'>
        <a
          target='_blank'
          className='menu-link'
          href={process.env.REACT_APP_PREVIEW_DOCS_URL + '/docs/changelog'}
        >
          <span className='menu-icon'>
            <KTIcon iconName='code' className='fs-2' />
          </span>
          <span className='menu-title'>Changelog {process.env.REACT_APP_VERSION}</span>
        </a>
      </div> */}
        </>
      )}
    </>
  )
}

export {SidebarMenuMain}
