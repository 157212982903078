import React, {useEffect, useState} from 'react'
import {KTIcon} from '../../../../_metronic/helpers'
import {MenuComponent} from '../../../../_metronic/assets/ts/components'
import {ValidFilter} from '../Core/_model'
import {useAuth} from '../../../modules/auth'
import {getOrdersWithProject} from '../Core/_requests'

type StatusOption = {
  value: string
  label: string
}

type Props = {
  searchFilters: ValidFilter
  setSearchFilter: (updateValues: ValidFilter) => void
  setValidityFilter?: (isValid: boolean) => void
  setApprovalFilter: (approval: string) => void
  setStatusFilter?: (status: string) => void
  getDocsList: () => void
  handlereset: () => void
  showStatusFilter?: boolean
  statusOptions?: StatusOption[]
}

const Filter2: React.FC<Props> = ({
  handlereset,
  setSearchFilter,
  searchFilters,
  setValidityFilter,
  getDocsList,
  showStatusFilter = true,
  statusOptions = [],
}) => {
  const {currentUser} = useAuth()
  const [valid, setValid] = useState('valid')
  const [status, setStatus] = useState('')
  const [approval, setApproval] = useState('')
  const [order, setOrder] = useState('')
  const [orderList, setOrderList] = useState([])

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  useEffect(() => {
    const getOrderWithProject = async () => {
      try {
        const res = await getOrdersWithProject(currentUser?.organization)
        setOrderList(res)
      } catch (error) {
        console.error('Error fetching client list:', error)
      }
    }

    if (currentUser.organization !== undefined) getOrderWithProject()
  }, [currentUser])

  useEffect(() => {
    setSearchFilter({
      filter: {
        isValid: valid === 'valid',
        status: status,
        approval: approval,
        order: order,
      },
    })
  }, [valid, status, approval, order])

  const handleValidityChange = (e) => {
    const isValid = e.target.value === 'valid'
    setValid(isValid ? 'valid' : 'invalid')
  }

  const handleStatusChange = (e) => {
    setStatus(e.target.value)
  }

  const handleApprovalChange = (e) => {
    setApproval(e.target.value)
  }

  const handleOrderChange = (e) => {
    setOrder(e.target.value)
  }

  return (
    <>
      <div
        className='btn btn-light-primary me-3'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
        data-kt-menu-flip='top-end'
      >
        <KTIcon iconName='filter' className='fs-2' />
        Filter
      </div>
      <div
        className='menu menu-sub menu-sub-dropdown w-400px w-md-400px p-5'
        data-kt-menu='true'
        style={{zIndex: 1000000}}
      >
        <div className='px-7 py-2'>
          <div className='fs-5 text-dark fw-bolder'>Filter Options</div>
        </div>
        <div className='separator border-gray-200'></div>
        <div className='px-7 py-5' data-kt-user-table-filter='form'>
          <div className='mb-5 d-flex flex-row align-content-center justify-content-center'>
            <label className='form-label fs-6 fw-bold mt-2 w-100px me-5'>Validity:</label>
            <select
              className='form-select form-select-solid fw-bolder w-75'
              onChange={handleValidityChange}
              // value={searchFilters.filter.isActive ? 'active' : 'inactive'}
              value={valid}
            >
              <option value='valid'>Valid</option>
              <option value='invalid'>Invalid</option>
            </select>
          </div>

          {showStatusFilter && ( // Render status filter only if showStatusFilter is true
            <div className='mb-5 d-flex flex-row align-content-center justify-content-center'>
              <label className='form-label fs-6 fw-bold mt-2 w-100px me-5'>Status:</label>
              <select
                className='form-select form-select-solid fw-bolder w-75'
                onChange={handleStatusChange}
                value={status}
              >
                <option value=''>All</option>
                {statusOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>
          )}

          <div className='mb-5 d-flex flex-row align-content-center justify-content-center'>
            <label className='form-label fs-6 fw-bold mt-2 w-100px me-5'>Approval:</label>
            <select
              className='form-select form-select-solid fw-bolder w-75'
              onChange={handleApprovalChange}
              value={approval}
            >
              <option value=''>All</option>
              <option value='pending'>Pending</option>
              <option value='accepted'>Accepted</option>
              <option value='rejected'>Rejected</option>
            </select>
          </div>

          <div className='mb-5 d-flex flex-row align-content-center justify-content-center'>
            <label className='form-label fs-6 fw-bold mt-2 w-100px me-5'>Project Order:</label>
            <select
              className='form-select form-select-solid fw-bolder w-75'
              onChange={handleOrderChange}
              value={order}
            >
              <option value=''>All</option>
              {orderList?.map((item, index) => (
                <option value={item?._id} key={item?._id}>
                  {item?.orderName}
                </option>
              ))}
            </select>
          </div>

          <div className='d-flex justify-content-end'>
            <button
              type='button'
              // disabled={isLoading}
              // onClick={filterData}
              className='btn btn-light btn-light-danger fw-bold me-2 px-6'
              data-kt-menu-dismiss='true'
              data-kt-user-table-filter='reset'
              onClick={handlereset}
            >
              Reset
            </button>
            <button
              type='button'
              // disabled={isLoading}
              // onClick={filterData}
              className='btn btn-light btn-light-primary fw-bold me-2 px-6'
              onClick={getDocsList}
            >
              Apply
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

export default Filter2
